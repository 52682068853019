import React, { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { useReactForm, VestingTaskHooks } from '../../../hooks';
import { StringKey } from '../../../lang';
import { SharePlanType, sharePlanTypeTitle } from '../../../types/pool-plans.types';
import { StepOne } from './StepOne';
import { FormSchema, formSchema } from './validation';

export type ExerciseFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  type: SharePlanType;
  sharePlanId: string;
};

export const ExerciseForm: FC<ExerciseFormProps> = ({
  isOpen,
  companyId,
  handleClose,
  invalidateQuery,
  type,
}) => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });
  const { request } = VestingTaskHooks.useRequestExercise();

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
  }, [reset, handleClose]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne } = data;

      // const rsa =
      //   type === SharePlanType.RSA &&
      //   stepOne.rsa?.rsaItems &&
      //   stepOne.rsa?.rsaItems.length > 0 &&
      //   stepOne.rsa?.rsaItems.map(({ exercised, grant }) => ({
      //     vestingTaskId: grant.id,
      //     value: exercised,
      //     filesLinks: [],
      //   }));

      const stock =
        type === SharePlanType.STOCK_OPTIONS &&
        stepOne.commonStock?.stockItems &&
        stepOne.commonStock.stockItems.length > 0 &&
        stepOne.commonStock?.stockItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised,
          filesLinks: [],
        }));

      const warrantExercise =
        type === SharePlanType.WARRANTS &&
        stepOne.warrantExercise?.warrantExerciseItems &&
        stepOne.warrantExercise.warrantExerciseItems.length > 0 &&
        stepOne.warrantExercise?.warrantExerciseItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised,
          filesLinks: [],
        }));

      const sar =
        type === SharePlanType.SAR &&
        stepOne.sar?.sarItems &&
        stepOne.sar?.sarItems.length > 0 &&
        stepOne.sar?.sarItems.map(({ sarCount, grant, sarPayout }) => ({
          vestingTaskId: grant.id,
          value: sarCount,
          payout: sarPayout,
          filesLinks: [],
        }));

      const hurdle =
        type === SharePlanType.GROWTH_HURDLE &&
        stepOne.hurdle?.hurdleItems &&
        stepOne.hurdle?.hurdleItems.length > 0 &&
        stepOne.hurdle?.hurdleItems
          .filter(({ selected }) => selected)
          .map(({ issuedShares, grantId: vestingTaskId }) => ({
            vestingTaskId,
            value: issuedShares,
            filesLinks: [],
          }));

      const bspce =
        type === SharePlanType.BSPCE &&
        stepOne.bspce?.bspceItems &&
        stepOne.bspce?.bspceItems.length > 0 &&
        stepOne.bspce?.bspceItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised * (grant?.grantItem?.plan?.conversionRatio || 1),
          filesLinks: [],
        }));

      const exerciseData = stock || warrantExercise || sar || hurdle || bspce;
      if (exerciseData) {
        request(
          {
            companyId,
            data: exerciseData[0],
          },
          {
            onSuccess: () => {
              toast.success(<AppFormattedMessage id={StringKey.EXERCISE_REQUESTED} />);
              invalidateQuery();
              handleCloseModal();
            },
          },
        );
      }
    },
    [companyId, request, handleCloseModal, invalidateQuery, type],
  );

  return (
    <Sheet open={isOpen}>
      <SheetContent
        className={twMerge(
          'h-fit max-h-[800px] w-[90%] max-w-[1422px] border-transparent bg-transparent p-2 shadow-none',
          type === SharePlanType.GROWTH_HURDLE && 'w-fit min-w-[490px]',
        )}
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-8 overflow-hidden rounded-2xl bg-gray-25 p-8">
          <div className="flex h-fit w-full items-center justify-between">
            <span className="text-xl font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.REQUEST_EXERCISE} /> -{' '}
              {<AppFormattedMessage id={sharePlanTypeTitle[type]} />}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full max-h-[520px] flex-col overflow-y-auto">
            <StepOne
              clearErrors={clearErrors}
              companyId={companyId}
              control={control}
              errors={errors}
              filedState={getFieldState}
              formData={getValues}
              handleCloseModal={handleCloseModal}
              reset={reset}
              resetField={resetField}
              setError={setError}
              setFormData={setValue}
              submitForm={() => handleSubmit(submitHandler)()}
              type={type}
            />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
