import React, { FC } from 'react';

import { CheckIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { useFormat, useLocale } from '../../../hooks';
import { Locale, StringKey } from '../../../lang';
import {
  Plan,
  SubscriptionDuration,
  subscriptionDurationMap,
  SubscriptionType,
} from '../../../types/planTypes';
export type SubscriptionCardProps = {
  selected?: boolean;
  subscriptionExp: number;
} & Plan;

const SubscriptionCard: FC<SubscriptionCardProps> = ({
  duration,
  selected = false,
  type,
  localCurrency,
  localPrice,
}) => {
  const { locale } = useLocale();
  const { format } = useFormat(locale === Locale.FR ? (number) => `${number} du mois` : undefined);
  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <span className="text-sm font-[550] text-black">
            {type === SubscriptionType.GROWTH ? (
              <AppFormattedMessage id={StringKey.GROWTH} />
            ) : type === SubscriptionType.START_UP ? (
              <AppFormattedMessage id={StringKey.STARTUP} />
            ) : (
              <AppFormattedMessage id={StringKey.ENTERPRISE} />
            )}
          </span>
          {duration === SubscriptionDuration.ANNUALY && (
            <span className="text-nowrap rounded bg-forest-50 px-2 py-[2px] text-label-md font-[450] text-forest-600">
              <AppFormattedMessage
                id={selected ? StringKey.SAVED_APPROX_V2 : StringKey.SAVE_APPROX_V2}
              />
            </span>
          )}
        </div>
        {selected && (
          <div className="ml-3 flex size-[22px] items-center justify-center rounded-full bg-brand-700">
            <CheckIcon height={10} iconColor="#ffffff" width={10} />
          </div>
        )}
      </div>
      <div className="flex w-full items-center gap-2">
        <span className="text-nowrap text-4xl font-bold uppercase text-gray-700">
          {localPrice / 100} {localCurrency}
        </span>
        <span className="text-nowrap text-sm font-[550] lowercase text-gray-400">
          /<AppFormattedMessage id={subscriptionDurationMap[duration]} />
        </span>
      </div>
      <span className="w-full text-label-md font-medium text-gray-700">
        {duration === SubscriptionDuration.ANNUALY ? (
          <AppFormattedMessage id={StringKey.BILLED_ANNUALY} />
        ) : (
          <AppFormattedMessage
            id={StringKey.BILLED_MONTHLY}
            values={{
              date: format(new Date(2024, 7, 13), 'do'),
            }}
          />
        )}
      </span>
    </>
  );
};

SubscriptionCard.displayName = 'SubscriptionCard';

export default SubscriptionCard;
