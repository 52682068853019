import { Transition } from '@headlessui/react';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  ChevronDownIcon,
  CloseIcon,
  FileIcon,
  FolderIcon,
  PlusIcon,
  SearchIcon,
} from '../../assets/icons';
import { FileIconVariant } from '../../assets/icons/FileIcon';
import { FolderIconVariant } from '../../assets/icons/FolderIcon';
import { AlertDialogWrapper } from '../../components/AlertDialog';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { QueryKey } from '../../constants';
import {
  useDebounce,
  useInvalidateQueries,
  useModalState,
  useSelectedCompany,
  VaultHooks,
} from '../../hooks';
import { StringKey } from '../../lang';
import { StakeholderRole } from '../../types/stakeholderTypes';
import {
  VaultFolder,
  VaultOrder,
  VaultSelect,
  VaultSelectVariant,
  VaultSortBy,
  VaultTab,
} from '../../types/vault.types';
import { getTranslation } from '../../utils/getTranslation';
import { MobileBreadcrumbs } from './Breadcrumbs';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { EmptyVault } from './EmptyVault';
import { VaultMobile } from './Mobile';
import { AddFileModal, CreateModal } from './Modals';
import { VaultTable } from './Table/Table';

const Vault: FC = () => {
  const [selectedTab, setTab] = useState<VaultTab>(VaultTab.REPOSITORY);
  const [selected, setSelected] = useState<VaultSelectVariant>();
  const [search, setSearch] = useState<null | string>(null);
  const [{ field: sortBy, sortMode: orderBy }, setSortState] = useState<{
    field?: VaultSortBy;
    sortMode?: VaultOrder;
  }>({});

  const [selectedFolders, setSelectedFolders] = useState<{ id: string; name: string }[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(undefined);

  const { selectedCompany, selectedCompany: { roleInCompany } = {} } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';
  const isStakeholder = roleInCompany === StakeholderRole.STAKEHOLDER || false;

  const { create } = VaultHooks.useCreateFolder();
  const { deleteFolder } = VaultHooks.useDeleteFolder();
  const { deleteFile } = VaultHooks.useDeleteFile();
  const { renameFile } = VaultHooks.useRenameFile();
  const { renameFolder } = VaultHooks.useRenameFolder();
  const { mutateFolderExport } = VaultHooks.useFolderExport();

  const { folders, isLoading, refetch } = VaultHooks.useHardcodedRepository({
    companyId,
    currentPage: 1,
    search: search || '',
    sortBy,
    orderBy,
  });

  const {
    folders: openFolders,
    isLoading: isOpenLoading,
    refetch: refetchOpenRepository,
  } = VaultHooks.useOpenRepository({
    companyId,
    currentPage: 1,
    search: search || '',
    sortBy,
    orderBy,
    enabled: !isStakeholder && selectedTab === VaultTab.OPEN_DRIVE,
  });

  const {
    files: openFiles,
    isLoading: isOpenFilesLoading,
    refetch: refetchOpenFiles,
  } = VaultHooks.useOpenFiles({
    companyId,
    currentPage: 1,
    enabled: !isStakeholder && selectedTab === VaultTab.OPEN_DRIVE,
    search: search || '',
    sortBy,
    orderBy,
  });

  const {
    folder,
    isLoading: isFolderLoading,
    refetch: refetchVaultFolder,
  } = VaultHooks.useVaultFolder({
    companyId,
    folderId: selectedFolderId || '',
    search: search || '',
    sortBy,
    orderBy,
  });

  const data = selectedFolderId
    ? { folders: folder?.folders, files: folder?.files, uploadPermission: folder?.uploadPermission }
    : selectedTab === VaultTab.REPOSITORY
      ? { folders, files: [] }
      : { folders: openFolders, files: openFiles };

  const shouldShowAddFilesButton =
    data?.uploadPermission &&
    selectedTab === VaultTab.REPOSITORY &&
    roleInCompany !== StakeholderRole.STAKEHOLDER;

  const { invalidateQuery } = useInvalidateQueries(
    QueryKey.GET_HARDCODED_REPOSITORY,
    QueryKey.GET_OPEN_REPOSITORY,
    QueryKey.GET_VAULT_FOLDER,
    QueryKey.GET_OPEN_FILES,
  );

  const handleFolderClick = (folder: VaultFolder) => {
    setSelectedFolderId(folder.id);
    setSelectedFolders((prev) => [...prev, { id: folder.id, name: folder.name }]);
  };

  const handleBreadcrumbClick = (index: number, id: string | undefined) => {
    setSelectedFolders((prev) => prev.slice(0, index + 1));
    setSelectedFolderId(id);
  };

  const { debounceCallback } = useDebounce({
    callback: () => {
      invalidateQuery();
    },
  });

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );

  const handleSortField = useCallback(
    (newField: VaultSortBy) => {
      setSortState((prevState) => {
        if (prevState.field === newField) {
          const nextSortMode = !prevState.sortMode
            ? VaultOrder.ASC
            : prevState.sortMode === VaultOrder.ASC
              ? VaultOrder.DESC
              : undefined;

          return { ...prevState, sortMode: nextSortMode };
        }
        return { field: newField, sortMode: VaultOrder.ASC };
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const handleSelect = (select: VaultSelectVariant) => {
    if (select === VaultSelect.FOLDER) handleOpenCreateModal(VaultSelect.FOLDER);
    if (select === VaultSelect.FILE) handleOpenAddFileModal();
    setSelected(select);
  };

  const handleDeleteFolder = (id: string) => {
    deleteFolder(
      { companyId, folderId: id },
      {
        onError: ({ body }) => toast.error(body?.message),
        onSuccess: () => {
          toast.success(getTranslation(StringKey.FOLDER_DELETED_SUCCESS));
          invalidateQuery();
        },
      },
    );
  };

  const handleDeleteFile = (id: string) => {
    deleteFile(
      { companyId, fileId: id },
      {
        onError: ({ body }) => toast.error(body?.message),
        onSuccess: () => {
          toast.success(getTranslation(StringKey.FILE_DELETED_SUCCESS));
          invalidateQuery();
        },
      },
    );
  };

  const handleRenameFile = (id: string, name: string, link?: string) => {
    renameFile(
      {
        companyId,
        fileId: id,
        data: {
          name: name,
          folderId: selectedFolderId || undefined,
          link,
        },
      },
      {
        onError: ({ body }) => toast.error(body?.message),
        onSuccess: () => {
          toast.success(getTranslation(StringKey.FILE_RENAMED_SUCCESS));
          invalidateQuery();
        },
      },
    );
  };

  const handleRenameFolder = (id: string, name: string) => {
    renameFolder(
      {
        companyId,
        folderId: id,
        data: {
          name: name,
          parentFolderId: selectedFolderId || undefined,
        },
      },
      {
        onError: ({ body }) => toast.error(body?.message),
        onSuccess: () => {
          toast.success(getTranslation(StringKey.FOLDER_RENAMED_SUCCESS));
          invalidateQuery();
        },
      },
    );
  };

  const handleDownloadFolder = (id: string) => {
    mutateFolderExport({
      folderId: id,
    });
  };

  const handleDownloadDocument = (link: string) => {
    window.open(link);
  };

  const {
    toggler: createModalToggler,
    isOpen: isOpenCreateModal,
    handleOpenModal: handleOpenCreateModal,
    handleCloseModal: handleCloseCreateModal,
    handleSuccessModal: handleSuccessCreateModal,
  } = useModalState<string | undefined, unknown, VaultSelect>({
    onSuccess: (name?: string) => {
      create(
        {
          companyId,
          data: {
            name: name || '',
            parentFolderId: selectedFolderId || undefined,
          },
        },
        {
          onError: ({ body }) => toast.error(body?.message),
          onSuccess: () => {
            toast.success(<AppFormattedMessage id={StringKey.FOLDER_CREATED_SUCCESSFULLY} />);
            invalidateQuery();
          },
        },
      );
    },
  });

  const {
    handleCloseModal: handleCloseAddFileModal,
    handleOpenModal: handleOpenAddFileModal,
    isOpen: isOpenAddFileModal,
  } = useModalState({});

  const folderNames = data?.folders?.map((folder: VaultFolder) => folder?.name?.toLowerCase());

  useEffect(() => {
    if (!search && companyId) {
      refetch();
      refetchOpenFiles();
      refetchOpenRepository();
      refetchVaultFolder();
    }
  }, [companyId, refetch, refetchOpenFiles, refetchOpenRepository, refetchVaultFolder, search]);

  if (isLoading || isFolderLoading || isOpenLoading || isOpenFilesLoading) return <Loader />;

  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: createModalToggler, open: isOpenCreateModal }}>
        <CreateModal
          folderNames={folderNames}
          onClose={handleCloseCreateModal}
          onSuccess={handleSuccessCreateModal}
        />
      </AlertDialogWrapper>

      <AddFileModal
        companyId={companyId}
        data={data}
        handleClose={handleCloseAddFileModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenAddFileModal}
        isOpenRepository={selectedTab === VaultTab.OPEN_DRIVE}
        selectedFolderId={selectedFolderId}
      />

      <div className="flex h-fit min-h-full w-full flex-col overflow-x-auto rounded-md lg:shadow-sm">
        {!isStakeholder && (
          <div className="flex w-full gap-4 border-b-[1px] border-gray-200 xlg:px-4 lg:pt-6">
            <Button
              className={twMerge(
                'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
                selectedTab === VaultTab.REPOSITORY && 'border-brand-700 font-[550] text-brand-700',
              )}
              onClick={() => {
                setTab(VaultTab.REPOSITORY);
                setSelectedFolderId(undefined);
                setSelectedFolders([]);
              }}
              styleType="NONE"
            >
              <AppFormattedMessage id={StringKey.REPOSITORY} />
            </Button>
            <Button
              className={twMerge(
                'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
                selectedTab === VaultTab.OPEN_DRIVE && 'border-brand-700 font-[550] text-brand-700',
              )}
              onClick={() => {
                setTab(VaultTab.OPEN_DRIVE);
                setSelectedFolderId(undefined);
                setSelectedFolders([]);
              }}
              styleType="NONE"
            >
              <AppFormattedMessage id={StringKey.OPEN_DRIVE} />
            </Button>
          </div>
        )}
        <div className="hidden w-full items-center justify-between border-b-[1px] border-gray-200 xlg:flex xlg:p-4">
          <Breadcrumbs
            onBreadcrumbClick={handleBreadcrumbClick}
            selectedFolders={selectedFolders}
          />
          <div className="flex w-fit items-center gap-3">
            <Input
              icon={search ? <CloseIcon /> : <SearchIcon />}
              onChange={handleSearch}
              onIconClick={() => {
                setTimeout(() => {
                  setSearch('');
                  invalidateQuery();
                }, 0);
              }}
              placeholder={search ? '' : <AppFormattedMessage id={StringKey.SEARCH} />}
              value={search || ''}
              wrapperClassName={'transition-all duration-1000 min-w-[303px] max-h-9'}
            />
            {shouldShowAddFilesButton && (
              <Button
                className="w-fit min-w-[113px] gap-1 text-nowrap p-2"
                onClick={handleOpenAddFileModal}
                styleType="BLUE_ROUNDED"
              >
                <PlusIcon />
                <AppFormattedMessage id={StringKey.ADD_FILES} />
              </Button>
            )}
            {selectedTab === VaultTab.OPEN_DRIVE && (
              <Select onChange={handleSelect} value={selected}>
                {({ open }) => (
                  <>
                    <div className="relative rounded border-gray-200 px-[6px]">
                      <SelectButton className="bg-brand-700">
                        <PlusIcon />
                        <span className="text-sm font-[450] text-white">
                          <AppFormattedMessage id={StringKey.ADD} />
                        </span>
                        <ChevronDownIcon className="ml-2 h-4 w-4" iconColor="#ffffff" />
                      </SelectButton>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={open}
                      >
                        <SelectOptions className="w-[115px]">
                          {selectedFolders.length < 7 && (
                            <SelectOption
                              className="flex items-center gap-[6px]"
                              key={VaultSelect.FOLDER}
                              selectedClassName="bg-white"
                              value={VaultSelect.FOLDER}
                            >
                              <FolderIcon
                                fill="#EAECF0"
                                iconColor="#344054"
                                variant={FolderIconVariant.FILLED}
                              />
                              <span className="block text-sm font-normal text-gray-700">
                                <AppFormattedMessage id={StringKey.FOLDER} />
                              </span>
                            </SelectOption>
                          )}
                          <SelectOption
                            className="flex items-center gap-[6px]"
                            key={VaultSelect.FILE}
                            selectedClassName="bg-white"
                            value={VaultSelect.FILE}
                          >
                            <FileIcon variant={FileIconVariant.FILLED} />
                            <span className="block text-sm font-normal text-gray-700">
                              <AppFormattedMessage id={StringKey.FILE} />
                            </span>
                          </SelectOption>
                        </SelectOptions>
                      </Transition>
                    </div>
                  </>
                )}
              </Select>
            )}
          </div>
        </div>
        <div className="flex flex-col pt-4 xlg:hidden">
          <Input
            icon={search ? <CloseIcon /> : <SearchIcon />}
            inputClassName="border-none"
            onChange={handleSearch}
            onIconClick={() => {
              setTimeout(() => {
                setSearch('');
                invalidateQuery();
              }, 0);
            }}
            placeholder={<AppFormattedMessage id={StringKey.SEARCH} />}
            value={search || ''}
            wrapperClassName={'transition-all duration-1000 w-full'}
          />
          <MobileBreadcrumbs
            onBreadcrumbClick={handleBreadcrumbClick}
            selectedFolders={selectedFolders}
          />
        </div>
        <VaultTable
          className="hidden xlg:block"
          data={data}
          handleDeleteFile={handleDeleteFile}
          handleDeleteFolder={handleDeleteFolder}
          handleDownloadDocument={handleDownloadDocument}
          handleDownloadFolder={handleDownloadFolder}
          handleFolderClick={handleFolderClick}
          handleRenameFile={handleRenameFile}
          handleRenameFolder={handleRenameFolder}
          onSortClick={handleSortField}
          searchValue={search || ''}
          selectedTab={selectedTab}
          sortField={sortBy}
          sortMode={orderBy}
        />
        <VaultMobile
          className="block xlg:hidden"
          data={data}
          handleDeleteFile={handleDeleteFile}
          handleDeleteFolder={handleDeleteFolder}
          handleDownloadDocument={handleDownloadDocument}
          handleDownloadFolder={handleDownloadFolder}
          handleFolderClick={handleFolderClick}
          handleRenameFile={handleRenameFile}
          handleRenameFolder={handleRenameFolder}
          onBackClick={handleBreadcrumbClick}
          selectedFolders={selectedFolders}
          selectedTab={selectedTab}
        />
        {data?.folders?.length === 0 &&
          data?.files?.length === 0 &&
          selectedTab == VaultTab.REPOSITORY && (
            <EmptyVault onAddFile={() => ''} onAddFolder={() => ''} selectedTab={selectedTab} />
          )}
        {data?.folders?.length === 0 &&
          data?.files?.length === 0 &&
          selectedTab == VaultTab.OPEN_DRIVE && (
            <EmptyVault
              folderButtonDisabled={selectedFolders.length >= 7}
              onAddFile={handleOpenAddFileModal}
              onAddFolder={handleOpenCreateModal}
              selectedTab={selectedTab}
            />
          )}
      </div>
    </>
  );
};

export default Vault;
