import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks, Stakeholder } from '../../../hooks';
import { activityNotificationBuilder, NotificationType } from '../../../types/notification.types';
import { StakeholderType, TerminationCause } from '../../../types/stakeholderTypes';
import Loader from '../../Loader';
import { ActivityNotification } from '../Notification';

export type ActivityTabProps = {
  companyId: string;
  unreadOnly: boolean;
  isAllRead: boolean;
  currencySymbol: string;
  handleCloseModal: () => void;
};

export const ActivityTab: FC<ActivityTabProps> = ({
  companyId,
  unreadOnly,
  isAllRead,
  currencySymbol,
  handleCloseModal,
}) => {
  const { notifications, fetchNextPage, hasNextPage, invalidateQuery } =
    NotificationHooks.useNotifications({
      companyId,
      resolved: false,
      type: NotificationType.ACTIVITY,
      unreadOnly,
    });
  const { invite } = Stakeholder.useInvitation();

  const { markAsRead } = NotificationHooks.useMarkRead({ companyId, onSuccess: invalidateQuery });

  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={notifications.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scroll-notifications-target"
    >
      {notifications.map(
        ({
          createdAt,
          is_read: isRead,
          id,
          action,
          company,
          adminId,
          adminName = '',
          stakeholderName = '',
          stakeholderType = StakeholderType.OTHER_INST,
          type,
          stakeholderId,
          terminationType,
          bulkCount,
          stakeholderTypeGroup,
          eventName,
          sharePlanType,
          sharesCount,
          grantId,
          fromName,
          totalInvestment,
          totalShares,
          sharePrice,
          preMoneyValuation,
          percentage,
          payout,
        }) => (
          <ActivityNotification
            createdAt={new Date(createdAt)}
            handleCloseModal={handleCloseModal}
            isRead={isAllRead || isRead}
            key={id}
            message={activityNotificationBuilder({
              action,
              adminName,
              stakeholderName,
              isAdminYou: adminId === company?.stakeholder?.id,
              stakeholderType,
              terminationType: terminationType || TerminationCause.RETIREMENT,
              currencySymbol,
              bulkCount,
              stakeholderTypeGroup,
              eventName,
              sharePlanType,
              sharesCount,
              grantId,
              fromName,
              totalInvestment,
              totalShares,
              sharePrice,
              preMoneyValuation,
              percentage,
              payout,
            })}
            onInviteUser={() =>
              stakeholderId && invite({ companyId, stakeholderId }, { onSuccess: invalidateQuery })
            }
            onMarkAsRead={() => markAsRead({ id, type })}
            type={action}
          />
        ),
      )}
    </InfiniteScroll>
  );
};
