import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { DropDown } from '../../../../components/Dropdown';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { FormInput, Input } from '../../../../components/Input';
import { Switch } from '../../../../components/Switch';
import { EventHooks, useFormat, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventTypeBackend, SortByType } from '../../../../types/events.types';
import { PageRoute } from '../../../../types/pageTypes';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import { EventCombobox } from '../Components';
import { defaultSharePrice, FormSchema } from '../validation';

export type FundraisingRoundStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  issuedShares: number;
  lockMode: boolean;
  handleCloseModal: () => void;
};

export const FundraisingRoundStepOne: FC<FundraisingRoundStepOneProps> = ({
  control,
  setFormData,
  companyId,
  issuedShares,
  lockMode,
  handleCloseModal,
}) => {
  const navigate = useNavigate();
  const { messagesLocale } = useLocale();
  const { format } = useFormat();
  const { stepOne, optionalStep } = useWatch<FormSchema>({ control });

  const { events } = EventHooks.useEvents({
    companyId,
    take: 1000,
    typesFilter: [SortByType.FUNDRAISING_ROUND],
  });

  const filteredFundraisingEvents = events.filter(
    (event) => event.type === EventTypeBackend.FUNDRAISING_ROUND,
  );

  const duplicatedEventId = filteredFundraisingEvents.find(
    (event) => event.valuation.id === stepOne?.eventDetails?.['fundraising-round']?.event?.id,
  )?.eventId;

  const handleOpenEditModal = () => {
    handleCloseModal();
    navigate(`${PageRoute.EVENTS}?openEditModal=true&selectedEventId=${duplicatedEventId}`, {
      replace: true,
    });
  };

  const defaultDate = new Date(+0);
  const event = stepOne?.eventDetails?.['fundraising-round']?.event;
  const preMoneyValuation =
    event?.sharePrice &&
    event?.sharePrice !== defaultSharePrice &&
    event?.issuedSharesOnStart !== undefined
      ? event?.sharePrice * event?.issuedSharesOnStart
      : event?.sharePrice && event?.sharePrice !== defaultSharePrice
        ? event?.sharePrice * issuedShares
        : 0;

  const latestDate = optionalStep?.['fundraising-round']?.investmentItems?.reduce<Date | null>(
    (latest, item) => {
      const currentDate = item.investmentDate;
      if (!latest || (currentDate && currentDate > latest)) {
        return currentDate || latest;
      }
      return latest;
    },
    null,
  );

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (isInitialLoad && stepOne?.eventDetails?.['fundraising-round']?.isOpen === undefined) {
      setFormData('stepOne.eventDetails.fundraising-round.isOpen', true);
    }
    setIsInitialLoad(false);
  }, [isInitialLoad, setFormData, stepOne?.eventDetails?.['fundraising-round']?.isOpen]);

  const [isSwitchChecked, setIsSwitchChecked] = useState(
    stepOne?.eventDetails?.['fundraising-round']?.isOpen ?? true,
  );

  useEffect(() => {
    setIsSwitchChecked(stepOne?.eventDetails?.['fundraising-round']?.isOpen ?? true);
  }, [stepOne?.eventDetails?.['fundraising-round']?.isOpen]);

  return (
    <div className="flex w-full flex-col gap-6">
      <div
        className={twMerge(
          'flex flex-col gap-3 rounded-md',
          isSwitchChecked
            ? 'border-[1px] border-gray-100 bg-gray-25 py-3'
            : 'bg-fireside-25 px-1 pb-1 pt-3',
        )}
      >
        <div className={twMerge('flex items-center gap-2', isSwitchChecked ? 'px-4' : 'px-3')}>
          <Switch
            checked={isSwitchChecked}
            onChange={(state) => {
              setIsSwitchChecked(state);
              setFormData('stepOne.eventDetails.fundraising-round.isOpen', state);
              setFormData('stepOne.eventDetails.fundraising-round.date', defaultDate);
            }}
          />
          <div className="flex flex-col gap-1">
            <span
              className={twMerge(
                'text-sm font-[550]',
                isSwitchChecked ? 'text-forest-600' : 'text-fireside-600',
              )}
            >
              {isSwitchChecked ? (
                <AppFormattedMessage id={StringKey.OPEN} />
              ) : (
                <AppFormattedMessage id={StringKey.CLOSED} />
              )}
            </span>
            <span className="text-xs font-[450] text-gray-500">
              <AppFormattedMessage
                id={StringKey.FUNDRAISING_ROUND_STATUS}
                values={{
                  state: isSwitchChecked
                    ? messagesLocale[StringKey.OPEN]
                    : messagesLocale[StringKey.CLOSED],
                }}
              />
            </span>
          </div>
        </div>
        {!isSwitchChecked && (
          <div className="rounded-md bg-white p-3 shadow-xs">
            <DatePicker
              calendar={{ fromDate: latestDate || undefined }}
              defaultMonth={stepOne?.eventDetails?.['fundraising-round']?.date || undefined}
              onSelect={(date) => setFormData(`stepOne.eventDetails.fundraising-round.date`, date)}
              value={stepOne?.eventDetails?.['fundraising-round']?.date}
              wrapperClassName="w-full"
            >
              <FormInput
                autoComplete="off"
                control={control}
                customValue={(value) => {
                  return value && new Date(value).getTime() !== defaultDate.getTime()
                    ? format(value, 'dd/MM/yyyy')
                    : '';
                }}
                disabledInput
                icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
                name={`stepOne.eventDetails.fundraising-round.date`}
                placeholder="Closing date"
                wrapperClassName="w-full"
              />
            </DatePicker>
          </div>
        )}
      </div>

      <div className="flex w-full flex-col gap-4">
        <EventCombobox
          companyId={companyId}
          control={control}
          disabled={lockMode && !stepOne?.eventDetails?.['fundraising-round']?.isOpen}
          fieldName={`stepOne.eventDetails.fundraising-round.event`}
          lockMode={lockMode}
          onlyWithSharePrice
        />
        {isSwitchChecked && (
          <DatePicker
            defaultMonth={stepOne?.eventDetails?.['fundraising-round']?.date || undefined}
            onSelect={(date) => setFormData(`stepOne.eventDetails.fundraising-round.date`, date)}
            value={stepOne?.eventDetails?.['fundraising-round']?.date}
            wrapperClassName="w-full"
          >
            <FormInput
              autoComplete="off"
              control={control}
              customValue={(value) => {
                return value && new Date(value).getTime() !== defaultDate.getTime()
                  ? format(value, 'dd/MM/yyyy')
                  : '';
              }}
              disabledInput
              icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
              name={`stepOne.eventDetails.fundraising-round.date`}
              placeholder={<AppFormattedMessage id={StringKey.OPEN_DATE} />}
              wrapperClassName="w-full"
            />
          </DatePicker>
        )}

        {!!duplicatedEventId && (
          <span className="rounded bg-fireside-50 p-3 text-xs font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.FUNDRAISING_ROUND_CONDITIONS} />
            <span
              className="cursor-pointer font-[550] text-brand-700 underline"
              onClick={handleOpenEditModal}
            >
              {' '}
              <AppFormattedMessage id={StringKey.CLICK_HERE} />
            </span>
            .
          </span>
        )}

        <FormInput
          control={control}
          currencySign
          customValue={(value) => {
            return value && Number(value) !== defaultSharePrice ? Math.round(value).toString() : '';
          }}
          disabled={
            stepOne?.eventDetails?.['fundraising-round']?.event?.id !== 'new' ||
            (lockMode && !stepOne?.eventDetails?.['fundraising-round']?.isOpen)
          }
          name={'stepOne.eventDetails.fundraising-round.event.sharePrice'}
          numberOnly
          placeholder={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
          shouldDisplayZero
          shouldFormatNumber
          wrapperClassName="w-full"
        />

        {stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice &&
          Number(stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice) === 0 && (
            <span className="rounded bg-fireside-50 p-3 text-xs font-[450] text-gray-700">
              Share price for fundraising round can not be zero
            </span>
          )}
      </div>
      <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 pb-1 pt-3">
        <div className="flex flex-col items-start gap-1 pl-[14px]">
          <div className="flex items-center gap-2">
            <span className="text-sm font-[550] text-brand-700">
              <AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />
            </span>
            <HoverCard>
              <HoverCardTrigger>
                <InfoCircle className="size-4 cursor-pointer" iconColor="#2565C8 " />
              </HoverCardTrigger>
              <HoverCardContent
                className="z-20 w-max rounded-lg bg-[#101828] px-3 pb-[6px] pt-1"
                isShownTriangle
                side="top"
                triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
              >
                <span className="text-label-md font-[550] text-white">
                  <AppFormattedMessage id={StringKey.CALCULATED_BY} />
                </span>
              </HoverCardContent>
            </HoverCard>
          </div>
          <span className="text-xs font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.ISSUED_SHARES} />:{' '}
            {(stepOne?.eventDetails?.['fundraising-round']?.event?.issuedSharesOnStart !== undefined
              ? stepOne?.eventDetails?.['fundraising-round']?.event?.issuedSharesOnStart
              : issuedShares
            )?.toLocaleString('en-US') || 0}
          </span>
        </div>
        <div className="flex flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
          <Input
            currencySign
            disabled
            onChange={() => {}}
            placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
            value={preMoneyValuation.toLocaleString('en-US')}
            wrapperClassName="w-full"
          />
          <DropDown
            answer="The Pre-Money Valuation is determined by multiplying the share price of the selected Round by the total number of issued shares at the time of the event. This value reflects the estimated value of the company before any new funding or investment is taken into account."
            className="rounded-lg bg-gray-50"
            question={<AppFormattedMessage id={StringKey.VALUE_CALCULATION_INFO} />}
          />
        </div>
      </div>
      <DropDown
        answer={[
          'A Fundraising Round event documents the process of securing investment from investors, resulting in the issuance of new shares and an influence of the capital for the company.',
          'Before adding a Fundraising Round please be sure to add a ”Valuation Event (for example Pre-Seed, Seed Round, Series A, etc) in order to associate the Fundraising Round to.',
          'Once all investments are complete and inputed onto CapQuest, be sure to “Close” the round using the toggle in this same page.',
        ]}
        className="rounded-lg bg-gray-50"
        question={<AppFormattedMessage id={StringKey.WHAT_IS_FUNDRAISING_ROUND} />}
      />
    </div>
  );
};
