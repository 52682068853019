import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

export const useHaveSafes = ({
  companyId,
  stakeholderId,
}: {
  companyId: string;
  stakeholderId: string;
}) => {
  const queryClient = useQueryClient();
  const { data: isHaveSafes, isLoading } = useAppQuery({
    queryKey: [QueryKey.IS_HAVE_SAFES, companyId, stakeholderId],
    queryFn: () =>
      ApiService.get<string>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'is-have-safes'],
      }),
    enabled: !!companyId && !!stakeholderId,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.IS_HAVE_SAFES] });
  };

  return {
    isHaveSafes: JSON.parse(isHaveSafes || 'false'),
    isLoading,
    invalidateQuery,
  };
};
