import React, { FC, ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { SafeHooks, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { getTranslation } from '../../../../utils/getTranslation';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { FinalStep, StepOne, StepThree, StepTwo } from '../Steps';
import { ChildrenFormProps } from '../type';
import { FormSchema, formSchema } from '../Validation';

export type AddSafeModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <StepThree {...props} />,
  (props) => <FinalStep {...props} />,
];

export const AddSafeModal: FC<AddSafeModalProps> = ({
  isOpenModal,
  companyId,
  handleClose,
  invalidateQuery,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  const { create, isPending } = SafeHooks.useCreate();

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo, stepThree } = data;
      create(
        {
          companyId,
          data: {
            stakeholderId: stepOne.stakeholder.id,
            investment: stepOne.investment,
            issueDate: toBackendDateFormat(stepOne.issueDate),
            capValue: stepTwo.cap?.capValue || undefined,
            discount: stepTwo.discount?.discountValue || undefined,
            floorValue: stepTwo.floor?.floorValue || undefined,
            maturityDate: stepTwo.floor?.maturityDate
              ? toBackendDateFormat(stepTwo.floor?.maturityDate)
              : undefined,
            additionalNotes: stepThree?.additionalNotes,
            filesLinks:
              stepThree?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
          },
        },
        {
          onSuccess: () => {
            toast.success(getTranslation(StringKey.SAFE_CREATED_SUCCESS));
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [companyId, create, handleCloseModal, invalidateQuery],
  );

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.ADD_SAFE} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex w-full items-center gap-[5px] bg-white px-6 pb-6">
            {formSteps.map((_, i) => (
              <div
                className={twMerge(
                  'h-1 w-full rounded-[25px]',
                  i + 1 <= currentStep ? 'bg-[#12B76A]' : 'bg-gray-100',
                  i + 1 === currentStep &&
                    currentStep === formSteps.length &&
                    'bg-gradient-to-r from-forest-500 to-forest-300',
                )}
                key={`${i}_${companyId}`}
              />
            ))}
          </div>

          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {formSteps[currentStep - 1]({
              companyId,
              formData: getValues,
              nextFormStep: handleNextStep,
              prevFormStep: handlePrevStep,
              setFormData: setValue,
              control,
              clearErrors,
              handleCloseModal,
              setError,
              filedState: getFieldState,
              errors,
              isLoading: isPending,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
