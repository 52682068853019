import { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { SelectedCompanyCurrency } from '../../../../components/SelectedCompanyCurrency';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Approval: FC<CardProps> = ({ event }) => {
  const { format } = useFormat();

  if (event.type !== EventTypeBackend.APPROVAL) return <></>;

  const {
    type,
    exercise: { items },
    createdAt,
  } = event;

  return (
    <>
      {items.map(({ id, vestingTask }) => (
        <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm" key={id}>
          <TransactionTitleItem
            createdAt={format(createdAt, 'dd/MM/yyyy')}
            name={<AppFormattedMessage id={eventFormType[type]} />}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
            value={<AppFormattedMessage id={eventFormType[type]} />}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
            value={`Hurdle Plan ${vestingTask.grantItem.stakeholder.fullName}`}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
            value={vestingTask.grantItem.plan.pool.shareClass.name}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.HURDLE_VALUE} />}
            value={
              <>
                <SelectedCompanyCurrency />
                {vestingTask.grantItem.plan.hardleValue?.toLocaleString('en-US')}
              </>
            }
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
            value={vestingTask.grantItem.numbersOfGrants.toLocaleString('en-US')}
          />
        </div>
      ))}
    </>
  );
};
