import { ReactNode } from 'react';

import { AppFormattedMessage } from '../components/AppFormattedMessage';
import { StringKey } from '../lang';
import {
  CompanyAnnualRevenue,
  CompanyFilter,
  CompanyIndustry,
  CompanyNumberOfEmployees,
  CompanyType,
} from '../types/companyTypes';

export const companyTypeTranslation: Record<CompanyType, StringKey> = {
  [CompanyType.PRIVATE_EQUITY]: StringKey.PRIVATE_EQUITY,
  [CompanyType.VENTURE_CAPITAL]: StringKey.VENTURE_CAPITAL,
  [CompanyType.SPV]: StringKey.SPV,
  [CompanyType.FAMILY_OFFICE]: StringKey.FAMILY_OFFICE,
  [CompanyType.COMPANY]: StringKey.COMPANY,
  [CompanyType.OTHER]: StringKey.OTHER_FINANCIAL,
};

export const listOfCompanyTypeTranslation = Object.entries(companyTypeTranslation);

export const companyIndustryTranslation: Record<CompanyIndustry, StringKey> = {
  [CompanyIndustry.AGRICULTURE_FARMING]: StringKey.AGRICULTURE_FARMING,
  [CompanyIndustry.AEROSPACE]: StringKey.AEROSPACE,
  [CompanyIndustry.ART]: StringKey.ART,
  [CompanyIndustry.ARTIFICIAL_INTELLIGENCE]: StringKey.ARTIFICIAL_INTELLIGENCE,
  [CompanyIndustry.AUTOMOTIVE]: StringKey.AUTOMOTIVE,
  [CompanyIndustry.BANKING_FINANCIAL_SERVICES]: StringKey.BANKING_FINANCIAL_SERVICES,
  [CompanyIndustry.BIOTECHNOLOGY]: StringKey.BIOTECHNOLOGY,
  [CompanyIndustry.BUILDING_MATERIALS_SUPPLIES]: StringKey.BUILDING_MATERIALS_SUPPLIES,
  [CompanyIndustry.BUSINESS_SERVICES]: StringKey.BUSINESS_SERVICES,
  [CompanyIndustry.CHEMICAL_INDUSTRY]: StringKey.CHEMICAL_INDUSTRY,
  [CompanyIndustry.CLEANING_PRODUCTS_SERVICES]: StringKey.CLEANING_PRODUCTS_SERVICES,
  [CompanyIndustry.CLOUD_COMPUTING]: StringKey.CLOUD_COMPUTING,
  [CompanyIndustry.COMPUTER_HARDWARE]: StringKey.COMPUTER_HARDWARE,
  [CompanyIndustry.CONSTRUCTION]: StringKey.CONSTRUCTION,
  [CompanyIndustry.CONSUMER_ELECTRONICS]: StringKey.CONSUMER_ELECTRONICS,
  [CompanyIndustry.CONSUMER_GOODS]: StringKey.CONSUMER_GOODS,
  [CompanyIndustry.CONSUMER_HEALTHCARE]: StringKey.CONSUMER_HEALTHCARE,
  [CompanyIndustry.CONSULTING]: StringKey.CONSULTING,
  [CompanyIndustry.CULTURE]: StringKey.CULTURE,
  [CompanyIndustry.CYBERSECURITY]: StringKey.CYBERSECURITY,
  [CompanyIndustry.DATA_STORAGE_MANAGEMENT]: StringKey.DATA_STORAGE_MANAGEMENT,
  [CompanyIndustry.DEFENSE]: StringKey.DEFENSE,
  [CompanyIndustry.E_COMMERCE]: StringKey.ECOMMERCE,
  [CompanyIndustry.EDUCATION_TRAINING]: StringKey.EDUCATION_TRAINING,
  [CompanyIndustry.ELECTRIC_VEHICLES]: StringKey.ELECTRIC_VEHICLES,
  [CompanyIndustry.ENERGY]: StringKey.ENERGY,
  [CompanyIndustry.ENTERTAINMENT]: StringKey.ENTERTAINMENT,
  [CompanyIndustry.ENVIRONMENTAL_SERVICES]: StringKey.ENVIRONMENTAL_SERVICES,
  [CompanyIndustry.EVENTS]: StringKey.EVENTS,
  [CompanyIndustry.FABRICS_TEXTILES]: StringKey.FABRICS_TEXTILES,
  [CompanyIndustry.FASHION]: StringKey.FASHION,
  [CompanyIndustry.FINTECH]: StringKey.FINTECH,
  [CompanyIndustry.FOOD_BEVERAGES]: StringKey.FOOD_BEVERAGES,
  [CompanyIndustry.GOVERNMENT_PUBLIC_INSTITUTIONS]: StringKey.GOVERNMENT_PUBLIC_INSTITUTIONS,
  [CompanyIndustry.HEALTHCARE]: StringKey.HEALTHCARE,
  [CompanyIndustry.HOSPITALITY]: StringKey.HOSPITALITY,
  [CompanyIndustry.INFORMATION_TECHNOLOGIES]: StringKey.INFORMATION_TECHNOLOGIES,
  [CompanyIndustry.INTERNET_OF_THINGS]: StringKey.INTERNET_OF_THINGS,
  [CompanyIndustry.INSURANCE]: StringKey.INSURANCE,
  [CompanyIndustry.LEGAL]: StringKey.LEGAL,
  [CompanyIndustry.LEISURE_RECREATION]: StringKey.LEISURE_RECREATION,
  [CompanyIndustry.LOGISTICS_SUPPLY_CHAIN]: StringKey.LOGISTICS_SUPPLY_CHAIN,
  [CompanyIndustry.LUXURY_GOODS]: StringKey.LUXURY_GOODS,
  [CompanyIndustry.MACHINERY_HEAVY_EQUIPMENT]: StringKey.MACHINERY_HEAVY_EQUIPMENT,
  [CompanyIndustry.MANUFACTURING]: StringKey.MANUFACTURING,
  [CompanyIndustry.MARKETING_ADVERTISING]: StringKey.MARKETING_ADVERTISING,
  [CompanyIndustry.MEDIA]: StringKey.MEDIA,
  [CompanyIndustry.MEDICAL_DEVICES_SUPPLIES]: StringKey.MEDICAL_DEVICES_SUPPLIES,
  [CompanyIndustry.MINING]: StringKey.MINING,
  [CompanyIndustry.MUSIC]: StringKey.MUSIC,
  [CompanyIndustry.MOBILITY_TRANSPORTATION]: StringKey.MOBILITY_TRANSPORTATION,
  [CompanyIndustry.PERSONAL_SERVICES]: StringKey.PERSONAL_SERVICES,
  [CompanyIndustry.PET_CARE_SUPPLIES]: StringKey.PET_CARE_SUPPLIES,
  [CompanyIndustry.PHARMACEUTICALS]: StringKey.PHARMACEUTICALS,
  [CompanyIndustry.PROFESSIONAL_SERVICES]: StringKey.PROFESSIONAL_SERVICES,
  [CompanyIndustry.PUBLISHING]: StringKey.PUBLISHING,
  [CompanyIndustry.REAL_ESTATE_PROPTECH]: StringKey.REAL_ESTATE_PROPTECH,
  [CompanyIndustry.RESTAURANTS_FOOD_SERVICES]: StringKey.RESTAURANTS_FOOD_SERVICES,
  [CompanyIndustry.RETAIL]: StringKey.RETAIL,
  [CompanyIndustry.ROBOTICS]: StringKey.ROBOTICS,
  [CompanyIndustry.SMART_CITY]: StringKey.SMART_CITY,
  [CompanyIndustry.SOCIAL_MEDIA]: StringKey.SOCIAL_MEDIA,
  [CompanyIndustry.SOFTWARE_DEVELOPMENT]: StringKey.SOFTWARE_DEVELOPMENT,
  [CompanyIndustry.SPORTS_FITNESS]: StringKey.SPORTS_FITNESS,
  [CompanyIndustry.TELECOM_CONNECTIVITY]: StringKey.TELECOM_CONNECTIVITY,
  [CompanyIndustry.TRAVEL_TOURISM]: StringKey.TRAVEL_TOURISM,
  [CompanyIndustry.VIDEO_GAMES]: StringKey.VIDEO_GAMES,
  [CompanyIndustry.WASTE_MANAGEMENT]: StringKey.WASTE_MANAGEMENT,
  [CompanyIndustry.NON_PROFIT]: StringKey.NON_PROFIT,
  [CompanyIndustry.OTHER]: StringKey.OTHER,
};

export const listOfCompanyIndustryTranslation = Object.entries(companyIndustryTranslation);

export const companyAnnualRevenueTranslation: Record<CompanyAnnualRevenue, ReactNode> = {
  [CompanyAnnualRevenue.NO_REV]: <AppFormattedMessage id={StringKey.NO_REV} />,
  [CompanyAnnualRevenue.LESS_1_MIL]: (
    <>
      $0 <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> $1M
    </>
  ),
  [CompanyAnnualRevenue.BETWEEN_1_5_MIL]: (
    <>
      $1M <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> $5M
    </>
  ),
  [CompanyAnnualRevenue.BETWEEN_5_20_MIL]: (
    <>
      $5M <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> $20M
    </>
  ),

  [CompanyAnnualRevenue.MORE_20_MIL]: '>$20M',
};

export const listOfCompanyAnnualRevenueTranslation = Object.entries(
  companyAnnualRevenueTranslation,
) as [CompanyAnnualRevenue, string][];

export const companyNumberOfEmployeesTranslation: Record<CompanyNumberOfEmployees, ReactNode> = {
  [CompanyNumberOfEmployees.BETWEEN_1_10]: (
    <>
      1 <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> 10
    </>
  ),
  [CompanyNumberOfEmployees.BETWEEN_11_25]: (
    <>
      11 <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> 25
    </>
  ),
  [CompanyNumberOfEmployees.BETWEEN_26_50]: (
    <>
      26 <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> 50
    </>
  ),
  [CompanyNumberOfEmployees.BETWEEN_51_250]: (
    <>
      51 <AppFormattedMessage id={StringKey.BETWEEN_SYMBOL} /> 250
    </>
  ),
  [CompanyNumberOfEmployees.MORE_250]: '>250',
};

export const listOfCompanyNumberOfEmployeesTranslation = Object.entries(
  companyNumberOfEmployeesTranslation,
) as [CompanyNumberOfEmployees, string][];

export const companyFilterTranslation: Record<CompanyFilter, ReactNode> = {
  [CompanyFilter.ACTIVE]: <AppFormattedMessage id={StringKey.ACTIVE} />,
  [CompanyFilter.INACTIVE]: <AppFormattedMessage id={StringKey.INACTIVE} />,
};
export const listOfCompanyFilter = Object.entries(companyFilterTranslation) as [
  CompanyFilter,
  string,
][];

export enum CompanySettingsCardVariant {
  COMPANY_DETAILS = 'details',
  COMPANY_ADDRESS = 'address',
  COMPANY_PLAN_BILLING = 'plan-billing',
}

export const CompanySettingsCardTranslation: Record<CompanySettingsCardVariant, ReactNode> = {
  [CompanySettingsCardVariant.COMPANY_DETAILS]: <AppFormattedMessage id={StringKey.DETAILS} />,
  [CompanySettingsCardVariant.COMPANY_ADDRESS]: <AppFormattedMessage id={StringKey.ADDRESS} />,
  [CompanySettingsCardVariant.COMPANY_PLAN_BILLING]: (
    <AppFormattedMessage id={StringKey.PLAN_BILLING} />
  ),
};

export const arrayOfCompanySettingsCardTranslation = Object.entries(
  CompanySettingsCardTranslation,
) as [CompanySettingsCardVariant, string][];
