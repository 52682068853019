import { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { SelectedCompanyCurrency } from '../../../../components/SelectedCompanyCurrency';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Exercised: FC<CardProps> = ({ event }) => {
  const { format } = useFormat();

  if (event.type !== EventTypeBackend.EXERCISE) return <></>;

  const {
    type,
    exercise: { items },
    createdAt,
  } = event;

  const planType = items[0]?.vestingTask?.grantItem?.plan?.type;

  if (planType === SharePlanType.STOCK_OPTIONS)
    return (
      <>
        {items.map(({ id, vestingTask, exercised }) => (
          <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm" key={id}>
            <TransactionTitleItem
              createdAt={format(createdAt, 'dd/MM/yyyy')}
              name={<AppFormattedMessage id={eventFormType[type]} />}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
              value={<AppFormattedMessage id={eventFormType[type]} />}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
              value={vestingTask.grantItem.stakeholder.fullName}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.EXERCISED_WARRANTS} />}
              value={exercised}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
              value={vestingTask.grantItem.plan.pool.shareClass.name}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
              value={vestingTask.grantItem.numbersOfGrants.toLocaleString('en-US')}
            />
          </div>
        ))}
      </>
    );

  if (planType === SharePlanType.WARRANTS)
    return (
      <>
        {items.map(({ id, vestingTask, exercised }) => (
          <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm" key={id}>
            <TransactionTitleItem
              createdAt={format(createdAt, 'dd/MM/yyyy')}
              name={<AppFormattedMessage id={eventFormType[type]} />}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
              value={<AppFormattedMessage id={eventFormType[type]} />}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
              value={vestingTask.grantItem.stakeholder.fullName}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.EXERCISED_OPTIONS} />}
              value={exercised}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
              value={vestingTask.grantItem.plan.pool.shareClass.name}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
              value={vestingTask.grantItem.numbersOfGrants.toLocaleString('en-US')}
            />
          </div>
        ))}
      </>
    );

  if (planType === SharePlanType.SAR)
    return (
      <>
        {items.map(({ id, vestingTask, exercised, payout }) => (
          <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm" key={id}>
            <TransactionTitleItem
              createdAt={format(createdAt, 'dd/MM/yyyy')}
              name={<AppFormattedMessage id={eventFormType[type]} />}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
              value={<AppFormattedMessage id={eventFormType[type]} />}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
              value={vestingTask.grantItem.stakeholder.fullName}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.SAR_EXERCISED} />}
              value={exercised}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
              value={vestingTask.grantItem.plan.pool.shareClass.name}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.SAR_PAYOUT} />}
              value={
                <>
                  <SelectedCompanyCurrency />
                  {payout && payout.toLocaleString('en-US')}
                </>
              }
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
              value={vestingTask.grantItem.numbersOfGrants.toLocaleString('en-US')}
            />
          </div>
        ))}
      </>
    );

  return <></>;
};
