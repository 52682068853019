import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { antiDilutionBaseTitle } from '../../../../types/share-classes.types';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  prevFormStep,
  formData,
  isLoading,
}) => {
  const { stepOne, stepFour, stepThree, stepTwo } = formData();
  const { format } = useFormat();
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.GENERAL} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS_NAME} />}
              value={stepOne.name}
            />

            <SummaryItem
              title={<AppFormattedMessage id={StringKey.CREATION_DATE} />}
              value={stepOne?.creationDate && format(stepOne.creationDate, 'dd/MM/yyyy')}
            />

            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.VOTING_RIGHT} />}
                value={
                  stepOne.votingRight?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />

              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                value={stepOne.votingRight?.value || '-'}
              />
            </div>
            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO} />}
                value={
                  stepOne.conversionRatio?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                value={stepOne.conversionRatio?.value || '-'}
              />
            </div>
            <SummaryItem
              className="pb-0"
              title={<AppFormattedMessage id={StringKey.DIVIDEND_RIGHT} />}
              value={
                stepOne.dividendRightEnabled ? (
                  <AppFormattedMessage id={StringKey.YES} />
                ) : (
                  <AppFormattedMessage id={StringKey.NO} />
                )
              }
            />
          </div>
        </div>
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.LIQUIDITY} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <div className="flex flex-col gap-3 pb-3">
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.SENIORITY} />}
                value={stepTwo?.liquidityPreferences?.seniority || '-'}
              />

              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                value={stepTwo?.liquidityPreferences?.multiple || '-'}
              />
            </div>
            <div className="flex flex-col gap-3 pt-3">
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.PARTICIPATING} />}
                value={
                  stepTwo?.liquidityPreferences?.participating?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.CAP_VALUE} />}
                value={stepTwo?.liquidityPreferences?.participating?.capValue || '-'}
              />
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.INTEREST} />}
                value={
                  stepTwo?.liquidityPreferences?.participating?.interest
                    ? stepTwo?.liquidityPreferences?.participating?.interest + '%'
                    : '-'
                }
              />
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.DAYS_PER_YEAR} />}
                value={stepTwo?.liquidityPreferences?.participating?.daysPersYear || '-'}
              />
            </div>
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.ANTI_DILUTION_RIGHTS} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.BASE} />}
              value={
                stepThree?.antiDilutionRights?.base ? (
                  <AppFormattedMessage
                    id={antiDilutionBaseTitle[stepThree?.antiDilutionRights?.base]}
                  />
                ) : (
                  '-'
                )
              }
            />

            <SummaryItem
              className="pb-0"
              title={<AppFormattedMessage id={StringKey.PRE_EMPTIVE_RIGHTS} />}
              value={
                stepThree?.antiDilutionRights?.enabled ? (
                  <AppFormattedMessage id={StringKey.YES} />
                ) : (
                  <AppFormattedMessage id={StringKey.NO} />
                )
              }
            />
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {stepFour?.files?.some(({ docLink }) => docLink) ? (
                stepFour.files.map(
                  ({ doc, docLink }) =>
                    docLink && (
                      <span
                        className="truncate text-xs font-[450] text-gray-700"
                        key={docLink + doc.name}
                      >
                        {doc.name}
                      </span>
                    ),
                )
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
              {stepFour?.additionalNotes || '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            isLoading={isLoading}
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.ADD_SHARE_CLASS} />
          </Button>
        </div>
      </div>
    </form>
  );
};
