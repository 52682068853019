import { FC } from 'react';

import importStakeholdersDropdown from '../../../assets/images/import-stakeholders-dropdown.png';
import selectedImportStakeholdersDropdown from '../../../assets/images/selected-import-stakeholders-dropdown.png';

export const ImportStakeholders: FC = () => (
  <>
    <div className="flex flex-col gap-2">
      <span className="text-xl font-semibold text-gray-700">Import Stakeholders Guide</span>
      <span className="text-xs font-[450] text-gray-700">
        The <strong>Import Stakeholders</strong> mechanism allows{' '}
        <strong>Admins and Contributors</strong> to add multiple stakeholders simultaneously by
        uploading a preformatted Excel file This ensures efficiency and accuracy in adding
        stakeholder data
      </span>
    </div>
    <h1 className="text-[16px] font-semibold leading-[2] text-gray-700">Step-by-Step Guide</h1>
    <div className="flex flex-col gap-2" id="main-step-1">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 1: Open the Import Stakeholders Blade
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Navigate to the <strong>Stakeholders</strong> module in CapQuest
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click on the <strong>Import Stakeholders</strong> option to open the Import blade
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-2">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 2: Select Stakeholder Type
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          In the Import Stakeholders blade, use the <strong>Stakeholder Type</strong> dropdown
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Select either <strong>Natural Persons</strong> or <strong>Legal Entities</strong>
        </li>
      </ul>
      <img src={importStakeholdersDropdown} width="320" />
    </div>
    <div className="flex flex-col gap-2" id="main-step-3">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 3: Download the Excel Template
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Click the <strong>Download Template</strong> button
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The downloaded file will include:
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 1:</strong> 'Stakeholder Import (Natural)' or 'Stakeholder Import
              (Legal)'
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 2:</strong> 'Dropdown' (Read-only)
            </li>
          </ul>
        </li>
      </ul>
      <img src={selectedImportStakeholdersDropdown} width="320" />
    </div>
    <div className="flex flex-col gap-2" id="main-step-4">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 4: Understanding the Excel File
      </h2>
      <ul className="flex flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          <strong>Sheet 1: 'Stakeholder Import (Natural)'</strong> contains{' '}
          <strong>editable fields</strong> for stakeholder information
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Editable Fields:</strong>
            </li>
            <ul className="flex list-disc flex-col gap-2 pl-5">
              <li className="text-xs font-[450] text-gray-700">Full Name</li>
              <li className="text-xs font-[450] text-gray-700">Email</li>
              <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
              <li className="text-xs font-[450] text-gray-700">Passport Number, etc</li>
            </ul>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Mandatory Fields:</strong>
            </li>
            <ul className="flex list-disc flex-col gap-2 pl-5">
              <li className="text-xs font-[450] text-gray-700">Full Name</li>
              <li className="text-xs font-[450] text-gray-700">Email</li>
              <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
            </ul>
          </ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Sheet 1: 'Stakeholder Import (Legal)'</strong> contains{' '}
          <strong>editable fields</strong> for company stakeholder
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Editable Fields:</strong>
            </li>
            <ul className="flex list-disc flex-col gap-2 pl-5">
              <li className="text-xs font-[450] text-gray-700">Full Name</li>
              <li className="text-xs font-[450] text-gray-700">Email</li>
              <li className="text-xs font-[450] text-gray-700">Country of Incorporation, etc</li>
            </ul>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Mandatory Fields:</strong>
            </li>
            <ul className="flex list-disc flex-col gap-2 pl-5">
              <li className="text-xs font-[450] text-gray-700">Full Name</li>
              <li className="text-xs font-[450] text-gray-700">Email</li>
              <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
            </ul>
          </ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Sheet 2: Dropdown</strong> contains predefined values for{' '}
          <strong>dropdown-linked fields</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Important</strong>
        </li>
        <ul className="flex list-disc flex-col gap-2 pl-5">
          <li className="text-xs font-[450] text-gray-700">Do not edit this sheet</li>
          <li className="text-xs font-[450] text-gray-700">
            Ensure that values in Sheet 1 match the provided options in <strong>Sheet 2</strong>
          </li>
        </ul>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-5">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 5: Fill in the Stakeholders List
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Open <strong>Sheet 1</strong> ("Stakeholder Import (Natural)" or "Stakeholder Import
          (Legal)" - depending on your selection)
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2"></ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Fill out the required stakeholder details
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Editable Fields:</strong>
            </li>
            <ul className="flex list-disc flex-col gap-2 pl-5">
              <li className="text-xs font-[450] text-gray-700">Full Name</li>
              <li className="text-xs font-[450] text-gray-700">Email</li>
              <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
              <li className="text-xs font-[450] text-gray-700">
                Country of Incorporation (for Legal Entities)
              </li>
              <li className="text-xs font-[450] text-gray-700">Company Identifier</li>
              <li className="text-xs font-[450] text-gray-700"> Date of Incorporation</li>
              <li className="text-xs font-[450] text-gray-700">
                Passport Number (for Natural Persons) etc
              </li>
            </ul>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Mandatory Fields:</strong>
            </li>
            <ul className="flex list-disc flex-col gap-2 pl-5">
              <li className="text-xs font-[450] text-gray-700">Full Name</li>
              <li className="text-xs font-[450] text-gray-700">Email</li>
              <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
            </ul>
          </ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Assign <strong>Access &amp; Permissions</strong> for each stakeholder, by choosing:
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Yes</strong> → to <strong>grant access</strong>
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>No</strong> → to <strong>deny access</strong>
            </li>
          </ul>
        </li>
        <ul>
          <li className="list-none text-xs font-[450] text-gray-700">Permissions include:</li>
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Cap Table</strong>
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong> Your Position (Issued) vs Cap Table</strong>
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Equity &amp; Capital Change</strong>
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Company Table</strong>
            </li>
          </ul>
        </ul>

        <li className="text-xs font-[450] text-gray-700">
          Ensure all values match the predefined dropdown options in <strong>Sheet 2</strong>{' '}
          (Dropdowns) to avoid validation errors
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Save the Excel file after completing the stakeholder list
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-6">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 6: Upload the Filled Template
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Return to the <strong>Import Stakeholders</strong> blade in CapQuest
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Ensure the correct <strong>Stakeholder Type</strong> is selected
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click <strong>Upload</strong> or <strong>drag and drop</strong> the Excel file
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The system will validate the file and display a result
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click <strong>Save</strong> to finish importing stakeholders
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="key-notes-and-tips">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">Key Notes and Tips</h2>
      <ol className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Ensure <strong>mandatory fields</strong> are filled before uploading to avoid errors
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The uploaded file should match the template format
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Do not modify <strong>Sheet 2: Dropdown</strong>.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Values in dropdown-linked fields must match options in{' '}
        </li>
        <li className="text-xs font-[450] text-gray-700">
          If an error occurs, check the format and re-upload the corrected file
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Note you cannot upload more stakeholders than is stipulated in your Plan
        </li>
      </ol>
    </div>
  </>
);
