import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { StringKey } from '../../../lang';
import { SharePlanType } from '../../../types/pool-plans.types';
import { StockOption, WarrantExercise } from './StepComponents';
import { Bspce } from './StepComponents/Bspce';
import { ApproveHurdlePlanList } from './StepComponents/Hurdle';
import { Sar } from './StepComponents/Sar';
import { ChildrenFormProps } from './type';
import { FormSchema, stepOne as stepOneFormSchema } from './validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  submitForm,
  control,
  setFormData,
  companyId,
  filedState,
  type,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);

  const isValid = success && !filedState('stepOne').invalid;

  if (!type) return;

  return (
    <>
      <div className="flex w-full flex-col gap-2">
        {type === SharePlanType.STOCK_OPTIONS && (
          <StockOption
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {/* {type === SharePlanType.RSA && (
          <Rsa
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )} */}
        {type === SharePlanType.WARRANTS && (
          <WarrantExercise
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === SharePlanType.SAR && (
          <Sar
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === SharePlanType.GROWTH_HURDLE && (
          <ApproveHurdlePlanList
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === SharePlanType.BSPCE && (
          <Bspce
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
      </div>

      {(type !== SharePlanType.GROWTH_HURDLE || stepOne?.hurdle?.planId) && (
        <div className="sticky bottom-0 left-0 z-10 flex h-[76px] w-full justify-between gap-3 bg-white pt-8">
          <Button
            className="w-fit bg-transparent p-3 text-sm font-[450] text-gray-700"
            onClick={handleCloseModal}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          <div className="flex gap-3">
            <Button
              className="h-full w-[260px] p-3 text-sm font-[550] text-gray-25"
              disabled={!isValid}
              onClick={submitForm}
              type="button"
            >
              <AppFormattedMessage id={StringKey.REQUEST_EXERCISE} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
