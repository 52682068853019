import { FC, useState } from 'react';

import { AddDocumentIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { PaginationController } from '../../../../components/PaginationController';
import { EventHooks, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { eventFormType, EventTypeBackend, GrantEventItem } from '../../../../types/events.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Grant: FC<CardProps> = ({ event, companyId, openFileUploadModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { format } = useFormat();
  const { items, totalPages } = EventHooks.useTransactions<GrantEventItem>({
    eventId: event.id,
    eventType: EventTypeBackend.GRANT,
    companyId: companyId,
    currentPage,
  });

  if (event.type !== EventTypeBackend.GRANT) return <></>;
  const { type, filesLinks, id } = event;
  return (
    <>
      {items.map(({ date, numbersOfGrants, plan, stakeholder, id: grantId }, i) => (
        <div
          className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm"
          key={i + stakeholder.id}
        >
          <TransactionTitleItem
            createdAt={format(date, 'dd/MM/yyyy')}
            name={stakeholder?.fullName}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
            value={<AppFormattedMessage id={eventFormType[type]} />}
          />
          <TransactionOverviewItem
            className="uppercase"
            title={<AppFormattedMessage id={StringKey.GRANT_ID} />}
            value={grantId?.split('-')[0]}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
            value={numbersOfGrants?.toLocaleString('en-US')}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.EQUITY_PLAN} />}
            value={plan?.name}
          />
          {filesLinks.length > 0 && (
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.DOCUMENTS} />}
              value={
                <HoverCard>
                  <HoverCardTrigger onClick={() => openFileUploadModal(filesLinks)}>
                    <AddDocumentIcon className="cursor-pointer" />
                  </HoverCardTrigger>

                  <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                    <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                      {filesLinks.length === 1
                        ? getS3FileOriginalName(filesLinks[0])
                        : `Download ${filesLinks.length} files`}
                    </span>
                  </HoverCardContent>
                </HoverCard>
              }
            />
          )}
        </div>
      ))}
      <PaginationController
        className="mt-6 shrink-0"
        currentPage={currentPage}
        onClick={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
