import { FC, useState } from 'react';

import { AddDocumentIcon } from '../../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../../components/HoverCard';
import { Company, EventHooks, useFormat, useModalState } from '../../../../../hooks';
import { EventTypeBackend } from '../../../../../types/events.types';
import { conversionMethodTitle, Safe } from '../../../../../types/safes.types';
import { getS3FileOriginalName } from '../../../../../utils/getS3FileOriginalName';
import { toNumber } from '../../../../../utils/toNumber';
import { FilesModal } from '../../../Modals';
import { BasicTable } from './BasicTable';
import { PaginationItem } from './PaginationItem';
import { Td } from './TableTd';
import { AmountOfFetchedItems, TableBodyProps } from './types';

export const ConvertedSafe: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  const { selectedCompany } = Company.useSelected();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.THREE);
  const { items, totalPages } = EventHooks.useTransactions<Safe>({
    eventId: props.eventId,
    eventType: EventTypeBackend.SAFE_CONVERSION,
    companyId: props.companyId,
    currentPage,
    take: Number(itemsToFetch),
    dateFilter: props.selectedDateFilter,
    customDateFilter: props.selectedCustomDateFilter,
  });

  const {
    isOpen: isOpenFilesModal,
    handleCloseModal: handleCloseFilesModal,
    handleOpenModal: handleOpenFilesModal,
    toggler: filesModalToggler,
  } = useModalState();

  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  const handleDownloadAllDocuments = async (links: string[]) => {
    for (let i = 0; i < links.length; i++) {
      await new Promise<void>((resolve) => {
        const link = links[i];
        const a = document.createElement('a');
        a.href = link;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setTimeout(() => {
          resolve();
        }, 1000);
      });
    }
  };

  if (props.type !== EventTypeBackend.SAFE_CONVERSION) return <></>;

  const { name } = props;
  return (
    <>
      <BasicTable eventType={EventTypeBackend.SAFE_CONVERSION}>
        {items.map(
          (
            {
              conversionDate,
              stakeholder,
              investment,
              convertedShares,
              conversionMethod,
              conversion: { valuation, preConversionSharePrice: eventSharePrice } = {},
              filesLinks,
            },
            index,
          ) => (
            <tr key={`${index}_${stakeholder}`}>
              <AlertDialogWrapper
                control={{ onOpenChange: filesModalToggler, open: isOpenFilesModal }}
              >
                <FilesModal
                  createdAt={format(conversionDate, 'dd/MM/yyyy')}
                  filesLinks={filesLinks}
                  name={name}
                  onClose={handleCloseFilesModal}
                  onDownloadFile={handleDocumentClick}
                  onSuccess={() => handleDownloadAllDocuments(filesLinks)}
                  stakeholderName={stakeholder.fullName}
                />
              </AlertDialogWrapper>
              <Td value={format(conversionDate, 'dd/MM/yyyy')} />
              <Td value={stakeholder.fullName} />
              <Td value={investment.toLocaleString('en-US')} />
              <Td
                value={
                  conversionMethod && (
                    <AppFormattedMessage id={conversionMethodTitle[conversionMethod]} />
                  )
                }
              />
              <Td value={valuation?.name} />
              <Td
                value={`${selectedCompany?.currency?.symbol} ${Math.round(toNumber(eventSharePrice)).toLocaleString('en-US')}`}
              />
              <Td value={convertedShares?.toLocaleString('en-US')} />
              <Td
                value={
                  <HoverCard>
                    <HoverCardTrigger>
                      <AddDocumentIcon
                        className="size-6 cursor-pointer p-[2px]"
                        onClick={() =>
                          filesLinks.length > 0 &&
                          (filesLinks.length === 1
                            ? handleDocumentClick(filesLinks[0])
                            : handleOpenFilesModal())
                        }
                      />
                    </HoverCardTrigger>
                    <HoverCardContent className="max-w-[180px] rounded-lg bg-[#101828] px-3 py-2">
                      <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                        {filesLinks.length === 1
                          ? getS3FileOriginalName(filesLinks[0])
                          : `${filesLinks.length} files attached to event`}
                      </span>
                    </HoverCardContent>
                  </HoverCard>
                }
              />
            </tr>
          ),
        )}
      </BasicTable>
      <PaginationItem
        currentPage={currentPage}
        handleChangeCurrentPage={setCurrentPage}
        handleFetchAmountChange={setItemsToFetch}
        itemsCount={items.length}
        itemsToFetch={itemsToFetch}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
