import { FC } from 'react';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../../types/events.types';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const Expire: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();

  if (props.type !== EventTypeBackend.EXPIRE) return <></>;

  const {
    type,
    exercise: { items },
    createdAt,
  } = props;
  return (
    <BasicTable eventType={EventTypeBackend.EXPIRE}>
      {items.map(({ id, exercised, vestingTask }) => (
        <tr key={id}>
          <Td value={format(createdAt, 'dd/MM/yyyy')} />
          <Td value={<AppFormattedMessage id={eventFormType[type]} />} />
          <Td value={vestingTask.grantItem.stakeholder.fullName} />
          <Td value={exercised} />
          <Td value={vestingTask.grantItem.plan.pool.shareClass.name} />
        </tr>
      ))}
    </BasicTable>
  );
};
