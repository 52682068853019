import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { DatePicker } from '../../../PoolForm/DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type WarrantExerciseProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const WarrantExercise: FC<WarrantExerciseProps> = ({
  control,
  setFormData,
  companyId,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: warrantItemsFields,
    append: warrantItemsAppend,
    remove: warrantItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.warrantExercise.warrantExerciseItems',
  });

  const handleRemoveWarrantItems = useCallback(
    (index: number) => {
      warrantItemsRemove(index);
    },
    [warrantItemsRemove],
  );

  const handleAppendWarrantItems = useCallback(() => {
    const defaultDate = new Date();
    warrantItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        grantItem: {
          plan: {
            warrantPrice: 0,
            expiryDate: defaultDate,
            conversionRatio: 1,
            pool: { shareClass: { name: '' } },
          },
        },
        exercisedCount: 0,
        vestedSharesCount: 0,
      },
      date: defaultDate,
      exercised: 0,
    });
  }, [warrantItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.warrantExercise.warrantExerciseItems.${index}.grant`);
  };

  const calculateBalance = useCallback(
    (index: number) => {
      const conversionRatio =
        stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
          ?.conversionRatio || 1;
      const vestedShares =
        stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares =
        stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.exercised || 0;
      const balance = Math.max(
        vestedShares / conversionRatio - exercisedShares / conversionRatio - value,
        0,
      );

      return balance.toLocaleString('en-US');
    },
    [stepOne?.warrantExercise?.warrantExerciseItems],
  );

  const checkExerciseDate = useCallback(
    (index: number) => {
      const expiryDate =
        stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan?.expiryDate;
      const execiseDate = stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.date;

      if (expiryDate && execiseDate) {
        const isValid = new Date(expiryDate).getTime() < new Date(execiseDate).getTime();
        return isValid;
      }

      return false;
    },
    [stepOne?.warrantExercise?.warrantExerciseItems],
  );

  useEffect(() => {
    if (warrantItemsFields.length === 0) {
      handleAppendWarrantItems();
    }
  }, [handleAppendWarrantItems, warrantItemsFields.length]);

  const showRemoveButton = warrantItemsFields.length > 1;

  return (
    <>
      <div className="flex w-[1340px] flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <div
          className={twMerge(
            'grid',
            showRemoveButton
              ? 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)]'
              : 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))]',
          )}
        >
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.STAKEHOLDER} />} />
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.GRANT} />} />
          <TableHeaderItem
            showTooltip
            text={<AppFormattedMessage id={StringKey.EXERCISE_DATE} />}
          />
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.SHARE_CLASS} />} />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.WARRANT_PRICE} />}
            variant="dark"
          />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.EXERCISED_WARRANTS} />}
            variant="dark"
          />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
            variant="dark"
          />
          {showRemoveButton && <TableHeaderItem text="" />}
        </div>
        {warrantItemsFields.map((field, index) => (
          <>
            <div
              className={twMerge(
                'grid border-b-[1px] border-b-gray-100',
                showRemoveButton
                  ? 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)]'
                  : 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))]',
              )}
              key={field.id}
            >
              <div className="px-4 pb-10 pt-7">
                <StakeholderCombobox
                  companyId={companyId}
                  control={control}
                  handleStakeholderChange={() => handleStakeholderChange(index)}
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.stakeholder`}
                  planType={SharePlanType.WARRANTS}
                />
              </div>
              <div className="px-4 pb-10 pt-7">
                <GrantCombobox
                  companyId={companyId}
                  control={control}
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.grant`}
                  selectedGrantIds={stepOne?.warrantExercise?.warrantExerciseItems?.map(
                    (item) => item?.grant?.id || '',
                  )}
                  sharePlanType={SharePlanType.WARRANTS}
                  stakeholderId={
                    stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.stakeholder?.id || ''
                  }
                />
              </div>
              <div className="px-4 pb-10 pt-7">
                <DatePicker
                  calendar={{
                    toDate:
                      stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem
                        ?.plan?.expiryDate,
                  }}
                  defaultMonth={
                    stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.date || undefined
                  }
                  onSelect={(date) =>
                    setFormData(`stepOne.warrantExercise.warrantExerciseItems.${index}.date`, date)
                  }
                  value={stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.date}
                  wrapperClassName="w-full"
                >
                  <FormInput
                    autoComplete="off"
                    control={control}
                    customValue={(value) => {
                      return value && format(value, 'dd/MM/yyyy');
                    }}
                    disabledInput
                    icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                    inputClassName="h-10"
                    name={`stepOne.warrantExercise.warrantExerciseItems.${index}.date`}
                    wrapperClassName="w-full"
                  />
                </DatePicker>
              </div>
              <div className="flex items-center px-4 pb-10 pt-7">
                <span className="text-sm font-[450] text-gray-700">
                  {
                    stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
                      ?.pool?.shareClass?.name
                  }
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <FormInput
                  control={control}
                  disabled={true}
                  inputClassName="h-10"
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.grant.grantItem.plan.warrantPrice`}
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
                <span className="block max-w-[164px] overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs font-[450] text-gray-600">
                  <AppFormattedMessage id={StringKey.TOTAL_COST} /> $
                  {(
                    (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
                      ?.warrantPrice || 0) *
                    (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.exercised || 1)
                  ).toLocaleString('en-US')}
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <FormInput
                  control={control}
                  inputClassName="h-10"
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.exercised`}
                  numberOnly
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
                <span className="text-xs font-[450] text-gray-600">
                  <AppFormattedMessage id={StringKey.AVAILABLE} /> {calculateBalance(index)}
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <div className="max-w-[164px] rounded-t-[4px] bg-gray-50 px-3 py-2">
                  <span className="block w-full overflow-hidden truncate text-ellipsis whitespace-nowrap text-sm font-[450] text-gray-700">
                    {(
                      (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.exercised || 0) *
                      (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem
                        ?.plan?.conversionRatio || 1)
                    ).toLocaleString('en-US')}
                  </span>
                </div>
                <span className="text-xs font-[450] text-gray-600">
                  <AppFormattedMessage id={StringKey.CONVERSION} />{' '}
                  {stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
                    ?.conversionRatio || 1}
                  x
                </span>
              </div>
              {showRemoveButton && (
                <div>
                  <Button
                    className="flex h-full w-full items-center justify-center"
                    onClick={() => handleRemoveWarrantItems(index)}
                    styleType="NONE"
                  >
                    <CloseIcon />
                  </Button>
                </div>
              )}
              {checkExerciseDate(index) && (
                <span className="w-[400px] px-4 pb-4 text-xs font-[450] text-fireside-600">
                  <AppFormattedMessage id={StringKey.EXERCISE_DATE_CANT_BE_LATER_THAN_EXPIRE} />
                </span>
              )}
            </div>
          </>
        ))}
      </div>

      {warrantItemsFields.length < 10 && (
        <div className="sticky bottom-[76px] z-10 w-full bg-white">
          <Button
            className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
            onClick={handleAppendWarrantItems}
            styleType="NONE"
          >
            + <AppFormattedMessage id={StringKey.ADD_EXERCISING} />
          </Button>
        </div>
      )}
    </>
  );
};
