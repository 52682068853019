import { StringKey } from '../../lang';

export enum ProfileTab {
  PERSONAL_INFO = 'personal-info',
  NOTIFICATIONS = 'notifications',
}

export const profileTabTitle: Record<ProfileTab, StringKey> = {
  [ProfileTab.PERSONAL_INFO]: StringKey.PERSONAL_INFO,
  [ProfileTab.NOTIFICATIONS]: StringKey.NOTIFICATIONS,
};

export const profileTabMap = Object.values(ProfileTab);

export const profileTabTitleMap = Object.entries(profileTabTitle);
