import React, { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { Company, SnapshotHooks, useScreenSize } from '../../hooks';
import { StringKey } from '../../lang';
import { sideBarContext } from '../../storage/sideBarContext';
import { PageRoute } from '../../types/pageTypes';
import { CapTableGraphWrapper } from '../CapTable/Graphs';
import { OverAllStatus } from '../CapTable/OverAllStatus';
import { GraphVariant } from '../CapTable/variables';
import { EmptyGraph, EventGraph } from '../Events/Desktop/GraphsDesk';
import { GraphVariant as EventGraphVariant } from '../Events/variables';

const Dashboard: FC = () => {
  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.OWNERSHIP);
  const { selectedCompanyId } = Company.useSelected();
  const { snapshot } = SnapshotHooks.useCurrent({
    companyId: selectedCompanyId,
    capTableMode: true,
  });
  const { snapshot: snapshotWithPhantom, isLoading: isCurrentLoading } = SnapshotHooks.useCurrent({
    companyId: selectedCompanyId,
    includePhantom: true,
  });
  const { snapshots, isLoading } = SnapshotHooks.useSnapshots({ companyId: selectedCompanyId });
  const { width } = useScreenSize();
  const { isOpen: isSideBarOpen } = useContext(sideBarContext);
  const navigate = useNavigate();

  const toggleGraphVariant = () => {
    setGraphVariant((prevVariant) =>
      prevVariant === GraphVariant.OWNERSHIP ? GraphVariant.VOTING_RIGHTS : GraphVariant.OWNERSHIP,
    );
  };

  const top5Stakeholders = useMemo(() => {
    if (!snapshot?.stats?.stakeholders) {
      return [];
    }
    const totalIssuedPercentage = snapshot.stats.stakeholders.reduce(
      (total, stakeholder) => total + stakeholder.issuedPercentage,
      0,
    );

    const totalDilutedPercentage = snapshot.stats.stakeholders.reduce(
      (total, stakeholder) => total + stakeholder.dilutedPercentage,
      0,
    );

    return snapshot.stats.stakeholders
      .sort((a, b) => b.issuedPercentage - a.issuedPercentage)
      .slice(0, 5)
      .map(({ name, issuedPercentage, dilutedPercentage, votingPercentage }) => ({
        name,
        issuedPercentage: ((issuedPercentage * 100) / (totalIssuedPercentage || 1)).toFixed(2),
        dilutedPercentage: ((dilutedPercentage * 100) / (totalDilutedPercentage || 1)).toFixed(2),
        votingPercentage,
      }));
  }, [snapshot?.stats?.stakeholders]);

  const isDesktop = width >= 1344;
  const isTablet = width >= (isSideBarOpen ? 1114 : 1024) && width < 1344;

  return (
    <div
      className={twMerge(
        'flex h-fit w-full flex-col gap-4 overflow-hidden lg:pl-1',
        (isLoading || isCurrentLoading) && 'animate-pulse',
      )}
    >
      <div className="flex h-max w-full items-stretch gap-4 max-lg:flex-col">
        <OverAllStatus
          hasValuation={!!snapshot?.valuation}
          shouldNavigate
          snapshot={snapshot}
          wrapperClassName={isTablet ? 'w-[40%]' : 'w-[100%]'}
        />
        <CapTableGraphWrapper
          isDesktop={isDesktop}
          isTablet={isTablet}
          selectedGraphVariant={selectedGraphVariant}
          snapshot={snapshot}
          toggleGraphVariant={toggleGraphVariant}
          wrapperClassName={isTablet ? 'w-[60%]' : 'w-[100%]'}
        />
      </div>
      <div className="flex h-max w-full justify-center gap-4 max-lg:flex-col">
        <div
          className={twMerge(
            'flex min-h-full w-full flex-col items-center rounded-lg bg-gray-50 p-1',
          )}
        >
          <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
            <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
              <AppFormattedMessage id={StringKey.TOP_5_STAKEHOLDERS} />
            </span>
            <ChevronDownIcon
              className="-rotate-90 transform cursor-pointer"
              onClick={() => navigate(PageRoute.STAKEHOLDERS)}
            />
          </div>
          <div className="h-full w-full rounded-md bg-white px-3 py-2 shadow-sm">
            {snapshot?.valuation ? (
              <div className="grid w-full grid-cols-[repeat(1,minmax(125px,3fr))_repeat(3,minmax(54px,1fr))] gap-3 p-3 pt-0">
                <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">
                  <AppFormattedMessage id={StringKey.NAME} />
                </span>
                <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">
                  <AppFormattedMessage id={StringKey.ISSUED_PERCENTAGE} />
                </span>
                <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">
                  <AppFormattedMessage id={StringKey.DILUTED_PERCENTAGE} />
                </span>
                <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">
                  <AppFormattedMessage id={StringKey.VOTING_PERCENTAGE} />
                </span>
              </div>
            ) : (
              <div className="grid w-full animate-pulse grid-cols-[repeat(1,minmax(125px,2fr))_repeat(2,minmax(54px,1fr))] gap-3 p-3 pt-0">
                <div className="h-2 w-full bg-gray-50" />
                <div className="h-2 w-full bg-gray-50" />
                <div className="h-2 w-full bg-gray-50" />
              </div>
            )}

            {snapshot?.valuation ? (
              top5Stakeholders.map((stakeholder, index) => (
                <div
                  className="grid w-full grid-cols-[repeat(1,minmax(125px,3fr))_repeat(3,minmax(54px,1fr))] gap-3 p-3"
                  key={`${stakeholder.name}_${index}`}
                >
                  <span className="truncate text-xs font-[450] text-gray-700">
                    {stakeholder.name}
                  </span>
                  <span className="text-xs font-[450] text-gray-700">
                    {stakeholder.issuedPercentage}
                  </span>
                  <span className="text-xs font-[450] text-gray-700">
                    {stakeholder.dilutedPercentage}
                  </span>
                  <span className="text-xs font-[450] text-gray-700">
                    {stakeholder.dilutedPercentage}
                  </span>
                </div>
              ))
            ) : (
              <div className="flex w-full animate-pulse flex-col gap-3">
                <div className="w-full rounded-lg bg-gray-50 p-4" />
                <div className="w-full rounded-lg bg-gray-50 p-4" />
                <div className="w-full rounded-lg bg-gray-50 p-4" />
                <div className="w-full rounded-lg bg-gray-50 p-4" />
                <div className="w-full rounded-lg bg-gray-50 p-4" />
              </div>
            )}
          </div>
        </div>
        {/**        <div
          className={twMerge(
            'flex min-h-full flex-col items-center overflow-x-hidden rounded-lg bg-gray-50 p-1',
            isDesktop || isTablet ? 'w-[62%]' : 'w-[100%]',
          )}
        >
          <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
            <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
              <AppFormattedMessage id={StringKey.POOL_ALLOCATION} />
            </span>
            <ChevronDownIcon
              className="-rotate-90 transform cursor-pointer"
              onClick={() => {
                navigate(PageRoute.POOL_PLANS);
              }}
            />
          </div>
          <div
            className={twMerge(
              'relative flex h-[280px] w-full gap-4 rounded-md bg-white shadow-sm',
              isDesktop ? 'p-6' : isTablet ? 'p-5' : 'h-full flex-col gap-6 p-2',
            )}
          >
            <PoolGraph
              isDesktop={isDesktop}
              isTablet={isTablet}
              snapshot={snapshot}
              variant={PoolGraphVariant.EQUITY}
            />
            <PoolGraph
              isDesktop={isDesktop}
              isTablet={isTablet}
              snapshot={snapshot}
              variant={PoolGraphVariant.GRANTED}
            />
          </div>
        </div>
          **/}
      </div>
      <div className="h-max w-full max-lg:hidden">
        <div className="flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1">
          <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
            <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
              <AppFormattedMessage id={StringKey.VALUATION_HISTORY} />
            </span>
            <ChevronDownIcon
              className="-rotate-90 transform cursor-pointer"
              onClick={() => {
                navigate(PageRoute.EVENTS);
              }}
            />
          </div>
          <div className="relative flex h-full w-full rounded-md bg-white p-6 pl-2 shadow-sm">
            <span className="absolute left-[90px] whitespace-nowrap text-label-sm font-medium text-gray-500">
              <AppFormattedMessage id={StringKey.CURRENCY_USD} />
            </span>
            <div className="h-[344px] w-full">
              {snapshots.length > 0 && !isLoading && !isCurrentLoading ? (
                <EventGraph
                  currentSnapshot={snapshotWithPhantom}
                  snapshots={snapshots}
                  variant={EventGraphVariant.VALUATION}
                />
              ) : (
                <EmptyGraph variant={EventGraphVariant.VALUATION} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
