export type GuideStep = {
  title: string;
  boldWords?: string[];
  items?: {
    title: string;
    boldWords?: string[];
    image?: string;
    subSteps?: { title: string; boldWords?: string[]; subSteps?: string[] }[];
  }[];
  image?: string;
};

export type GuideProps = {
  steps: GuideStep[];
  guideTitle: string;
  description?: string;
  note?: string;
  tips?: { title: string }[];
};

export enum ExcelGuide {
  IMPORT_STAKEHOLDERS = 'import-stakeholders',
  MODIFY_STAKEHOLDERS = 'modify-stakeholders',
  IMPORT_GRANTS = 'import-grants',
  IMPORT_SAFES = 'import-safes',
}
