import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useCookies } from '../../context/cookies.context';
import { StringKey } from '../../lang';
import { TermsTab } from '../../pages/Terms';
import { PageRoute } from '../../types/pageTypes';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import { CloseModalButton } from '../CloseModalButton';
import { Sheet, SheetContent } from '../Sheet';
import { Switch } from '../Switch';

export type CookieSettingsModalProps = {
  handleCloseModal: () => void;
  handleOpenCookieModal: () => void;
  isOpenModal: boolean;
};

export const CookieSettingsModal: FC<CookieSettingsModalProps> = ({
  isOpenModal,
  handleCloseModal,
  handleOpenCookieModal,
}) => {
  const { cookieSettings, setCookies, toggleCookieModalOpenState } = useCookies();

  const handleAcceptAll = () => {
    setCookies({ analytics: true, essential: true, functional: true, marketing: true });
    toggleCookieModalOpenState(false);
    handleCloseModal();
  };

  const handleSaveSettings = () => {
    toggleCookieModalOpenState(false);
    handleCloseModal();
  };

  const handleCloseWithoutChoice = () => {
    handleCloseModal();
    toggleCookieModalOpenState(false);
    if (!cookieSettings.essential) {
      handleOpenCookieModal();
    }
  };

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[557px] border-transparent bg-transparent p-2 shadow-3xl"
        onInteractOutside={handleCloseWithoutChoice}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-6 overflow-hidden rounded-[4px] bg-white p-6">
          <div className="flex flex-col gap-4">
            <div className="flex w-full items-center justify-between">
              <span className="text-xl font-semibold text-gray-700">
                <AppFormattedMessage id={StringKey.COOKIE_SETTINGS} />
              </span>
              <CloseModalButton onClose={handleCloseWithoutChoice} />
            </div>
            <span className="text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.COOKIE_CONSENT_POLICY} />{' '}
              <Link
                className="inline h-fit w-fit text-brand-700 underline"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.POLICY }}
                to={PageRoute.TERMS}
              >
                <AppFormattedMessage id={StringKey.PRIVACY_POLICY} />
              </Link>
            </span>
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">
                    <AppFormattedMessage id={StringKey.ESSENTIAL} />
                  </span>
                  <Switch checked={true} disabled />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.ADMIN_PRIVILEGES_WARNING} />
                </span>
              </div>
            </div>

            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">
                    <AppFormattedMessage id={StringKey.PERSONALISED} />
                  </span>
                  <Switch
                    checked={cookieSettings.functional}
                    onChange={(state) => {
                      setCookies({ functional: state });
                    }}
                  />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.COOKIES_STORE_PREFERENCES} />
                </span>
              </div>
            </div>

            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">
                    <AppFormattedMessage id={StringKey.ANALYTICS} />
                  </span>
                  <Switch
                    checked={cookieSettings.analytics}
                    onChange={(state) => {
                      setCookies({ analytics: state });
                    }}
                  />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.ANALYTICS_POLICY} />
                </span>
              </div>
            </div>

            <div className="rounded-[4px] bg-gray-50 p-3">
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-semibold text-gray-700">
                    <AppFormattedMessage id={StringKey.MARKETING} />
                  </span>
                  <Switch
                    checked={cookieSettings.marketing}
                    onChange={(state) => {
                      setCookies({ marketing: state });
                    }}
                  />
                </div>
                <span className="text-xs text-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.MARKETING_POLICY} />
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between gap-3">
            <Button
              className="w-fit text-nowrap border-[1px] border-gray-300 bg-transparent px-6 py-[10px] text-sm font-[550] text-gray-700"
              onClick={handleSaveSettings}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.SAVE_SETTINGS} />
            </Button>
            <Button
              className="h-full w-full text-nowrap py-[10px] text-sm font-[550] text-white"
              onClick={handleAcceptAll}
              type="button"
            >
              <AppFormattedMessage id={StringKey.ACCEPT_ALL} />
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
