import { Field, Label } from '@headlessui/react';
import { FC } from 'react';

import { Checkbox } from '../../../../../components/Checkbox';
import { DatePicker } from '../../../../../components/DatePicker';
import { TableRow } from '../../../../../components/Table';
import { ApproveHurdleColumn, approveHurdleColumnMap } from './types';

export type HurdleTableRowProps = {
  isSelected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
  id: string;
  stakeholderName: string;
  grantId: string;
  shareClassName: string;
  hurdleValue: number;
  currentValuation: number;
  issuedShares: number;
  onDateChange: (data: Date) => void;
};

export const HurdleTableRow: FC<HurdleTableRowProps> = ({
  isSelected,
  onDeselect,
  onSelect,
  id,
  currentValuation,
  grantId,
  hurdleValue,
  issuedShares,
  shareClassName,
  stakeholderName,
  onDateChange,
}) => {
  return (
    <TableRow
      columns={approveHurdleColumnMap}
      fields={{
        id,
        [ApproveHurdleColumn.SELECT]: (
          <Field className="flex h-full w-full items-center">
            <Checkbox
              checked={isSelected}
              id={grantId}
              onChange={(checked) => {
                checked ? onSelect() : onDeselect();
              }}
            />
            <Label
              className="absolute left-0 top-0 h-full w-full cursor-pointer"
              htmlFor={grantId}
            />
          </Field>
        ),
        [ApproveHurdleColumn.STAKEHOLDER]: stakeholderName,
        [ApproveHurdleColumn.GRANT]: grantId.split('-')[0].toUpperCase(),
        [ApproveHurdleColumn.APPROVAL_DATE]: (
          <DatePicker
            className="border-b border-gray-700 bg-gray-50 px-3 py-2"
            onSelect={onDateChange}
          />
        ),
        [ApproveHurdleColumn.SHARE_CLASS]: shareClassName,
        [ApproveHurdleColumn.HURDLE]: `$${hurdleValue.toLocaleString('en-US')}`,
        [ApproveHurdleColumn.CURRENT_VALUATION]: `$${currentValuation.toLocaleString('en-US')}`,
        [ApproveHurdleColumn.ISSUED_SHARES]: issuedShares.toLocaleString('en-US'),
      }}
      tdClassName="pl-4 h-[108px]"
      trClassName={isSelected && 'bg-gray-50'}
    />
  );
};
