import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { Switch } from '../../../../components/Switch';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepTwo as stepTwoFormSchema } from '../Validation';
export type StepTwoProps = ChildrenFormProps;

export const StepTwo: FC<StepTwoProps> = ({
  handleCloseModal,
  control,
  setFormData,
  nextFormStep,
  prevFormStep,
  lockMode,
  filedState,
}) => {
  const { stepTwo, stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepTwoFormSchema.safeParse(stepTwo);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { format } = useFormat();

  const isValid = success && !filedState('stepTwo').invalid;

  stepTwo;

  const isDateValid =
    stepOne?.issueDate && stepTwo?.floor?.maturityDate
      ? new Date(stepOne?.issueDate).getTime() <= new Date(stepTwo?.floor?.maturityDate).getTime()
      : true;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="rounded-md bg-gray-50">
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepTwo?.cap?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepTwo.cap.enabled', true);
                setFormData('stepTwo.cap', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.CAP} />
            </span>
          </div>
          {stepTwo?.cap?.enabled && (
            <div className="flex items-center justify-between gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
              <span className="text-nowrap text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.CAP} />
              </span>
              <FormInput
                control={control}
                currencySign
                name="stepTwo.cap.capValue"
                numberOnly
                placeholder={<AppFormattedMessage id={StringKey.AMOUNT} />}
                shouldFormatNumber
                wrapperClassName="w-[224px]"
              />
            </div>
          )}
        </div>

        <div className="rounded-md bg-gray-50">
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepTwo?.discount?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepTwo.discount.enabled', true);
                setFormData('stepTwo.discount', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.DISCOUNT} />
            </span>
          </div>
          {stepTwo?.discount?.enabled && (
            <div className="flex items-center justify-between gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
              <span className="text-nowrap text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.DISCOUNT} />
              </span>
              <FormInput
                control={control}
                name="stepTwo.discount.discountValue"
                placeholder={<AppFormattedMessage id={StringKey.PERCENTAGE} />}
                signAfterValue="%"
                wrapperClassName="w-[224px]"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 rounded-lg p-2 shadow-sm">
          <span className="text-label-md font-medium text-gray-500">
            <AppFormattedMessage id={StringKey.OPTIONAL} />
          </span>
          <div className="rounded-md bg-gray-50">
            <div className="flex items-center gap-2 px-4 py-3">
              <Switch
                checked={stepTwo?.floor?.enabled}
                onChange={(state) => {
                  if (state) return setFormData('stepTwo.floor.enabled', true);
                  setFormData('stepTwo.floor', undefined);
                }}
              />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.FLOOR_AND_MATURITY_DATE} />
              </span>
            </div>
            {stepTwo?.floor?.enabled && (
              <div className="flex w-full flex-col gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
                <div className="flex items-center justify-between gap-3">
                  <span className="text-nowrap text-sm font-[450] text-gray-700">
                    <AppFormattedMessage id={StringKey.FLOOR} />
                  </span>
                  <FormInput
                    control={control}
                    currencySign
                    name="stepTwo.floor.floorValue"
                    numberOnly
                    placeholder={<AppFormattedMessage id={StringKey.AMOUNT_OPTIONAL} />}
                    shouldFormatNumber
                    wrapperClassName="w-[224px]"
                  />
                </div>
                <div className="flex w-full items-center justify-between gap-3">
                  <span className="text-nowrap text-sm font-[450] text-gray-700">
                    <AppFormattedMessage id={StringKey.MATURITY_DATE} />
                  </span>
                  <FormDatePicker
                    calendar={{ fromDate: stepOne?.issueDate }}
                    defaultMonth={stepTwo?.floor?.maturityDate || undefined}
                    inputValue={
                      stepTwo?.floor?.maturityDate
                        ? format(stepTwo?.floor?.maturityDate, 'dd/MM/yyyy')
                        : ''
                    }
                    inputWrapperClassName="ml-11"
                    isDefaultOpenCalendar={isOpenCalendar}
                    onSelect={(date) => {
                      setFormData('stepTwo.floor.maturityDate', date);
                      setOpenCalendar(false);
                    }}
                    placeholder={<AppFormattedMessage id={StringKey.DATE_OPTIONAL} />}
                    value={stepTwo?.floor?.maturityDate}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {!isDateValid && (
          <span className="flex gap-1 text-xs font-[450] text-fireside-600">
            <InfoCircle />
            <AppFormattedMessage id={StringKey.MATURITY_DATE_VALIDATION} />
          </span>
        )}
        <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
          <DropDown
            answer={[
              'The valuation cap sets the maximum company valuation at which a SAFE will convert into equity. Essentially, it offers a ceiling on the valuation at which the SAFE holder can convert into shares, regardless of the company’s actual valuation at the time of the next funding round.',
              "Example: If an investor holds a SAFE with a $10 million cap and the company raises a Series A round at a $20 million valuation, the investor's SAFE will convert at the $10 million cap.",
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_CAP} />}
          />
          <DropDown
            answer={[
              'The discount rate offers SAFE investors a reduction on the price per share when the SAFE converts during a future funding round, compared to new investors. This provides SAFE holders with preferential pricing as an incentive for investing early. ',
              'In cases where both a Valuation Cap and a Discount are set on the same SAFE, the Discount is only applied if the price per share in the next funding round is lower than the cap. The investor will receive the more favorable terms—either the cap or the discount.',
              "Example: Investor holds a SAFE with a 20% discount and a $10 million valuation cap. If the next funding round values the company at $12 million, the investor's SAFE will convert at the $10 million cap. If however the next funding round values the company lower than $10 million, the 20% discount will be applied, thus ensuring that the SAFE holder always retains the most benefit.",
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_DISCOUNT} />}
          />
          <DropDown
            answer={[
              'The SAFE Floor sets the minimum valuation at which a SAFE converts into equity. It protects the company and existing shareholders by preventing excessive dilution in case a future funding round is priced lower than expected.',
              'If a priced round occurs below the floor, the SAFE will still convert as if the valuation were at the floor, limiting the number of shares issued to SAFE holders.',
              'Example: If a SAFE has a $5M floor and the next round is priced at $3M, the SAFE converts as if the company were valued at $5M. This prevents SAFE investors from receiving a larger-than-expected ownership percentage due to a lower valuation.',
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_FLOOR} />}
          />
          <DropDown
            answer={[
              'The SAFE Maturity Date is the date when a SAFE may convert if a qualifying financing event has not occurred.',
              'While traditional SAFEs often have no maturity, some agreements include a maturity date to define when investors can request conversion or repayment. Conversion at maturity is subject to the terms set in the agreement.',
              'Example: If a SAFE has a 3-year maturity and no fundraising event happens by that time, the investor may have the right to convert into equity at a predetermined valuation or request repayment, depending on the SAFE terms.',
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_SAFE_MATURITY_DATE} />}
          />
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid || !isDateValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
