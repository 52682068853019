import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { SummaryItem } from '../../../components/SummaryItem';
import { SafeHooks, useFormat } from '../../../hooks';
import { useSelected } from '../../../hooks/company';
import { StringKey } from '../../../lang';
import { toRound } from '../../../utils/getRoundedNumber';
import { getS3FileOriginalName } from '../../../utils/getS3FileOriginalName';
import { toNumber } from '../../../utils/toNumber';
import { ConvertSafeItem } from './ConversionSummary';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  safeId: string;
};

export const ViewConversionModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  safeId,
}) => {
  const { format } = useFormat();
  const { safe } = SafeHooks.useSafe({ companyId, safeId });
  const { selectedCompany } = useSelected();

  const conversionId = safe?.conversion?.id || '';
  const { safeConversion } = SafeHooks.useSafeConversion({ companyId, conversionId });
  const valuation = safe?.conversion?.valuation;

  const issuedShares = valuation?.issuedShares || valuation?.issuedSharesOnStart;
  const preMoneyValuation =
    toNumber(valuation?.issuedSharesOnStart) * toNumber(valuation?.sharePrice);
  const postMoneyValuation = toNumber(issuedShares) * toNumber(valuation?.sharePrice);
  const investment = safeConversion?.items?.reduce((acc, item) => {
    acc += item.investment;
    return acc;
  }, 0);
  const convertedShares = safe?.conversion?.convertedShares || 0;
  const totalOwnership = safeConversion?.items?.reduce((acc, item) => {
    acc += Math.min(item.investment / (item?.capValue || 1), 1);
    return acc;
  }, 0);

  const sharePrice = parseFloat(
    ((postMoneyValuation || 0) / (toNumber(issuedShares) + convertedShares || 1)).toFixed(2),
  );

  const safeIds = safeConversion?.items?.map((safeItem) => safeItem.id);

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleClose}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.VIEW_CONVERSION_DETAILS} />
            </span>
            <CloseModalButton onClose={handleClose} />
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.GENERAL} />
                </span>
                <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.CONVERSION_DATE} />}
                    value={format(safe?.conversionDate, 'dd/MM/yyyy')}
                  />

                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.LINKED_EVENT} />}
                    value={valuation?.name}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
                    value={safe?.conversion?.shareClass?.name}
                  />
                </div>
              </div>

              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.SELECTED_SAFES} />
                </span>
                <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white px-4 py-1">
                  {safeConversion?.items?.map((safeItem) => (
                    <SummaryItem
                      key={safeItem.id}
                      title={safeItem?.stakeholder?.fullName}
                      value={`${selectedCompany?.currency?.symbol} ${safeItem?.investment.toLocaleString('en-US')} ${format(safeItem?.issueDate, 'dd/MM/yyyy')}`}
                    />
                  ))}
                </div>
              </div>

              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.GENERAL_CONVERSION_SUMMARY} />
                </span>
                <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white px-4 py-1">
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.EVENT_SHARE_PRICE_POST_CONVERSION} />}
                    value={`${selectedCompany?.currency?.symbol} ${toRound(sharePrice, 2)}`}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.SAFE_INVESTMENT_CONVERSION_SUM} />}
                    value={`${selectedCompany?.currency?.symbol} ${investment?.toLocaleString('en-US')}`}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />}
                    value={`${selectedCompany?.currency?.symbol}  ${preMoneyValuation?.toLocaleString('en-US')}`}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.TOTAL_PERCENT_OWNED_SAFE} />}
                    value={`${parseFloat(((totalOwnership || 0) * 100).toFixed(2))}%`}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.CONVERTED_SHARES} />}
                    value={toRound(convertedShares, 0).toLocaleString('en-US')}
                  />
                </div>
              </div>

              <div className="mt-2 flex w-full flex-col gap-4 rounded-lg p-4 shadow-sm">
                <span className="text-label-md font-[500] text-gray-500">
                  <AppFormattedMessage id={StringKey.CONVERSION_DETAILS} />
                </span>
                <div className="flex w-full flex-col gap-2">
                  {safeConversion?.items?.map((safeItem) => (
                    <>
                      <ConvertSafeItem
                        allSafeIds={safeIds}
                        companyId={companyId}
                        currencySign={selectedCompany?.currency?.symbol}
                        documents={safeItem?.filesLinks}
                        key={safeItem.id}
                        safeId={safeItem.id}
                        valuationId={safeConversion?.valuation.id}
                      />
                    </>
                  ))}
                </div>
              </div>

              <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
                <div className={'flex gap-3 text-nowrap px-4 py-2'}>
                  <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
                    <AppFormattedMessage id={StringKey.DOCUMENTS} />:
                  </span>
                  <div className="flex max-w-[220px] flex-col gap-2">
                    {safe?.filesLinks?.length ? (
                      safe?.conversion?.filesLinks.map((link) => (
                        <span className="truncate text-xs font-[450] text-gray-700" key={link}>
                          {getS3FileOriginalName(link)}
                        </span>
                      ))
                    ) : (
                      <span className="truncate text-xs font-[450] text-gray-700">-</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
                <div className="flex w-full gap-3 px-4 py-2">
                  <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
                    {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
                  </span>
                  <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
                    {safe?.additionalNotes || '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex h-9 w-full justify-end gap-3 px-6">
              <Button
                className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
                onClick={handleClose}
                type="button"
              >
                <AppFormattedMessage id={StringKey.CLOSE} />
              </Button>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
