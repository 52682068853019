import React, { FC } from 'react';

import { CheckIcon } from '../../assets/icons';
import image from '../../assets/images/sunglasses-smile.png';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';

const HangTight: FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-10">
      <div className="mb-4 flex h-fit w-fit max-w-[458px] flex-col items-center justify-center text-center">
        <div
          className="rounded-full p-[12px]"
          style={{
            boxShadow: '0px 0px 8px 0px #F7900921 inset',
          }}
        >
          <div
            className="flex size-[112px] items-center justify-center rounded-full"
            style={{
              boxShadow: '0px 0px 8px 0px #0985F721 inset',
              background:
                'linear-gradient(180deg, rgba(253, 176, 34, 0.13) 0%, rgba(220, 104, 3, 0.13) 100%)',
            }}
          >
            <img alt="calendar" className="size-14" src={image} />
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-2">
          <span
            className="!bg-clip-text text-4xl font-bold text-transparent"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            <AppFormattedMessage id={StringKey.HANG_TIGHT} />!
          </span>
          <span className="w-full text-sm font-[450] text-gray-500">
            We're working on your request and will be in touch shortly
          </span>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col gap-6 rounded-2xl border-[1px] border-gray-200 p-6 pt-4">
          <div className="flex flex-col items-center">
            <span className="text-xl font-semibold text-gray-700">Enterprise & FI</span>
            <span className="text-label-md font-medium text-gray-700">for custom pricing</span>
          </div>

          <div className="h-[1px] w-full bg-gray-200" />
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <CheckIcon iconColor="#344054" />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.LOAN_MODELING} />
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon iconColor="#344054" />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.EVENT_MANAGEMENT_X} />
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon iconColor="#344054" />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.DASHBOARD_CUSTOMIZATION_X} />
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon iconColor="#344054" />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.ASSISTED_ONBOARDING_PROCESS} />
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon iconColor="#344054" />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.DEDICATED_ACCOUNT_MANAGER} />
              </span>
            </div>
          </div>

          <div className="h-[1px] w-full bg-gray-200" />
          <span className="text-sm font-[550] text-forest-600">
            Submitted - We’ll Contact You Soon
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex w-full max-w-[458px] flex-col items-start gap-3 rounded-lg bg-gray-50 p-6">
            <span
              className="!bg-clip-text py-[6px] text-m font-bold text-transparent"
              style={{
                background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
              }}
            >
              Next Steps:
            </span>
            <span className="pb-3 text-sm font-[450] text-gray-700">
              We typically respond within 24 hours. In the meantime, feel free to explore our
              resources below to learn more about how CapQuest can help you manage your equity and
              asset classes efficiently,
            </span>
            <a
              className="cursor-pointer text-sm font-[450] text-brand-700"
              href=""
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              Customer success stories
            </a>
            <a
              className="cursor-pointer text-sm font-[450] text-brand-700"
              href=""
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              Comprehensive Guides
            </a>
            <a
              className="cursor-pointer text-sm font-[450] text-brand-700"
              href=""
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              Frequently asked questions (FAQ’s)
            </a>
          </div>
          <div className="flex w-full max-w-[458px] flex-col items-start gap-4 rounded-xl border-[1px] border-gray-200 p-4">
            <span className="text-sm font-[550] text-[#172335]">Immediate questions?</span>
            <span className="text-label-md text-[#475467]">
              Write us at{' '}
              <a
                className="cursor-pointer text-label-md font-[450] text-brand-700"
                href="mailto:support@capquest.io"
                rel="noreferrer noopener"
                target="_blank"
              >
                support@capquest.io
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HangTight;
