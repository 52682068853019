import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, UseFormGetFieldState, UseFormSetValue, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  CircleCheckIcon,
  CloudUploadIcon,
  DownloadIcon,
  ImportIcon,
  InfoCircle,
  UploadIcon,
} from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { useAppMutation } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { PageRoute } from '../../../../types/pageTypes';
import { ExcelGuide } from '../../../ModifyGuide/types';
import { FormSchema } from '../validation';

export type BulkGrantStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  filedState: UseFormGetFieldState<FormSchema>;
};

export const BulkGrantStepOne: FC<BulkGrantStepOneProps> = ({
  control,
  setFormData,
  companyId,
  filedState,
}) => {
  const { error } = filedState('stepOne.eventDetails.bulk-import-grant.file');
  const navigate = useNavigate();
  const { stepOne } = useWatch<FormSchema>({ control });
  const { mutate: mutateTemplate } = useAppMutation(
    [QueryKey.BULK_GRANT_DOWNLOAD_TEMPLATE, companyId],
    {
      mutationFn: () =>
        ApiService.downloadFile({
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'events', 'bulk-grant-template'],
        }),
    },
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFormData('stepOne.eventDetails.bulk-import-grant.file', acceptedFiles[0], {
        shouldValidate: true,
      });
    },
    [setFormData],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  const isValidFileState =
    stepOne?.eventDetails?.['bulk-import-grant']?.file &&
    !filedState('stepOne.eventDetails.bulk-import-grant.file').invalid;

  return (
    <>
      <div className="flex h-11 w-full justify-between">
        <Button
          className={twMerge(
            'flex w-fit cursor-pointer items-center gap-4 rounded border border-gray-100 px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs',
          )}
          onClick={() => mutateTemplate()}
          styleType="NONE"
          type="button"
        >
          <DownloadIcon iconColor="#344054" />
          <AppFormattedMessage id={StringKey.DOWNLOAD_TEMPLATE} />
        </Button>
        <Button
          className="flex w-fit cursor-pointer items-center gap-2 text-sm font-[450] text-brand-700 underline"
          onClick={() => navigate(PageRoute.GUIDES, { state: ExcelGuide.IMPORT_GRANTS })}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.VIEW_IMPORT_GUID} />
          <ImportIcon />
        </Button>
      </div>
      <input {...getInputProps()} accept=".xlsx" className="hidden" type="file" />
      <label
        {...getRootProps()}
        className="relative flex w-full border-spacing-[7px] cursor-pointer flex-col items-center gap-6 rounded-lg border border-dashed border-gray-200 py-6"
      >
        {isValidFileState ? (
          <CircleCheckIcon />
        ) : (
          <>{error ? <InfoCircle className="size-14" thinIconVariant /> : <CloudUploadIcon />}</>
        )}
        <div className="flex flex-col items-center gap-2 text-center">
          <div className="flex justify-center">
            {isValidFileState ? (
              <span className="text-sm font-[550] text-forest-500">
                <AppFormattedMessage id={StringKey.FILE_PROCESSED_SUCCESSFULLY} />
              </span>
            ) : error ? (
              <span className="text-sm font-[550] text-fireside-600">
                {error.message?.toString()}
              </span>
            ) : (
              <p className="text-sm font-medium text-gray-700">
                <span className="underline">
                  <AppFormattedMessage id={StringKey.CLICK_TO_UPLOAD} />{' '}
                </span>
                <AppFormattedMessage id={StringKey.OR_DRAG_AND_DROP} />
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1 text-[10px] font-[450] leading-4 text-gray-500">
            {isValidFileState ? (
              <>
                <span>
                  {(stepOne?.eventDetails?.['bulk-import-grant']?.file as unknown as File).name}
                </span>
                <span>
                  {(
                    (stepOne?.eventDetails?.['bulk-import-grant']?.file as unknown as File).size /
                    1024
                  ).toFixed(3)}
                  <AppFormattedMessage id={StringKey.KILOBYTE} />
                </span>
              </>
            ) : (
              <>
                <span>
                  <AppFormattedMessage
                    id={StringKey.SUPPORTED_FORMATS}
                    values={{
                      formats: 'xlsx',
                    }}
                  />
                </span>
                <span>
                  <AppFormattedMessage id={StringKey.MAXIMUM_FILE_SIZE} values={{ size: 10 }} />
                </span>
              </>
            )}
          </div>
          {error && (
            <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
              <UploadIcon />
              <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
            </span>
          )}
        </div>
      </label>
    </>
  );
};
