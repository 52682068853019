import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { Pool, useSelectedCompany } from '../../../../../hooks';
import { PlanStakeholder } from '../../../../../types/stakeholderTypes';
import { ScrollablePoolPlanItem } from './ScrollablePoolPlanItem';

export type ScrollableStakeholderItemProps = {
  stakeholder: PlanStakeholder;
  isSelected: boolean;
  selectedPlan: string[];
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
};

export const ScrollableStakeholderItem: FC<ScrollableStakeholderItemProps> = ({
  stakeholder,
  isSelected,
  selectedPlan,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditTerminationDateClick,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const granted = stakeholder?.granted?.toLocaleString('en-US');
  const totalGrantVested = stakeholder?.totalGrantVested?.toLocaleString('en-US');
  const sharesVested = stakeholder?.sharesVested?.toLocaleString('en-US');
  const exercisable = stakeholder?.exercisable?.toLocaleString('en-US');
  const exercised = stakeholder?.exercised?.toLocaleString('en-US');
  const expired = stakeholder?.expired?.toLocaleString('en-US');
  const cashSettled = stakeholder.cashSettled?.toLocaleString('en-US');

  const { pools, isEmpty } = Pool.usePoolsByStakeholder({
    companyId,
    stakeholderId: stakeholder?.id || '',
  });

  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !granted && 'text-gray-300',
            )}
          >
            {granted || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !totalGrantVested && 'text-gray-300',
            )}
          >
            {totalGrantVested || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !sharesVested && 'text-gray-300',
            )}
          >
            {sharesVested || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !exercisable && 'text-gray-300',
            )}
          >
            {exercisable || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !exercised && 'text-gray-300',
            )}
          >
            {exercised || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !cashSettled && 'text-gray-300',
            )}
          >
            {cashSettled || '-'}
          </span>
        </td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !expired && 'text-gray-300',
            )}
          >
            {expired || '-'}
          </span>
        </td>
        <td className="relative"></td>
      </tr>
      {!isEmpty && isSelected && pools && (
        <>
          {pools.map((pool) => (
            <ScrollablePoolPlanItem
              companyId={companyId}
              isSelected={selectedPlan.includes(pool.id)}
              key={pool.id}
              onDeleteClick={onDeleteClick}
              onDeletePlanClick={onDeletePlanClick}
              onEditClick={onEditClick}
              onEditPlanClick={onEditPlanClick}
              onEditTerminationDateClick={onEditTerminationDateClick}
              onViewClick={onViewClick}
              onViewPlanClick={onViewPlanClick}
              pool={pool}
              stakeholderId={stakeholder.id}
            />
          ))}
        </>
      )}
    </>
  );
};
