import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks, useFormat, useLocale } from '../../../hooks';
import {
  notificationRequestTitle,
  requestNotificationBuilder,
} from '../../../types/notification.types';
import { SharePlanType } from '../../../types/pool-plans.types';
import { StakeholderType } from '../../../types/stakeholderTypes';
import { toNumber } from '../../../utils/toNumber';
import Loader from '../../Loader';
import { ResolvedNotification } from '../Notification';

export type ResolvedTabProps = {
  companyId: string;
  currencySymbol: string;
};

export const ResolvedTab: FC<ResolvedTabProps> = ({ companyId, currencySymbol }) => {
  const { notifications, fetchNextPage, hasNextPage } = NotificationHooks.useNotifications({
    companyId,
    resolved: true,
  });
  const { messagesLocale } = useLocale();
  const { format } = useFormat();

  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={notifications.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scroll-notifications-target"
    >
      {notifications.map(
        ({
          createdAt,
          id,
          company,
          stakeholderName = '',
          adminId,
          stakeholderType = StakeholderType.OTHER_INST,
          adminName = '',
          requestSharesCount = 0,
          requestPlan = '',
          numberOfGrants = 0,
          requestTotalSharesCount = 0,
          requestNextTriggerTime = '',
          requestHurdleValue,
          requestPlanType,
        }) => (
          <ResolvedNotification
            adminName={adminName}
            createdAt={new Date(createdAt)}
            key={id}
            message={requestNotificationBuilder({
              hurdleValue: toNumber(requestHurdleValue),
              milestoneDate: format(requestNextTriggerTime, 'dd/MM/yyyy'),
              milestoneName: '',
              planAvailableCount: toNumber(requestTotalSharesCount),
              planGrunts: toNumber(numberOfGrants),
              planName: requestPlan,
              planType: requestPlanType || SharePlanType.BSPCE,
              requestedSharesCount: toNumber(requestSharesCount),
              adminName,
              stakeholderName,
              isAdminYou: adminId === company?.stakeholder?.id,
              stakeholderType,
              currencySymbol,
            })}
            requestDate={new Date(createdAt)}
            type={
              (requestPlanType && messagesLocale[notificationRequestTitle[requestPlanType]]) || ''
            }
          />
        ),
      )}
    </InfiniteScroll>
  );
};
