import { FC } from 'react';

import img1 from '../../../assets/images/modify-guide-1.png';
import img2 from '../../../assets/images/modify-guide-2.png';

export const BulkModifyStkaeholders: FC = () => (
  <>
    <div className="flex flex-col gap-2" id="heading">
      <span className="text-xs font-[450] text-gray-700">
        The <span className="font-semibold">Bulk Modify Stakeholders</span> mechanism allows Admins
        and Contributors to update multiple stakeholder records simultaneously, saving time and
        reducing manual errors. By downloading a preformatted Excel sheet, users can make changes to
        stakeholder information in bulk and re-upload the updated file to apply modifications.
      </span>
      <span className="text-xl font-semibold text-gray-700">Step-by-Step Guide</span>
    </div>
    <div className="flex flex-col gap-2" id={`main-step-1`}>
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 1: Open the Bulk Modify Blade
      </h2>
      <ol className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Navigate to the <strong>Stakeholders Page</strong> in the Admin Panel.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click on the <strong>Bulk Modify</strong> option to open the Bulk Modify
        </li>
      </ol>
    </div>

    <div className="flex flex-col gap-2" id={`main-step-2`}>
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 2: Select Stakeholder Type
      </h2>
      <ol className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Within the Bulk Modify blade, select either <strong>Natural Persons</strong> or{' '}
          <strong>Legal Entity</strong> from the dropdown menu.
        </li>
      </ol>
      <img src={img1} width={320} />
    </div>

    <div className="flex flex-col gap-2" id={`main-step-3`}>
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 3: Download the Excel Template
      </h2>
      <ol className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Click the <strong>Download Stakeholder Info</strong> button.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The downloaded file will include:
          <ul className="flex list-disc flex-col gap-2 pl-4 pt-4">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 1:</strong> "Bulk Upload (Natural)" or "Bulk Upload (Legal)," depending
              on your selection.
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 2:</strong> "Dropdown" (Read-only).
            </li>
          </ul>
        </li>
      </ol>
      <img src={img2} width={320} />
    </div>

    <div className="flex flex-col gap-2" id={`main-step-4`}>
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 4: Understanding the Excel File
      </h2>
      <span className="text-xs font-[550] text-gray-700">Sheet 1: "Bulk Upload (Natural)"</span>
      <span className="text-xs font-[450] text-gray-700">
        This sheet contains editable fields for stakeholder information:
      </span>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          <strong>Editable Fields:</strong> Full Name, Email, Stakeholder Type, Passport Number,
          Passport Expiry Date (Day, Month, Year), etc.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Mandatory Fields:</strong> Ensure the following fields are always filled out:
          <ul className="flex list-disc flex-col gap-2 pl-4 pt-4">
            <li className="text-xs font-[450] text-gray-700">Full Name</li>
            <li className="text-xs font-[450] text-gray-700">Email</li>
            <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
          </ul>
        </li>
      </ul>

      <span className="pt-4 text-xs font-[550] text-gray-700">Sheet 1: "Bulk Upload (Legal)"</span>
      <span className="text-xs font-[450] text-gray-700">
        This sheet contains editable fields for stakeholder information:
      </span>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          <strong>Editable Fields:</strong> Full Name, Email, Stakeholder Type, Country of
          Incorporation, Company Identifier, Date of Incorporation (Day), etc.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Mandatory Fields:</strong> Ensure the following fields are always filled out:
          <ul className="flex list-disc flex-col gap-2 pl-4 pt-4">
            <li className="text-xs font-[450] text-gray-700">Full Name</li>
            <li className="text-xs font-[450] text-gray-700">Email</li>
            <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
          </ul>
        </li>
      </ul>

      <span className="pt-4 text-xs font-[550] text-gray-700">Sheet 2: "Dropdown"</span>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          This sheet includes prefilled values for dropdown options used in Sheet 1.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Important:</strong> Do not edit this sheet. It is used to populate dropdown menus
          in Sheet 1.
        </li>
      </ul>

      <span className="pt-4 text-xs font-[550] text-gray-700">Dropdown-linked Fields:</span>
      <span className="text-xs font-[450] text-gray-700">
        Ensure values match exactly with the options listed in Sheet 2 for the following fields:
      </span>
      <span className="pt-4 text-xs font-[550] text-gray-700">For Natural Persons:</span>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
        <li className="text-xs font-[450] text-gray-700">Nationality</li>
        <li className="text-xs font-[450] text-gray-700">Passport Expiry Date (Month)</li>
        <li className="text-xs font-[450] text-gray-700">Birthday (Month)</li>
        <li className="text-xs font-[450] text-gray-700">
          Access & Permissions (Cap Table, Your Position, Equity & Capital Change, Company Table)
        </li>
      </ul>
      <span className="pt-4 text-xs font-[550] text-gray-700">For Legal Entities:</span>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">Country of Incorporation</li>
        <li className="text-xs font-[450] text-gray-700">Date of Incorporation (Month)</li>
        <li className="text-xs font-[450] text-gray-700">Phone Number (Prefix)</li>
        <li className="text-xs font-[450] text-gray-700">
          Access & Permissions (Cap Table, Your Position, Equity & Capital Change, Company Table)
        </li>
      </ul>
    </div>

    <div className="flex flex-col gap-2" id={`main-step-5`}>
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 5: Editing the Excel File
      </h2>
      <ol className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Open <strong>Sheet 1</strong> ("Bulk Upload (Natural)" or "Bulk Upload (Legal)").
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Update the necessary stakeholder information.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Ensure values for dropdown-linked fields match exactly with the options in Sheet 2.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Save the Excel file after completing the edits.
        </li>
      </ol>
    </div>

    <div className="flex flex-col gap-2" id={`main-step-6`}>
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 6: Upload the Updated File
      </h2>
      <ol className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Return to the <strong>Bulk Modify</strong> Stakeholders section.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Ensure <strong>Natural Persons</strong> (or <strong>Legal Entity</strong>, as applicable)
          is selected in the dropdown.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Upload Excel</strong> (or drag and drop)
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Verify the uploaded file’s content during the system’s validation process.
        </li>
      </ol>
    </div>

    <div className="flex flex-col gap-2" id="key-notes-and-tips">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">Key Notes and Tips</h2>
      <ol className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Ensure all <strong>mandatory fields</strong> are filled; missing information will result
          in upload errors.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Do not modify <strong>Sheet 2: Dropdown.</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Values in dropdown-linked fields must match exactly with the options in Sheet 2 to avoid
          errors.
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Save a backup copy of the original file before making changes.
        </li>
      </ol>
    </div>
  </>
);
