import { FC, useState } from 'react';

import { AddDocumentIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { PaginationController } from '../../../../components/PaginationController';
import { Company, EventHooks, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventTypeBackend } from '../../../../types/events.types';
import { conversionMethodTitle, Safe } from '../../../../types/safes.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const SafeConversion: FC<CardProps> = ({ event, companyId, openFileUploadModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { format } = useFormat();
  const { selectedCompany } = Company.useSelected();
  const { items, totalPages } = EventHooks.useTransactions<Safe>({
    eventId: event.id,
    eventType: EventTypeBackend.SAFE_CONVERSION,
    companyId: companyId,
    currentPage,
  });

  if (event.type !== EventTypeBackend.SAFE_CONVERSION) return <></>;
  return (
    <>
      {items.map(
        (
          {
            conversionDate,
            stakeholder,
            investment,
            convertedShares,
            conversionMethod,
            conversion: { valuation, preConversionSharePrice: eventSharePrice } = {},
            filesLinks,
          },
          i,
        ) => (
          <div
            className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm"
            key={i + stakeholder.id}
          >
            <TransactionTitleItem
              createdAt={format(conversionDate, 'dd/MM/yyyy')}
              name={stakeholder?.fullName}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
              value={stakeholder.fullName}
            />

            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.INVESTMENT} />}
              value={investment.toLocaleString('en-US')}
            />
            {conversionMethod && (
              <TransactionOverviewItem
                title={<AppFormattedMessage id={StringKey.CONVERSION_METHOD} />}
                value={<AppFormattedMessage id={conversionMethodTitle[conversionMethod]} />}
              />
            )}
            <TransactionOverviewItem
              className="uppercase"
              title={<AppFormattedMessage id={StringKey.LINKED_EVENT} />}
              value={valuation?.name}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.SHARE_PRICE} />}
              value={`${selectedCompany?.currency?.symbol} ${eventSharePrice?.toLocaleString('en-US')}`}
            />
            <TransactionOverviewItem
              title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
              value={convertedShares?.toLocaleString('en-US')}
            />

            {filesLinks.length > 0 && (
              <TransactionOverviewItem
                title={<AppFormattedMessage id={StringKey.DOCUMENTS} />}
                value={
                  <HoverCard>
                    <HoverCardTrigger onClick={() => openFileUploadModal(filesLinks)}>
                      <AddDocumentIcon className="cursor-pointer" />
                    </HoverCardTrigger>

                    <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                      <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                        {filesLinks.length === 1
                          ? getS3FileOriginalName(filesLinks[0])
                          : `Download ${filesLinks.length} files`}
                      </span>
                    </HoverCardContent>
                  </HoverCard>
                }
              />
            )}
          </div>
        ),
      )}
      <PaginationController
        className="mt-6 shrink-0"
        currentPage={currentPage}
        onClick={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
