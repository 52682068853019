import { FC, ReactNode } from 'react';

import { CloudDownloadIcon, EmptyDocumentIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { useFormat } from '../../../hooks';
import { StringKey } from '../../../lang';
import { getS3FileOriginalName } from '../../../utils/getS3FileOriginalName';

export type FilesModalProps = {
  onSuccess: () => void;
  onClose: () => void;
  onDownloadFile: (file: string) => void;
  filesLinks: string[];
  name: string | ReactNode;
  createdAt: string;
  stakeholderName?: string;
};

export const FilesModal: FC<FilesModalProps> = ({
  onClose,
  onSuccess,
  onDownloadFile,
  filesLinks,
  name,
  createdAt,
  stakeholderName,
}) => {
  const { format } = useFormat();
  return (
    <div className="m-2 flex max-w-[358px] flex-1 flex-col gap-8 rounded bg-white p-4 xs:max-w-[453px]">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 border-b-[1px] border-gray-100 pb-4">
          <span className="max-w-[358px] truncate text-xl font-semibold text-gray-700">
            {stakeholderName || <AppFormattedMessage id={StringKey.FILES} />}
          </span>
          <span className="text-xs font-[450] text-gray-800">
            {name} - {format(createdAt, 'dd/MM/yyyy')}
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-xs font-[450] text-gray-500">
            <AppFormattedMessage
              id={StringKey.ATTACHED_FILES}
              values={{ count: filesLinks.length }}
            />
          </span>
          <div className="flex flex-col gap-2">
            {filesLinks.map((file) => (
              <div
                className="flex w-full items-center justify-between gap-2 rounded border-[1px] border-brand-50 bg-brand-25 p-3"
                key={file}
              >
                <div className="flex items-center gap-2 overflow-hidden">
                  <EmptyDocumentIcon className="size-4" iconColor="#2565C8" />
                  <span className="truncate text-xs font-[450] text-brand-700">
                    {getS3FileOriginalName(file)}
                  </span>
                </div>
                <CloudDownloadIcon
                  className="size-4 cursor-pointer"
                  onClick={() => onDownloadFile(file)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex h-11 w-full justify-end gap-3">
        <Button
          className="h-full w-fit rounded border border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700"
          onClick={onClose}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="h-full w-fit bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white"
          onClick={onSuccess}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.DOWNLOAD_ALL} />
        </Button>
      </div>
    </div>
  );
};
