import React, {
  DetailedHTMLProps,
  FocusEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { InfoCircle } from '../../assets/icons';
import { Company } from '../../hooks';
import { useForwardRef } from '../../hooks/useForwardRef';
import {
  InputStatus,
  inputStatusToPlaceholderClassNamesMap,
  inputStatusToWrapperClassNamesMap,
} from './constants';

export type InputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: ReactNode;
  type?: HTMLInputTypeAttribute;
  isError?: boolean;
  errorMessage?: false | string | ReactNode;
  isSuccess?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  iconBeforeInput?: ReactNode;
  wrapperClassName?: string;
  onIconClick?: () => void;
  onIconBeforeInputClick?: () => void;
  customStatus?: InputStatus;
  disabledInput?: boolean;
  isShownErrorMessage?: boolean;
  numberOnly?: true;
  maxDecimalPlaces?: number;
  placeholderClassName?: string;
  shouldFormatNumber?: boolean;
  inputClassName?: string;
  iconBeforeInputClassName?: string;
  currencySign?: boolean;
  signAfterValue?: string;
  shouldDisplayZero?: boolean;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'inputMode' | 'placeholder'
>;

const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        value,
        onChange,
        placeholder,
        type,
        isError,
        errorMessage,
        isSuccess,
        disabled,
        icon,
        wrapperClassName,
        onBlur,
        onFocus,
        onIconClick,
        onIconBeforeInputClick,
        iconBeforeInput,
        customStatus,
        disabledInput,
        isShownErrorMessage = true,
        numberOnly,
        maxDecimalPlaces,
        placeholderClassName,
        shouldFormatNumber = false,
        inputClassName,
        iconBeforeInputClassName,
        className,
        currencySign,
        signAfterValue,
        shouldDisplayZero,
        ...props
      },
      ref,
    ) => {
      const stringValue = String(value);

      const { selectedCompany: { currency } = {} } = Company.useSelected();

      const formatNumber = useCallback((value: string): string => {
        const number = parseFloat(value);
        if (isNaN(number)) return value;
        return new Intl.NumberFormat('en-US').format(number);
      }, []);

      const inputRef = useForwardRef<HTMLInputElement>(ref);
      const [isFocused, setIsFocused] = useState(false);
      const [status, setStatus] = useState(InputStatus.IDLE_EMPTY);
      const [inputValue, setInputValue] = useState(value);

      useEffect(() => {
        if (isError || errorMessage) {
          setStatus(InputStatus.ERROR);
          return;
        }

        if (isSuccess) {
          setStatus(InputStatus.SUCCESS);
          return;
        }

        if (isFocused) {
          setStatus(InputStatus.FOCUSED);
          return;
        }

        setStatus(value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
      }, [errorMessage, inputRef, isError, isFocused, isSuccess, value]);

      useEffect(() => {
        if (shouldFormatNumber || numberOnly) {
          if (!shouldDisplayZero && stringValue === '0') {
            setInputValue('');
          } else {
            setInputValue(formatNumber(stringValue));
          }
        } else {
          setInputValue(stringValue);
        }
      }, [stringValue, shouldFormatNumber, formatNumber, numberOnly, shouldDisplayZero]);

      const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(true);
          onFocus?.(event);
        },
        [onFocus, setIsFocused],
      );

      const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(false);
          onBlur?.(event);
        },
        [setIsFocused, onBlur],
      );

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = e;

        const regex = new RegExp(`^\\d*\\.?\\d{0,${maxDecimalPlaces}}$`);
        const numberOnlyRegexp = shouldFormatNumber ? new RegExp(`[^0-9,]`) : new RegExp(`[^0-9]`);

        if (
          (numberOnly && numberOnlyRegexp.test(value)) ||
          (maxDecimalPlaces && !regex?.test(value))
        ) {
          e.preventDefault();
          return;
        }

        const parsedValue = shouldFormatNumber ? value.replace(/,/g, '') : value;

        setInputValue(parsedValue);
        onChange(parsedValue);
      };

      const handleClick = useCallback(() => {
        inputRef.current?.focus();
      }, [inputRef]);

      return (
        <div
          className={twMerge(
            'flex cursor-text flex-col gap-3 disabled:cursor-default',
            wrapperClassName,
          )}
          onClick={handleClick}
        >
          <div
            className={twMerge(
              `relative flex h-14 items-center gap-2.5 rounded-t bg-gray-50 px-2.5 ${inputStatusToWrapperClassNamesMap[customStatus ?? status]} ${disabled && 'cursor-default !border-transparent'}`,
              inputClassName,
            )}
          >
            {iconBeforeInput && (
              <div
                className={twMerge(onIconClick ? 'cursor-pointer' : '', iconBeforeInputClassName)}
                onClick={onIconBeforeInputClick}
              >
                {iconBeforeInput}
              </div>
            )}

            {placeholder && (
              <div
                className={`pointer-events-none absolute left-2.5 top-1/2 -translate-y-1/2 font-inter text-sm font-[450] transition-all duration-75 ${inputStatusToPlaceholderClassNamesMap[status]} ${isFocused || value ? '!top-[8px] !translate-y-0 !text-xs' : ''} ${placeholderClassName}`}
              >
                {placeholder}
              </div>
            )}
            <div className="flex h-full w-full flex-col justify-end pb-2 font-inter text-sm font-[450] text-gray-700">
              {!!value && currencySign && !!currency?.symbol && (
                <span className="translate-y-full">{currency.symbol}</span>
              )}

              <input
                className={twMerge(
                  'z-1 w-full !bg-transparent pr-3 !outline-none',
                  className,
                  !!value && currencySign && 'pl-3',
                  !!value &&
                    currencySign &&
                    currency?.symbol &&
                    currency?.symbol?.length > 1 &&
                    'pl-8',
                )}
                disabled={disabledInput || disabled}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                ref={inputRef}
                type={type}
                value={inputValue ?? ''}
                {...props}
              />
              {!!value && signAfterValue && (
                <span
                  className="absolute"
                  style={{
                    left: `${value.length * 9 + 10}px`,
                  }}
                >
                  {value.length * 9 + 10 > 200 ? '' : signAfterValue}
                </span>
              )}
            </div>
            {icon && (
              <div className={`${onIconClick ? 'cursor-pointer' : ''}`} onClick={onIconClick}>
                {icon}
              </div>
            )}
          </div>
          {errorMessage && isShownErrorMessage && (
            <div className="flex items-center gap-1">
              <InfoCircle />
              <div className="whitespace-pre-line text-left font-inter text-xs font-[450] text-fireside-600">
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      );
    },
  ),
);

Input.displayName = 'Input';

export default Input;
