import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ChevronDownIcon } from '../../../../../assets/icons';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../../components/Combobox';
import Loader from '../../../../../components/Loader';
import { VestingTaskHooks } from '../../../../../hooks';
import { VestingTasksResponse } from '../../../../../hooks/vestingTask';
import { StringKey } from '../../../../../lang';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { VestingTask } from '../../../../../types/vestingTasks.types';
import { AppFormattedMessage } from '../../../../AppFormattedMessage';
import { FormSchema } from '../validation';

type StakeholdersItemsListProps = {
  data: VestingTask[];
  control: Control<FormSchema>;
  name: string;
  hasNextPage: boolean;
  isLoading: boolean;
  selectedGrantIds?: string[];
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<VestingTasksResponse, unknown>, Error>>;
};

const StakeholdersItemsList: FC<StakeholdersItemsListProps> = ({
  data,
  control,
  name,
  hasNextPage,
  fetchNextPage,
  isLoading,
  selectedGrantIds,
}) => {
  const filteredData = data.filter(({ id }) => !selectedGrantIds?.includes(id));
  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={data.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scrollbar-target"
    >
      {filteredData.length > 0 ? (
        <>
          {filteredData.map(({ grantItem, id, exercisedCount, vestedSharesCount }) => (
            <FormComboboxOption
              className="w-full"
              control={control}
              key={id}
              name={name as keyof FormSchema}
              value={
                {
                  id,
                  grantItem,
                  exercisedCount,
                  vestedSharesCount,
                } as unknown as FormSchema[keyof FormSchema]
              }
            >
              <span className="truncate text-sm uppercase text-gray-700">
                {grantItem?.id?.split('-')[0]}
              </span>
            </FormComboboxOption>
          ))}
        </>
      ) : isLoading ? (
        <span className="py-[6px] text-sm font-normal text-gray-700">
          <AppFormattedMessage id={StringKey.LOADING} />
        </span>
      ) : (
        <span className="py-[6px] text-sm font-normal text-gray-700">
          <AppFormattedMessage id={StringKey.NO_GRANTS_FOUND} />
        </span>
      )}
    </InfiniteScroll>
  );
};

export type GrantComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
  stakeholderId: string;
  name: string;
  sharePlanType: SharePlanType;
  selectedGrantIds?: string[];
};

export const GrantCombobox: FC<GrantComboboxProps> = ({
  control,
  companyId,
  name,
  stakeholderId = '',
  sharePlanType,
  selectedGrantIds,
}) => {
  const { vestingTasks, fetchNextPage, hasNextPage, isLoading } = VestingTaskHooks.useVestingTasks({
    companyId,
    stakeholderId,
    sharePlanType,
    exercisable: true,
  });

  return (
    <>
      <FormCombobox
        className="w-full min-w-[130px]"
        control={control}
        disabled={!stakeholderId}
        name={name as keyof FormSchema}
      >
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => (value as VestingTask)?.grantItem?.id?.split('-')[0]}
            icon={<ChevronDownIcon />}
            name={name as keyof FormSchema}
            readOnly
            toUppercase
            wrapperClassName="h-10"
          />
          <ComboboxButton className="absolute left-0 top-0 z-20 h-full w-full" />
        </div>
        <ComboboxOptions className="z-20 max-h-[157px] w-fit max-w-[260px] overflow-x-hidden">
          <StakeholdersItemsList
            control={control}
            data={vestingTasks || []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            name={name}
            selectedGrantIds={selectedGrantIds}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
