import { FC } from 'react';

import safeImportButton from '../../../assets/images/import-safe-button.png';
import importSafeTemplate from '../../../assets/images/import-safe-template-button.png';

export const ImportSafes: FC = () => (
  <>
    <div className="flex flex-col gap-2">
      <span className="text-xl font-semibold text-gray-700">SAFE Import Guide</span>
      <span className="text-xs font-[450] text-gray-700">
        The <strong>SAFE Import</strong> mechanism allows <strong>Admins and Contributors</strong>{' '}
        to quickly add multiple SAFEs at once by uploading a pre-formatted Excel file This ensures
        an efficient and accurate process for recording SAFE agreements
      </span>
    </div>
    <h1 className="text-[16px] font-semibold leading-[2] text-gray-700">Step-by-Step Guide</h1>
    <div className="flex flex-col gap-2" id="main-step-1">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 1: Open the SAFE Import Blade
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Navigate to the <strong>SAFEs</strong> module in CapQuest
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click on the <strong>Import SAFEs</strong> option to open the import flow
        </li>
      </ul>
      <img src={safeImportButton} width="320" />
    </div>
    <div className="flex flex-col gap-2" id="main-step-2">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 2: Download the Excel Template
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Click the <strong>Download Template</strong> button
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The downloaded file will include:
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 1</strong>: "SAFE Upload" (editable)
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 2</strong>: "Dropdowns" (read-only)
            </li>
          </ul>
        </li>
      </ul>
      <img src={importSafeTemplate} width="320" />
    </div>
    <div className="flex flex-col gap-2" id="main-step-3">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 3: Understanding the Excel Template
      </h2>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          <strong>Sheet 1: "SAFE Upload" (Editable)</strong> is the main{' '}
          <strong>working sheet</strong> where you enter SAFE details
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Each row represents a <strong>single SAFE entry</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The following <strong>fields must be filled out</strong>:
        </li>
        <ul className="flex list-disc flex-col gap-2 pl-5">
          <li className="text-xs font-[450] text-gray-700">
            <strong>Stakeholder Email</strong> (Must match an existing stakeholder in the company
            from <strong>Sheet 2: Dropdowns</strong>)
          </li>
          <li className="text-xs font-[450] text-gray-700">
            <strong>Investment</strong>
          </li>
          <li className="text-xs font-[450] text-gray-700">
            <strong>Issue Date</strong>
          </li>
          <li className="text-xs font-[450] text-gray-700">
            <strong>Cap or Discount</strong> (At least one of these fields must be filled in)
          </li>
        </ul>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Optional Fields</strong>:
        </li>
        <ul className="flex list-disc flex-col gap-2 pl-5">
          <li className="text-xs font-[450] text-gray-700">
            <strong>Floor</strong>
          </li>
          <li className="text-xs font-[450] text-gray-700">
            <strong>Maturity Date</strong>
          </li>
          <li className="text-xs font-[450] text-gray-700">
            <strong>Additional Notes</strong>
          </li>
        </ul>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Important Notes</strong>:
        </li>
        <ul className="flex list-disc flex-col gap-2 pl-5">
          <li className="text-xs font-[450] text-gray-700">
            If a row is missing any <strong>mandatory</strong> fields, the system{' '}
            <strong>will reject the file</strong>
          </li>
        </ul>
        <li className="list-none text-xs font-[450] text-gray-700">
          <strong>Sheet 2: "Dropdowns" (Read-Only)</strong> contains{' '}
          <strong>list of stakeholder emails added in your company</strong>
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">Do not modify this sheet</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-4">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 4: Fill in the SAFE Details
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Open <strong>Sheet 1</strong> ("SAFE Upload")
        </li>
        <li className="text-xs font-[450] text-gray-700">Enter the required SAFE details</li>
        <li className="text-xs font-[450] text-gray-700">Save the completed file</li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-5">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 5: Upload the Filled Template
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Return to the <strong>SAFE Import</strong> blade
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click <strong>Upload</strong> or <strong>drag and drop</strong> the completed Excel file
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The system will validate the file and display a result of SAFEs import
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="key-notes-and-tips">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">Key Notes and Tips</h2>
      <ol className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Ensure <strong>mandatory fields</strong> are filled out before uploading; missing
          information will result in upload errors
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Do not modify <strong>Sheet 2 (Dropdowns)</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          If an error occurs, review the format and re-upload the corrected file
        </li>
      </ol>
    </div>
  </>
);
