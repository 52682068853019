import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { StringKey } from '../../../lang';
import { CompanySettingsCardComponentProps } from '../CompanySettings';
import { TableItem } from './TableItem';

export type BillingHistoryProps = CompanySettingsCardComponentProps;

export const BillingHistory: FC<BillingHistoryProps> = ({ data }) => {
  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  const invoices = data?.invoices;

  return (
    <>
      <div className="w-full shrink-0 overflow-x-auto *:text-nowrap">
        <table className="h-fit w-full divide-y-0 bg-white">
          <thead>
            <tr className="h-11 border-b-[1px] border-[#F2F2F2] bg-gray-50">
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.INVOICE_NO} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.DATE} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.PLAN} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.PAYMENT_FREQUENCY} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.TOTAL} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.STATUS} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.INVOICE} />
                  </span>
                </div>
              </td>
            </tr>
          </thead>

          <tbody className="w-full !divide-y-0 bg-white">
            {invoices &&
              invoices?.length > 0 &&
              invoices.map((invoice) => (
                <TableItem
                  key={invoice.id}
                  planType={
                    data?.latestSubscriptionData?.items?.data[0]?.price?.product?.metadata?.type
                  }
                  {...invoice}
                  handleDocumentClick={handleDocumentClick}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
