import { create } from 'zustand';

import { cookieSettingsStore, UserCookies } from '../storage/cookie-settings';

type Store = {
  cookieSettings: UserCookies;
  isOpenCookieModal: boolean;
  toggleCookieModalOpenState: (state: boolean) => void;
  setCookies: (userCookies: Partial<UserCookies>) => void;
};

export const useCookies = create<Store>()((set) => {
  const cookieSettings = cookieSettingsStore.get();

  return {
    isOpenCookieModal: !cookieSettings,
    toggleCookieModalOpenState: (state) => set(() => ({ isOpenCookieModal: state })),
    cookieSettings: cookieSettings || {
      essential: false,
      analytics: false,
      functional: false,
      marketing: false,
    },

    setCookies: (newSettings) =>
      set((state) => {
        const newCookieSettings = { ...state.cookieSettings, ...newSettings };

        cookieSettingsStore.set(newCookieSettings);

        return { cookieSettings: newCookieSettings };
      }),
  };
});
