import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchUserNotificationSettings } from '../../types/userTypes';
import { useAppMutation } from '../useAppMutation';

export enum NotificationSettingsType {
  IN_APP = 'in-app',
  EMAIL = 'email',
}

export const useUpdateNotificationSettings = () => {
  const { mutate: update, isPending } = useAppMutation(
    [QueryKey.PATCH_USER_NOTIFICATION_SETTINGS],
    {
      mutationFn: ({
        data,
        type,
      }: {
        data: PatchUserNotificationSettings;
        type: NotificationSettingsType;
      }) =>
        ApiService.patch(
          {
            endpoint: BackendRoute.USERS,
            routePath: [
              'notification-settings',
              type === NotificationSettingsType.EMAIL ? 'email' : 'in-app',
            ],
          },
          { body: data },
        ),
    },
  );
  return { update, isPending };
};
