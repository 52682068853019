import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { z } from 'zod';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { FormInput } from '../../../components/Input';
import { useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { getTranslation } from '../../../utils/getTranslation';

const regex = /^(?![_\-.])[a-zA-Z0-9 _.-]+(?<![_\-.])$/;

export const formSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(255, { message: getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 255 }) })
    .regex(regex, { message: getTranslation(StringKey.INVALID_NAME) }),
});

export type FormSchema = z.infer<typeof formSchema>;

export type CreateModalProps = {
  onSuccess: (name: string | undefined) => void;
  onClose: () => void;
  folderNames?: string[];
};

export const CreateModal: FC<CreateModalProps> = ({ onClose, onSuccess, folderNames }) => {
  const { control } = useReactForm({
    schema: formSchema,
  });
  const { name } = useWatch<FormSchema>({ control });
  const { success } = formSchema.safeParse({ name });
  const isNameDuplicates = name ? folderNames?.includes(name?.toLowerCase()) : false;

  const isValid = success && !isNameDuplicates;

  const handleCreate = () => {
    onSuccess(name);
  };
  return (
    <div className="m-2 flex w-full max-w-[493px] flex-col gap-8 rounded bg-white p-4">
      <div className="flex flex-col gap-4">
        <span className="text-xl font-semibold text-gray-700">
          <AppFormattedMessage id={StringKey.CREATE_NEW_FOLDER} />
        </span>
        <FormInput
          control={control}
          errorMessage={isNameDuplicates && `${name} already exists`}
          name={'name'}
          placeholder={<AppFormattedMessage id={StringKey.FOLDER} />}
          wrapperClassName={'transition-all duration-1000 w-full'}
        />
      </div>
      <div className="flex h-11 w-full justify-end gap-3">
        <Button
          className="h-full w-fit rounded border border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700"
          onClick={onClose}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="h-full w-fit bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white"
          disabled={!isValid}
          onClick={handleCreate}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CREATE} />
        </Button>
      </div>
    </div>
  );
};
