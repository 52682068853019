import React, { FC } from 'react';

import { CircleConfirmIcon, CloseCircleIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { useFormat } from '../../hooks';
import { StringKey } from '../../lang';
import { ShareClass } from '../../types/share-classes.types';

export type ShareClassItemProps = Pick<
  ShareClass,
  | 'antiDilutionRights'
  | 'conversionRatio'
  | 'creationDate'
  | 'dividendRightEnabled'
  | 'id'
  | 'liquidityPreferences'
  | 'name'
  | 'votingRight'
  | 'votingRightEnabled'
  | 'isUsed'
  | 'stakeholders'
>;

export const ShareClassItem: FC<ShareClassItemProps> = ({
  conversionRatio,
  dividendRightEnabled,
  name,
  stakeholders,
  votingRight,
  votingRightEnabled,
  antiDilutionRights,
  creationDate = new Date(),
  liquidityPreferences,
  isUsed,
}) => {
  const { format } = useFormat();

  return (
    <div className="flex w-full max-w-[432px] flex-col divide-y-[1px] divide-gray-100 rounded-md bg-white shadow-sm">
      <div className="flex w-full items-center justify-between gap-8 p-3">
        <div className="flex flex-col gap-3 overflow-hidden">
          <span className="w-full truncate text-base font-[550] text-gray-700">{name}</span>
          <span className="text-xs font-[450] text-gray-700">
            {format(creationDate, 'dd/MM/yyyy')}
          </span>
        </div>
        {isUsed ? (
          <span className="shrink-0 rounded-2xl bg-forest-50 px-2 py-[2px] text-label-md font-[450] text-forest-600">
            <AppFormattedMessage id={StringKey.FILTER_IN_USE} />
          </span>
        ) : (
          <span className="shrink-0 rounded-2xl bg-fireside-50 px-2 py-[2px] text-label-md font-[450] text-fireside-600">
            <AppFormattedMessage id={StringKey.FILTER_NOT_USED} />
          </span>
        )}
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.STAKEHOLDERS} />
        </span>
        <span className="text-sm font-[450] text-gray-700">{stakeholders}</span>
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-4 p-3">
        <span className="text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.STAKEHOLDERS} />
        </span>
        <div className="flex gap-3 text-xs font-normal">
          <div className="flex items-center gap-1">
            {dividendRightEnabled ? <CircleConfirmIcon /> : <CloseCircleIcon className="size-4" />}
            <AppFormattedMessage id={StringKey.DIVIDENDS} />
          </div>

          <div className="flex items-center gap-1">
            {antiDilutionRights ? <CircleConfirmIcon /> : <CloseCircleIcon className="size-4" />}
            <AppFormattedMessage id={StringKey.ANTI_DILUTION} />
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.VOTING_RIGHTS} />
        </span>
        <span className="text-sm font-[450] text-gray-700">
          {votingRightEnabled ? votingRight : '-'}
        </span>
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.CONVERSION_RATIO} />
        </span>
        <span className="text-sm font-[450] text-gray-700">
          {conversionRatio ? conversionRatio.toFixed(2) + 'x' : '-'}
        </span>
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.SENIORITY} />
        </span>
        <span className="text-sm font-[450] text-gray-700">
          {liquidityPreferences ? liquidityPreferences?.seniority : '-'}
        </span>
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-4 overflow-hidden p-3">
        <span className="text-sm font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.LIQUIDITY_PREFERENCES} />
        </span>
        {liquidityPreferences ? (
          <div className="flex w-fit max-w-[150px] flex-col text-wrap break-all text-xs font-normal text-gray-700">
            <span>
              <AppFormattedMessage id={StringKey.MULTIPLY} />:{liquidityPreferences.multiple}
            </span>
            {liquidityPreferences.participating?.capValue && (
              <span>
                <AppFormattedMessage id={StringKey.PARTICIPATING_CAP} />:
                {liquidityPreferences.participating?.capValue}
              </span>
            )}
            {liquidityPreferences.participating?.interest && (
              <span>
                <AppFormattedMessage id={StringKey.INTEREST} />:
                {liquidityPreferences.participating?.interest}%
              </span>
            )}
          </div>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};
