import { UseMutateFunction } from '@tanstack/react-query';
import React, { FC, memo, ReactNode, useCallback, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckIcon, ChevronDownIcon, EditIcon, LockIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../components/Combobox';
import { CookieSettingsModal } from '../../components/CookiePolicy';
import { FormInput, Input } from '../../components/Input';
import { useCookies } from '../../context/cookies.context';
import { useLocaleContext } from '../../context/locale.context';
import { useLocale, useModalState, useReactForm, useScreenSize } from '../../hooks';
import { localeTitleMap, StringKey } from '../../lang';
import { AuthProvider } from '../../types/authTypes';
import { PatchUserDto, User } from '../../types/userTypes';
import { getErrorMessages } from '../../utils/get-zod-messages';
import { FormSchema, formSchema, PasswordErrorMessage, passwordSchema } from './Validation';

export type ProfileEditFormProps = {
  patchUser: UseMutateFunction<unknown, unknown, PatchUserDto>;
  handleOpenDeleteModal: () => void;
  setEditing: (state: boolean) => void;
  isEditing: boolean;
} & User;

export type PasswordToolTipProps = {
  className?: string;
  fatalIconColor?: false | string;
  text: string;
};

const passwordErrorsText: Record<PasswordErrorMessage, ReactNode> = {
  [PasswordErrorMessage.UPPER_CASE_LETTER_CHECK]: (
    <AppFormattedMessage id={StringKey.AT_LEAST_ONE_UPPER_CASE_LETTER} />
  ),
  [PasswordErrorMessage.LOWER_CASE_LETTER_CHECK]: (
    <AppFormattedMessage id={StringKey.AT_LEAST_ONE_LOWER_CASE_LETTER} />
  ),
  [PasswordErrorMessage.MIN_LENGTH_CHECK]: (
    <AppFormattedMessage id={StringKey.AT_LEAST_EIGHT_CHARS} />
  ),
  [PasswordErrorMessage.SPECIAL_LETTER_CHECK]: (
    <AppFormattedMessage id={StringKey.AT_LEAST_ONE_SPECIAL_CHAR} />
  ),
};

export const PasswordToolTip: FC<PasswordToolTipProps> = memo(
  ({ className, text, fatalIconColor }) => (
    <div className="flex gap-2">
      <CheckIcon
        height={'16px'}
        iconColor={fatalIconColor ? fatalIconColor : '#12B76A'}
        width={'16px'}
      />
      <span className={twMerge('text-xs font-[450]', className)}>{text}</span>
    </div>
  ),
);

PasswordToolTip.displayName = 'PasswordToolTip';

const { checkValue } = getErrorMessages({
  errorMessages: passwordErrorsText,
  schema: passwordSchema,
});

const ProfileEditForm: FC<ProfileEditFormProps> = memo(
  ({ authProvider, email, fullName, patchUser, handleOpenDeleteModal, isEditing, setEditing }) => {
    const { locale, messagesLocale } = useLocale();
    const { setLocale } = useLocaleContext();
    const {
      control,
      watch,
      handleSubmit,
      resetField,
      formState: { isValid },
    } = useReactForm({
      disabled: !isEditing,
      schema: formSchema,
      defaultValues: {
        fullName,
        confirmPassword: '',
        password: '',
      },
      values: {
        fullName,
        confirmPassword: '',
        password: '',
        language: locale,
      },
    });
    const { width } = useScreenSize();
    const { toggleCookieModalOpenState, isOpenCookieModal } = useCookies();

    const {
      isOpen: isOpenCookieSettingsModal,
      handleOpenModal: handleOpenCookieSettingsModal,
      handleCloseModal: handleCloseCookieSettingsModal,
    } = useModalState();

    useEffect(() => {
      if (isEditing) {
        resetField('password');
        resetField('confirmPassword');
      }
    }, [isEditing, resetField]);

    const submitHandler = useCallback(
      (data: FormSchema) => {
        patchUser(data);
        setLocale(data.language);
      },
      [patchUser, setLocale],
    );
    const passwordValue = watch('password') ?? '';

    const passwordChecksState = checkValue(passwordValue || '');
    const isMobile = width <= 1023;

    const handleOpenAndCloseModal = () => {
      if (isOpenCookieModal) {
        toggleCookieModalOpenState(false);
      }
      handleOpenCookieSettingsModal();
    };
    return (
      <>
        <CookieSettingsModal
          handleCloseModal={handleCloseCookieSettingsModal}
          handleOpenCookieModal={() => toggleCookieModalOpenState(true)}
          isOpenModal={isOpenCookieSettingsModal}
        />

        <div className="flex flex-wrap items-center gap-4">
          <div className="flex w-full max-w-[430px] flex-col justify-center gap-4 overflow-y-auto rounded bg-white p-4 shadow-sm">
            <div className="flex w-full flex-col items-center justify-between gap-6">
              <div className="flex w-full items-start justify-between lg:items-center">
                <div className="flex w-fit gap-3">
                  <div
                    className="flex size-10 shrink-0 items-center justify-center self-center rounded-full bg-brand-100 font-[450] text-[#344054] lg:size-16"
                    style={{
                      fontSize: isMobile ? '17.5px' : '28px',
                      lineHeight: isMobile ? '25px' : '40px',
                    }}
                  >
                    {fullName[0]}
                  </div>
                  <div className="flex flex-col">
                    <span className="max-w-[200px] truncate text-xl font-[550] text-black lg:max-w-[280px]">
                      {fullName}
                    </span>
                    <div className="flex items-center gap-1">
                      <span className="max-w-[240px] truncate text-xs font-[450] text-gray-500 lg:text-sm">
                        {email}
                      </span>
                      <LockIcon height={'16px'} width={'16px'} />
                    </div>
                  </div>
                </div>

                {!isEditing && (
                  <Button
                    className="size-6 h-fit w-fit rounded p-1 transition-colors hover:bg-gray-100"
                    onClick={() => setEditing(true)}
                    styleType="NONE"
                  >
                    <EditIcon className="size-6" iconColor="#2565C8" />
                  </Button>
                )}
              </div>
              <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit(submitHandler)}>
                <FormInput
                  control={control}
                  name="fullName"
                  placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
                />
                {authProvider === AuthProvider.EMAIL && (
                  <>
                    {isEditing ? (
                      <>
                        <FormInput
                          control={control}
                          icon={!isEditing && <LockIcon />}
                          name="password"
                          placeholder={<AppFormattedMessage id={StringKey.PASSWORD} />}
                          type="password"
                        />

                        <FormInput
                          control={control}
                          name="confirmPassword"
                          placeholder={<AppFormattedMessage id={StringKey.CONFIRM_PASSWORD} />}
                          type="password"
                        />
                      </>
                    ) : (
                      <Input
                        disabled
                        icon={<LockIcon height={'24px'} width={'24px'} />}
                        onChange={() => ''}
                        placeholder={<AppFormattedMessage id={StringKey.PASSWORD} />}
                        value={'********************'}
                      />
                    )}
                    {isEditing && passwordValue && (
                      <>
                        <div className="left-[550px] flex h-fit w-full flex-col gap-4 rounded-md bg-gray-50 p-4 shadow-sm lg:absolute lg:w-fit">
                          <div className="absolute -left-[6px] top-1/2 size-3 -rotate-45 border border-b-transparent border-r-transparent bg-gray-50" />
                          <span className="text-sm font-[450] text-gray-700">
                            <AppFormattedMessage id={StringKey.PASSWORD_RECOMMENDATION} />
                          </span>
                          <div className="flex flex-col gap-3">
                            {passwordChecksState?.map(({ fatal, message }) => (
                              <PasswordToolTip
                                className={fatal ? 'text-gray-300' : 'text-forest-500'}
                                fatalIconColor={fatal && '#D0D5DD'}
                                key={message}
                                text={message}
                              />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                <FormCombobox control={control} name="language">
                  <div className="relative">
                    <FormComboboxInput
                      control={control}
                      customValue={(value) =>
                        value ? messagesLocale[localeTitleMap[value]] : value
                      }
                      icon={<ChevronDownIcon className={twMerge('mt-3', !isEditing && 'hidden')} />}
                      name="language"
                      placeholder="Language"
                      readOnly
                      wrapperClassName={!isEditing && 'border-none'}
                    />
                    <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
                  </div>

                  <ComboboxOptions>
                    {Object.entries(localeTitleMap).map(([localeKey, locale]) => (
                      <FormComboboxOption
                        className="!text-sm"
                        control={control}
                        key={localeKey}
                        name="language"
                        value={localeKey}
                      >
                        {messagesLocale[locale]}
                      </FormComboboxOption>
                    ))}
                  </ComboboxOptions>
                </FormCombobox>

                {isEditing && (
                  <div className="flex w-full justify-end gap-4 pt-4">
                    <Button
                      className="border-[1px] border-gray-100 px-3 py-[6px] text-sm text-gray-700 transition-colors hover:bg-gray-100"
                      onClick={() => {
                        setEditing(false);
                        resetField('fullName');
                      }}
                      styleType="DEFAULT_ROUNDED"
                    >
                      <AppFormattedMessage id={StringKey.CANCEL} />
                    </Button>
                    <Button
                      className="w-full px-4 py-[10px] text-sm font-[550] text-gray-25 transition-colors hover:bg-brand-600"
                      disabled={!isValid}
                      type="submit"
                    >
                      <AppFormattedMessage id={StringKey.UPDATE} />
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="flex w-full max-w-[430px] rounded-md bg-white p-4 shadow-sm">
          <Button
            className="h-fit w-full justify-start text-sm text-gray-700 underline"
            onClick={handleOpenAndCloseModal}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.COOKIE_SETTINGS} />
          </Button>
        </div>
        <div className="flex w-full max-w-[430px] justify-between rounded-md bg-white p-4 shadow-sm">
          <span className="text-nowrap text-sm text-gray-700">
            <AppFormattedMessage id={StringKey.DELETE_YOUR_CAPQUEST_ACCOUNT} />
          </span>
          <Button
            className="h-fit w-fit text-sm text-fireside-500"
            onClick={handleOpenDeleteModal}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.DELETE} />
          </Button>
        </div>
      </>
    );
  },
);

ProfileEditForm.displayName = 'ProfileEditForm';

export default ProfileEditForm;
