import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CapQuestLogo } from '../../assets/icons';
import { useScreenSize } from '../../hooks';
import { StringKey } from '../../lang';
import { TermsTab } from '../../pages/Terms';
import { cookieSettingsStore } from '../../storage/cookie-settings';
import { PageRoute } from '../../types/pageTypes';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import { Sheet, SheetContent } from '../Sheet';

export type CookiePolicyModalProps = {
  handleCloseModal: () => void;
  handleOpenSettingsModal: () => void;
  isOpenModal: boolean;
};

export const CookiePolicyModal: FC<CookiePolicyModalProps> = ({
  isOpenModal,
  handleOpenSettingsModal,
  handleCloseModal,
}) => {
  const { isMobile } = useScreenSize();

  const handleCustomize = () => {
    handleCloseModal();
    handleOpenSettingsModal();
  };

  const handleAcceptAll = () => {
    cookieSettingsStore.set({
      essential: true,
      functional: true,
      analytics: true,
      marketing: true,
    });
    handleCloseModal();
  };

  const handleDeclineAll = () => {
    cookieSettingsStore.set({
      essential: true,
      functional: false,
      analytics: false,
      marketing: false,
    });
    handleCloseModal();
  };

  return (
    <Sheet modal={false} open={isOpenModal}>
      <SheetContent
        className="m-auto flex h-fit w-fit justify-center border-transparent bg-transparent p-2 pb-6 shadow-none"
        side="BOTTOM"
      >
        <div
          className={twMerge(
            'flex w-full max-w-[1269px] flex-row items-center justify-between gap-2 rounded-2xl border-[1px] border-gray-100 bg-white p-4 shadow-2xl',
            isMobile && 'flex-col px-4 py-2',
          )}
        >
          <div className="flex items-center gap-4">
            <CapQuestLogo />
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.COOKIE_CONSENT_TEXT} />{' '}
              <Link
                className="inline h-fit w-fit font-[550] text-brand-700 underline"
                onClick={() => window.scrollTo(0, 0)}
                state={{ selectedTab: TermsTab.POLICY }}
                to={PageRoute.TERMS}
              >
                <AppFormattedMessage id={StringKey.MORE_INFO} />
              </Link>
            </span>
          </div>
          <div className="flex items-center gap-3">
            <Button
              className="w-fit text-nowrap bg-transparent px-6 py-[10px] text-sm font-[550] text-gray-700"
              onClick={handleCustomize}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.CUSTOMIZE} />
            </Button>
            <Button
              className="w-fit text-nowrap border-[1px] border-gray-300 bg-transparent px-6 py-[10px] text-sm font-[550] text-gray-700"
              onClick={handleDeclineAll}
              styleType="NONE"
              type="button"
            >
              {isMobile ? (
                <AppFormattedMessage id={StringKey.DECLINE_ALL} />
              ) : (
                'Reject non-essential'
              )}
            </Button>
            <Button
              className="h-full w-fit text-nowrap px-6 py-[10px] text-sm font-[550] text-white"
              onClick={handleAcceptAll}
              type="button"
            >
              <AppFormattedMessage id={StringKey.ACCEPT_ALL} />
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
