import { FC } from 'react';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { useFormat, ValuationHooks } from '../../../../../hooks';
import {
  EventExercisedTypeBackend,
  eventFormType,
  EventTypeBackend,
} from '../../../../../types/events.types';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { toNumber } from '../../../../../utils/toNumber';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const Payout: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  const { valuation } = ValuationHooks.useCurrent({ companyId: props.companyId });
  if (props.type !== EventTypeBackend.PAYOUT) return <></>;

  const {
    type,
    exercise: { items },
    createdAt,
  } = props;

  const planType = items[0]?.vestingTask?.grantItem?.plan?.type;
  return (
    <>
      {planType === SharePlanType.PHANTOM ? (
        <BasicTable eventType={EventExercisedTypeBackend.PHANTOM}>
          {items.map(({ id, exercised, vestingTask, payout }) => (
            <tr key={id}>
              <Td value={vestingTask.grantItem.stakeholder.fullName} />
              <Td className="uppercase" value={vestingTask.grantItem.id.split('-')[0]} />
              <Td value={format(createdAt, 'dd/MM/yyyy')} />
              <Td value={payout && payout / exercised} />
              <Td value={exercised?.toLocaleString('en-US')} />
              <Td value={payout && payout?.toLocaleString('en-US')} />
            </tr>
          ))}
        </BasicTable>
      ) : (
        <BasicTable eventType={EventTypeBackend.PAYOUT}>
          {items.map(({ id, exercised, vestingTask }) => (
            <tr key={id}>
              <Td value={format(createdAt, 'dd/MM/yyyy')} />
              <Td value={<AppFormattedMessage id={eventFormType[type]} />} />
              <Td value={vestingTask.grantItem.stakeholder.fullName} />
              <Td value={exercised} />
              <Td value={`${exercised * toNumber(valuation?.sharePrice)}`} />
              <Td value={vestingTask.grantItem.numbersOfGrants.toLocaleString('en-US')} />
            </tr>
          ))}
        </BasicTable>
      )}
    </>
  );
};
