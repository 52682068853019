import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { useSelected } from '../../../../hooks/company';
import { StringKey } from '../../../../lang';
import { toRound } from '../../../../utils/getRoundedNumber';
import { ConvertSafeItem } from '../ConversionSummary/ConvertSafeItem';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  formData,
  prevFormStep,
  companyId,
  isLoading,
}) => {
  const { selectedCompany } = useSelected();
  const { stepOne, stepThree } = formData();
  const { format } = useFormat();

  const findSafeFiles = (safeId: string) => {
    return stepOne.safes.find((safe) => safe.id === safeId)?.files || [];
  };
  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.GENERAL} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.CONVERSION_DATE} />}
              value={format(stepOne?.conversionDate, 'dd/MM/yyyy')}
            />

            <SummaryItem
              title={<AppFormattedMessage id={StringKey.LINKED_EVENT} />}
              value={stepOne?.linkedEvent?.name}
            />
            <SummaryItem
              className="pb-0"
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
              value={stepOne?.shareClass?.name}
            />
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.SELECTED_SAFES} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white px-4 py-1">
            {stepOne?.safes?.map((safe) => (
              <SummaryItem
                key={safe.id}
                title={safe?.stakeholder?.fullName}
                value={`${selectedCompany?.currency?.symbol} ${safe?.investment.toLocaleString('en-US')} ${format(safe?.issueDate, 'dd/MM/yyyy')}`}
              />
            ))}
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.GENERAL_CONVERSION_SUMMARY} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white px-4 py-1">
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.EVENT_SHARE_PRICE_POST_CONVERSION} />}
              value={`${selectedCompany?.currency?.symbol} ${toRound(stepOne?.conversionSummary?.sharePrice, 2)}`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.SAFE_INVESTMENT_CONVERSION_SUM} />}
              value={`${selectedCompany?.currency?.symbol} ${stepOne?.conversionSummary?.investment?.toLocaleString('en-US')}`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />}
              value={`${selectedCompany?.currency?.symbol}  ${stepOne?.conversionSummary?.preMoneyValuation?.toLocaleString('en-US')}`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.TOTAL_PERCENT_OWNED_SAFE} />}
              value={` ${stepOne?.conversionSummary?.totalOwned}%`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.CONVERTED_SHARES} />}
              value={toRound(stepOne?.conversionSummary?.convertedShares, 0).toLocaleString(
                'en-US',
              )}
            />
          </div>
        </div>

        <div className="mt-2 flex w-full flex-col gap-4 rounded-lg p-4 shadow-sm">
          <span className="text-label-md font-[500] text-gray-500">
            <AppFormattedMessage id={StringKey.CONVERSION_DETAILS} />
          </span>
          <div className="flex w-full flex-col gap-2">
            {stepOne?.safeIds?.map((safeId) => (
              <>
                <ConvertSafeItem
                  allSafeIds={stepOne?.safeIds}
                  companyId={companyId}
                  currencySign={selectedCompany?.currency?.symbol}
                  documents={findSafeFiles(safeId)}
                  key={safeId}
                  safeId={safeId}
                  valuationId={stepOne?.linkedEvent?.id}
                />
              </>
            ))}
          </div>
        </div>

        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {stepThree?.files?.some(({ docLink }) => docLink) ? (
                stepThree.files.map(
                  ({ doc, docLink }) =>
                    docLink && (
                      <span
                        className="truncate text-xs font-[450] text-gray-700"
                        key={docLink + doc.name}
                      >
                        {doc.name}
                      </span>
                    ),
                )
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
              {stepThree?.additionalNotes || '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            isLoading={isLoading}
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.CONVERT} />
          </Button>
        </div>
      </div>
    </>
  );
};
