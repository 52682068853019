import { z } from 'zod';

import { StringKey } from '../../../lang';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];
export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

export const subFilesSchema = z.array(
  z.object({
    docLink: z.string(),
    loadProgress: z.number().max(100),
    abort: z.function(),
    id: z.string(),
    doc: z
      .custom<File>()
      .or(
        z.object({
          size: z.number(),
          type: z.string(),
          name: z.string(),
        }),
      )
      .refine(
        ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
        getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
      )
      .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
  }),
);

export const stepOne = z.object({
  conversionDate: z.coerce.date(),
  linkedEvent: z.object({
    name: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
    id: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
    sharePrice: z.coerce.number(),
    issuedSharesOnStart: z.coerce.number(),
    issuedShares: z.coerce.number(),
  }),
  shareClass: z.object({
    name: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
    id: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  }),
  safeIds: z
    .array(z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }))
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  safes: z
    .array(
      z.object({
        id: z
          .string()
          .trim()
          .min(1, { message: getTranslation(StringKey.REQUIRED) }),
        stakeholder: z.object({
          fullName: z
            .string()
            .trim()
            .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          id: z
            .string()
            .trim()
            .min(1, { message: getTranslation(StringKey.REQUIRED) }),
        }),
        investment: z.coerce
          .number()
          .min(1, { message: getTranslation(StringKey.INVESTMENT_AMOUNT_REQUIRED) }),
        issueDate: z.coerce.date(),
        floorValue: z.coerce.number(),
        files: subFilesSchema.optional(),
      }),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  conversionSummary: z
    .object({
      sharePrice: z.coerce.number(),
      investment: z.coerce.number(),
      preMoneyValuation: z.coerce.number(),
      totalOwned: z.coerce.number(),
      convertedShares: z.coerce.number(),
    })
    .optional(),
});

export const stepThree = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepThree,
});

export type FormSchema = z.infer<typeof formSchema>;
