import { FC, Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CapQuestLogo, CrossIcon } from '../../assets/icons';
import Button from '../../components/Button';
import { useScreenSize } from '../../hooks';
import { cookieSections, dpaSections, policySections, termsSections } from './info';

export enum TermsTab {
  TERMS = 'terms',
  POLICY = 'policy',
  COOKIES = 'cookies',
  DPA = 'dpa',
}

const List: FC<{ text: string[] }> = ({ text }) => (
  <ul className="flex flex-col gap-4 pl-10">
    {text.map((info, i) => (
      <li className="list-disc text-xs font-[450] text-gray-700" key={`list-info-${i}`}>
        {info}
      </li>
    ))}
  </ul>
);

export const Terms: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenSize();

  const initialTab = location.state?.selectedTab || TermsTab.TERMS;
  const [selectedTab, setTab] = useState<TermsTab>(initialTab);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  let count = 0;

  return (
    <div className="relative flex h-screen w-full flex-col gap-2 bg-brand-25">
      <div className="flex w-full justify-between rounded-b-3xl bg-white px-8 py-3">
        <div className="flex items-center gap-2" onClick={() => navigate('/')}>
          <CapQuestLogo size={32} />
          <span className="text-xl font-semibold text-gray-700">CapQuest</span>
        </div>
        <Button
          className="shadow-xxs size-9 rounded-full border-[1px] border-[#f2f2f2] bg-white"
          onClick={() => navigate('/')}
        >
          <CrossIcon className="size-4" />
        </Button>
      </div>

      <div className="flex h-full w-full flex-col gap-8 rounded-t-3xl bg-white px-8 py-4">
        <div className="flex h-[38px] w-full gap-4 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              selectedTab === TermsTab.TERMS && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(TermsTab.TERMS);
              setTimeout(() => {
                scrollToSection(`${termsSections[0].title}-1`);
              }, 0);
            }}
            styleType="NONE"
          >
            Terms of service
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              selectedTab === TermsTab.POLICY && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(TermsTab.POLICY);
              setTimeout(() => {
                scrollToSection(`heading`);
              }, 0);
            }}
            styleType="NONE"
          >
            Privacy Policy
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              selectedTab === TermsTab.COOKIES && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(TermsTab.COOKIES);
              setTimeout(() => {
                scrollToSection(`cookie-heading`);
              }, 0);
            }}
            styleType="NONE"
          >
            Cookie Policy
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              selectedTab === TermsTab.DPA && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(TermsTab.DPA);
              setTimeout(() => {
                scrollToSection(`${dpaSections[0].title}-1`);
              }, 0);
            }}
            styleType="NONE"
          >
            {isMobile ? 'DPA' : 'Data Processing Agreement'}
          </Button>
        </div>

        <div className="flex w-full gap-6">
          <div className="hidden h-fit max-h-[80vh] w-max flex-col gap-2 rounded-md py-4 shadow-sm xlg:flex">
            <span
              className="text-nowrap px-6 py-2 text-label-sm font-bold uppercase text-gray-400"
              style={{ letterSpacing: '1px' }}
            >
              On this page
            </span>
            {selectedTab === TermsTab.TERMS && (
              <div className="flex h-full w-full flex-col gap-[2px] overflow-y-auto">
                {termsSections.map((section, i) => (
                  <div
                    className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                    key={`${section.title}-${i + 1}`}
                    onClick={() => {
                      scrollToSection(`${section.title}-${i + 1}`);
                    }}
                  >
                    {section.title}
                  </div>
                ))}
              </div>
            )}
            {selectedTab === TermsTab.POLICY && (
              <div className="flex w-full flex-col gap-[2px]">
                <div
                  className="w-full cursor-pointer text-nowrap p-2 px-6 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                  onClick={() => scrollToSection('heading')}
                >
                  Policy Section Heading
                </div>
                {policySections.map((section, i) => (
                  <div
                    className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                    key={`${section.title}-${i + 1}`}
                    onClick={() => scrollToSection(`${section.title}-${i + 1}`)}
                  >
                    {section.title}
                  </div>
                ))}
              </div>
            )}
            {selectedTab === TermsTab.COOKIES && (
              <div className="flex h-full w-full flex-col gap-[2px] overflow-y-auto">
                {cookieSections.map(({ title }, i) => (
                  <div
                    className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                    key={`${title}-${i + 1}`}
                    onClick={() => scrollToSection(`${title}-${i + 1}`)}
                  >
                    {title}
                  </div>
                ))}
              </div>
            )}
            {selectedTab === TermsTab.DPA && (
              <div className="flex h-full w-full flex-col gap-[2px] overflow-y-auto">
                {dpaSections.map(({ title }, i) => (
                  <div
                    className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                    key={`${title}-${i + 1}`}
                    onClick={() => scrollToSection(`${title}-${i + 1}`)}
                  >
                    {title}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex h-[80vh] w-full flex-col gap-2 overflow-y-auto rounded-md p-4 shadow-sm">
            {selectedTab === TermsTab.TERMS && (
              <>
                {termsSections.map(({ title, text }, i) => (
                  <div
                    className="flex flex-col gap-2"
                    id={`${title}-${i + 1}`}
                    key={`${title}-${i + 1}`}
                  >
                    <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">{title}</h2>
                    {text?.map((info, i) => {
                      if (!Array.isArray(info)) count++;

                      return (
                        <Fragment key={`${title}-text-${i}`}>
                          {Array.isArray(info) ? (
                            <List text={info} />
                          ) : (
                            <span className="text-xs font-[450] text-gray-700">{`${count}. ${info}`}</span>
                          )}
                          <br />
                        </Fragment>
                      );
                    })}
                  </div>
                ))}
                <span className="text-xs font-[450] text-gray-700">
                  Last updated on 16 December 2024
                </span>
              </>
            )}

            {selectedTab === TermsTab.POLICY && (
              <>
                <div className="flex flex-col gap-2" id="heading">
                  <span className="text-xl font-semibold text-gray-700">Privacy policy</span>
                  <span className="text-xs font-[450] text-gray-700">
                    CapQuest Software Limited (“CapQuest,” “we,” “us,” or “our”) respects your
                    privacy and is committed to protecting your personal data. This Privacy Policy
                    outlines how we collect, use, disclose and protect your personal information
                    when you use our website (the “Website”), application (the “Application”) and
                    services (the "Services").
                  </span>
                </div>
                {policySections.map(({ title, text }, i) => (
                  <div
                    className="flex flex-col gap-2"
                    id={`${title}-${i + 1}`}
                    key={`${title}-${i + 1}`}
                  >
                    <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">{title}</h2>
                    {text?.map((info, i) => (
                      <Fragment key={`${title}-text-${i}`}>
                        {Array.isArray(info) ? (
                          <List text={info} />
                        ) : (
                          <span className="text-xs font-[450] text-gray-700">{info}</span>
                        )}
                        <br />
                      </Fragment>
                    ))}
                  </div>
                ))}
                <span className="text-xs font-[450] text-gray-700">
                  Last updated on 16 December 2024
                </span>
              </>
            )}

            {selectedTab === TermsTab.COOKIES && (
              <>
                <div className="flex flex-col gap-2" id="cookie-heading">
                  <span className="text-xl font-semibold text-gray-700">Cookie Policy</span>
                  <span className="text-xs font-[450] text-gray-700">
                    This Cookie Policy (the “Policy”) explains how CapQuest Software Limited ("we,"
                    "us," "our") uses cookies and similar technologies on our website
                    www.capquest.io (the "Website") and our application app.capquest.io (the
                    "Application"). The Policy provides information about what cookies are, how we
                    use them and your rights to control our use of them.
                  </span>
                  <span className="text-xs font-[450] text-gray-700">
                    We respect your privacy and are committed to processing personal data in
                    accordance with applicable laws. For more information on how we handle your
                    personal data, please see our Privacy Policy available on our Website. This
                    Policy should be read together with our Privacy Policy.
                  </span>
                </div>
                {cookieSections.map(({ title, text }, i) => {
                  return (
                    <div
                      className="flex flex-col gap-2"
                      id={`${title}-${i + 1}`}
                      key={`${title}-${i + 1}`}
                    >
                      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                        {title}
                      </h2>
                      {text?.map((info, i) => {
                        return (
                          <Fragment key={`${title}-text-${i}`}>
                            {Array.isArray(info) ? (
                              <List text={info} />
                            ) : (
                              <span className="text-xs font-[450] text-gray-700">{info}</span>
                            )}
                            <br />
                          </Fragment>
                        );
                      })}
                    </div>
                  );
                })}
                <span className="text-xs font-[450] text-gray-700">
                  Last updated on 16 December 2024
                </span>
              </>
            )}

            {selectedTab === TermsTab.DPA && (
              <>
                {dpaSections.map(({ title, text }, i) => {
                  let subCounter = 0;
                  if (i > 1) count++;

                  return (
                    <div
                      className="flex flex-col gap-2"
                      id={`${title}-${i + 1}`}
                      key={`${title}-${i + 1}`}
                    >
                      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                        {!!count && <span>{count}.</span>} {title}
                      </h2>
                      {text?.map((info, i) => {
                        if (!Array.isArray(info)) subCounter++;
                        return (
                          <Fragment key={`${title}-text-${i}`}>
                            {Array.isArray(info) ? (
                              <List text={info} />
                            ) : (
                              <span className="text-xs font-[450] text-gray-700">
                                {!!count && (
                                  <span>
                                    {count}.{subCounter}
                                  </span>
                                )}{' '}
                                {info}
                              </span>
                            )}
                            <br />
                          </Fragment>
                        );
                      })}
                    </div>
                  );
                })}
                <span className="text-xs font-[450] text-gray-700">
                  Last updated on 16 December 2024
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
