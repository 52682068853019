import React, { FC, useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { useAppMutation, useDebounce, useFormat, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { PoolCombobox } from '../PoolCombobox';
import { SharePlanTypeCombobox } from '../SharePlanTypeCombobox';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  formData,
  lockMode,
  setError,
  clearErrors,
  filedState,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const { format } = useFormat();
  const { messagesLocale } = useLocale();
  const { mutate } = useAppMutation(
    [QueryKey.SHARE_PLAN_NAME_CHECK, { name: stepOne?.name || '' }],
    {
      mutationFn: async (name: string) =>
        await ApiService.get<{ isInUse: boolean }>({
          endpoint: BackendRoute.SHARE_PLANS,
          routePath: ['check-is-name-in-use', name],
        }),
      onSuccess: ({ isInUse }) => {
        setError('stepOne.name', {
          message: `${messagesLocale[StringKey.NAME_SHOULD_BE_UNIQUE]}`,
          type: 'validate',
          types: { required: true },
        });
        if (!isInUse) return clearErrors('stepOne.name');
      },
      defaultErrorHandling: false,
    },
  );

  const isValid = success && !filedState('stepOne').invalid;

  const { debounceCallback } = useDebounce<string>({
    callback: (name) => name && mutate(name),
    debounce: 300,
  });

  const handleTypeChange = useCallback(
    (type: SharePlanType) => {
      const { financialDetails, type: prevType } = formData('stepOne');
      const value: FormSchema['stepOne']['financialDetails'] = {
        [prevType]: undefined,
        [type]: {
          enabled: true,
          ...financialDetails?.[type],
        },
      };
      setFormData('stepOne.financialDetails', value);
    },
    [formData, setFormData],
  );

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <FormInput
          control={control}
          name="stepOne.name"
          onChange={debounceCallback}
          placeholder={<AppFormattedMessage id={StringKey.PLAN_NAME} />}
        />
        <PoolCombobox companyId={companyId} control={control} />
        <FormDatePicker
          defaultMonth={stepOne?.date || undefined}
          inputValue={stepOne?.date ? format(stepOne?.date, 'dd/MM/yyyy') : ''}
          inputWrapperClassName="w-full"
          isDefaultOpenCalendar={isOpenCalendar}
          onSelect={(date) => {
            setFormData('stepOne.date', date);
            setOpenCalendar(false);
          }}
          placeholder={<AppFormattedMessage id={StringKey.CREATION_DATE} />}
          value={stepOne?.date}
        />
        <SharePlanTypeCombobox control={control} onSelect={handleTypeChange} />
        {stepOne?.type === SharePlanType.GROWTH_HURDLE && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                {<AppFormattedMessage id={StringKey.HURDLE} />}
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  name="stepOne.financialDetails.growth-hurdle.value"
                  numberOnly
                  placeholder={<AppFormattedMessage id={StringKey.VALUATION_REQUIRED} />}
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
              </div>
            </div>
            <DropDown
              answer={[
                "A Hurdle Plan is an equity compensation structure where shares become available for exercising only if the company's valuation surpasses a predetermined threshold, called the Hurdle Value.",
                'This aligns incentives by ensuring participants benefit only when the company grows beyond a set benchmark.',
              ]}
              className="rounded-lg bg-gray-50"
              question={<AppFormattedMessage id={StringKey.WHAT_IS_HURDLE} />}
            />
          </>
        )}
        {stepOne?.type === SharePlanType.BSPCE && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                <AppFormattedMessage id={StringKey.STRIKE_PRICE} />
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.bspce.value"
                  placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="The strike price indicates the value at which the stakeholder(s) associated to the Plan will effectively purchase the shares."
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.WHAT_IS_STRIKE_PURCHASE_PRICE} />}
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.bspce.conversionRatio"
              placeholder={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X_OPTIONAL} />}
              wrapperClassName="w-full"
            />
            <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
              <DropDown
                answer={[
                  'The Conversion Ratio determines how grants convert into Common Stock upon exercise. It is set at the plan level and affects the number of shares a stakeholder ultimately receives.',
                  'Example: Alice receives 1,000 grants under a Stock Options Plan with a 2x conversion ratio. At the moment of exercising she will receive 2,000 Common Stock ',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_CONVERSION_RATIO} />}
              />
              <DropDown
                answer="BSPCE (Bons de Souscription de Parts de Créateur d’Entreprise) is a French stock option plan designed to reward startup employees by granting them the right to buy shares at a preferential price, benefiting from tax advantages."
                question={<AppFormattedMessage id={StringKey.WHAT_IS_BSPCE_PLAN} />}
              />
            </div>
          </>
        )}
        {stepOne?.type === SharePlanType.STOCK_OPTIONS && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                {<AppFormattedMessage id={StringKey.STRIKE_PRICE} />}
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.stock-options.value"
                  placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="The Strike Price (or Exercise Price) is the fixed price at which stock option holders can purchase shares under an Option Plan. If the market value of the shares rises above the Strike Price, holders can buy at a discount, realizing a financial gain."
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.WHAT_IS_STRIKE_PRICE} />}
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.stock-options.conversionRatio"
              placeholder={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X_OPTIONAL} />}
              wrapperClassName="w-full"
            />
            <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
              <DropDown
                answer={[
                  'The Conversion Ratio determines how grants convert into Common Stock upon exercise. It is set at the plan level and affects the number of shares a stakeholder ultimately receives.',
                  'Example: Alice receives 1,000 grants under a Stock Options Plan with a 2x conversion ratio. At the moment of exercising she will receive 2,000 Common Stock ',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_CONVERSION_RATIO} />}
              />
              <DropDown
                answer="A Stock Options Plan grants employees or stakeholders the right to buy company shares at a predetermined price (Strike Price) after meeting certain conditions, such as a vesting schedule."
                question={<AppFormattedMessage id={StringKey.WHAT_IS_STOCK_OPTIONS_PLAN} />}
              />
            </div>
          </>
        )}
        {stepOne?.type === SharePlanType.WARRANTS && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                <AppFormattedMessage id={StringKey.WARRANT_PRICE} />
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.warrants.value"
                  placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer={[
                    'The Warrant Price is the predetermined price at which a warrant holder can purchase shares in the future. It is similar to a stock option’s strike price, meaning the holder must pay this price to exercise the warrant and acquire shares. ',
                    "Warrant prices are often set at a discount to the company's future valuation, making them attractive incentives in venture financing and corporate transactions.",
                  ]}
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.WHAT_IS_WARRANT_PRICE} />}
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.warrants.conversionRatio"
              placeholder={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X_OPTIONAL} />}
              wrapperClassName="w-full"
            />
            <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
              <DropDown
                answer={[
                  'The Conversion Ratio determines how grants convert into Common Stock upon exercise. It is set at the plan level and affects the number of shares a stakeholder ultimately receives.',
                  'Example: Alice receives 1,000 grants under a Stock Options Plan with a 2x conversion ratio. At the moment of exercising she will receive 2,000 Common Stock ',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_CONVERSION_RATIO} />}
              />
              <DropDown
                answer="A Warrant Plan gives investors or stakeholders the right (but not the obligation) to buy company shares at a predetermined price within a set timeframe. Unlike stock options, warrants are often issued in financing deals or as incentives to lenders."
                question={<AppFormattedMessage id={StringKey.WHAT_IS_WARRANT_PLAN} />}
              />
            </div>
          </>
        )}

        {stepOne?.type === SharePlanType.SAR && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                <AppFormattedMessage id={StringKey.SAR_BASE_PRICE} />
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.sar.value"
                  placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="The SAR (Stock Appreciation Rights) base price is the initial stock price set at the time of grant, used to calculate the payout when the SAR is exercised. The payout is based on the difference between the stock's market price at the time of exercise and the base price."
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.WHAT_IS_SAR_BASE_PRICE} />}
                />
              </div>
            </div>
            <DropDown
              answer="A Stock Appreciation Right (SAR) is a form of equity compensation that allows recipients to benefit from a company's stock price increase without having to buy shares. Upon exercise, the recipient receives the difference between the grant price and the stock’s current value, typically in cash or shares. Unlike stock options, SARs do not require an upfront purchase, making them a cash-efficient way to reward employees."
              className="rounded-lg bg-gray-50"
              question={<AppFormattedMessage id={StringKey.WHAT_IS_SAR_PLAN} />}
            />
          </>
        )}

        {stepOne?.type === SharePlanType.RSU && (
          <>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.rsu.conversionRatio"
              placeholder={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X_OPTIONAL} />}
              wrapperClassName="w-full"
            />
            <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
              <DropDown
                answer={[
                  'The Conversion Ratio determines how grants convert into Common Stock upon exercise. It is set at the plan level and affects the number of shares a stakeholder ultimately receives.',
                  'Example: Alice receives 1,000 grants under a Stock Options Plan with a 2x conversion ratio. At the moment of exercising she will receive 2,000 Common Stock ',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_CONVERSION_RATIO} />}
              />
              <DropDown
                answer={[
                  'A Restricted Stock Unit (RSU) Plan grants stakeholders the right to receive company shares in the future, usually once certain vesting conditions, such as time-based or performance-based milestones, are met.',
                  'Unlike RSAs, RSUs do not provide actual shares upon grant. Instead, the shares are issued only after the vesting period is completed. RSUs are often used to incentivize and retain employees, aligning their interests with the company’s long-term success. If the vesting conditions are not met, the RSUs may be forfeited.',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_RSU_PLAN} />}
              />
            </div>
          </>
        )}

        {stepOne?.type === SharePlanType.PHANTOM && (
          <>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.phantom.conversionRatio"
              placeholder={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X_OPTIONAL} />}
              wrapperClassName="w-full"
            />
            <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
              <DropDown
                answer={[
                  'The Conversion Ratio determines how grants convert into Common Stock upon exercise. It is set at the plan level and affects the number of shares a stakeholder ultimately receives.',
                  'Example: Alice receives 1,000 grants under a Stock Options Plan with a 2x conversion ratio. At the moment of exercising she will receive 2,000 Common Stock ',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_CONVERSION_RATIO} />}
              />
              <DropDown
                answer={[
                  'Phantom plans offer stakeholders a benefit tied to the company’s equity without granting actual ownership. Phantom shares are not included in the Cap Table and do not affect company dilution. These plans are typically used to distribute dividends.',
                  'Note that despite any dilution conditions set at the associated pool (such as "Include in Cap Table" or "Exclude in Cap Table"), phantom shares will never impact the dilution or appear on the Cap Table.',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_PHANTOM_PLAN} />}
              />
            </div>
          </>
        )}

        {stepOne?.type === SharePlanType.RSA && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                <AppFormattedMessage id={StringKey.RSA_PURCHASE_PRICE} />
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.rsa.value"
                  placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="The Restricted Stock Award (RSA) Purchase Price is the amount an employee must pay to acquire restricted stock at the time of grant. Unlike stock options, RSAs are purchased upfront, often at a nominal or discounted price."
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.WHAT_IS_RSA_PURCHASE_PRICE} />}
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.rsa.conversionRatio"
              placeholder={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X_OPTIONAL} />}
              wrapperClassName="w-full"
            />
            <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
              <DropDown
                answer={[
                  'The Conversion Ratio determines how grants convert into Common Stock upon exercise. It is set at the plan level and affects the number of shares a stakeholder ultimately receives.',
                  'Example: Alice receives 1,000 grants under a Stock Options Plan with a 2x conversion ratio. At the moment of exercising she will receive 2,000 Common Stock ',
                ]}
                question={<AppFormattedMessage id={StringKey.WHAT_IS_CONVERSION_RATIO} />}
              />
              <DropDown
                answer="A Restricted Stock Award (RSA) Plan grants company shares to employees or stakeholders at the time of issuance, often at a discounted price or no cost. The shares may be subject to vesting conditions, such as time-based or performance-based milestones, and may be forfeited if the conditions are not met. Unlike RSUs, RSA shares are owned from the grant date."
                question={<AppFormattedMessage id={StringKey.WHAT_IS_RSA_PLAN} />}
              />
            </div>
          </>
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
