import { z } from 'zod';

import { StringKey } from '../../../lang';
import {
  SharePlanType,
  SharePlanVestingType,
  TerminationInterval,
  VestingOnOption,
} from '../../../types/pool-plans.types';
import { getTranslation } from '../../../utils/getTranslation';
import { ACCEPTED_FILE_MIME_TYPES, MAX_FILE_SIZE } from '../PoolForm/Validation';

const hurdle = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const stockOptions = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(0.01, { message: getTranslation(StringKey.REQUIRED) }),
  conversionRatio: z.coerce.number().optional(),
});
const warrants = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
  conversionRatio: z.coerce.number().optional(),
});

const sar = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const rsu = z.object({
  enabled: z.boolean(),
  conversionRatio: z.coerce.number().optional(),
});

const phantom = z.object({
  enabled: z.boolean(),
  conversionRatio: z.coerce.number().optional(),
});

const rsa = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().optional(),
  conversionRatio: z.coerce.number().optional(),
});

const commonStock = z.object({
  enabled: z.boolean().optional(),
});

const bspce = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(0.01, { message: getTranslation(StringKey.REQUIRED) }),
  conversionRatio: z.coerce.number().optional(),
});

export const stepOne = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  pool: z.object({
    name: z.string(),
    id: z.string(),
  }),
  date: z.coerce.date(),
  type: z.nativeEnum(SharePlanType),
  financialDetails: z.object({
    [SharePlanType.GROWTH_HURDLE]: hurdle.optional(),
    [SharePlanType.STOCK_OPTIONS]: stockOptions.optional(),
    [SharePlanType.WARRANTS]: warrants.optional(),
    [SharePlanType.SAR]: sar.optional(),
    [SharePlanType.RSA]: rsa.optional(),
    [SharePlanType.RSU]: rsu.optional(),
    [SharePlanType.PHANTOM]: phantom.optional(),
    [SharePlanType.COMMON_STOCK]: commonStock.optional(),
    [SharePlanType.BSPCE]: bspce.optional(),
  }),
});

export const formSchemaTimeBasedStandard = z
  .object({
    vestingOn: z.nativeEnum(VestingOnOption),
    vestingDuration: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
    vestingFrequency: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
    cliffPeriod: z.coerce.number().optional(),
    accelerationDate: z.coerce.date().optional(),
  })
  .refine(({ vestingDuration, vestingFrequency }) => vestingDuration % vestingFrequency === 0, {
    message: getTranslation(StringKey.DURATION_SHOULD_BE_MULTIPLE_OF_FREQUENCY),
    path: ['vestingDuration'],
  });

export const formSchemaTimeBasedDynamic = z
  .object({
    vestingOn: z.nativeEnum(VestingOnOption, {
      required_error: getTranslation(StringKey.MUST_SELECT_VESTING_OPTION),
    }),

    enabled: z.boolean(),
    dynamicVestings: z
      .array(
        z
          .object({
            vestingDuration: z.coerce
              .number()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            vestingFrequency: z.coerce
              .number()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            planAllocation: z.coerce
              .number()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          })
          .refine(
            ({ vestingDuration, vestingFrequency }) => vestingDuration % vestingFrequency === 0,
            {
              message: getTranslation(StringKey.DURATION_SHOULD_BE_MULTIPLE_OF_FREQUENCY),
              path: ['dynamicVestings'],
            },
          ),
      )
      .max(10, {
        message: getTranslation(StringKey.CANNOT_ADD_MORE_THAN_COUNT_ITEMS, { count: 10 }),
      }),
    accelerationDate: z.coerce.date().optional(),
  })
  .refine(
    ({ dynamicVestings, enabled }) =>
      enabled
        ? dynamicVestings.reduce((sum, vesting) => sum + (vesting.planAllocation ?? 0), 0) === 100
        : true,
    {
      message: getTranslation(StringKey.PLAN_ALLOCATION_DOES_NOT_REACH_100),
      path: ['dynamicVestings'],
    },
  );

export const formSchemaTargetBased = z
  .object({
    enabled: z.boolean(),
    targetVestings: z
      .array(
        z
          .object({
            name: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            weight: z.coerce.number().max(100),
            targetDate: z.coerce.date(),
          })
          .refine(
            ({ name, weight, targetDate }) => {
              if (!name || weight === undefined) return true;
              return targetDate.getTime() !== new Date(+0).getTime();
            },
            {
              message: getTranslation(StringKey.INVALID_TARGET_DATE),
              path: ['targetDate'],
            },
          ),
      )
      .max(10, {
        message: getTranslation(StringKey.CANNOT_ADD_MORE_THAN_COUNT_ITEMS, { count: 10 }),
      }),
  })
  .refine(
    ({ targetVestings, enabled }) =>
      enabled
        ? targetVestings.reduce((sum, vesting) => sum + (vesting.weight ?? 0), 0) === 100
        : true,
    {
      message: getTranslation(StringKey.WEIGHT_ALLOCATION_DOES_NOT_REACH_100),
      path: ['targetVestings'],
    },
  );

const vesting = z
  .object({
    enabled: z.boolean(),
    vestingMechanism: z.nativeEnum(SharePlanVestingType),
    timeBasedStandard: formSchemaTimeBasedStandard.optional(),
    timeBasedDynamic: formSchemaTimeBasedDynamic.optional(),
    targetBasedStandard: formSchemaTargetBased.optional(),
  })
  .refine(
    ({ targetBasedStandard, timeBasedDynamic, timeBasedStandard }) =>
      targetBasedStandard || timeBasedDynamic || timeBasedStandard,
  );

export const stepTwo = z
  .object({
    vesting: vesting.optional(),
    expiryDate: z
      .object({
        enabled: z.boolean(),
        date: z.coerce.date(),
      })
      .optional(),
  })
  .optional();

const terminationCauseSchema = z.union([
  z.object({
    enabled: z.literal(true),
    value: z.coerce.number().min(1),
    interval: z.nativeEnum(TerminationInterval),
  }),
  z.object({
    enabled: z.literal(false),
  }),
]);

export const formSchemaTerminationLogic = z.object({
  enabled: z.boolean(),
  resignation: terminationCauseSchema,
  termination: terminationCauseSchema,
  terminationWithCause: terminationCauseSchema,
  retirement: terminationCauseSchema,
});

export const stepThree = z
  .object({
    terminationLogic: formSchemaTerminationLogic.optional(),
  })
  .optional();

export const stepFour = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        id: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(
            ({ size }) => size <= MAX_FILE_SIZE,
            getTranslation(StringKey.FILE_SIZE_TOO_LARGE),
          ),
      })
      .array()
      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
});

export type FormSchema = z.infer<typeof formSchema>;
