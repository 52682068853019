import { StringKey } from '../lang';
import { SubscriptionDuration } from '../types/planTypes';

type SubscriptionDurationInfo = {
  description: StringKey;
  duration: StringKey;
};

export const subscriptionDurationTranslation: Record<
  SubscriptionDuration,
  SubscriptionDurationInfo
> = {
  [SubscriptionDuration.ANNUALY]: {
    description: StringKey.BILLED_ANNUALY,
    duration: StringKey.MONTH,
  },
  [SubscriptionDuration.MONTHLY]: {
    description: StringKey.BILLED_MONTHLY_PLAN,
    duration: StringKey.MONTH,
  },
};

export const startUpBenefits: StringKey[] = [
  StringKey.DATA_ROOM,
  StringKey.FUNDRAISING_TRACKER,
  StringKey.POOLS_PLANS_MANAGEMENT,
  StringKey.CAP_TABLE_MANAGEMENT,
  StringKey.BASIC_SUPPORT,
];

export const growthBenefits: StringKey[] = [
  StringKey.CONVERTIBLE_MANAGEMENT,
  StringKey.VENTURE_DEBT_MANAGEMENT,
  StringKey.FUNDRAISING_MODELING,
  StringKey.LIQUIDITY_MODELING,
  StringKey.PRIORITY_SUPPORT,
];

export const enterpriseBenefits: StringKey[] = [
  StringKey.LOAN_MODELING,
  StringKey.EVENT_MANAGEMENT_X,
  StringKey.DASHBOARD_CUSTOMIZATION_X,
  StringKey.ASSISTED_ONBOARDING_PROCESS,
  StringKey.DEDICATED_ACCOUNT_MANAGER,
];
