import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon } from '../../../assets/icons';
import { Company } from '../../../hooks';
import { StringKey } from '../../../lang';
import {
  NotificationAction,
  notificationActionTitle,
  renderMyHoldingButtonTypes,
} from '../../../types/notification.types';
import { PageRoute } from '../../../types/pageTypes';
import { SubscriptionType } from '../../../types/planTypes';
import { AppFormattedMessage } from '../../AppFormattedMessage';
import Button from '../../Button';
import { useIntervalFromToday } from '../formatDate';

type NotificationProps = {
  type?: NotificationAction;
  createdAt: Date;
  message: string;
  isRead: boolean;
  onMarkAsRead: () => void;
  onInviteUser: () => void;
  handleCloseModal: () => void;
};

export const ActivityNotification: FC<NotificationProps> = ({
  createdAt,
  isRead,
  message,
  onInviteUser,
  onMarkAsRead,
  type,
  handleCloseModal,
}) => {
  const navigate = useNavigate();
  const { selectedCompany } = Company.useSelected();
  const date = useIntervalFromToday(createdAt);
  const [read, setRead] = useState(false);
  const companySubscriptionType =
    selectedCompany?.latestSubscriptionData?.items?.data[0]?.price?.product?.metadata?.type;

  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 rounded-lg border border-brand-50 bg-brand-25 px-4 py-3',
        (isRead || read) && 'border-gray-200 bg-white',
      )}
    >
      <div className="flex w-full justify-between text-label-md font-medium text-gray-500">
        <span>{type && <AppFormattedMessage id={notificationActionTitle[type]} />}</span>
        <span>{date}</span>
      </div>
      <div className="flex gap-3">
        <div className="size-10 shrink-0 rounded-full bg-brand-700" />
        <div className="flex flex-col gap-4">
          <span className="text-sm font-[450] text-black">{message}</span>
          <div className="flex items-center gap-4 max-lg:justify-center">
            {!(isRead || read) && (
              <>
                <Button
                  className="w-fit text-nowrap text-sm font-medium text-brand-700"
                  onClick={() => {
                    onMarkAsRead();
                    setRead(true);
                  }}
                  styleType="NONE"
                >
                  <AppFormattedMessage id={StringKey.MARK_AS_READ} />
                </Button>
              </>
            )}
            {type === NotificationAction.STAKEHOLDER_ADDED && (
              <>
                <div className="size-[5px] rounded-full bg-gray-300" />

                <Button
                  className="h-[22px] w-fit gap-2 text-nowrap rounded-full border border-gray-200 bg-white px-2 !text-label-md font-medium leading-[18px] text-gray-700"
                  onClick={onInviteUser}
                  styleType="NONE"
                >
                  <AppFormattedMessage id={StringKey.INVITE_USER} />
                  <ArrowIcon className="rotate-180" />
                </Button>
              </>
            )}
            {type === NotificationAction.STAKEHOLDER_LIMIT_REACHED && (
              <>
                <div className="size-[5px] rounded-full bg-gray-300" />

                <Button
                  className="h-[22px] w-fit gap-2 text-nowrap rounded-full border border-gray-200 bg-white px-2 !text-label-md font-medium leading-[18px] text-gray-700"
                  onClick={() => {
                    handleCloseModal();
                    companySubscriptionType === SubscriptionType.ENTERPRISE
                      ? (window.location.href = 'mailto:contact@capquest.io')
                      : navigate(PageRoute.COMPANY_SETTINGS);
                  }}
                  styleType="NONE"
                >
                  {companySubscriptionType === SubscriptionType.ENTERPRISE ? (
                    'Talk to Sales'
                  ) : (
                    <AppFormattedMessage id={StringKey.UPGRADE} />
                  )}

                  <ArrowIcon className="rotate-180" />
                </Button>
              </>
            )}
            {type && renderMyHoldingButtonTypes.includes(type) && (
              <Button
                className="h-[22px] w-fit gap-2 text-nowrap rounded-full border border-gray-200 bg-white px-2 !text-label-md font-medium leading-[18px] text-gray-700"
                onClick={() => {
                  handleCloseModal();
                  navigate(PageRoute.MY_HOLDINGS);
                }}
                styleType="NONE"
              >
                <AppFormattedMessage id={StringKey.MY_HOLDINGS} />
                <ArrowIcon className="rotate-180" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
