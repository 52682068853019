import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { AppFormattedMessage } from '../../../../AppFormattedMessage';
import { DatePicker } from '../../../../DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type StockOptionProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const StockOption: FC<StockOptionProps> = ({
  control,
  setFormData,
  companyId,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: stockItemsFields,
    append: stockItemsAppend,
    remove: stockItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.commonStock.stockItems',
  });

  const handleRemoveStockItems = useCallback(
    (index: number) => {
      stockItemsRemove(index);
    },
    [stockItemsRemove],
  );

  const handleAppendStockItems = useCallback(() => {
    const defaultDate = new Date();
    stockItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        grantItem: {
          plan: { strikePrice: 0, conversionRatio: 1, pool: { shareClass: { name: '' } } },
        },
        exercisedCount: 0,
        vestedSharesCount: 0,
      },
      date: defaultDate,
      exercised: 0,
    });
  }, [stockItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.commonStock.stockItems.${index}.grant`);
  };

  const calculateBalance = useCallback(
    (index: number) => {
      const conversionRatio =
        stepOne?.commonStock?.stockItems?.[index]?.grant?.grantItem?.plan?.conversionRatio || 1;
      const vestedShares = stepOne?.commonStock?.stockItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares = stepOne?.commonStock?.stockItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.commonStock?.stockItems?.[index]?.exercised || 0;
      const balance = Math.max(
        vestedShares / conversionRatio - exercisedShares / conversionRatio - value,
        0,
      );

      return balance.toLocaleString('en-US');
    },
    [stepOne?.commonStock?.stockItems],
  );

  useEffect(() => {
    if (stockItemsFields.length === 0) {
      handleAppendStockItems();
    }
  }, [handleAppendStockItems, stockItemsFields.length]);

  return (
    <>
      <div className="flex flex-col overflow-x-auto rounded-lg border-[1px] border-gray-100 bg-white">
        <div className="overflow-x-auto">
          <div className="grid grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)]">
            <TableHeaderItem
              showTooltip
              text={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
            />
            <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.GRANT} />} />
            <TableHeaderItem
              showTooltip
              text={<AppFormattedMessage id={StringKey.EXERCISE_DATE} />}
            />
            <TableHeaderItem text={<AppFormattedMessage id={StringKey.SHARE_CLASS} />} />
            <TableHeaderItem text={<AppFormattedMessage id={StringKey.STRIKE_PRICE} />} />
            <TableHeaderItem
              text={<AppFormattedMessage id={StringKey.EXERCISED_OPTIONS} />}
              variant="dark"
            />
            <TableHeaderItem
              text={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
              variant="dark"
            />
            <TableHeaderItem text="" />
          </div>
          {stockItemsFields.map((field, index) => (
            <div
              className="grid grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)] border-b-[1px] border-b-gray-100"
              key={field.id}
            >
              <div className="px-4 pb-10 pt-7">
                <StakeholderCombobox
                  companyId={companyId}
                  control={control}
                  handleStakeholderChange={() => handleStakeholderChange(index)}
                  name={`stepOne.commonStock.stockItems.${index}.stakeholder`}
                  planType={SharePlanType.STOCK_OPTIONS}
                />
              </div>
              <div className="px-4 pb-10 pt-7">
                <GrantCombobox
                  companyId={companyId}
                  control={control}
                  name={`stepOne.commonStock.stockItems.${index}.grant`}
                  selectedGrantIds={stepOne?.commonStock?.stockItems?.map(
                    (item) => item?.grant?.id || '',
                  )}
                  sharePlanType={SharePlanType.STOCK_OPTIONS}
                  stakeholderId={stepOne?.commonStock?.stockItems?.[index]?.stakeholder?.id || ''}
                />
              </div>
              <div className="px-4 pb-10 pt-7">
                <DatePicker
                  className="w-full"
                  defaultMonth={stepOne?.commonStock?.stockItems?.[index]?.date || undefined}
                  onSelect={(date) =>
                    setFormData(`stepOne.commonStock.stockItems.${index}.date`, date)
                  }
                >
                  <FormInput
                    autoComplete="off"
                    control={control}
                    customValue={(value) => {
                      return value && format(value, 'dd/MM/yyyy');
                    }}
                    disabledInput
                    icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                    inputClassName="h-10 w-[150px]"
                    name={`stepOne.commonStock.stockItems.${index}.date`}
                    wrapperClassName="w-full"
                  />
                </DatePicker>
              </div>
              <div className="flex items-center px-4 pb-10 pt-7">
                <span className="text-sm font-[450] text-gray-700">
                  {
                    stepOne?.commonStock?.stockItems?.[index]?.grant?.grantItem?.plan?.pool
                      ?.shareClass?.name
                  }
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <FormInput
                  control={control}
                  disabled={true}
                  inputClassName="h-10"
                  name={`stepOne.commonStock.stockItems.${index}.grant.grantItem.plan.strikePrice`}
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
                <span className="text-xs font-[450] text-gray-600">
                  <AppFormattedMessage id={StringKey.TOTAL_COST} /> $
                  {(
                    (stepOne?.commonStock?.stockItems?.[index]?.grant?.grantItem?.plan
                      ?.strikePrice || 0) *
                    (stepOne?.commonStock?.stockItems?.[index]?.exercised || 1)
                  ).toLocaleString('en-US')}
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <FormInput
                  control={control}
                  inputClassName="h-10"
                  name={`stepOne.commonStock.stockItems.${index}.exercised`}
                  numberOnly
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
                <span className="text-xs font-[450] text-gray-600">
                  <AppFormattedMessage id={StringKey.AVAILABLE} /> {calculateBalance(index)}
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <div className="rounded-t-[4px] bg-gray-50 px-3 py-2">
                  <span className="text-sm font-[450] text-gray-700">
                    {(
                      (stepOne?.commonStock?.stockItems?.[index]?.exercised || 0) *
                      (stepOne?.commonStock?.stockItems?.[index]?.grant?.grantItem?.plan
                        ?.conversionRatio || 1)
                    ).toLocaleString('en-US')}
                  </span>
                </div>
                <span className="text-xs font-[450] text-gray-600">
                  <AppFormattedMessage id={StringKey.CONVERSION} />{' '}
                  {stepOne?.commonStock?.stockItems?.[index]?.grant?.grantItem?.plan
                    ?.conversionRatio || 1}
                  x
                </span>
              </div>
              <div>
                {stockItemsFields.length > 1 && (
                  <Button
                    className="flex h-full w-full items-center justify-center"
                    onClick={() => handleRemoveStockItems(index)}
                    styleType="NONE"
                  >
                    <CloseIcon />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
