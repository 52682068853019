import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Loader from '../../components/Loader';
import { DeprecatedModalWrapper } from '../../components/Modal';
import { QueryKey } from '../../constants';
import { useInvalidateQueries, User, useSearchParams } from '../../hooks';
import { useModalState } from '../../hooks';
import { accessTokenStorage } from '../../storage/accessTokenStorage';
import DeleteAccountModal from './DeleteAccountModal';
import { NotificationSettings } from './EmailSettings';
import ProfileEditForm from './ProfileEditForm';
import { ProfileTab, profileTabMap, profileTabTitleMap } from './types';

const Profile: FC = () => {
  const [isEditing, setEditing] = useState(false);
  const { value: defaultTab } = useSearchParams('tab');

  const [selectedTab, setTab] = useState(
    profileTabMap.includes(defaultTab as ProfileTab) ? defaultTab : ProfileTab.PERSONAL_INFO,
  );
  const { user, isLoading } = User.useUser();
  const { update } = User.useUpdate();
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_USER);
  const { deleteUser } = User.useDelete();

  const {
    isOpen: isOpenDeleteModal,
    handleDismissModal,
    handleOpenModal,
    handleSuccessModal,
  } = useModalState({
    onSuccess: () => {
      handleDeleteProfile();
    },
  });

  const handleDeleteProfile = useCallback(
    () =>
      deleteUser(undefined, {
        onSuccess: () => {
          toast.success('Account was successfully deleted');
          setTimeout(() => {
            accessTokenStorage.delete();
            window.location.reload();
          }, 1000);
        },
      }),
    [deleteUser],
  );

  const handleTabChange = (tab: ProfileTab) => {
    invalidateQuery();
    setTab(tab);
  };
  if (!user || isLoading) return <Loader />;

  return (
    <>
      <DeprecatedModalWrapper isOpen={isOpenDeleteModal}>
        <DeleteAccountModal onClose={handleDismissModal} onSuccess={handleSuccessModal} />
      </DeprecatedModalWrapper>
      <div className="flex h-fit min-h-full w-full flex-col gap-4 rounded-md max-xs:shadow-none lg:p-4 lg:shadow-sm">
        <div className="flex h-[38px] w-full gap-4 border-b-[1px] border-gray-200">
          {profileTabTitleMap.map(([key, titleKey]) => (
            <span
              className={twMerge(
                'flex h-full w-fit cursor-pointer rounded-none border-b-2 border-brand-700 border-transparent text-sm font-[450] text-gray-400',
                selectedTab === key && 'border-b-2 border-brand-700 font-[550] text-brand-700',
              )}
              key={key}
              onClick={() => handleTabChange(key)}
            >
              <AppFormattedMessage id={titleKey} />
            </span>
          ))}
        </div>

        {selectedTab === ProfileTab.PERSONAL_INFO && (
          <ProfileEditForm
            {...user}
            handleOpenDeleteModal={handleOpenModal}
            isEditing={isEditing}
            patchUser={(data, callbacks) =>
              update(
                { data },
                {
                  onSuccess: (data, variables, context) => {
                    toast.success('Data successfully updated');
                    callbacks?.onSuccess?.(data, variables.data, context);
                    setEditing(false);
                    invalidateQuery();
                  },
                },
              )
            }
            setEditing={setEditing}
          />
        )}

        {selectedTab === ProfileTab.NOTIFICATIONS && <NotificationSettings />}
      </div>
    </>
  );
};

Profile.displayName = 'Profile';

export default Profile;
