import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Company, SharePlan, useFormat, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { AppNotification } from '../../../types/notification.types';
import { PageRoute } from '../../../types/pageTypes';
import { sharePlanTypeTitle } from '../../../types/pool-plans.types';
import { TargetBasedVestingMilestoneItem } from '../../../types/vestingTasks.types';
import { getTranslation } from '../../../utils/getTranslation';
import { AppFormattedMessage } from '../../AppFormattedMessage';
import Button from '../../Button';
import { useIntervalFromToday } from '../formatDate';

type NotificationProps = {
  type: string | ReactNode;
  createdAt: Date;
  isRead: boolean;
  milestones: TargetBasedVestingMilestoneItem[];
  onMarkRead: () => void;
  setMilestoneIndex: (index: number | null) => void;
  handleCloseModal: () => void;
} & Pick<AppNotification, 'targetBasedMilestoneRequest'>;

export const ApproveNotification: FC<NotificationProps> = ({
  createdAt,
  isRead,
  onMarkRead,
  type,
  setMilestoneIndex,
  milestones,
  targetBasedMilestoneRequest,
  handleCloseModal,
}) => {
  const { selectedCompanyId: companyId } = Company.useSelected();
  const date = useIntervalFromToday(createdAt);
  const { sharePlan } = SharePlan.useSharePlan({
    companyId: companyId,
    planId: targetBasedMilestoneRequest?.sharePlanId || '',
  });

  const { setParamValue } = useModalState('target-based-approve-modal');
  const [read, setRead] = useState(false);
  const { format } = useFormat();
  const navigate = useNavigate();

  const milestoneIndex = targetBasedMilestoneRequest
    ? milestones?.findIndex(
        (milestone) =>
          milestone.name === targetBasedMilestoneRequest.name &&
          milestone.weight === targetBasedMilestoneRequest.weight &&
          milestone.targetDate === targetBasedMilestoneRequest.targetDate &&
          milestone.isCompleted === targetBasedMilestoneRequest.isCompleted &&
          milestone.sharePlanId === targetBasedMilestoneRequest.sharePlanId,
      )
    : null;

  const onEditMilestoneDateClick = () => {
    const currentPath = window.location.pathname;
    const newPath = `${PageRoute.POOL_PLANS}?openEditPlanModal=true&paramPlanId=${sharePlan?.id}`;

    if (currentPath === PageRoute.POOL_PLANS) {
      handleCloseModal();
      navigate(newPath, { replace: true });
    } else {
      handleCloseModal();
      navigate(newPath);
    }
  };

  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 rounded-lg border border-brand-50 bg-brand-25 px-4 py-3',
        (isRead || read) && 'border-gray-200 bg-white',
      )}
    >
      <div className="flex w-full justify-between text-label-md font-medium text-gray-500">
        <span>{type}</span>
        <span>{date}</span>
      </div>
      <div className="flex w-full gap-3">
        <div className="size-10 shrink-0 rounded-full bg-brand-700" />
        <div className="flex w-full flex-col gap-4">
          <span className="text-sm font-[450] text-black">
            {`${getTranslation(StringKey.MILESTONE)} ${targetBasedMilestoneRequest?.name} ${getTranslation(StringKey.UNDER_TARGET_BASED)} ${sharePlan?.name} (${sharePlan?.type && sharePlanTypeTitle[sharePlan.type]}) ${getTranslation(StringKey.HAS_SET_TARGET_DATE)} ${format(targetBasedMilestoneRequest?.targetDate, 'dd/MM/yyyy')}. ${getTranslation(StringKey.WOULD_YOU_LIKE_APPROVE_TARGET)}`}
          </span>
          <div className="flex w-full gap-4 max-lg:flex-col lg:justify-between">
            {!(isRead || read) && (
              <>
                <Button
                  className="w-fit text-nowrap text-sm font-medium text-brand-700"
                  onClick={() => {
                    onMarkRead();
                    setRead(true);
                  }}
                  styleType="NONE"
                >
                  <AppFormattedMessage id={StringKey.MARK_AS_READ} />
                </Button>
              </>
            )}
            {((milestoneIndex && milestoneIndex > 0) || milestoneIndex === 0) && (
              <div className="flex h-9 w-full justify-end gap-2 text-sm font-[450] text-gray-700">
                <Button
                  className="w-fit rounded border border-gray-100 bg-white px-3 shadow-xs"
                  onClick={onEditMilestoneDateClick}
                  styleType="NONE"
                >
                  Edit Milestone Date
                </Button>
                <Button
                  className="w-fit px-4 font-[550] text-white"
                  onClick={() => {
                    if (!targetBasedMilestoneRequest) return;
                    setMilestoneIndex(milestoneIndex);
                    setParamValue(targetBasedMilestoneRequest?.sharePlanId || '');
                  }}
                >
                  <AppFormattedMessage id={StringKey.APPROVE} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
