import React, { FC, useCallback } from 'react';
import { toast } from 'react-toastify';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { Stakeholder as StakeholderHook, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { Stakeholder } from '../../../../types/stakeholderTypes';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { TerminateForm } from './TerminateForm';
import { FormSchema, formSchema } from './validation';

export type TerminateStakeholderModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  stakeholders?: Stakeholder[];
  setSelectedStakeholderIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const TerminateStakeholderModal: FC<TerminateStakeholderModalProps> = ({
  isOpen,
  companyId,
  handleClose,
  invalidateQuery,
  stakeholders,
  setSelectedStakeholderIds,
}) => {
  const { control, reset, handleSubmit, setValue, getFieldState, resetField } = useReactForm({
    schema: formSchema,
  });

  const { terminate } = StakeholderHook.useTerminate();

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setSelectedStakeholderIds([]);
  }, [reset, handleClose, setSelectedStakeholderIds]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { terminateForm } = data;
      const stakeholders = terminateForm.stakeholderItems.map(
        ({ id, terminationCause, terminationDate, grantItems }) => ({
          id,
          terminationDate: toBackendDateFormat(terminationDate),
          terminationCause,
          lastVestingDate: toBackendDateFormat(terminationDate),
          grantItems:
            grantItems?.map(
              ({ id, finalExerciseDate, grantItem, vestedSharesCount, exercisedCount }) => ({
                id,
                finalExerciseDate:
                  finalExerciseDate?.getTime() === 0
                    ? undefined
                    : finalExerciseDate
                      ? toBackendDateFormat(finalExerciseDate)
                      : undefined,
                planType: grantItem.plan.type,
                numbersOfGrants: grantItem?.numbersOfGrants || null,
                vestedSharesCount: vestedSharesCount || null,
                unvestedSharesCount: grantItem.numbersOfGrants
                  ? grantItem.numbersOfGrants - (vestedSharesCount || 0)
                  : null,
                exercisedCount: exercisedCount || null,
                unexercisedSharesCount:
                  vestedSharesCount && exercisedCount ? vestedSharesCount - exercisedCount : null,
              }),
            ) || undefined,
        }),
      );

      terminate(
        {
          companyId,
          data: { stakeholders, sendEmails: !!terminateForm.isSendEmails },
        },
        {
          onSuccess: () => {
            toast.success(
              `${terminateForm.stakeholderItems.length} stakeholder${terminateForm.stakeholderItems.length > 1 ? 's' : ''} terminated`,
            );
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [companyId, handleCloseModal, invalidateQuery, terminate],
  );

  const handleTerminate = useCallback(() => {
    handleSubmit(submitHandler)();
  }, [handleSubmit, submitHandler]);

  return (
    <Sheet open={isOpen}>
      <SheetContent
        className="h-fit max-h-[800px] w-[90%] max-w-[1326px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-fit w-full flex-col gap-10 rounded-2xl bg-white p-8">
          <div className="flex h-fit w-full items-end justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-xl font-semibold text-gray-700">
                <AppFormattedMessage id={StringKey.TERMINATE_STAKEHOLDER} />
              </span>
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.FILL_IN_DETAILS} />
              </span>
            </div>
            <span className="cursor-pointer text-sm font-[500] text-brand-700 underline">
              <AppFormattedMessage id={StringKey.LEARN_MORE_TERMINATION} />
            </span>
          </div>
          <div className="flex h-full max-h-[520px] flex-col overflow-y-auto">
            <TerminateForm
              companyId={companyId}
              control={control}
              filedState={getFieldState}
              handleCloseModal={handleCloseModal}
              handleTerminate={handleTerminate}
              resetField={resetField}
              setFormData={setValue}
              stakeholders={stakeholders}
            />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
