import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormCheckbox } from '../../../../components/Checkbox';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { FormTextarea } from '../../../../components/Textarea';
import { Company, useReactForm } from '../../../../hooks';
import { reasonToChangeToEnterpriseTitleMap } from '../../../../hooks/company';
import { StringKey } from '../../../../lang';
import { PageRoute } from '../../../../types/pageTypes';
import { FormSchema, formSchema } from './validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
};

export const EnterpriseModal: FC<ModalProps> = ({ isOpenModal, handleClose, companyId }) => {
  const navigate = useNavigate();
  const { enterpriseRequest, isPending } = Company.useEnterpriseRequest();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
  });

  const handleCloseModal = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const submitHandler = useCallback(
    ({ additionalStakeholdersDetails, additionalDetails, reasonsToChange }: FormSchema) => {
      enterpriseRequest(
        {
          companyId,
          additionalNotesAboutStakeholders: additionalStakeholdersDetails,
          additionalNotes: additionalDetails,
          reasonsToChange,
        },
        {
          onSuccess: () => {
            navigate(PageRoute.ALL_DONE);
          },
        },
      );
    },
    [companyId, enterpriseRequest, navigate],
  );

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[500px] border-transparent bg-transparent p-2 shadow-3xl"
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden rounded-[4px] bg-white p-4">
          <div className="flex max-h-[80vh] w-full flex-col gap-4 overflow-y-auto">
            <div className="flex flex-col gap-2">
              <span className="text-xl font-semibold text-gray-700">
                <AppFormattedMessage id={StringKey.HELP_US_UNDERSTAND} />
              </span>
              <span className="text-xs font-[450] text-gray-800">
                <AppFormattedMessage id={StringKey.SALES_TEAM_CONTACT} />
              </span>
            </div>

            <div className="flex flex-col gap-3 rounded-[4px] bg-gray-50 p-3">
              <span className="text-xs font-semibold text-gray-700">
                <AppFormattedMessage id={StringKey.DECISION_TO_UPGRADE} /> <sup>*</sup>
              </span>
              <div className="flex flex-col gap-1">
                {reasonToChangeToEnterpriseTitleMap.map(([key, title]) => (
                  <div className="flex w-full items-center gap-1" key={key}>
                    <FormCheckbox control={control} name={`reasonsToChange.${key}`} />
                    <span className="px-1 py-[6px] text-xs font-[450] text-gray-700">
                      {<AppFormattedMessage id={title} />}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 rounded-[4px] border-[1px] border-gray-100 p-3">
              <div className="flex flex-col gap-2">
                <span className="text-xs font-semibold text-gray-700">
                  <AppFormattedMessage id={StringKey.ADDITIONAL_DETAILS} />
                </span>
                <span className="text-xs font-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.ADDITIONAL_STAKEHOLDERS} />
                </span>
              </div>
              <FormTextarea
                className="text-xs"
                control={control}
                isShownCharCount={false}
                name="additionalStakeholdersDetails"
                placeholder={<AppFormattedMessage id={StringKey.SHARE_ADDITIONAL_DETAILS} />}
                placeholderClassName="text-xs"
                styleVariant="LIGHT_W_PLACEHOLDER"
                textAreaClassName="border-none"
                wrapperClassName="border-[1px] border-gray-100 rounded-[4px] mr-2"
              />
            </div>

            <FormTextarea
              className="text-xs"
              control={control}
              isShownCharCount={false}
              name="additionalDetails"
              placeholder={<AppFormattedMessage id={StringKey.SHARE_ADDITIONAL_DETAILS} />}
              placeholderClassName="text-xs"
              styleVariant="LIGHT_W_PLACEHOLDER"
              textAreaClassName="border-none"
              wrapperClassName="border-[1px] border-gray-100 rounded-[4px] mr-2"
            />
          </div>
          <div className="mt-4 flex h-[44px] w-full justify-end gap-3">
            <Button
              className="h-full w-fit rounded border border-gray-300 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={handleCloseModal}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.CANCEL} />
            </Button>
            <Button
              className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
              disabled={!isValid}
              isLoading={isPending}
              onClick={() => handleSubmit(submitHandler)()}
              type="button"
            >
              <AppFormattedMessage id={StringKey.SUBMIT} />
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
