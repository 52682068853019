import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import Loader from '../../../components/Loader';
import { EventHooks } from '../../../hooks';
import { EventsResponse } from '../../../hooks/event';
import { StringKey } from '../../../lang';
import { EventTypeBackend, SortByType, ValuationEvent } from '../../../types/events.types';
import { FormSchema } from './Validation';

type EventType = {
  id: string;
  name: string;
  sharePrice: number;
  issuedSharesOnStart: number;
  issuedShares: number;
};

export type EventsItemsListProps = {
  data: ValuationEvent[];
  control: Control<FormSchema>;
  hasNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<EventsResponse, unknown>, Error>>;
};

const EventsItemsList: FC<EventsItemsListProps> = ({
  data,
  control,
  hasNextPage,
  fetchNextPage,
}) => {
  const navigate = useNavigate();
  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={10}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scrollbar-target"
    >
      {data.length > 0 ? (
        <>
          {data
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map(({ name, id, sharePrice, issuedSharesOnStart, issuedShares }, i) => (
              <FormComboboxOption
                className="w-full"
                control={control}
                key={`${name}_${i}`}
                name="stepOne.linkedEvent"
                value={{
                  id,
                  name,
                  sharePrice,
                  issuedSharesOnStart,
                  issuedShares,
                }}
              >
                <span className="truncate text-sm text-gray-700">{name}</span>
              </FormComboboxOption>
            ))}
          <FormComboboxOption
            className="w-full"
            control={control}
            name="stepOne.linkedEvent"
            value={{
              id: 'no-valuation',
              name: 'No Valuation Event',
              sharePrice: 0,
              issuedSharesOnStart: 0,
              issuedShares: 0,
            }}
          >
            <span className="truncate text-sm text-gray-700">
              <AppFormattedMessage id={StringKey.NO_VALUATION_EVENT} />
            </span>
          </FormComboboxOption>
        </>
      ) : (
        <>
          <span className="ml-2 px-2 py-[6px] text-sm font-normal text-gray-700">
            <AppFormattedMessage id={StringKey.NO_RESULTS_FOUND} />
          </span>
        </>
      )}
      <Button
        className="h-[37px] w-full justify-start rounded bg-brand-25 px-[6px] text-start text-sm font-[450] text-brand-700"
        onClick={() => navigate('/events?openAddValuationModal=true')}
        styleType="NONE"
      >
        + <AppFormattedMessage id={StringKey.ADD_EVENT} />
      </Button>
    </InfiniteScroll>
  );
};

export type EventComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
};

export const EventCombobox: FC<EventComboboxProps> = ({ control, companyId }) => {
  const {
    events: backendEvents,
    fetchNextPage,
    hasNextPage,
  } = EventHooks.useEvents({
    companyId,
    typesFilter: [SortByType.VALUATION],
  });

  if (!backendEvents) return;
  const filteredEvents = backendEvents.filter(
    (event): event is ValuationEvent => event.type === EventTypeBackend.VALUATION,
  );

  return (
    <>
      <FormCombobox className="relative w-full" control={control} name="stepOne.linkedEvent">
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => (value as EventType)?.name}
            icon={<ChevronDownIcon className="mt-3" />}
            name="stepOne.linkedEvent"
            placeholder={<AppFormattedMessage id={StringKey.LINKED_EVENT} />}
            readOnly
          />
          <ComboboxButton className="absolute top-0 z-1 h-full w-full" />
        </div>

        <ComboboxOptions className="w-full overflow-x-hidden">
          <EventsItemsList
            control={control}
            data={filteredEvents.filter(({ sharePrice }) => sharePrice) as ValuationEvent[]}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
