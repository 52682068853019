import { cookieUtil } from '../utils/cookies-util';

export type UserCookies = {
  essential: boolean;
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
};

export const cookieSettingsStore = cookieUtil<UserCookies>('cookie-settings');
