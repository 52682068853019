import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { ModalWrapper } from '../../components/ModalWrapper';
import { PaginationController } from '../../components/PaginationController';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import {
  AnalyticHooks,
  Pool,
  SharePlan,
  useAppMutation,
  useModalState,
  useQueryParams,
  useSelectedCompany,
} from '../../hooks';
import { StringKey } from '../../lang';
import { ApiService } from '../../services';
import { PossibleAction } from '../../types/google-analytics-types';
import {
  ExerciseType,
  NumberOfFetchedItems,
  PoolPlansTab,
  PoolsPlansFilter,
  SelectNumberOfFetchedItems,
  SelectVariant,
} from '../../types/pool-plans.types';
import { ExerciseForm } from './Exercise/ExerciseForm';
import { AddPlanForm } from './PlanForm';
import { EditPlanModal, PlanTerminationDateModal } from './PlanForm/Edit';
import { EditPlanStepVariant } from './PlanForm/type';
import {
  AddPoolForm,
  EditPoolModal,
  EditShareClassStepVariant,
  PoolTerminationDateModal,
} from './PoolForm';
import PoolPlansHeader from './PoolPlansHeader';
import { PoolPlansMobile } from './PoolPlansMobile/PoolPlansMobile';
import { PoolPlansCommonTable } from './PoolPlansTable';

export type SelectedShareClass = {
  id: string;
  step: EditShareClassStepVariant;
  mode: 'EDIT' | 'VIEW';
};

export type SelectedPlan = {
  id: string;
  step: EditPlanStepVariant;
  mode: 'EDIT' | 'VIEW';
};

const selectVariant = Object.values(NumberOfFetchedItems);

const PoolPlans: FC = () => {
  const [selectedFilter, setSelectedFilter] = useState<PoolsPlansFilter>(PoolsPlansFilter.BY_POOLS);
  const [selected, setSelected] = useState<SelectVariant>();
  const [expandedId, setExpandedId] = useState({ poolId: [''], planId: [''] });
  const { selectedCompany } = useSelectedCompany();
  const [selectedTab, setTab] = useState<PoolPlansTab>(PoolPlansTab.ACTIVE);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfFetchedItems, setNumberOfFetchedItems] = useState<SelectNumberOfFetchedItems>(
    NumberOfFetchedItems.FIVE,
  );

  const [selectedPool, setSelectedPool] = useState<SelectedShareClass | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });

  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });
  const handleFilterSelect = (filter: PoolsPlansFilter) => {
    setSelectedFilter(filter);
    setSelectedPool(null);
    setSelectedPlan(null);
  };
  const companyId = selectedCompany?.id || '';

  const { get } = useQueryParams();

  const {
    handleCloseModal: handleCloseAddPoolModal,
    handleOpenModal: handleOpenAddPoolModal,
    isOpen: isOpenAddPoolModal,
    toggler: AddPoolModalToggler,
  } = useModalState({ initialValue: !!get('openAddModal') });

  const {
    handleCloseModal: handleCloseEditPoolModal,
    handleOpenModal: handleOpenEditPoolModal,
    isOpen: isOpenEditPoolModal,
  } = useModalState({});
  const queryClient = useQueryClient();

  const { pools, totalPages, isLoading } = Pool.usePools({
    companyId,
    currentPage,
    take: Number(numberOfFetchedItems),
  });
  const {
    pools: terminatedPools,
    totalPages: terminatedTotalPages,
    isLoading: isTerminatedLoading,
  } = Pool.useTerminatedPools({
    companyId,
    currentPage,
    take: Number(numberOfFetchedItems),
  });
  const { terminate: terminatePool } = Pool.useTerminate();
  const companyDeletePoolTracker = AnalyticHooks.useClick(PossibleAction.DELETE_POOL);
  const { deletePool } = Pool.useDelete();
  const { terminate: terminatePlan } = SharePlan.useTerminate();
  const deleteSharePlanTracker = AnalyticHooks.useClick(PossibleAction.DELETE_PLAN);
  const { deletePlan } = SharePlan.useDelete();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOLS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOL] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_TERMINATED_POOLS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLANS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLAN] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.SHARE_PLAN_STAKEHOLDERS] });
  }, [queryClient]);

  const {
    isOpen: isOpenPlanModal,
    handleOpenModal: handleOpenPlanModal,
    handleCloseModal: handleClosePlanModal,
    toggler: PlanModalToggler,
  } = useModalState({ initialValue: !!get('openAddPlanModal') });

  const {
    handleCloseModal: handleCloseEditPlanModal,
    handleOpenModal: handleOpenEditPlanModal,
    isOpen: isOpenEditPlanModal,
  } = useModalState({ initialValue: !!get('openEditPlanModal') });

  const paramPlanId = get('paramPlanId');

  const {
    handleCloseModal: handleClosePoolTerminationDateModal,
    handleOpenModal: handleOpenPoolTerminationDateModal,
    isOpen: isOpenPoolTerminationDateModal,
  } = useModalState({});

  const {
    handleCloseModal: handleClosePlanTerminationDateModal,
    handleOpenModal: handleOpenPlanTerminationDateModal,
    isOpen: isOpenPlanTerminationDateModal,
  } = useModalState({});

  const {
    handleCloseModal: handleCloseExerciseStockModal,
    handleOpenModal: handleOpenExerciseStockModal,
    isOpen: isOpenExerciseStockModal,
    modalData: exerciseType,
  } = useModalState<unknown, unknown, ExerciseType>();

  const { mutate: mutateExport } = useAppMutation([QueryKey.EXPORT_POOLS_PLANS, companyId], {
    mutationFn: async () => {
      if (!selectedCompany) return;

      await ApiService.downloadFile({
        endpoint: BackendRoute.COMPANIES,
        routePath: [selectedCompany?.id || '', 'pools-plans-export'],
      });
    },
  });

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      setCurrentPage(pageNumber);
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const handleSelectNumberOfFetchedItems = useCallback(
    (select: SelectNumberOfFetchedItems) => {
      setNumberOfFetchedItems(select);
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const {
    stakeholders,
    totalPages: stakeholdersTotalPages,
    isLoading: stakeholdersLoading,
  } = Pool.useStakeholdersWithGrants({
    companyId,
    currentPage,
    take: Number(numberOfFetchedItems),
  });

  useEffect(() => {
    const isOpen = !!get('openEditPlanModal');
    const planId = get('paramPlanId');

    if (isOpen && planId) {
      handleOpenEditPlanModal();
    } else {
      handleCloseEditPlanModal();
    }
  }, [get('openEditPlanModal'), get('paramPlanId')]);

  if (
    !pools ||
    !terminatedPools ||
    isLoading ||
    isTerminatedLoading ||
    !stakeholders ||
    stakeholdersLoading
  )
    return <Loader />;

  const isLastItemOnPage = pools.length === 1;
  const isFirstPage = currentPage === 1;

  return (
    <>
      <ModalWrapper
        control={{ open: isOpenPlanModal, onOpenChange: PlanModalToggler }}
        side="RIGHT"
      >
        <AddPlanForm
          closeModal={handleClosePlanModal}
          companyId={companyId}
          invalidateQuery={invalidateQuery}
        />
      </ModalWrapper>
      <ModalWrapper
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        control={{ open: isOpenAddPoolModal, onOpenChange: AddPoolModalToggler }}
        side="RIGHT"
      >
        <AddPoolForm
          companyId={companyId}
          handleClose={handleCloseAddPoolModal}
          invalidateQuery={invalidateQuery}
        />
      </ModalWrapper>
      <EditPoolModal
        companyId={companyId}
        handleClose={handleCloseEditPoolModal}
        handleTerminate={({ date, id }) =>
          terminatePool({ date, poolId: id, companyId }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPoolModal}
        onEditClick={({ id, step }) => setSelectedPool({ id, mode: 'EDIT', step })}
        poolId={selectedPool?.id || ''}
        previewMode={selectedPool?.mode === 'VIEW' || undefined}
        step={selectedPool?.step || 1}
      />
      <EditPlanModal
        companyId={companyId}
        handleClose={handleCloseEditPlanModal}
        handleTerminate={({ date, id }) =>
          terminatePlan({ date, planId: id, companyId }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPlanModal}
        onEditClick={({ id, step }) => setSelectedPlan({ id, mode: 'EDIT', step })}
        planId={selectedPlan?.id || paramPlanId || ''}
        previewMode={selectedPlan?.mode === 'VIEW' || undefined}
        step={paramPlanId ? 2 : selectedPlan?.step || 1}
      />
      <PoolTerminationDateModal
        companyId={companyId}
        handleClose={handleClosePoolTerminationDateModal}
        handleTerminate={({ date, id }) =>
          terminatePool({ companyId, date, poolId: id }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenPoolTerminationDateModal}
        poolId={selectedPool?.id || ''}
      />
      <PlanTerminationDateModal
        companyId={companyId}
        handleClose={handleClosePlanTerminationDateModal}
        handleTerminate={({ date, id }) =>
          terminatePlan({ companyId, date, planId: id }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenPlanTerminationDateModal}
        planId={selectedPlan?.id || ''}
      />
      <ExerciseForm
        companyId={companyId}
        handleClose={handleCloseExerciseStockModal}
        invalidateQuery={invalidateQuery}
        isOpen={isOpenExerciseStockModal}
        type={exerciseType}
      />
      <div className="flex h-fit min-h-full w-full flex-col gap-4 overflow-x-auto rounded-md lg:p-4 lg:shadow-sm">
        <div className="flex h-[36px] w-full gap-4 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedTab === PoolPlansTab.ACTIVE && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(PoolPlansTab.ACTIVE);
              setCurrentPage(1);
            }}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.ACTIVE} />
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedTab === PoolPlansTab.TERMINATED &&
                'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(PoolPlansTab.TERMINATED);
              setCurrentPage(1);
              setSelectedFilter(PoolsPlansFilter.BY_POOLS);
            }}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.TERMINATED} />
          </Button>
        </div>
        <div className="hidden h-fit min-h-full w-full flex-1 flex-col rounded-md border-gray-200 py-4 shadow-sm xlg:flex">
          <PoolPlansHeader
            isDemo={selectedCompany?.isDemo || false}
            onAddPlanClick={handleOpenPlanModal}
            onAddPoolClick={handleOpenAddPoolModal}
            onExportClick={mutateExport}
            onOpenExerciseStockModal={handleOpenExerciseStockModal}
            selected={selected}
            selectedFilter={selectedFilter}
            selectedTab={selectedTab}
            setSelected={setSelected}
            setSelectedFilter={handleFilterSelect}
          />

          <PoolPlansCommonTable
            deselectPlan={(id) =>
              setExpandedId((prev) => ({
                ...prev,
                planId: prev.planId.filter((selectedId) => selectedId !== id),
              }))
            }
            deselectPool={(id) =>
              setExpandedId((prev) => ({
                ...prev,
                poolId: prev.poolId.filter((selectedId) => selectedId !== id),
              }))
            }
            isSortedByPools={selectedFilter === PoolsPlansFilter.BY_POOLS}
            onDeleteClick={(id) => {
              deletePool(
                { companyId, poolId: id },
                {
                  onSuccess: () => {
                    companyDeletePoolTracker();
                    if (isLastItemOnPage && !isFirstPage) {
                      setCurrentPage((prev) => prev - 1);
                    }
                    invalidateQuery();
                    setSelectedPool(null);
                  },
                },
              );
            }}
            onDeletePlanClick={(id) => {
              deletePlan(
                { companyId, planId: id },
                {
                  onSuccess: () => {
                    deleteSharePlanTracker();
                    toast.success(<AppFormattedMessage id={StringKey.PLAN_SUCCESSFULLY_DELETED} />);
                    invalidateQuery();
                    setSelectedPlan(null);
                  },
                },
              );
            }}
            onEditClick={(id) => {
              handleOpenEditPoolModal();
              setSelectedPool({
                id,
                mode: 'EDIT',
                step: 3,
              });
            }}
            onEditPlanClick={(id) => {
              handleOpenEditPlanModal();
              setSelectedPlan({
                id,
                mode: 'EDIT',
                step: 5,
              });
            }}
            onEditPlanTerminationDateClick={(id) => {
              handleOpenPlanTerminationDateModal();
              setSelectedPlan({
                id,
                mode: 'EDIT',
                step: 3,
              });
            }}
            onEditTerminationDateClick={(id) => {
              handleOpenPoolTerminationDateModal();
              setSelectedPool({
                id,
                mode: 'EDIT',
                step: 3,
              });
            }}
            onViewClick={(id) => {
              handleOpenEditPoolModal();

              setSelectedPool({
                id,
                mode: 'VIEW',
                step: 3,
              });
            }}
            onViewPlanClick={(id) => {
              handleOpenEditPlanModal();

              setSelectedPlan({
                id,
                mode: 'VIEW',
                step: 5,
              });
            }}
            pools={selectedTab === PoolPlansTab.ACTIVE ? pools : terminatedPools}
            selectedPlan={expandedId.planId}
            selectedPool={expandedId.poolId}
            selectedTab={selectedTab}
            setSelectedPlan={(id) =>
              setExpandedId((prev) => ({ ...prev, planId: [...prev.planId, id] }))
            }
            setSelectedPool={(id) =>
              setExpandedId((prev) => ({ ...prev, poolId: [...prev.poolId, id] }))
            }
            stakeholders={stakeholders}
          />
          <div className="mt-auto flex items-center justify-between py-4 pl-6">
            <Select onChange={handleSelectNumberOfFetchedItems} value={numberOfFetchedItems}>
              {({ open }) => (
                <div className="relative rounded border-gray-200">
                  <SelectButton className="flex items-center gap-4 bg-gray-25 py-[10px] pl-[12px] pr-[6px]">
                    <span className="text-m font-[450] text-gray-700">{numberOfFetchedItems}</span>
                    <ChevronDownIcon
                      className={twMerge(
                        'h-[13px] w-[13px] transition-transform duration-200',
                        open && 'rotate-180',
                      )}
                    />
                  </SelectButton>
                  <SelectOptions
                    className={twMerge(
                      'absolute left-0 right-0 top-[-6px] mt-1 max-h-60 w-[58px] -translate-y-full transform overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                      pools &&
                        pools.length === 0 &&
                        selectedFilter === PoolsPlansFilter.BY_POOLS &&
                        'pt-10',
                      stakeholders.length === 0 &&
                        selectedFilter === PoolsPlansFilter.BY_STAKEHOLDER &&
                        'top-[260px]',
                    )}
                  >
                    {selectVariant.map((select) => (
                      <SelectOption key={select} value={select}>
                        <span className="block text-m font-normal text-gray-700">{select}</span>
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </div>
              )}
            </Select>
            <PaginationController
              className="max-lg:px-4 lg:mx-6 lg:ml-auto"
              currentPage={currentPage}
              onClick={handlePageChange}
              totalPages={
                selectedTab === PoolPlansTab.ACTIVE
                  ? selectedFilter === PoolsPlansFilter.BY_STAKEHOLDER
                    ? stakeholdersTotalPages || 0
                    : totalPages || 0
                  : terminatedTotalPages || 0
              }
            />
          </div>
        </div>
        <div className="flex w-full flex-col border-gray-200 xlg:hidden">
          <PoolPlansMobile
            companyId={companyId}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            selectedTab={selectedTab}
          />
        </div>
      </div>
    </>
  );
};

export default PoolPlans;
