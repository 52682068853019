import { z } from 'zod';

import { StringKey } from '../../../lang';
import { DilutionCondition } from '../../../types/pool-plans.types';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];
export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

export const stepOne = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
    dillutedSharesCount: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
    shareClass: z.object({
      name: z.string(),
      id: z.string(),
    }),
    date: z.coerce.date(),
    dilutionCondition: z.nativeEnum(DilutionCondition),
    granted: z.number().optional(),
  })
  .refine(({ granted, dillutedSharesCount }) => !granted || dillutedSharesCount >= granted, {
    message: 'Granted shares less then diluted amount',
    path: ['dillutedSharesCount'],
  });

export const stepTwo = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
});

export type FormSchema = z.infer<typeof formSchema>;
