import React, { FC } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { FormInput, Input } from '../../../../components/Input';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventCombobox } from '../Components';
import { defaultSharePrice, FormSchema } from '../validation';

export type ShareIssuanceStepOneProps = {
  companyId: string;
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  issuedShares: number;
  lockMode: boolean;
};

export const ShareIssuanceStepOne: FC<ShareIssuanceStepOneProps> = ({
  companyId,
  control,
  lockMode,
  setFormData,
  issuedShares,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const event = stepOne?.eventDetails?.['share-issuance']?.event;
  const preMoneyValuation =
    event?.sharePrice &&
    event?.sharePrice !== defaultSharePrice &&
    event?.issuedSharesOnStart !== undefined
      ? event?.sharePrice * event?.issuedSharesOnStart
      : event?.sharePrice && event?.sharePrice !== defaultSharePrice
        ? event?.sharePrice * issuedShares
        : 0;

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4">
        <EventCombobox
          companyId={companyId}
          control={control}
          fieldName={`stepOne.eventDetails.share-issuance.event`}
          lockMode={lockMode}
        />
        <FormDatePicker
          defaultMonth={
            stepOne?.eventDetails?.['share-issuance']?.date
              ? new Date(stepOne?.eventDetails?.['share-issuance']?.date)
              : undefined
          }
          iconColor="#98A2B3"
          inputValue={
            stepOne?.eventDetails?.['share-issuance']?.date
              ? format(stepOne?.eventDetails?.['share-issuance']?.date, 'dd/MM/yyyy')
              : ''
          }
          inputWrapperClassName="w-full"
          onSelect={(date) => {
            setFormData('stepOne.eventDetails.share-issuance.date', date);
          }}
          placeholder={<AppFormattedMessage id={StringKey.DATE} />}
          value={stepOne?.eventDetails?.['share-issuance']?.date}
        />
        <FormInput
          control={control}
          currencySign
          customValue={(value) => {
            return value !== undefined && value !== null && Number(value) !== defaultSharePrice
              ? Math.round(value).toString()
              : '';
          }}
          disabled={stepOne?.eventDetails?.['share-issuance']?.event?.id !== 'new'}
          name={'stepOne.eventDetails.share-issuance.event.sharePrice'}
          numberOnly
          placeholder={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
          shouldDisplayZero
          shouldFormatNumber
          wrapperClassName="w-full"
        />
      </div>
      <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 pb-1 pt-3">
        <div className="flex flex-col items-start gap-1 pl-[14px]">
          <div className="flex items-center gap-2">
            <span className="text-sm font-[550] text-brand-700">
              <AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />
            </span>
            <HoverCard>
              <HoverCardTrigger>
                <InfoCircle className="size-4 cursor-pointer" iconColor="#2565C8 " />
              </HoverCardTrigger>
              <HoverCardContent
                className="z-20 w-max rounded-lg bg-[#101828] px-3 pb-[6px] pt-1"
                isShownTriangle
                side="top"
                triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
              >
                <span className="text-label-md font-[550] text-white">
                  {' '}
                  <AppFormattedMessage id={StringKey.CALCULATED_BY} />
                </span>
              </HoverCardContent>
            </HoverCard>
          </div>
          <span className="text-xs font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.ISSUED_SHARES} />:{' '}
            {(stepOne?.eventDetails?.['share-issuance']?.event?.issuedSharesOnStart !== undefined
              ? stepOne?.eventDetails?.['share-issuance']?.event?.issuedSharesOnStart
              : issuedShares
            )?.toLocaleString('en-US') || 0}
          </span>
        </div>
        <div className="flex flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
          <Input
            currencySign
            disabled
            onChange={() => {}}
            placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
            value={preMoneyValuation.toLocaleString('en-US')}
            wrapperClassName="w-full"
          />
          <DropDown
            answer="The Pre-Money Valuation is determined by multiplying the share price of the selected Round by the total number of issued shares at the time of the event. This value reflects the estimated value of the company before any new funding or investment is taken into account or new shares are issued"
            className="rounded-lg bg-gray-50"
            question={<AppFormattedMessage id={StringKey.VALUE_CALCULATION_INFO} />}
          />
        </div>
      </div>
      <DropDown
        answer="A Share Issuance event is the process of creating and distributing new shares of the company to investors, employees, or other stakeholders."
        className="rounded-lg bg-gray-50"
        question={<AppFormattedMessage id={StringKey.WHAT_IS_SHARE_ISSUANCE} />}
      />
    </div>
  );
};
