import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components/HoverCard';
import { StringKey } from '../../../lang';
import { IssuedSharesCardType } from '../../../types/companyTypes';

export type EquityCardProps = {
  selected?: boolean;
  isDefault?: boolean;
  capTableManagement: IssuedSharesCardType;
};

export const EquityCard: FC<EquityCardProps> = ({
  selected = false,
  capTableManagement,
  isDefault,
}) => {
  const { description, title, tooltip } = capTableManagement;
  return (
    <>
      <div className="relative flex w-full items-center justify-between px-6 py-8">
        <div className="flex flex-col">
          <span className="text-base font-semibold text-gray-700">
            {<AppFormattedMessage id={title} />}
          </span>
          <span className="text-label-md font-medium text-gray-500">
            {<AppFormattedMessage id={description} />}
          </span>
        </div>
        {selected && (
          <div className="flex size-[22px] items-center justify-center rounded-full bg-brand-700">
            <CheckIcon height={10} iconColor="#ffffff" width={10} />
          </div>
        )}
        {isDefault && (
          <span className="absolute right-0 top-0 rounded-l-[4px] rounded-tr-2xl bg-gray-100 px-2 py-[2px] text-label-md font-medium text-gray-700">
            <AppFormattedMessage id={StringKey.DEFAULT} />
          </span>
        )}
      </div>
      <div
        className={twMerge(
          'w-full rounded-b-2xl px-6 py-3',
          selected ? 'bg-brand-25' : 'bg-gray-50',
        )}
      >
        <HoverCard>
          <HoverCardTrigger>
            <span className="text-xs font-[450] text-brand-700 underline">
              <AppFormattedMessage id={StringKey.WHAT_IS_THIS} />
            </span>
          </HoverCardTrigger>
          <HoverCardContent
            className="z-20 max-w-[296px] rounded-lg bg-[#101828] px-3 pb-[6px] pt-1"
            isShownTriangle
            side="bottom"
            triangleClassName="top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ml-0"
          >
            <span className="font-semi-bold text-label-md text-white">
              {<AppFormattedMessage id={tooltip} />}
            </span>
          </HoverCardContent>
        </HoverCard>
      </div>
    </>
  );
};
