import React, { FC, useEffect, useState } from 'react';
import {
  Control,
  UseFormGetFieldState,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { HelpIcon } from '../../../../assets/icons';
import { HelpIconVariant } from '../../../../assets/icons/HelpIcon';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';
import { StringKey } from '../../../../lang';
import { Stakeholder } from '../../../../types/stakeholderTypes';
import { TableItem } from './FormComponents';
import { FormSchema, terminateForm as terminateFormSchema } from './validation';

export type TerminateFormProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
  filedState: UseFormGetFieldState<FormSchema>;
  handleCloseModal: () => void;
  handleTerminate: () => void;
  stakeholders?: Stakeholder[];
  editMode?: boolean;
};

export const TerminateForm: FC<TerminateFormProps> = ({
  control,
  setFormData,
  companyId,
  filedState,
  handleCloseModal,
  handleTerminate,
  stakeholders,
  editMode,
}) => {
  const [selectedId, setSelectedId] = useState<string>('');
  const { terminateForm } = useWatch<FormSchema>({ control });
  const { success } = terminateFormSchema.safeParse(terminateForm);

  const isValid = success && !filedState('terminateForm').invalid;

  useEffect(() => {
    if (
      !terminateForm?.stakeholderItems?.every(
        ({ grantItems }) => !grantItems || !grantItems.length,
      ) ||
      terminateForm?.isAgree
    )
      return;
    setFormData('terminateForm.isAgree', true);
  }, [setFormData, terminateForm?.isAgree, terminateForm?.stakeholderItems]);

  if (!stakeholders) return;

  return (
    <>
      <div className="flex w-full flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <table className="h-fit w-full transition-all">
          <thead>
            <tr className="flex bg-gray-50">
              <td className="p-0">
                <div className="h-full w-[48px]" />
              </td>
              <td className="w-[195px] items-center px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.STAKEHOLDER} />
                </span>
              </td>
              <td className="w-[129px] items-center px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.CURRENT_TYPE} />
                </span>
              </td>
              <td className="flex w-[129px] items-center gap-2 px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.NEW_TYPE} />
                </span>
                <HelpIcon variant={HelpIconVariant.EXERCISE} />
              </td>
              <td className="flex w-[184px] items-center gap-2 px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.TERMINATION_TYPE} />
                </span>
                <HelpIcon variant={HelpIconVariant.EXERCISE} />
              </td>
              <td className="flex w-[184px] items-center gap-2 px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.TERMINATION_DATE} />
                </span>
                <HelpIcon variant={HelpIconVariant.EXERCISE} />
              </td>
              <td className="flex w-[184px] items-center gap-2 px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.LAST_VESTING_DATE} />
                </span>
                <HelpIcon variant={HelpIconVariant.EXERCISE} />
              </td>
              <td className="flex w-[190px] items-center px-4 py-3">
                <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                  <AppFormattedMessage id={StringKey.CONTACT_INFO} />
                </span>
              </td>
            </tr>
          </thead>
          <tbody className="divide-y-[1px] divide-gray-100">
            {stakeholders.map((stakeholder, index) => (
              <TableItem
                companyId={companyId}
                control={control}
                key={stakeholder.id}
                selectedId={selectedId}
                setFormData={setFormData}
                setSelectedId={setSelectedId}
                stakeholder={stakeholder}
                stakeholderIndex={index}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex h-[84px] w-full items-end justify-between gap-3 bg-white pt-10">
        <div className="flex h-11 items-center gap-8">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={terminateForm?.isAgree}
              onChange={(e) => {
                setFormData('terminateForm.isAgree', e);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.CONFIRM_DAYS_CORRECT} />
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              checked={terminateForm?.isSendEmails}
              onChange={(e) => {
                setFormData('terminateForm.isSendEmails', e);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SEND_EMAIL_TERMINATION} />
            </span>
          </div>
        </div>

        <div className="flex h-11 gap-4">
          <Button
            className="w-fit bg-transparent p-3 text-sm font-[450] text-gray-700"
            onClick={handleCloseModal}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          {editMode ? (
            <Button
              className="h-full w-[260px] bg-brand-700 p-3 text-sm font-[550] text-gray-25"
              disabled={!isValid}
              onClick={handleTerminate}
              type="button"
            >
              <AppFormattedMessage id={StringKey.UPDATE_TERMINATION} />
            </Button>
          ) : (
            <Button
              className="h-full w-[260px] bg-fireside-600 p-3 text-sm font-[550] text-gray-25"
              disabled={!isValid}
              onClick={handleTerminate}
              type="button"
            >
              <AppFormattedMessage id={StringKey.TERMINATE} />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
