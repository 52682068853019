import { Switch as HeadlessSwitch } from '@headlessui/react';
import React, { ComponentPropsWithoutRef, FC, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type SwitchProps = {
  onSelect?: (state: boolean) => void;
  toggleClassName?: string;
  enabled?: boolean;
  disabled?: boolean;
} & ComponentPropsWithoutRef<typeof HeadlessSwitch>;

export const Switch: FC<SwitchProps> = ({
  onChange,
  className,
  toggleClassName,
  checked,
  enabled: enabledState = false,
  disabled = false,
  ...props
}) => {
  const [enabled, setEnabled] = useState(enabledState);

  const handleEnabled = useCallback(
    (state: boolean) => {
      if (disabled) return;
      onChange?.(state);
      setEnabled(state);
    },
    [disabled, onChange],
  );

  return (
    <HeadlessSwitch
      checked={checked || enabled}
      className={twMerge(
        'group relative flex h-5 w-9 shrink-0 cursor-pointer rounded-full bg-gray-100 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-forest-500 data-[focus]:outline-1 data-[focus]:outline-white',
        disabled ? 'cursor-not-allowed data-[checked]:bg-gray-300' : '',
        className,
      )}
      defaultChecked={checked}
      onChange={handleEnabled}
      {...props}
    >
      <span
        aria-hidden="true"
        className={twMerge(
          'pointer-events-none inline-block size-4 shrink-0 -translate-x-[2px] -translate-y-[2px] rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-[14px]',
          toggleClassName,
        )}
      />
    </HeadlessSwitch>
  );
};
