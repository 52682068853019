import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { SwitchIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { useSelected } from '../../../hooks/company';
import { StringKey } from '../../../lang';
import { IssuedSharesFormula } from '../../../types/companyTypes';
import { Snapshot } from '../../../types/snapshot.types';
import { GraphVariant } from '../variables';
import { CapTableGraph, GraphData } from './CapTableGraph';
import { EmptyCapTableGraph } from './EmptyCapTableGraph';

export type CapTableGraphWrapperProps = {
  wrapperClassName?: string;
  selectedGraphVariant: GraphVariant;
  isTablet: boolean;
  isDesktop: boolean;
  snapshot?: Snapshot;
  toggleGraphVariant: () => void;
};

export const CapTableGraphWrapper: FC<CapTableGraphWrapperProps> = ({
  wrapperClassName,
  selectedGraphVariant,
  isTablet,
  isDesktop,
  snapshot,
  toggleGraphVariant,
}) => {
  const { selectedCompany } = useSelected();

  const data = useMemo(() => {
    if (!snapshot?.stats?.stakeholdersTypes) {
      return [];
    }

    const totalVotingPercentage = snapshot.stats.stakeholdersTypes.reduce(
      (total, stakeholderType) => total + stakeholderType.votingPercentage,
      0,
    );

    const totalIssued = snapshot.issuedShares;

    const stakeholdersData: GraphData[] = snapshot.stats.stakeholdersTypes.map(
      ({ type, votingPercentage, issued }) => ({
        type,
        issuedPercentage: (issued * 100) / (totalIssued || 1),
        votingPercentage: (votingPercentage * 100) / (totalVotingPercentage || 1),
      }),
    );

    if (
      selectedCompany?.issuedSharesFormula === IssuedSharesFormula.ISSUED_SHARES_AND_AVAILABLE_POOL
    ) {
      const totalIssuedPercentage = stakeholdersData.reduce(
        (total, { issuedPercentage }) => total + issuedPercentage,
        0,
      );

      stakeholdersData.push({
        type: 'Pool',
        votingPercentage: 0,
        issuedPercentage: 100 - totalIssuedPercentage,
      });
    }

    return stakeholdersData;
  }, [
    snapshot?.stats?.stakeholdersTypes,
    snapshot?.issuedShares,
    selectedCompany?.issuedSharesFormula,
  ]);

  const showEmptyGraph = data.every((stakeholder) => stakeholder.issuedPercentage === 0);

  return (
    <div
      className={twMerge(
        'flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1',
        wrapperClassName,
      )}
    >
      <div className="flex w-full items-center justify-between gap-1 px-4">
        <span className="w-full py-3 text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          {selectedGraphVariant === GraphVariant.OWNERSHIP ? (
            <AppFormattedMessage id={StringKey.OWNERSHIP_PERCENTAGE} />
          ) : (
            <AppFormattedMessage id={StringKey.VOTING_RIGHTS_PERCENTAGE} />
          )}
        </span>
        <Button
          className="flex w-fit items-center gap-1 whitespace-nowrap"
          onClick={toggleGraphVariant}
          styleType="NONE"
        >
          <SwitchIcon />
          <span className="text-xs font-[500] text-gray-700">
            {isTablet || isDesktop ? (
              selectedGraphVariant === GraphVariant.OWNERSHIP ? (
                <AppFormattedMessage id={StringKey.SWITCH_TO_VOTING_RIGHTS} />
              ) : (
                <AppFormattedMessage id={StringKey.SWITCH_TO_OWNERSHIP} />
              )
            ) : (
              <AppFormattedMessage id={StringKey.SWITCH} />
            )}
          </span>
        </Button>
      </div>
      {!showEmptyGraph ? (
        <CapTableGraph
          data={data}
          isDesktop={isDesktop}
          isTablet={isTablet}
          variant={selectedGraphVariant}
        />
      ) : (
        <EmptyCapTableGraph className="animate-pulse" isDesktop={isDesktop} isTablet={isTablet} />
      )}
    </div>
  );
};
