import { Content, DialogTitle } from '@radix-ui/react-dialog';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { SheetOverlay } from './SheetOverlay';
import { SheetPortal } from './SheetPortal';

enum SheetSideVariant {
  BOTTOM = 'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
  LEFT = 'inset-y-0 left-0 h-full w-fit border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
  RIGHT = 'inset-y-0 right-0 h-full w-fit  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
  TOP = 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
  CENTER = 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 data-[state=closed]:fade-out data-[state=open]:fade-in',
}
export type SheetContentProps = {
  side?: keyof typeof SheetSideVariant;
} & ComponentPropsWithoutRef<typeof Content>;

export const SheetContent = forwardRef<ElementRef<typeof Content>, SheetContentProps>(
  ({ children, className, side = 'LEFT', ...props }, ref) => {
    return (
      <SheetPortal>
        <Content
          className={twMerge(
            'fixed z-[51] w-fit gap-4 bg-white p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out',
            SheetSideVariant[side],
            className,
          )}
          ref={ref}
          {...props}
        >
          {children}
          <DialogTitle />
        </Content>
        <SheetOverlay />
      </SheetPortal>
    );
  },
);
SheetContent.displayName = Content.displayName;
