import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  ActivityOverviewIcon,
  CloseIcon,
  ExportIcon,
  PlusIcon,
  RefreshIcon,
  SearchIcon,
  UploadIcon,
} from '../../assets/icons';
import { RefreshIconVariant } from '../../assets/icons/RefreshIcon';
import { AlertDialogWrapper } from '../../components/AlertDialog';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader';
import { PaginationController } from '../../components/PaginationController';
import { Sheet, SheetContent, SheetTrigger } from '../../components/Sheet';
import { QueryKey } from '../../constants';
import {
  SafeHooks,
  useDebounce,
  useEventOutsideElement,
  useInvalidateQueries,
  useModalState,
  useScreenSize,
  useSelectedCompany,
} from '../../hooks';
import { StringKey } from '../../lang';
import { safesSortStore } from '../../storage/safesSortStore';
import { sideBarContext } from '../../storage/sideBarContext';
import {
  AmountOfFetchedItems,
  amountOfFetchedItemsMap,
  DetailsTab,
  detailsTabTitleMap,
  SafeOrder,
  SafeSortBy,
} from '../../types/safes.types';
import { getTranslation } from '../../utils/getTranslation';
import { ConvertSafeModal, ViewConversionModal } from './ConvertModal';
import { DesktopDetails } from './Desktop';
import { EmptySafes } from './EmptySafes';
import { MobileDetails } from './Mobile';
import { AddSafeModal } from './Modals/Add';
import { BulkActionsModal } from './Modals/BulkActionModal';
import { DeleteSafeModal } from './Modals/Delete';
import { EditSafeModal } from './Modals/Edit';
import { ImportSafes } from './Modals/Import';
import { EditSafeStepVariant } from './Modals/type';
import { OverallStatus } from './OverallStatus';
import { GraphWrapper } from './SafeGraph';

export type SelectedSafe = {
  id: string;
  step: EditSafeStepVariant;
  mode: 'EDIT' | 'VIEW';
};

const Safes: FC = () => {
  const { selectedCompany } = useSelectedCompany();
  const { isOpen: isSideBarExpanded } = useContext(sideBarContext);
  const inputRef = useRef<HTMLDivElement>(null);
  useEventOutsideElement(inputRef, 'click', () => !search && setOpenSearch(false));

  const { width } = useScreenSize();
  const isTablet = width <= 1233;
  const showMobileSearchBar = isTablet || (width <= 1300 && isSideBarExpanded);

  const [search, setSearch] = useState<null | string>(null);
  const [isOpenSearch, setOpenSearch] = useState(false);
  const [isFocusedInput, setInputFocus] = useState(false);
  const [selectedTab, setSelectedTab] = useState<DetailsTab>(DetailsTab.PENDING_CONVERSION);
  const [selectedSafesIds, setSelectedSafesIds] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState<AmountOfFetchedItems | '4'>(
    AmountOfFetchedItems.TEN,
  );
  const companyId = selectedCompany?.id || '';

  const [selectedSafe, setSelectedSafe] = useState<SelectedSafe | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });
  const [sortState, setSortState] = useState<{
    field: SafeSortBy | null;
    sortMode: SafeOrder | null;
  }>(safesSortStore.get() || { field: null, sortMode: null });

  const { invalidateQuery } = useInvalidateQueries(
    QueryKey.GET_SAFES,
    QueryKey.GET_SAFE,
    QueryKey.GET_SAFE_RECAP,
    QueryKey.GET_SAFE_OVERALL_STATUS,
  );

  const { isLoading, safes, totalPages, totalSafesCount, refetch } = SafeHooks.useSafes({
    currentPage,
    sortOrder: sortState.sortMode,
    sortBy: sortState.field,
    companyId,
    numberOfFetchedItems: Number(itemsToFetch),
    search: search || '',
    convertedOnly: selectedTab === DetailsTab.CONVERTED,
  });

  const { deleteSafe } = SafeHooks.useDelete();

  const { debounceCallback } = useDebounce({
    callback: () => {
      invalidateQuery();
    },
  });

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );

  const handleSortField = useCallback(
    (newField: SafeSortBy) => {
      setSortState((prevState) => {
        if (prevState.field === newField) {
          const nextSortMode = !prevState.sortMode
            ? SafeOrder.ASC
            : prevState.sortMode === SafeOrder.ASC
              ? SafeOrder.DESC
              : null;

          safesSortStore.set({ ...prevState, sortMode: nextSortMode });
          return { ...prevState, sortMode: nextSortMode };
        } else {
          safesSortStore.set({
            field: newField,
            sortMode: SafeOrder.ASC,
          });
          return { field: newField, sortMode: SafeOrder.ASC };
        }
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const handleBulkDelete = async (safesIds: string[]) => {
    let hasError = false;

    for (const safeId of safesIds) {
      try {
        await new Promise<void>((resolve, reject) => {
          deleteSafe(
            { companyId, safeId },
            {
              onSuccess: () => {
                resolve();
              },
              onError: (error) => {
                reject(error);
              },
            },
          );
        });
      } catch (error) {
        hasError = true;
      }
    }

    if (hasError) {
      toast.error(getTranslation(StringKey.ERROR_DELETING_SAFES));
    } else {
      invalidateQuery();
      setSelectedSafesIds([]);
      toast.success(`${safesIds.length} ${getTranslation(StringKey.SAFES_DELETED)}`);
    }
  };

  const {
    isOpen: isOpenBulkActionsModal,
    handleDismissModal: handleDismissBulkActionsModal,
    handleOpenModal: handleOpenBulkActionsModal,
  } = useModalState({
    onSuccess: () => setSelectedSafesIds([]),
  });

  const {
    handleCloseModal: handleCloseAddSafeModal,
    handleOpenModal: handleOpenAddSafeModal,
    isOpen: isOpenAddSafeModal,
  } = useModalState();

  const {
    handleCloseModal: handleCloseEditSafeModal,
    handleOpenModal: handleOpenEditSafeModal,
    isOpen: isOpenEditSafeModal,
  } = useModalState({});

  const {
    handleCloseModal: handleCloseViewConversionModal,
    handleOpenModal: handleOpenViewConversionModal,
    isOpen: isOpenViewConversionModal,
  } = useModalState({});

  const {
    handleCloseModal: handleCloseDeleteModal,
    handleSuccessModal: handleSuccessDeleteModal,
    handleOpenModal: handleOpenDeleteModal,
    isOpen,
    toggler,
  } = useModalState({
    onSuccess: () => handleBulkDelete(selectedSafesIds),
  });

  const {
    handleCloseModal: handleCloseConvertSafeModal,
    handleOpenModal: handleOpenConvertSafeModal,
    isOpen: isOpenConvertSafeModal,
  } = useModalState();

  const {
    toggler: togglerImportSafeModal,
    isOpen: isOpenImportSafeModal,
    handleOpenModal: handleOpenImportSafeModal,
  } = useModalState();

  const { mutateExport } = SafeHooks.useExport();
  useEffect(() => {
    if (selectedSafesIds.length !== 0) return handleOpenBulkActionsModal();
    handleDismissBulkActionsModal();
  }, [handleDismissBulkActionsModal, handleOpenBulkActionsModal, selectedSafesIds.length]);

  useEffect(() => {
    if (!search && companyId) {
      refetch();
    }
  }, [companyId, refetch, search]);

  useEffect(() => {
    if (isOpenConvertSafeModal) {
      setSelectedSafesIds([]);
    }
  }, [isOpenConvertSafeModal]);

  useEffect(() => {
    if (isTablet) {
      setItemsToFetch('4');
    } else {
      setItemsToFetch(AmountOfFetchedItems.TEN);
    }
  }, [isTablet]);

  if (isLoading) return <Loader />;

  return (
    <>
      <BulkActionsModal
        handleConvert={(safesIds) => {
          setSelectedSafesIds(safesIds);
          handleOpenConvertSafeModal();
        }}
        handleDelete={(safesIds) => {
          setSelectedSafesIds(safesIds);
          handleOpenDeleteModal();
        }}
        isOpenModal={isOpenBulkActionsModal}
        onRemoveSelectAll={() => setSelectedSafesIds([])}
        safes={safes || []}
        selectedSafesIds={selectedSafesIds}
      />
      <AddSafeModal
        companyId={companyId}
        handleClose={handleCloseAddSafeModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenAddSafeModal}
      />
      <ViewConversionModal
        companyId={companyId}
        handleClose={handleCloseViewConversionModal}
        isOpenModal={isOpenViewConversionModal}
        safeId={selectedSafe?.id || ''}
      />

      <EditSafeModal
        companyId={companyId}
        handleClose={handleCloseEditSafeModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditSafeModal}
        onEditClick={({ id, step }) => setSelectedSafe({ id, mode: 'EDIT', step })}
        previewMode={selectedSafe?.mode === 'VIEW' || undefined}
        safeId={selectedSafe?.id || ''}
        step={selectedSafe?.step || 1}
      />
      <ConvertSafeModal
        companyId={companyId}
        handleClose={handleCloseConvertSafeModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenConvertSafeModal}
        selectedSafeIds={selectedSafesIds}
        selectedSafes={safes?.filter((safe) => selectedSafesIds.includes(safe.id)) || []}
      />
      <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
        <DeleteSafeModal
          isPlural={selectedSafesIds.length > 1}
          onClose={handleCloseDeleteModal}
          onSuccess={handleSuccessDeleteModal}
        />
      </AlertDialogWrapper>

      <ImportSafes isOpenModal={isOpenImportSafeModal} setOpen={togglerImportSafeModal} />

      <div className="flex h-full min-h-[86vh] w-full flex-col gap-4 p-1">
        <div className="hidden items-center justify-between rounded-md p-4 shadow-sm xlg:flex">
          <div className="flex items-center gap-2">
            <ActivityOverviewIcon />
            <span className="text-base font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.OVERVIEW} />
            </span>
          </div>

          <div className="flex items-center gap-3">
            <Sheet modal={false} open={showMobileSearchBar && isOpenSearch}>
              <div className="flex items-center justify-end" ref={inputRef}>
                <Input
                  onBlur={() => setInputFocus(false)}
                  onChange={handleSearch}
                  onFocus={() => {
                    setInputFocus(true);
                  }}
                  placeholder={search ? '' : <AppFormattedMessage id={StringKey.SEARCH} />}
                  value={search || ''}
                  wrapperClassName={twMerge(
                    'transition-all duration-500 w-[200px] overflow-hidden max-h-9',
                    isOpenSearch ? 'delay-500' : 'w-0',
                    showMobileSearchBar && 'w-0',
                  )}
                />
                {!showMobileSearchBar && isOpenSearch && typeof search === 'string' && (
                  <div
                    className="absolute mr-8 cursor-pointer p-2"
                    onClick={() => {
                      setSearch('');
                      invalidateQuery();
                    }}
                  >
                    <CloseIcon />
                  </div>
                )}
                <SheetTrigger
                  className={twMerge(
                    'flex size-9 items-center justify-center rounded border-[1px] border-gray-100 bg-gray-50 transition-all duration-300',
                    !showMobileSearchBar && isOpenSearch
                      ? 'h-9 rounded-l-none rounded-br-none border-x-0 border-t-0 border-b-black'
                      : 'delay-1000',
                    !showMobileSearchBar &&
                      isFocusedInput &&
                      'border-b-[2px] border-b-brand-700 transition-none',
                  )}
                  onClick={() => {
                    setOpenSearch((prev) => !prev);
                    setSearch(null);
                    invalidateQuery();
                  }}
                >
                  <SearchIcon iconColor={isFocusedInput ? '#2565C8' : '#344054'} />
                </SheetTrigger>
              </div>
              <SheetContent
                className="w-full"
                onClick={(e) => e.stopPropagation()}
                onInteractOutside={() => {
                  if (!search) {
                    setOpenSearch(false);
                    setSearch(null);
                    invalidateQuery();
                  }
                }}
                side="TOP"
              >
                <div className={twMerge('flex')}>
                  <Input
                    icon={search && <CloseIcon />}
                    onBlur={() => setInputFocus(false)}
                    onChange={handleSearch}
                    onFocus={(e) => {
                      setInputFocus(true);
                      e.stopPropagation();
                    }}
                    onIconClick={() => {
                      setTimeout(() => {
                        setSearch('');
                        invalidateQuery();
                      }, 0);
                    }}
                    placeholder={<AppFormattedMessage id={StringKey.SEARCH} />}
                    value={search || ''}
                    wrapperClassName={twMerge('transition-all duration-1000 w-full')}
                  />
                </div>
              </SheetContent>
            </Sheet>
            <Button
              className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
              onClick={() => mutateExport()}
            >
              <ExportIcon className="max-lg:rotate-180" />
              <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
                {isSideBarExpanded && isTablet ? '' : <AppFormattedMessage id={StringKey.EXPORT} />}
              </span>
            </Button>
            <Button
              className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
              onClick={handleOpenImportSafeModal}
            >
              <UploadIcon className="max-lg:rotate-180" />
              <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
                {isSideBarExpanded && isTablet ? '' : <AppFormattedMessage id={StringKey.IMPORT} />}
              </span>
            </Button>
            <Button
              className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] xlg:w-fit"
              onClick={handleOpenConvertSafeModal}
            >
              <RefreshIcon variant={RefreshIconVariant.SAFES} />
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.CONVERT_SAFE} />
              </span>
            </Button>
            <Button
              className="flex size-9 gap-1 px-4 py-[6px] xlg:w-fit"
              onClick={handleOpenAddSafeModal}
            >
              <PlusIcon />

              <span className="text-sm font-[450] text-white">
                <AppFormattedMessage id={StringKey.ADD_SAFE} />
              </span>
            </Button>
          </div>
        </div>

        {totalSafesCount !== 0 ? (
          <>
            <div className="flex h-max w-full items-stretch gap-4 max-lg:flex-col">
              <OverallStatus companyId={companyId} />
              <GraphWrapper companyId={companyId} />
            </div>
            <div className="flex h-fit flex-col gap-4 rounded-md p-4 shadow-sm">
              <div className="flex h-9 w-fit divide-x divide-gray-200 rounded border border-gray-200">
                {detailsTabTitleMap.map(([filterKey, filter]) => (
                  <Button
                    className={twMerge(
                      'h-full w-fit rounded-none px-4 text-sm font-[450] text-[#858593]',
                      selectedTab === filterKey && 'bg-brand-25 text-sm font-[550] text-[#172335]',
                    )}
                    key={filterKey}
                    onClick={() => {
                      setSelectedTab(filterKey);
                      setSelectedSafesIds([]);
                    }}
                    styleType="NONE"
                  >
                    <AppFormattedMessage id={filter} />
                  </Button>
                ))}
              </div>

              {safes && safes.length === 0 ? (
                <EmptySafes
                  text={
                    selectedTab === DetailsTab.CONVERTED ? (
                      <AppFormattedMessage id={StringKey.WHEN_AVAILABLE_CONVERTED_SAFES} />
                    ) : (
                      <AppFormattedMessage id={StringKey.WHEN_AVAILABLE_SAFES} />
                    )
                  }
                  title={
                    selectedTab === DetailsTab.CONVERTED ? (
                      <AppFormattedMessage id={StringKey.NO_SAFES_CONVERTED} />
                    ) : (
                      <AppFormattedMessage id={StringKey.NO_SAFES_FOUND} />
                    )
                  }
                />
              ) : (
                <div className="flex h-full w-full flex-col justify-between rounded-md lg:shadow-sm">
                  <DesktopDetails
                    handleConvert={() => {
                      handleOpenConvertSafeModal();
                    }}
                    handleDelete={(safeId) => {
                      deleteSafe(
                        { companyId, safeId },
                        {
                          onSuccess: () => {
                            invalidateQuery();
                            setSelectedSafe(null);
                          },
                        },
                      );
                    }}
                    handleEdit={(safeId) => {
                      handleOpenEditSafeModal();
                      setSelectedSafe({
                        id: safeId,
                        mode: 'EDIT',
                        step: 4,
                      });
                    }}
                    handleView={(safeId) => {
                      handleOpenEditSafeModal();
                      setSelectedSafe({
                        id: safeId,
                        mode: 'VIEW',
                        step: 4,
                      });
                    }}
                    handleViewConversion={(safeId) => {
                      handleOpenViewConversionModal();
                      setSelectedSafe({ id: safeId, mode: 'VIEW', step: 1 });
                    }}
                    isSelected={(selectedId) => {
                      return !!selectedSafesIds.find((id) => id === selectedId);
                    }}
                    isSelectStarted={selectedSafesIds.length !== 0}
                    onRemoveSelect={(selectedId) =>
                      setSelectedSafesIds((prev) => prev.filter((id) => id !== selectedId))
                    }
                    onRemoveSelectAll={() => setSelectedSafesIds([])}
                    onSelect={(companyId) => {
                      setSelectedSafesIds((prev) => [...prev, companyId]);
                    }}
                    onSelectAll={(companiesIds) => setSelectedSafesIds(companiesIds)}
                    onSortClick={(newField) => handleSortField(newField)}
                    safes={safes || []}
                    selectedTab={selectedTab}
                    sortField={sortState.field}
                    sortMode={sortState.sortMode}
                  />
                  <MobileDetails safes={safes || []} selectedTab={selectedTab} />
                  <div className="w-full">
                    <PaginationController
                      currentPage={currentPage}
                      itemsToFetch={itemsToFetch}
                      itemsToFetchMap={amountOfFetchedItemsMap}
                      onClick={setCurrentPage}
                      onFetchAmountChange={setItemsToFetch}
                      totalPages={totalPages || 0}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <EmptySafes
            onClick={handleOpenAddSafeModal}
            showButton
            text={<AppFormattedMessage id={StringKey.WHEN_AVAILABLE_SAFES} />}
            title={<AppFormattedMessage id={StringKey.NO_SAFES_FOUND} />}
          />
        )}
      </div>
    </>
  );
};
export default Safes;
