import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { SharePlan } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { Pool } from '../../../../../types/pool-plans.types';
import { PoolPlansPopover } from '../../PoolPlansPopover';

type ScrollablePoolPlanItemProps = {
  companyId: string;
  stakeholderId: string;
  pool: Pool;
  isSelected: boolean;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
};

export const ScrollablePoolPlanItem: FC<ScrollablePoolPlanItemProps> = ({
  pool,
  isSelected,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  companyId,
  stakeholderId,
  onEditTerminationDateClick,
}) => {
  const granted = pool?.granted?.toLocaleString('en-US');
  const totalGrantVested = pool?.totalGrantVested?.toLocaleString('en-US');
  const sharesVested = pool?.sharesVested?.toLocaleString('en-US');
  const exercisable = pool?.exercisable?.toLocaleString('en-US');
  const exercised = pool?.exercised?.toLocaleString('en-US');
  const expired = pool?.expired?.toLocaleString('en-US');
  const returnedToPool = pool.returnedToPool?.toLocaleString('en-US');
  const cashSettled = pool.cashSettled?.toLocaleString('en-US');

  const { sharePlans, isEmpty } = SharePlan.useSharePlansByStakeholder({
    companyId,
    stakeholderId,
    poolId: pool?.id || '',
  });

  const isTerminatedDatePassed = useMemo(() => {
    return pool?.terminationDate
      ? new Date(pool.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [pool?.terminationDate]);

  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !granted && 'text-gray-300',
            )}
          >
            {granted || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !totalGrantVested && 'text-gray-300',
            )}
          >
            {totalGrantVested || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !sharesVested && 'text-gray-300',
            )}
          >
            {sharesVested || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !exercisable && 'text-gray-300',
            )}
          >
            {exercisable || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !exercised && 'text-gray-300',
            )}
          >
            {exercised || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !cashSettled && 'text-gray-300',
            )}
          >
            {cashSettled || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !returnedToPool && 'text-gray-300',
            )}
          >
            {returnedToPool || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !expired && 'text-gray-300',
            )}
          >
            {expired || '-'}
          </span>
        </td>
        <td className="relative">
          <PoolPlansPopover
            id={pool.id}
            isTerminated={isTerminatedDatePassed}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            onEditTerminationDateClick={onEditTerminationDateClick}
            onViewClick={onViewClick}
            text="Pool"
          />
        </td>
      </tr>
      {isSelected && sharePlans && !isEmpty && (
        <>
          {sharePlans.map((plan) => (
            <tr className="h-[72px] w-full bg-white" key={plan.id}>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.granted?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.totalGrantVested?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.sharesVested?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.exercisable?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.exercised?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.cashSettled?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {plan?.expired?.toLocaleString('en-US') || '-'}
                </span>
              </td>
              <td className="relative">
                {!plan.isTerminated && (
                  <PoolPlansPopover
                    id={plan.id}
                    isTerminated={
                      plan?.terminationDate
                        ? new Date(plan.terminationDate).getTime() < new Date().getTime()
                        : false
                    }
                    onDeleteClick={onDeletePlanClick}
                    onEditClick={onEditPlanClick}
                    onViewClick={onViewPlanClick}
                    text={<AppFormattedMessage id={StringKey.PLAN} />}
                  />
                )}
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  );
};
