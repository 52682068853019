import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { CalendarIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { SharePlan, useFormat, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import { toNumber } from '../../../../utils/toNumber';
import { toPositive } from '../../../../utils/toPositive';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import {
  FormDropDown,
  SharePlanCombobox,
  StakeholderCombobox,
  SubEventFileUpload,
} from '../Components';
import { FormSchema } from '../validation';

export type GrantStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  formData: UseFormGetValues<FormSchema>;
  setError: UseFormSetError<FormSchema>;
  clearErrors: UseFormClearErrors<FormSchema>;
  errors: FieldErrors<FormSchema>;
};

type GrantItemProps = {
  index: number;
  field: FieldArrayWithId<FormSchema>;
  handleRemoveGrantItems: (index: number) => void;
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  getFullTitle: (index: number) => string;
  isShownRemoveButton: boolean;
  companyId: string;
  hasDuplicates: boolean;
  firstDuplicateIndex: number | null;
  scrollToFirstDuplicate: () => void;
  itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  formData: UseFormGetValues<FormSchema>;
  setError: UseFormSetError<FormSchema>;
  clearErrors: UseFormClearErrors<FormSchema>;
  errors: FieldErrors<FormSchema>;
};
const GrantItem: FC<GrantItemProps> = ({
  control,
  field,
  handleRemoveGrantItems,
  formData,
  setFormData,
  getFullTitle,
  index,
  isShownRemoveButton,
  companyId,
  firstDuplicateIndex,
  hasDuplicates,
  itemRefs,
  scrollToFirstDuplicate,
  setError,
  clearErrors,
  errors: { stepOne: errors },
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const [sharePlanId, setSharePlan] = useState(
    stepOne?.eventDetails?.grant?.grantItems?.[index]?.sharePlan?.id || '',
  );
  const { format } = useFormat();
  const { sharePlan } = SharePlan.useSharePlan({ companyId, planId: sharePlanId });

  const initialShares =
    stepOne?.eventDetails?.grant?.grantItems?.[index]?.initialShares || undefined;
  const initialSharePlanId =
    stepOne?.eventDetails?.grant?.grantItems?.[index]?.initialSharePlanId || undefined;
  const shouldAddInitialShares = initialShares && initialSharePlanId === sharePlanId;

  const poolAvailable = shouldAddInitialShares
    ? toNumber(sharePlan?.pool?.dillutedSharesCount) -
      toNumber(sharePlan?.pool?.granted) +
      initialShares
    : toNumber(sharePlan?.pool?.dillutedSharesCount) - toNumber(sharePlan?.pool?.granted);

  const poolTotalUsedShares = toNumber(
    stepOne?.eventDetails?.grant?.grantItems
      ?.filter(
        ({ sharePlan: grantItemSharePlan }) => grantItemSharePlan?.pool?.id === sharePlan?.pool.id,
      )
      .reduce((acc, { shares }) => acc + toNumber(shares), 0),
  );

  const available = poolAvailable - poolTotalUsedShares;

  useEffect(() => {
    if (poolAvailable) {
      setFormData(`stepOne.eventDetails.grant.grantItems.${index}.balance`, Number(poolAvailable));
    }
  }, [index, poolAvailable, setFormData]);

  return (
    <>
      <FormDropDown
        handleRemove={() => handleRemoveGrantItems(index)}
        key={field.id}
        showRemoveButton={isShownRemoveButton}
        title={getFullTitle(index)}
      >
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col gap-3" ref={(el) => (itemRefs.current[index] = el)}>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.GRANT_DATE} />
              </span>
              <DatePicker
                defaultMonth={
                  stepOne?.eventDetails?.grant?.grantItems?.[index]?.grantDate || undefined
                }
                onSelect={(date) =>
                  setFormData(`stepOne.eventDetails.grant.grantItems.${index}.grantDate`, date)
                }
                value={stepOne?.eventDetails?.grant?.grantItems?.[index]?.grantDate}
                wrapperClassName="w-[224px]"
              >
                <FormInput
                  autoComplete="off"
                  control={control}
                  customValue={(value) => {
                    return value && new Date(value).getTime() !== new Date(+0).getTime()
                      ? format(value, 'dd/MM/yyyy')
                      : '';
                  }}
                  disabledInput
                  icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
                  name={`stepOne.eventDetails.grant.grantItems.${index}.grantDate`}
                  placeholder={<AppFormattedMessage id={StringKey.DATE} />}
                  wrapperClassName="w-full"
                />
              </DatePicker>
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.STAKEHOLDER} />
              </span>
              <StakeholderCombobox
                companyId={companyId}
                control={control}
                name={`stepOne.eventDetails.grant.grantItems.${index}.stakeholder`}
              />
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.EQUITY_PLAN} />
              </span>
              <SharePlanCombobox
                companyId={companyId}
                control={control}
                index={index}
                onSelect={setSharePlan}
              />
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.NO_OF_SHARES} />
              </span>
              <div className="flex flex-col items-start gap-2">
                <FormInput
                  control={control}
                  errorMessage={available < 0 && 'Available already run out'}
                  name={`stepOne.eventDetails.grant.grantItems.${index}.shares`}
                  numberOnly
                  placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                  shouldFormatNumber
                  wrapperClassName="w-[224px]"
                />
                <span className="text-xs text-gray-600">
                  <AppFormattedMessage id={StringKey.AVAILABLE} />{' '}
                  {toPositive(available).toLocaleString('en-US')}
                </span>
              </div>
            </div>
          </div>
          <SubEventFileUpload
            clearErrors={clearErrors}
            errors={errors?.eventDetails?.grant?.grantItems?.[index]?.files || undefined}
            field={`stepOne.eventDetails.grant.grantItems.${index}.files`}
            files={stepOne?.eventDetails?.grant?.grantItems?.[index]?.files || []}
            formData={formData}
            setError={setError}
            setFormData={setFormData}
            text="grant"
          />
        </div>
      </FormDropDown>
      {hasDuplicates && firstDuplicateIndex === index && (
        <span className="rounded bg-fireside-50 p-3 text-xs font-[450] text-gray-700">
          <AppFormattedMessage id={StringKey.GRANT_ASSOCIATION_INFO} />
          <span
            className="cursor-pointer font-[550] text-brand-700 underline"
            onClick={scrollToFirstDuplicate}
          >
            <AppFormattedMessage id={StringKey.CLICK_HERE} />
          </span>
          .
        </span>
      )}
    </>
  );
};

export const GrantStepOne: FC<GrantStepOneProps> = ({
  control,
  setFormData,
  companyId,
  formData,
  setError,
  clearErrors,
  errors,
}) => {
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [firstDuplicateIndex, setFirstDuplicateIndex] = useState<number | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { messagesLocale } = useLocale();
  const { initialStep, stepOne } = useWatch<FormSchema>({ control });

  const defaultDate = useMemo(() => new Date(+0), []);
  const watchedGrantItems = useWatch({
    control,
    name: 'stepOne.eventDetails.grant.grantItems',
  });

  const {
    fields: grantItemsFields,
    append: grantItemsAppend,
    remove: grantItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.eventDetails.grant.grantItems',
  });

  const handleRemoveGrantItems = useCallback(
    (index: number) => {
      grantItemsRemove(index);
    },
    [grantItemsRemove],
  );

  const handleAppendGrantItems = useCallback(() => {
    grantItemsAppend({
      grantDate: defaultDate,
      sharePlan: { id: '', name: '', pool: { id: '' } },
      stakeholder: { id: '', fullName: '' },
      shares: 0,
      balance: 0,
    });
  }, [defaultDate, grantItemsAppend]);

  const checkForDuplicates = useCallback(() => {
    const combinations = new Set<string>();
    let duplicatesFound = false;
    let firstDuplicateIdx: number | null = null;

    if (watchedGrantItems) {
      watchedGrantItems.forEach((field, index) => {
        const combination = `${field?.stakeholder?.id}-${field?.sharePlan?.id}`;
        if (combinations.has(combination) && field?.stakeholder?.id && field?.sharePlan?.id) {
          duplicatesFound = true;
          if (firstDuplicateIdx === null) {
            firstDuplicateIdx = index;
          }
        } else {
          combinations.add(combination);
        }
      });
    }

    setHasDuplicates(duplicatesFound);
    setFirstDuplicateIndex(firstDuplicateIdx);
  }, [watchedGrantItems]);

  const scrollToFirstDuplicate = useCallback(() => {
    if (firstDuplicateIndex !== null && itemRefs.current[firstDuplicateIndex]) {
      itemRefs.current[firstDuplicateIndex - 1]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [firstDuplicateIndex]);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        stepOne?.eventDetails?.grant?.grantItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `${messagesLocale[StringKey.GRANT]} - ${truncateName(stakeholderName)}`;
      }

      return messagesLocale[StringKey.GRANT];
    },
    [stepOne?.eventDetails?.grant?.grantItems],
  );

  useEffect(() => {
    if (initialStep?.type === EventFormType.GRANT && grantItemsFields.length === 0) {
      handleAppendGrantItems();
    }
  }, [grantItemsFields.length, handleAppendGrantItems, initialStep?.type]);

  useEffect(() => {
    checkForDuplicates();
  }, [checkForDuplicates, grantItemsFields]);

  return (
    <>
      {grantItemsFields.map((field, index) => (
        <GrantItem
          clearErrors={clearErrors}
          companyId={companyId}
          control={control}
          errors={errors}
          field={field}
          firstDuplicateIndex={firstDuplicateIndex}
          formData={formData}
          getFullTitle={getFullTitle}
          handleRemoveGrantItems={handleRemoveGrantItems}
          hasDuplicates={hasDuplicates}
          index={index}
          isShownRemoveButton={grantItemsFields.length > 1}
          itemRefs={itemRefs}
          key={`${field}_${index}`}
          scrollToFirstDuplicate={scrollToFirstDuplicate}
          setError={setError}
          setFormData={setFormData}
        />
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        disabled={grantItemsFields.length >= 30}
        onClick={handleAppendGrantItems}
        styleType="NONE"
      >
        + <AppFormattedMessage id={StringKey.ADD_ANOTHER_GRANT} />
      </Button>
      <DropDown
        answer={[
          'A Grant refers to the allocation of equity or equity-like instruments (such as stock options, RSAs, or hurdle grants) to employees or stakeholders.',
          'Grants are always linked to specific Plans, like Stock Option, RSA, or Hurdle Plans, which must be defined before the grant is made. These Plans outline the terms, conditions, and criteria for the grant, such as vesting schedules or performance milestones.',
          'The Grant is the actual award given based on the Plan’s structure and rules.',
        ]}
        className="rounded-lg bg-gray-50"
        question={<AppFormattedMessage id={StringKey.WHAT_IS_GRANT} />}
      />
    </>
  );
};
