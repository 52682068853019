import { FC, useState } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Loader from '../../../components/Loader';
import { Radio, RadioGroup } from '../../../components/RadioGroup';
import { Switch } from '../../../components/Switch';
import { Table, TableRow } from '../../../components/Table';
import { Tag } from '../../../components/Tag';
import { QueryKey } from '../../../constants';
import { Company, useInvalidateQueries, User } from '../../../hooks';
import { NotificationSettingsType } from '../../../hooks/user';
import { StringKey } from '../../../lang';
import { StakeholderRole } from '../../../types/stakeholderTypes';
import {
  importanceColor,
  importanceTitle,
  notificationTagDescription,
  notificationTagImportance,
  notificationTagTitle,
  notificationTagToUserNotificationSettings,
} from '../../../types/userTypes';
import {
  NotificationSettingTab,
  notificationSettingTabTitleMap,
  NotificationTableColumn,
  notificationTableColumnMap,
  notificationTableColumnTitleMap,
  notificationTabTags,
} from './types';

const notificationTableTitle = notificationTableColumnTitleMap.reduce(
  (acc, [key, titleKey]) => ({ ...acc, [key]: <AppFormattedMessage id={titleKey} /> }),
  {},
);

export const NotificationSettings: FC = () => {
  const { user, isLoading } = User.useUser();
  const { selectedCompany } = Company.useSelected();
  const [selectedTab, setTab] = useState(
    selectedCompany?.stakeholder?.role === StakeholderRole.STAKEHOLDER
      ? NotificationSettingTab.STAKEHOLDER
      : NotificationSettingTab.ADMIN,
  );
  const { update } = User.useUpdateNotificationSettings();
  const { invalidateQuery } = useInvalidateQueries(
    QueryKey.GET_USER,
    QueryKey.GET_UNREAD_NOTIFICATION_COUNT,
  );

  const settings = {
    inApp: user?.inAppSettings,
    email: user?.emailSettings,
  };

  const handleTabChange = (tab: NotificationSettingTab) => {
    invalidateQuery();
    setTab(tab);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="-mx-4">
      <div className="p-4 pt-0">
        <RadioGroup
          className="h-fit w-fit divide-x divide-gray-200 overflow-hidden rounded border border-gray-200"
          onChange={handleTabChange}
          value={selectedTab}
          wrapperClassName={'max-lg:hidden h-9'}
        >
          {selectedCompany?.stakeholder?.role === StakeholderRole.STAKEHOLDER ? (
            <Radio
              className="flex h-9 w-fit items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
              styleType="NONE"
              value={NotificationSettingTab.STAKEHOLDER}
            >
              <span>
                <AppFormattedMessage id={StringKey.STAKEHOLDER} />
              </span>
            </Radio>
          ) : (
            <>
              {notificationSettingTabTitleMap.map(([key, titleKey]) => (
                <Radio
                  className="flex h-9 w-fit items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
                  key={key}
                  styleType="NONE"
                  value={key}
                >
                  <span>
                    <AppFormattedMessage id={titleKey} />
                  </span>
                </Radio>
              ))}
            </>
          )}
        </RadioGroup>
      </div>
      <Table
        columns={notificationTableColumnMap}
        columnsTitle={notificationTableTitle}
        tHeadClassNames={{
          thClassName: 'pl-4',
          spanClassName: 'font-500 whitespace-nowrap text-[#172335] text-label-md',
        }}
      >
        {notificationTabTags[selectedTab].map((key) => (
          <TableRow
            columns={notificationTableColumnMap}
            fields={{
              id: '1',
              [NotificationTableColumn.EMAIL]: (
                <Switch
                  enabled={settings?.email?.[notificationTagToUserNotificationSettings[key]]}
                  key={`${selectedTab}-email-switch-${key}`}
                  onChange={(emailState) =>
                    update({
                      data: {
                        [notificationTagToUserNotificationSettings[key]]: emailState,
                      },
                      type: NotificationSettingsType.EMAIL,
                    })
                  }
                />
              ),
              [NotificationTableColumn.IMPORTANCE]: (
                <Tag
                  title={importanceTitle[notificationTagImportance[key]]}
                  variant={importanceColor[notificationTagImportance[key]]}
                />
              ),
              [NotificationTableColumn.IN_APP]: (
                <Switch
                  enabled={settings?.inApp?.[notificationTagToUserNotificationSettings[key]]}
                  key={`${selectedTab}-in-app-switch-${key}`}
                  onChange={(inAppState) =>
                    update({
                      data: {
                        [notificationTagToUserNotificationSettings[key]]: inAppState,
                      },
                      type: NotificationSettingsType.IN_APP,
                    })
                  }
                />
              ),
              [NotificationTableColumn.NOTIFICATION_DESCRIPTION]: notificationTagDescription[key],
              [NotificationTableColumn.NOTIFICATION_TYPE]: notificationTagTitle[key],
            }}
            key={key}
            tdClassName="pl-4 h-[72px]"
          />
        ))}
      </Table>
    </div>
  );
};
