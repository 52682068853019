import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { NotificationType } from '../../types/notification.types';
import { useAppQuery } from '../useAppQuery';

export const useUnreadNotificationCount = ({
  companyId,
  type,
  enabled,
  stakeholderInAppNotifications,
  aminInAppNotifications,
}: {
  companyId: string;
  type?: NotificationType;
  enabled?: boolean;
  stakeholderInAppNotifications?: boolean;
  aminInAppNotifications?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { data: count, isLoading } = useAppQuery({
    queryKey: [
      QueryKey.GET_UNREAD_NOTIFICATION_COUNT,
      companyId,
      type,
      stakeholderInAppNotifications,
      aminInAppNotifications,
    ],
    queryFn: () =>
      ApiService.get<number>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'notifications', 'unread-count'],
        },
        { queryParams: { type } },
      ),
    enabled: !!companyId && enabled,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_UNREAD_NOTIFICATION_COUNT] });
  };

  return {
    unread: {
      count,
      type,
    },
    isLoading,
    invalidateQuery,
  };
};
