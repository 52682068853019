import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { addMonths, addYears } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { CrossIcon, DiscountIcon, LockIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput, Input } from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import { ReactPortal } from '../../../../components/Modal/ReactPortal';
import {
  StripeCardCvcInput,
  StripeCardExpiryInput,
  StripeCardNumberInput,
} from '../../../../components/Stripe';
import { QueryKey } from '../../../../constants';
import { useHeaderContext } from '../../../../context/headerContext';
import {
  Company,
  useAppMutation,
  useAppQuery,
  useFormat,
  useInvalidateQueries,
  useReactForm,
} from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { Plan, SubscriptionDuration } from '../../../../types/planTypes';
import { toRound } from '../../../../utils/getRoundedNumber';
import { percentage } from '../../../../utils/percentage';
import { toNumber } from '../../../../utils/toNumber';
import { FormSchema, formSchema } from './Validation';

export interface BillingFormProps {
  clientSecret: string;
  companyId: string;
  isLoading: boolean;
  onSuccess: () => void;
  selectedPlanInfo: Plan | undefined;
  setLoading: (state: boolean) => void;
}

const BillingForm: FC<BillingFormProps> = ({
  clientSecret,
  onSuccess,
  selectedPlanInfo,
  setLoading,
  isLoading,
  companyId,
}) => {
  const { format } = useFormat();
  const {
    formState: { isValid },
    setValue,
    setError,
    trigger,
    control,
    handleSubmit,
  } = useReactForm({
    schema: formSchema,
    mode: 'all',
  });

  const { showCompanySelect } = useHeaderContext();
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [showDiscount, setShowDiscount] = useState(false);

  const { invalidateQuery } = useInvalidateQueries(
    QueryKey.GET_CLIENT_SECRET,
    QueryKey.GET_SELECTED_COMPANY,
    QueryKey.GET_COMPANIES,
  );
  const { deactivate } = Company.useDeleteCoupon(companyId);

  const onCancelPromoCode = () => {
    deactivate(undefined, { onSuccess: invalidateQuery });
    setShowPromoInput(false);
    setPromoCode('');
    setShowDiscount(false);
  };

  const onApplyPromoCode = () => {
    setShowPromoInput(false);
    setShowDiscount(true);
  };

  const { data: { amountOff = 0, percentOff = 0 } = {}, isLoading: isLoadingCoupon } =
    Company.useCoupon(companyId, promoCode, showDiscount, onCancelPromoCode);

  const { activate, isPending: isCouponPending } = Company.useActivateCoupon(companyId);

  const stripe = useStripe();
  const elements = useElements();
  const { data } = useAppQuery<PaymentIntentResult | undefined>({
    queryKey: [QueryKey.GET_RETRIEVE_PAYMENT_INTENT, clientSecret || ''],
    queryFn: () => {
      if (!clientSecret) return;
      return stripe?.retrievePaymentIntent(clientSecret);
    },
    enabled: !!clientSecret && !!stripe,
  });

  const { company: { plan: selectedPlan } = {} } = Company.useCompany({ companyId });

  const { setSelected } = Company.useSetSelected();

  useEffect(() => {
    if (data || !isLoading) return setLoading(false);
  }, [data, isLoading, setLoading]);

  const onPaymentSuccess = useCallback(() => {
    showCompanySelect();
    onSuccess();

    setTimeout(() => {
      setSelected(companyId, { onSuccess: invalidateQuery });
    }, 1000);
  }, [companyId, invalidateQuery, onSuccess, setSelected, showCompanySelect]);

  const { isPending: stripeIsPending, mutate } = useAppMutation<
    PaymentIntentResult | undefined,
    StripeError,
    {
      cardNumber: StripeCardNumberElement;
      cardholderName: string;
    }
  >([], {
    mutationFn: async (data: { cardNumber: StripeCardNumberElement; cardholderName: string }) => {
      return await stripe?.confirmCardPayment(clientSecret, {
        payment_method: {
          card: data.cardNumber,
          billing_details: { name: data.cardholderName },
        },
      });
    },
    onSuccess: (paymentRes) => {
      if (paymentRes?.error)
        return toast(paymentRes.error?.message, {
          type: 'error',
          position: 'bottom-center',
        });
      onPaymentSuccess();
    },
    onError: (paymentError) => {
      toast(paymentError.message, {
        type: 'error',
        position: 'bottom-center',
      });
    },
  });
  const priceInCents = toNumber(data?.paymentIntent?.amount);

  const discount = percentOff || Math.min(percentage(priceInCents, amountOff), 100);
  const currency = data?.paymentIntent?.currency || selectedPlan?.localCurrency || 'USD';

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      if (!elements || !stripe) return;
      const { error } = await elements.submit();

      if (error)
        return toast(error.message, {
          type: 'error',
          position: 'bottom-center',
        });

      const cardNumber = elements.getElement('cardNumber');
      if (!cardNumber) return;

      if (discount)
        return activate(
          { coupon: promoCode },
          {
            onSuccess: () => {
              invalidateQuery();
              if (discount === 100) return onPaymentSuccess();
              setTimeout(
                () =>
                  mutate({
                    cardholderName: data.cardholderName,
                    cardNumber,
                  }),
                3000,
              );
            },
          },
        );

      mutate({
        cardholderName: data.cardholderName,
        cardNumber,
      });
    },
    [activate, discount, elements, invalidateQuery, mutate, onPaymentSuccess, promoCode, stripe],
  );

  const planPrice = useMemo(
    () =>
      selectedPlanInfo?.duration === SubscriptionDuration.MONTHLY
        ? priceInCents / 100
        : priceInCents / 100 / 12,
    [priceInCents, selectedPlanInfo?.duration],
  );

  const renewDate = useMemo(
    () =>
      selectedPlanInfo?.duration === SubscriptionDuration.MONTHLY
        ? addMonths(new Date(), 1)
        : addYears(new Date(), 1),
    [selectedPlanInfo?.duration],
  );

  const renewFormattedDate = useMemo(
    () => format(renewDate, 'cccc, dd MMMM Y'),
    [format, renewDate],
  );

  const isPromoCodeApllied = promoCode ? showDiscount : true;

  if (isLoading || isLoadingCoupon)
    return (
      <ReactPortal initialPortalId="modal">
        <Loader className="absolute left-0 top-0 z-[999999999] h-screen w-screen pl-10 pt-10 max-lg:pl-16 max-lg:pt-36" />
      </ReactPortal>
    );

  const priceDiscountInCents = (priceInCents * discount) / 100;

  return (
    <div className="flex w-full max-w-[400px] flex-col items-center gap-4 lg:pb-4">
      <span className="w-fit rounded-2xl bg-gray-100 px-2 py-[2px] text-label-md font-[450] text-gray-700">
        <AppFormattedMessage id={StringKey.PLAN_SELECTED} />
      </span>
      <span
        className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
        style={{
          background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
        }}
      >
        {selectedPlanInfo?.title}
      </span>
      <div className="flex w-full flex-col gap-1 border-t-[1px] border-gray-200 pt-4">
        <div className="flex w-full items-center justify-between px-3 py-2">
          <span className="text-sm font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.PER_MONTH} />
          </span>
          <span className="text-sm font-[550] uppercase text-gray-700">
            {toRound(planPrice)} {currency}
          </span>
        </div>
        <div className="flex w-full flex-col gap-1 rounded-lg bg-gray-50 p-3">
          <div className="flex w-full items-center justify-between py-2">
            <span className="text-sm font-medium text-gray-700">Subtotal - Month Payment</span>
            <span className="text-sm font-semibold uppercase text-gray-700">
              {toRound((priceInCents || priceInCents) / 100)} {currency}
            </span>
          </div>
          {showPromoInput ? (
            <Input
              icon={
                <Button
                  className="text-sm font-[550] text-brand-700"
                  onClick={onApplyPromoCode}
                  styleType="NONE"
                >
                  <AppFormattedMessage id={StringKey.APPLY} />
                </Button>
              }
              inputClassName="bg-white"
              name="promocode"
              onChange={(value) => setPromoCode(value.trim())}
              placeholder={<AppFormattedMessage id={StringKey.ADD_PROMO_CODE} />}
              value={promoCode}
            />
          ) : (
            <>
              {showDiscount ? (
                <>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-4 rounded-[4px] bg-white p-2">
                      <div className="flex items-center gap-[6px]">
                        <DiscountIcon />
                        <span className="text-sm font-[450] text-gray-700">
                          Save {toRound(discount, 0)}%
                        </span>
                      </div>
                      <CrossIcon className="cursor-pointer" onClick={onCancelPromoCode} />
                    </div>
                    <span className="text-sm font-[450] uppercase text-gray-500">
                      -{toRound(priceDiscountInCents / 100)} {currency}
                    </span>
                  </div>
                  <div className="flex w-full items-center justify-between py-2">
                    <span className="text-sm font-medium text-gray-700">
                      <AppFormattedMessage id={StringKey.AMOUNT_TO_PAY_TODAY} />
                    </span>
                    <span className="text-sm font-semibold uppercase text-gray-700">
                      {toRound((priceInCents - priceDiscountInCents) / 100)} {currency}
                    </span>
                  </div>
                </>
              ) : (
                <Button
                  className="w-fit pb-4 pt-2 text-xs font-[550] text-brand-700 underline"
                  onClick={() => setShowPromoInput(true)}
                  styleType="NONE"
                >
                  <AppFormattedMessage id={StringKey.ADD_PROMO_CODE} />
                </Button>
              )}
            </>
          )}
        </div>
        <div className="flex w-full items-center justify-between px-3 py-2">
          <span className="text-sm font-medium text-gray-700">
            <AppFormattedMessage id={StringKey.AUTO_RENEW_ON} />
          </span>
          <span className="text-sm font-semibold text-gray-700">{renewFormattedDate}</span>
        </div>
      </div>
      <form
        className="flex w-full flex-col gap-4 border-t-[1px] border-gray-200 pt-4"
        onSubmit={handleSubmit(submitHandler)}
      >
        <FormInput
          control={control}
          name="cardholderName"
          placeholder={<AppFormattedMessage id={StringKey.CARDHOLDER_NAME} />}
        />
        <StripeCardNumberInput
          onError={(error) => {
            setError('cardNumber', { ...error });
          }}
          onSuccess={() => {
            setValue('cardNumber', true);
            trigger('cardNumber');
          }}
        />
        <div className="flex w-full justify-between gap-6">
          <StripeCardExpiryInput
            onError={(error) => {
              setError('cardExpiry', { ...error });
            }}
            onSuccess={() => {
              setValue('cardExpiry', true);
              trigger('cardExpiry');
            }}
          />
          <StripeCardCvcInput
            onError={(error) => {
              setError('cardCvc', { ...error });
            }}
            onSuccess={() => {
              setValue('cardCvc', true);
              trigger('cardCvc');
            }}
          />
        </div>
        <Button
          className="mt-4 flex w-full gap-2"
          disabled={!isPromoCodeApllied || !isValid}
          isLoading={isCouponPending || stripeIsPending}
          type="submit"
        >
          <LockIcon iconColor={isValid ? '#FFFFFF' : '#D0D5DD'} />
          <AppFormattedMessage id={StringKey.PAY_NOW} />
        </Button>
      </form>
    </div>
  );
};

export default BillingForm;
