import { StringKey } from '../../../lang';
import {
  AdminNotificationTag,
  adminNotificationTagMap,
  NotificationTag,
  notificationTagMap,
} from '../../../types/userTypes';

export enum NotificationTableColumn {
  NOTIFICATION_TYPE = 'notification-type',
  NOTIFICATION_DESCRIPTION = 'notification-description',
  IMPORTANCE = 'importance',
  EMAIL = 'email',
  IN_APP = 'in-app',
}

export const notificationTableColumnMap = Object.values(NotificationTableColumn);

export const notificationTableColumnTitle: Record<NotificationTableColumn, StringKey> = {
  [NotificationTableColumn.EMAIL]: StringKey.EMAIL,
  [NotificationTableColumn.IMPORTANCE]: StringKey.IMPORTANCE,
  [NotificationTableColumn.IN_APP]: StringKey.IN_APP,
  [NotificationTableColumn.NOTIFICATION_DESCRIPTION]: StringKey.NOTIFICATION_DESCRIPTION,
  [NotificationTableColumn.NOTIFICATION_TYPE]: StringKey.NOTIFICATION_TYPE,
};

export const notificationTableColumnTitleMap = Object.entries(notificationTableColumnTitle);

export enum NotificationSettingTab {
  ADMIN = 'admin',
  STAKEHOLDER = 'stakeholder',
}

export const notificationSettingTabTitle: Record<NotificationSettingTab, StringKey> = {
  [NotificationSettingTab.ADMIN]: StringKey.ADMIN,
  [NotificationSettingTab.STAKEHOLDER]: StringKey.STAKEHOLDER,
};

export const notificationTabTags: Record<
  NotificationSettingTab,
  (NotificationTag | AdminNotificationTag)[]
> = {
  [NotificationSettingTab.ADMIN]: adminNotificationTagMap,
  [NotificationSettingTab.STAKEHOLDER]: notificationTagMap,
};

export const notificationSettingTabTitleMap = Object.entries(notificationSettingTabTitle);
