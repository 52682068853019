import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { TableRowData } from './Table';

export type TableRowProps<T extends string, AdditionalCallbackFields extends object> = {
  fields: TableRowData<T, AdditionalCallbackFields>;
  trClassName?: string | falsy;
  columns: T[];
  tdClassName?: string;
  spanClassName?: string;
  additionalCellContent?: RequireAtLeastOne<
    Record<T, (id: string, additionalFields: AdditionalCallbackFields) => ReactNode>
  >;
};

export const TableRow = <TColumn extends string, AdditionalCallbackFields extends object = object>({
  fields,
  tdClassName,
  trClassName,
  spanClassName,
  columns,
  additionalCellContent,
}: TableRowProps<TColumn, AdditionalCallbackFields>) => (
  <tr className={twMerge(trClassName)}>
    {columns.map(
      (field, i) =>
        columns.includes(field) && (
          <td className="relative" key={`${field}_${i}`}>
            <div className={twMerge('flex h-[72px] items-center gap-8 pl-2', tdClassName)}>
              <span className={twMerge('text-sm font-[450] text-gray-700', spanClassName)}>
                {typeof fields[field] === 'function'
                  ? fields[field](fields.id, fields)
                  : fields[field]}
              </span>
              {additionalCellContent?.[field]?.(fields.id, fields)}
            </div>
          </td>
        ),
    )}
  </tr>
);
