import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import { QueryKey } from '../constants';
import { HttpException } from '../exceptions';
import { Company, NotificationHooks, useInvalidateQueries, User } from '../hooks';
import { accessTokenStorage } from '../storage/accessTokenStorage';
import { AppNotification, NotificationType } from '../types/notification.types';
import { toNumber } from '../utils/toNumber';
import { toPositive } from '../utils/toPositive';

interface NotificationsContext {
  unreadNotification: { total: number } & Record<NotificationType, number>;
  handleReadNotification: (type: NotificationType) => void;
  handleReadAllNotification: () => void;
}

const initialValue: NotificationsContext = {
  unreadNotification: { activity: 0, total: 0, request: 0 },
  handleReadNotification() {},
  handleReadAllNotification() {},
};

export const notificationsContext = createContext(initialValue);

export interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const { selectedCompanyId: companyId } = Company.useSelected();
  const { user } = User.useUser();

  const { unread, isLoading } = NotificationHooks.useUnreadNotificationCount({
    companyId,
    aminInAppNotifications: user?.adminInAppNotifications,
    stakeholderInAppNotifications: user?.stakeholderInAppNotifications,
  });

  const { unread: activityUnread, isLoading: isLoadingActivity } =
    NotificationHooks.useUnreadNotificationCount({
      companyId,
      type: NotificationType.ACTIVITY,
      aminInAppNotifications: user?.adminInAppNotifications,
      stakeholderInAppNotifications: user?.stakeholderInAppNotifications,
    });

  const { unread: requestUnread, isLoading: isLoadingRequest } =
    NotificationHooks.useUnreadNotificationCount({
      companyId,
      type: NotificationType.REQUEST,
      aminInAppNotifications: user?.adminInAppNotifications,
      stakeholderInAppNotifications: user?.stakeholderInAppNotifications,
    });

  const [unreadNotification, setUnread] = useState<
    { total: number } & Record<NotificationType, number>
  >({
    activity: 0,
    total: 0,
    request: 0,
  });

  useEffect(() => {
    if (isLoading || isLoadingActivity || isLoadingRequest) return;

    setUnread({
      request: toNumber(requestUnread.count),
      activity: toNumber(activityUnread.count),
      total: toNumber(unread.count),
    });
  }, [
    activityUnread.count,
    isLoading,
    isLoadingRequest,
    isLoadingActivity,
    requestUnread.count,
    unread.count,
  ]);

  const handleReadNotification = (type: NotificationType) =>
    setUnread((prev) => ({
      ...prev,
      [type]: toPositive(prev[type] - 1),
      total: toPositive(prev.total - 1),
    }));

  const handleReadAllNotification = () =>
    setUnread({
      activity: 0,
      total: 0,
      request: 0,
    });

  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_NOTIFICATIONS);

  useEffect(() => {
    const accessToken = accessTokenStorage.get() || '';

    const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      transports: ['websocket'],
      auth: { isAdmin: false, accessToken },
      secure: true,
      withCredentials: true,
    });

    socket.on('connect_error', (error) => console.error('Connection error:', error));

    socket.on('connect', () => {
      socket
        .on('disconnect', (e) => {
          console.log('disconnected', e);
        })
        .on('error', (e: HttpException) => console.error(e));

      socket.on('notification', (notificationString: string) => {
        const { type } = JSON.parse<Pick<AppNotification, 'id' | 'type'>>(notificationString);

        setUnread((prev) => ({
          ...prev,
          total: prev.total + 1,
          [type]: toNumber(prev[type] + 1),
        }));

        invalidateQuery();
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <notificationsContext.Provider
      value={{
        unreadNotification,
        handleReadNotification,
        handleReadAllNotification,
      }}
    >
      {children}
    </notificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(notificationsContext);
