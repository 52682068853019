import React, { FC, useEffect, useState } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Radio, RadioGroup } from '../../../components/RadioGroup';
import { SnapshotHooks, useFormat, ValuationHooks } from '../../../hooks';
import { StringKey } from '../../../lang';
import { toRound } from '../../../utils/getRoundedNumber';
import { toNumber } from '../../../utils/toNumber';
import { ActivityOverview } from '../ActivityOverview';
import { GraphVariant, graphVariantMobileMap } from '../variables';
import { EventDateCombobox } from './EventDateCombobox';

type EventsMobileProps = {
  companyId: string;
};

export const EventsMobile: FC<EventsMobileProps> = ({ companyId }) => {
  const { format } = useFormat();
  const { snapshots } = SnapshotHooks.useSnapshots({ companyId });
  const { valuation: currentValuation } = ValuationHooks.useCurrent({ companyId });

  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.VALUATION);
  const [selectedSnapshot, setSelectedSnapshot] = useState({
    id: currentValuation?.id,
    date: currentValuation?.date,
  });

  const currentSnapshot = snapshots.find(
    (snapshot) => snapshot.valuation.id === selectedSnapshot?.id,
  );

  const sortedByDateSnapshots = snapshots.sort((a, b) => {
    const dateA = new Date(a.valuation.date).getTime();
    const dateB = new Date(b.valuation.date).getTime();

    if (dateA === dateB) {
      return b.postMoneyValuation - a.postMoneyValuation;
    }

    return dateB - dateA;
  });

  useEffect(() => {
    if (currentValuation) {
      setSelectedSnapshot({
        id: currentValuation.id,
        date: currentValuation.date,
      });
    }
  }, [currentValuation]);

  return (
    <div className="flex w-full flex-col gap-4 rounded-md bg-white p-3 shadow-sm">
      <ActivityOverview companyId={companyId} />
      <div className="rounded-3xl border border-gray-200 p-1">
        <RadioGroup
          onChange={setGraphVariant}
          value={selectedGraphVariant}
          wrapperClassName="w-full grow flex-1"
        >
          {graphVariantMobileMap.map(([graphKey, graphTitle]) => (
            <Radio
              className="h-8 rounded-[32px] border-transparent bg-transparent !px-3 !py-2 text-xs font-[450] text-gray-700 data-[checked]:font-[550] data-[checked]:text-brand-25"
              key={graphKey}
              value={graphKey}
            >
              <span className="text-nowrap">{graphTitle}</span>
            </Radio>
          ))}
        </RadioGroup>
      </div>
      {selectedSnapshot.id && selectedSnapshot.date && currentSnapshot && (
        <div className="flex w-full flex-col gap-2 rounded-lg p-2 shadow-sm">
          <EventDateCombobox
            defaultData={{
              id: selectedSnapshot.id,
              date: selectedSnapshot.date,
            }}
            onSelect={(state) => setSelectedSnapshot(state)}
            snapshots={sortedByDateSnapshots}
          />
          <div className="flex w-full gap-2">
            <div className="flex w-full flex-col gap-2 rounded-lg bg-brand-25 px-2 py-3">
              <span className="font-semibold leading-4 text-[10] text-gray-700">
                {currentSnapshot?.valuation.name}
              </span>
              <span className="text-sm font-[450] text-gray-700">
                {format(
                  currentSnapshot?.valuation.date ? currentSnapshot?.valuation.date : new Date(),
                  'dd/MM/yyyy',
                )}
              </span>
            </div>
            <div className="flex w-full flex-col gap-2 rounded-lg bg-forest-25 px-2 py-3">
              <span className="font-semibold leading-4 text-[10] text-gray-700">
                <AppFormattedMessage id={StringKey.POST_MONEY_VALUATION} />
              </span>
              <span className="text-sm font-[450] text-forest-700">
                {toNumber(toRound(currentSnapshot?.postMoneyValuation, 2))?.toLocaleString('en-US')}
              </span>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-hidden rounded-md">
              <thead className="h-9 rounded-t-md border-b border-[#F2F2F2] bg-[#F9FAFB]">
                <tr>
                  <th className="px-4 text-left">
                    <span className="text-label-md font-medium text-[#172335]">
                      <AppFormattedMessage id={StringKey.SHAREHOLDER} />
                    </span>
                  </th>
                  <th className="px-4 text-left">
                    <span className="text-label-md font-medium text-[#172335]">
                      <AppFormattedMessage id={StringKey.SHARES} />
                    </span>
                  </th>
                  <th className="px-4 text-left">
                    <span className="text-label-md font-medium text-[#172335]">
                      {selectedGraphVariant === GraphVariant.VALUATION ? (
                        <AppFormattedMessage id={StringKey.VALUE} />
                      ) : (
                        <AppFormattedMessage id={StringKey.OWNERSHIP_PERCENTAGE} />
                      )}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {currentSnapshot.stats.stakeholders
                  .sort((a, b) => b.issued - a.issued)
                  .slice(0, selectedGraphVariant === GraphVariant.VALUATION ? 5 : 10)
                  .filter((stakeholder) => stakeholder.issued !== 0)
                  .map((stakeholder) => (
                    <tr>
                      <td className="max-w-[120px] overflow-hidden truncate px-4 py-3">
                        <span className="truncate text-sm font-[450] text-gray-700">
                          {stakeholder.name}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <div className="max-w-[120px] overflow-hidden truncate">
                          <span className="text-sm font-[450] text-gray-700">
                            {stakeholder.issued.toLocaleString('en-US')}
                          </span>
                        </div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="max-w-[120px] overflow-hidden truncate">
                          <span className="text-sm font-[450] text-gray-700">
                            {selectedGraphVariant === GraphVariant.VALUATION
                              ? toNumber(
                                  toRound(
                                    stakeholder.issued * currentSnapshot.valuation.sharePrice,
                                    2,
                                  ),
                                ).toLocaleString('en-US')
                              : `${Math.round(stakeholder.issuedPercentage * 100)}%`}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
