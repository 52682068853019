import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { Company, SharePlan } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { SharePlanUnionType } from '../../../../../types/pool-plans.types';
import { PoolPlansPopover } from '../../PoolPlansPopover';

type ScrollablePlanItemProps = {
  plan: SharePlanUnionType;
  isSelected: boolean;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditPlanTerminationDateClick: (id: string) => void;
};

export const ScrollablePlanItem: FC<ScrollablePlanItemProps> = ({
  plan,
  isSelected,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditPlanTerminationDateClick,
}) => {
  const { selectedCompanyId } = Company.useSelected();
  const { stakeholders } = SharePlan.useStakeholders({
    companyId: selectedCompanyId,
    planId: plan.id,
  });

  const granted = plan.granted?.toLocaleString('en-US');
  const totalGrantVested = plan.totalGrantVested?.toLocaleString('en-US');
  const sharesVested = plan.sharesVested?.toLocaleString('en-US');
  const exercised = plan.exercised?.toLocaleString('en-US');
  const expired = plan.expired?.toLocaleString('en-US');
  const exercisable = plan.exercisable?.toLocaleString('en-US');
  const cashSettled = plan.cashSettled?.toLocaleString('en-US');

  const isTerminatedDatePassed = useMemo(() => {
    return plan?.terminationDate
      ? new Date(plan.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [plan?.terminationDate]);

  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>{granted}</span>
        </td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>
            {totalGrantVested}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>
            {sharesVested}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>
            {exercisable}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>
            {exercised}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>
            {cashSettled}
          </span>
        </td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="overflow-hidden p-4">
          <span className={twMerge('max-w-[100px] truncate text-sm text-gray-700')}>{expired}</span>
        </td>
        <td className="relative">
          <PoolPlansPopover
            id={plan.id}
            isTerminated={isTerminatedDatePassed}
            onDeleteClick={onDeletePlanClick}
            onEditClick={onEditPlanClick}
            onEditTerminationDateClick={onEditPlanTerminationDateClick}
            onViewClick={onViewPlanClick}
            text={<AppFormattedMessage id={StringKey.PLAN} />}
          />
        </td>
      </tr>
      {isSelected && stakeholders && (
        <>
          {stakeholders.map(
            ({
              granted,
              totalGrantVested,
              sharesVested,
              exercisable,
              exercised,
              expired,
              id,
              cashSettled,
            }) => (
              <tr className="h-[72px] w-full bg-white" key={id}>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {granted?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {totalGrantVested?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {sharesVested?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {exercisable?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {exercised?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {cashSettled?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {expired?.toLocaleString('en-US')}
                  </span>
                </td>
                <td className="relative"></td>
              </tr>
            ),
          )}
        </>
      )}
    </>
  );
};
