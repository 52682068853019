import { FC } from 'react';

import bulkImportGrantsButton from '../../../assets/images/bulk-import-grants.png';
import bulkImportGrantsTemplate from '../../../assets/images/bulk-import-grants-template-button.png';

export const GrantImport: FC = () => (
  <>
    <div className="flex flex-col gap-2">
      <span className="text-xl font-semibold text-gray-700">Bulk Grant Import Guide</span>
      <span className="text-xs font-[450] text-gray-700">
        The <strong>Bulk Grant Import</strong> mechanism allows{' '}
        <strong>Admins and Contributors</strong> to quickly add multiple grants at once by uploading
        a pre-formatted Excel file. This simplifies the grant creation process, reduces manual
        input, and ensures data accuracy.
      </span>
    </div>
    <h1 className="text-[16px] font-semibold leading-[2] text-gray-700">Step-by-Step Guide</h1>
    <span className="text-xs font-[450] text-gray-700">
      <strong>Important Note</strong>: In order to utilize the Bulk Grant mechanism, you must first
      ensure that all stakeholders <strong>and</strong> equity plans have been added to CapQuest.
      The Bulk Grant Import mechanism will automate the association and grant of these plans to the
      corresponding Stakeholders but will not create the respective stakeholders and plans. Ensure
      these are already added to the platform before starting the Bulk Grant mechanism.
    </span>
    <div className="flex flex-col gap-2" id="main-step-1">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 1: Open the Bulk Grant Import Blade
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Navigate to the <strong>Events</strong> module in CapQuest
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click on <strong>Add event</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Choose <strong>Bulk Import Grants</strong> option to open the import flow
        </li>
      </ul>
      <img src={bulkImportGrantsButton} width="320" />
    </div>
    <div className="flex flex-col gap-2" id="main-step-2">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 2: Download the Excel Template
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Click the <strong>Download Template</strong> button
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The downloaded file will include:
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 1</strong>: 'Bulk Grant' (editable)
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Sheet 2</strong>: 'Dropdowns' (read-only)
            </li>
          </ul>
        </li>
      </ul>
      <img src={bulkImportGrantsTemplate} width="320" />
    </div>
    <div className="flex flex-col gap-2" id="main-step-3">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 3: Understanding the Excel Template
      </h2>
      <ul className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          <strong>Sheet 1: Bulk Grant</strong> is the <strong>main working sheet</strong> where you
          enter grant details
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Each row represents a <strong>single grant entry</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The following <strong>fields must be filled out</strong>
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              <strong>Grant Date</strong>
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Stakeholder Email</strong> (Must match an existing stakeholder in the company
              from <strong>Sheet 2</strong>: <strong>Dropdowns</strong>)
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Equity Plan</strong> (Includes the list of created plans in CapQuest; must
              match an existing Plan in the company from <strong>Sheet 2</strong>)
            </li>
            <li className="text-xs font-[450] text-gray-700">
              <strong>Number of Grants</strong>
            </li>
          </ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Important</strong>
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              All fields are <strong>mandatory</strong> If any are left blank, the system will
              reject the file
            </li>
            <li className="text-xs font-[450] text-gray-700">
              Values must be entered exactly as specified in <strong>Sheet 2 (Dropdowns)</strong>
            </li>
          </ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          <strong>Sheet 2: Dropdowns </strong> sheet contains{' '}
          <strong>list of stakeholder emails added</strong> in your company
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">Do not modify this sheet</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-4">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 4: Fill in the Grant Details
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Open <strong>Sheet 1</strong> ("Bulk Grant").
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Enter the required details for each grant
          <ul className="flex list-disc flex-col gap-2 pl-5 pt-2">
            <li className="text-xs font-[450] text-gray-700">
              Grant Date: Please ensure Grant Date follows format DD.MM.YYY (Example 23.11.2024)
            </li>
            <li className="text-xs font-[450] text-gray-700">
              Stakeholder Email: Select from dropdown
            </li>
            <li className="text-xs font-[450] text-gray-700">Equity plan: Select from dropdown </li>
            <li className="text-xs font-[450] text-gray-700">
              Number of Grants: Open field where you can add the number of associated grants
            </li>
          </ul>
        </li>
        <li className="text-xs font-[450] text-gray-700">Save the completed file</li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="main-step-5">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
        Step 5: Upload the Filled Template
      </h2>
      <ul className="flex list-decimal flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Return to the <strong>Bulk Grant Import</strong> blade
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Click <strong>Upload </strong>or <strong>drag and drop</strong> the completed Excel file
        </li>
        <li className="text-xs font-[450] text-gray-700">
          The system will validate the file and display a result of grants import
        </li>
      </ul>
    </div>
    <div className="flex flex-col gap-2" id="key-notes-and-tips">
      <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">Key Notes and Tips</h2>
      <ol className="flex list-disc flex-col gap-2 pl-10">
        <li className="text-xs font-[450] text-gray-700">
          Ensure <strong>mandatory fields</strong> are filled out before uploading; missing
          information will result in upload errors
        </li>
        <li className="text-xs font-[450] text-gray-700">
          Do not modify <strong>Sheet 2 (Dropdowns)</strong>
        </li>
        <li className="text-xs font-[450] text-gray-700">
          If an error occurs, review the format and re-upload the corrected file
        </li>
      </ol>
    </div>
  </>
);
