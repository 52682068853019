import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { StringKey } from '../../lang';
import {
  CapTableManagementTypes,
  EnterpriseFeatures,
} from '../../pages/CompanySetup/CompanySetupForm/EnterpriseModal/types';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export enum ReasonToChangeToEnterprise {
  NEED_STAKEHOLDERS = 'need-stakeholders',
  PRIORITY_SERVICE = 'priority-service',
  NEW_FEATURES = 'new-features',
}

export const reasonToChangeToEnterpriseTitle: Record<ReasonToChangeToEnterprise, StringKey> = {
  [ReasonToChangeToEnterprise.NEED_STAKEHOLDERS]: StringKey.NEED_ADDITIONAL_STAKEHOLDERS,
  [ReasonToChangeToEnterprise.PRIORITY_SERVICE]: StringKey.NEED_PRIORITY_SERVICE,
  [ReasonToChangeToEnterprise.NEW_FEATURES]: StringKey.NEED_NEW_FEATURES,
};

export const reasonToChangeToEnterpriseTitleMap = Object.entries(reasonToChangeToEnterpriseTitle);

type RequestEnterpriseDto = {
  companyId: string;
  capTableManagement: CapTableManagementTypes;
  email?: string;
  phonoNumber?: string;
  additionalNotes?: string;
  features?: Record<EnterpriseFeatures, boolean>;
  anotherSoftware?: string;
};

type RequestChangeToEnterpriseDto = {
  companyId: string;
  reasonsToChange: Record<ReasonToChangeToEnterprise, boolean>;
  additionalNotesAboutStakeholders?: string;
  additionalNotes?: string;
};

export const useEnterpriseRequest = () => {
  const { mutate: enterpriseRequest, isPending } = useAppMutation([QueryKey.CHANGE_TO_ENTERPRISE], {
    mutationFn: (body: RequestEnterpriseDto | RequestChangeToEnterpriseDto) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [body.companyId, 'request-enterprise'],
        },
        { body },
      ),
  });
  return { enterpriseRequest, isPending };
};
