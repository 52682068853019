import React, { FC, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import {
  AttachmentIcon,
  CloseIcon,
  InfoCircle,
  UploadedFileIcon,
  UploadIcon,
} from '../../../../assets/icons';
import LoaderIcon from '../../../../assets/icons/LoaderIcon';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { BackendRoute } from '../../../../config';
import { useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { getTranslation } from '../../../../utils/getTranslation';
import { FormSchema, subFilesSchema } from '../validation';

type SubFile = {
  docLink?: string | undefined;
  abort?: {} | undefined;
  doc?:
    | File
    | {
        name?: string | undefined;
        type?: string | undefined;
        size?: number | undefined;
      }
    | undefined;
  loadProgress?: number | undefined;
  id?: string | undefined;
};

type FieldPath =
  | `stepOne.eventDetails.grant.grantItems.${number}.files`
  | `optionalStep.fundraising-round.investmentItems.${number}.files`
  | `stepOne.eventDetails.buyback.buyBackItems.${number}.files`
  | `stepOne.eventDetails.secondaries.secondariesItems.${number}.files`;

type SubEventFileUploadProps = {
  files: SubFile[];
  setFormData: UseFormSetValue<FormSchema>;
  formData: UseFormGetValues<FormSchema>;
  setError: UseFormSetError<FormSchema>;
  clearErrors: UseFormClearErrors<FormSchema>;
  errors?: Merge<FieldError, FieldErrorsImpl<FormSchema>>;
  field: FieldPath;
  text: string;
};
export const SubEventFileUpload: FC<SubEventFileUploadProps> = ({
  files,
  setFormData,
  formData,
  setError,
  clearErrors,
  errors,
  field,
  text,
}) => {
  const { messagesLocale } = useLocale();

  const uploadedFiles = files.reduce((prev, curr) => (curr.docLink ? prev + 1 : prev), 0);
  const isReachedUploadMaximum = uploadedFiles >= 20;
  const getSubFilesData = useCallback(() => formData(field) || [], [field, formData]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((e) => {
            if (e.code === 'file-invalid-type') {
              setError(field, {
                type: 'manual',
                message: `${messagesLocale[StringKey.INVALID_FILE_TYPE].replace('{fileType}', file.name)}`,
              });
            }
          });
        });
        return;
      }

      if (isReachedUploadMaximum) return;
      const existingFiles = getSubFilesData();
      const filesToUpload = acceptedFiles.slice(0, 20 - existingFiles.length);
      filesToUpload.forEach(async (file) => {
        const { callback, abort } = ApiService.uploadFile<string>();
        const id = uuidv4();
        const { error, success } = subFilesSchema.safeParse([
          { doc: file, docLink: '', loadProgress: 1, abort, id },
        ]);

        if (!success) return setError(field, error.errors[0]);

        if (success) clearErrors(field);

        const existingFiles = getSubFilesData();

        setFormData(
          field,
          [...existingFiles, { doc: file, docLink: '', loadProgress: 1, abort, id }],
          { shouldValidate: true },
        );

        const link = await callback(
          { endpoint: BackendRoute.SHARE_CLASSES, routePath: ['file'] },
          ['file', file],
          {
            onUploadProgress: (n) => {
              const currentFiles = getSubFilesData();
              setFormData(
                field,
                currentFiles.map((f) => (f.id === id ? { ...f, loadProgress: n } : f)),
              );
            },
            onAboard: () => {
              const currentFiles = getSubFilesData();
              setFormData(
                field,
                currentFiles.filter((f) => f.id !== id),
              );
            },
          },
        );

        if (!link) return;
        const currentFiles = getSubFilesData();
        setFormData(
          field,
          currentFiles.map((f) => (f.id === id ? { ...f, loadProgress: 100, docLink: link } : f)),
        );
      });
    },
    [
      clearErrors,
      field,
      getSubFilesData,
      isReachedUploadMaximum,
      messagesLocale,
      setError,
      setFormData,
    ],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    disabled: isReachedUploadMaximum,
    onDropRejected: () =>
      setError(field, {
        message: getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
        type: 'validate',
      }),
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
      'text/csv': ['.csv'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const handleDeleteFile = useCallback(
    (id: string) => {
      const current = (formData(field) || []).find((file) => file.id === id);

      if (current && !current.docLink) current.abort();

      const currentData = (formData(field) || []).filter((file) => file.id !== id);
      setFormData(field, currentData);
      if (currentData.length === 0) return clearErrors(field);
    },
    [clearErrors, field, formData, setFormData],
  );

  return (
    <div className="flex w-full max-w-[403px] flex-col gap-3">
      <input
        {...getInputProps()}
        accept=".pdf, .doc, .docx, .xlsx, .xls, .csv, .png, .jpeg"
        className="hidden"
        multiple
        type="file"
      />
      <label
        {...getRootProps()}
        className={twMerge(
          'relative flex items-center gap-4 rounded-lg border-[1px] border-dashed border-gray-200 p-4',
          isReachedUploadMaximum ? 'cursor-auto' : 'cursor-pointer',
        )}
      >
        {errors ? <InfoCircle className="size-8" thinIconVariant /> : <AttachmentIcon />}
        <div className="flex w-full flex-col gap-2">
          <div className="flex flex-col gap-1">
            {errors ? (
              <span className="text-sm font-[550] text-fireside-600">
                {errors?.message?.toString()}
              </span>
            ) : (
              <span className="text-sm font-[450] text-gray-700 underline">
                Attach files to this {text}
              </span>
            )}
            <div className="flex items-center text-label-sm font-[450] text-gray-500">
              <span>doc/docx, xls/xlsx, csv, jpeg, png or pdf - </span>
              <span>
                <AppFormattedMessage id={StringKey.MAXIMUM_FILE_SIZE} values={{ size: 10 }} />
              </span>
            </div>
          </div>
          {errors && (
            <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
              <UploadIcon />
              <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
            </span>
          )}
        </div>
      </label>
      {(files?.length || 0) > 0 && (
        <div className="flex w-full flex-col gap-2">
          <span className="truncate pb-2 text-xs font-[450] text-gray-500">
            {uploadedFiles > 1 ? (
              <AppFormattedMessage
                id={StringKey.COUNT_FILES_UPLOADED}
                values={{ count: uploadedFiles }}
              />
            ) : (
              <AppFormattedMessage id={StringKey.ONE_FILE_UPLOADED} />
            )}
          </span>
          {files?.map(({ doc, docLink, loadProgress, id }, i) => (
            <div
              className="flex flex-col overflow-hidden rounded border border-brand-50 bg-brand-25"
              key={`${id}_${docLink}`}
            >
              <div className="flex justify-between p-3" key={`${doc?.name}_${i}`}>
                <div className="flex items-center gap-2 overflow-hidden">
                  {docLink ? <UploadedFileIcon /> : <LoaderIcon className="size-4" />}
                  <span className="truncate text-xs font-[450] text-gray-700">{doc?.name}</span>
                </div>
                <Button
                  className="w-fit p-1 hover:bg-gray-50"
                  onClick={() => handleDeleteFile(id || '')}
                  styleType="NONE"
                  type="button"
                >
                  <CloseIcon className="size-2" />
                </Button>
              </div>
              <div
                className={twMerge(
                  'h-[2px] shrink-0 bg-brand-700 transition-all duration-700',
                  docLink && 'bg-transparent',
                )}
                style={{ width: (loadProgress || (docLink && 100)) + '%' }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
