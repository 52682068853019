import React, { FC } from 'react';

import image from '../../assets/images/clapping-hands.png';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';
import { LandingSolutionPageRoute } from '../../types/pageTypes';

const AllDonePage: FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <div className="mb-4 flex h-fit w-fit max-w-[458px] flex-col items-center justify-center text-center">
        <div
          className="rounded-full p-[12px]"
          style={{
            boxShadow: '0px 0px 8px 0px #0398551C inset',
          }}
        >
          <div
            className="flex size-[112px] items-center justify-center rounded-full"
            style={{
              boxShadow: '0px 0px 8px 0px #0985F721 inset',
              background:
                'linear-gradient(180deg, rgba(108, 233, 166, 0.1) 0%, rgba(3, 152, 85, 0.1) 100%)',
            }}
          >
            <img alt="calendar" className="size-14" src={image} />
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-4">
          <span
            className="!bg-clip-text text-4xl font-bold text-transparent"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            <AppFormattedMessage id={StringKey.ALL_DONE} />!
          </span>
          <span className="w-full text-sm font-[450] text-gray-500">
            A member of our sales team will get in touch with you shortly.
          </span>
        </div>
      </div>
      <div className="flex w-full max-w-[458px] flex-col items-start gap-3 rounded-lg bg-gray-50 p-6">
        <span
          className="!bg-clip-text py-[6px] text-m font-bold text-transparent"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          Next Steps:
        </span>
        <span className="pb-3 text-sm font-[450] text-gray-700">
          We typically respond within 24 hours. In the meantime, feel free to explore our resources
          below to learn more about how CapQuest can help you manage your equity and asset classes
          efficiently,
        </span>
        <a
          className="cursor-pointer text-sm font-[450] text-brand-700"
          href={`${process.env.REACT_APP_LANDING_URL}/solutions/${LandingSolutionPageRoute.SIMPLIFIED_CAP_TABLE}`}
          rel="noreferrer noopener nofollow"
          target="_blank"
        >
          Cap Table Management
        </a>
        <a
          className="cursor-pointer text-sm font-[450] text-brand-700"
          href={`${process.env.REACT_APP_LANDING_URL}/solutions/${LandingSolutionPageRoute.EQUITY_PLAN_AUTOMATION}`}
          rel="noreferrer noopener nofollow"
          target="_blank"
        >
          Equity Plan Automation
        </a>
        <a
          className="cursor-pointer text-sm font-[450] text-brand-700"
          href={`${process.env.REACT_APP_LANDING_URL}/solutions/${LandingSolutionPageRoute.OPTIMIZED_FUNDRAISING_INSTRUMENTS}`}
          rel="noreferrer noopener nofollow"
          target="_blank"
        >
          Optimized Fundraising Instruments
        </a>
      </div>
      <div className="flex w-full max-w-[458px] flex-col items-start gap-4 rounded-xl border-[1px] border-gray-200 p-4">
        <span className="text-sm font-[550] text-[#172335]">Immediate questions?</span>
        <span className="text-label-md text-[#475467]">
          Write us at{' '}
          <a
            className="cursor-pointer text-label-md font-[450] text-brand-700"
            href="mailto:support@capquest.io"
            rel="noreferrer noopener"
            target="_blank"
          >
            {process.env.REACT_APP_CAP_QUEST_SUPPORT_EMAIL}
          </a>
        </span>
      </div>
    </div>
  );
};
export default AllDonePage;
