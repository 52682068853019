import { StringKey } from '../../../../lang';

export enum CapTableManagementTypes {
  EXCEL = 'excel',
  NOT_YET = 'not-yet',
  ANOTHER = 'another',
}

export const CapTableManagementTypesTitle: Record<CapTableManagementTypes, StringKey> = {
  [CapTableManagementTypes.EXCEL]: StringKey.EXCEL,
  [CapTableManagementTypes.NOT_YET]: StringKey.NO_CAP_TABLE_MANAGEMENT,
  [CapTableManagementTypes.ANOTHER]: StringKey.OTHER_CAP_TABLE_SOFTWARE,
};

export const arrayOfCapTableManagementTypes = Object.entries(CapTableManagementTypesTitle);

export enum EnterpriseFeatures {
  EQUITY = 'equity',
  ESOP = 'esop',
  VENTURE_DEBT = 'venture-debt',
  FUNDRAISING_TRACKER = 'fundraising-tracker',
  DATA_ROOM = 'data-room',
}

export const EnterpriseFeaturesTitle: Record<EnterpriseFeatures, StringKey> = {
  [EnterpriseFeatures.EQUITY]: StringKey.EQUITY_MANAGEMENT,
  [EnterpriseFeatures.ESOP]: StringKey.ESOP_MANAGEMENT,
  [EnterpriseFeatures.VENTURE_DEBT]: StringKey.VENTURE_DEBT_MANAGEMENT,
  [EnterpriseFeatures.FUNDRAISING_TRACKER]: StringKey.FUNDRAISING_TRACKER,
  [EnterpriseFeatures.DATA_ROOM]: StringKey.DATA_ROOM,
};

export const arrayOfEnterpriseFeatures = Object.entries(EnterpriseFeaturesTitle);
