import { FC } from 'react';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { SelectedCompanyCurrency } from '../../../../../components/SelectedCompanyCurrency';
import { useFormat } from '../../../../../hooks';
import {
  EventExercisedTypeBackend,
  eventFormType,
  EventTypeBackend,
} from '../../../../../types/events.types';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const Exercised: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();

  if (props.type !== EventTypeBackend.EXERCISE) return <></>;

  const {
    type,
    exercise: { items },
    createdAt,
  } = props;

  const planType = items[0]?.vestingTask?.grantItem?.plan?.type;

  if (planType === SharePlanType.STOCK_OPTIONS)
    return (
      <BasicTable eventType={EventExercisedTypeBackend.STOCK_OPTIONS}>
        {items.map(({ id, exercised, vestingTask }) => (
          <tr key={id}>
            <Td value={format(createdAt, 'dd/MM/yyyy')} />
            <Td value={<AppFormattedMessage id={eventFormType[type]} />} />
            <Td value={vestingTask.grantItem.stakeholder.fullName} />
            <Td value={exercised / (vestingTask.grantItem.plan.conversionRatio || 1)} />
            <Td value={vestingTask.grantItem.plan.pool.shareClass.name} />
            <Td value={vestingTask.grantItem.numbersOfGrants} />
          </tr>
        ))}
      </BasicTable>
    );

  if (planType === SharePlanType.WARRANTS)
    return (
      <BasicTable eventType={EventExercisedTypeBackend.STOCK_OPTIONS}>
        {items.map(({ id, exercised, vestingTask }) => (
          <tr key={id}>
            <Td value={format(createdAt, 'dd/MM/yyyy')} />
            <Td value={<AppFormattedMessage id={eventFormType[type]} />} />
            <Td value={vestingTask.grantItem.stakeholder.fullName} />
            <Td value={exercised / (vestingTask.grantItem.plan.conversionRatio || 1)} />
            <Td value={vestingTask.grantItem.plan.pool.shareClass.name} />
            <Td value={vestingTask.grantItem.numbersOfGrants} />
          </tr>
        ))}
      </BasicTable>
    );

  if (planType === SharePlanType.SAR)
    return (
      <BasicTable eventType={EventExercisedTypeBackend.SAR}>
        {items.map(({ id, exercised, vestingTask, payout }) => (
          <tr key={id}>
            <Td value={format(createdAt, 'dd/MM/yyyy')} />
            <Td value={<AppFormattedMessage id={eventFormType[type]} />} />
            <Td value={vestingTask.grantItem.stakeholder.fullName} />
            <Td value={exercised} />
            <Td value={vestingTask.grantItem.plan.pool.shareClass.name} />
            <Td
              value={
                <>
                  <SelectedCompanyCurrency />
                  {payout && payout.toLocaleString('en-US')}
                </>
              }
            />
            <Td value={vestingTask.grantItem.numbersOfGrants} />
          </tr>
        ))}
      </BasicTable>
    );

  // if (planType === SharePlanType.RSA)
  //   return (
  //     <BasicTable eventType={EventExercisedTypeBackend.RSA}>
  //       <tr>
  //         <Td value={format(createdAt, 'dd/MM/yyyy')} />
  //         <Td value={<AppFormattedMessage id={eventFormType[type]} />} />
  //         <Td value={stakeholder.fullName} />
  //         <Td value={exercised / (conversionRatio || 1)} />
  //         <Td value={shareClass.name} />
  //         <Td
  //           value={
  //             <>
  //               <SelectedCompanyCurrency />
  //               {rsaPurchasePrice || 0}
  //             </>
  //           }
  //         />
  //         <Td value={exercised} />
  //       </tr>
  //     </BasicTable>
  //   );

  return <></>;
};
