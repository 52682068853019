import React, { FC, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { SafeConversionSummaryResponse } from '../../../../hooks/safe';
import { StringKey } from '../../../../lang';
import { toRound } from '../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../utils/toNumber';
import { FormSchema } from '../Validation';
import { ConvertSafeItem } from './ConvertSafeItem';

export type ConversionSummaryWithId = {
  id: string;
  summary: SafeConversionSummaryResponse;
};

export type GeneralSummary = {
  sharePrice?: number;
  investment?: number;
  preMoneyValuation?: number;
  totalOwned?: number;
  convertedShares?: number;
};

export type ConversionSummaryProps = {
  companyId: string;
  allSafeIds?: string[];
  currencySign?: string;
  setValue: UseFormSetValue<FormSchema>;
  conversionSummary?: GeneralSummary;
  preMoneyValuation?: number;
  postMoneyValuation?: number;
  valuationId?: string;
  valuationIssuedShares?: number;
};

export const ConversionSummary: FC<ConversionSummaryProps> = ({
  currencySign,
  companyId,
  allSafeIds,
  setValue,
  conversionSummary,
  preMoneyValuation,
  postMoneyValuation,
  valuationId,
  valuationIssuedShares,
}) => {
  const [conversionSummaries, setConversionSummaries] = useState<ConversionSummaryWithId[]>([]);
  const [generalSummary, setGeneralSummary] = useState<GeneralSummary | null>(null);
  useEffect(() => {
    const newPreMoneyValuation = preMoneyValuation || 0;
    const { investment, totalOwned, convertedShares } = conversionSummaries.reduce(
      (acc, item) => {
        acc.investment += item.summary.investment;
        acc.totalOwned += item.summary.capitalOwned;
        acc.convertedShares += item.summary.sharesConverted;
        return acc;
      },
      { investment: 0, totalOwned: 0, convertedShares: 0 },
    );
    const sharePrice = parseFloat(
      (
        (postMoneyValuation || 0) / (toNumber(valuationIssuedShares) + convertedShares || 1)
      ).toFixed(2),
    );

    const newSummary = {
      sharePrice,
      preMoneyValuation: newPreMoneyValuation,
      convertedShares,
      investment,
      totalOwned: parseFloat((totalOwned * 100).toFixed(2)),
    };

    setGeneralSummary(newSummary);

    if (JSON.stringify(conversionSummary) !== JSON.stringify(newSummary)) {
      setValue('stepOne.conversionSummary', newSummary);
    }
  }, [
    conversionSummaries,
    conversionSummary,
    postMoneyValuation,
    preMoneyValuation,
    setValue,
    valuationIssuedShares,
  ]);

  return (
    <>
      <div className="mt-2 flex flex-col gap-3 rounded-md bg-brand-25 p-1 pt-3">
        <span className="text-sm font-[550] text-brand-700">
          <AppFormattedMessage id={StringKey.GENERAL_CONVERSION_SUMMARY} />
        </span>
        <div className="divide-y-[1px] divide-gray-100 rounded-md bg-white p-3 shadow-sm">
          <div className="flex w-full items-center justify-between py-3">
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.EVENT_SHARE_PRICE_POST_CONVERSION} />
            </span>
            <span className="text-xs font-[450] text-gray-700">
              {currencySign}
              {toRound(generalSummary?.sharePrice, 2)}
            </span>
          </div>
          <div className="flex w-full items-center justify-between py-3">
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SAFE_INVESTMENT_CONVERSION_SUM} />
            </span>
            <span className="text-xs font-[450] text-gray-700">
              {currencySign}
              {generalSummary?.investment?.toLocaleString('en-US')}
            </span>
          </div>
          <div className="flex w-full items-center justify-between py-3">
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />
            </span>
            <span className="text-xs font-[450] text-gray-700">
              {currencySign}
              {generalSummary?.preMoneyValuation?.toLocaleString('en-US')}
            </span>
          </div>
          <div className="flex w-full items-center justify-between py-3">
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.TOTAL_PERCENT_OWNED_SAFE} />
            </span>
            <span className="text-xs font-[450] text-gray-700">{generalSummary?.totalOwned} %</span>
          </div>
          <div className="flex w-full items-center justify-between py-3">
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.CONVERTED_SHARES} />
            </span>
            <span className="text-xs font-[450] text-gray-700">
              {toRound(generalSummary?.convertedShares, 0).toLocaleString('en-US')}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-2 flex w-full flex-col gap-4 rounded-lg p-4 shadow-sm">
        <span className="text-label-md font-[500] text-gray-500">
          <AppFormattedMessage id={StringKey.CONVERSION_DETAILS} />
        </span>
        <div className="flex w-full flex-col gap-2">
          {allSafeIds?.map((safeId) => (
            <ConvertSafeItem
              allSafeIds={allSafeIds}
              companyId={companyId}
              currencySign={currencySign}
              key={safeId}
              safeId={safeId}
              setConversionSummaries={setConversionSummaries}
              valuationId={valuationId}
            />
          ))}
        </div>
      </div>
    </>
  );
};
