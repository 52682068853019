import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CapQuestLogo, CrossIcon } from '../../assets/icons';
import Button from '../../components/Button';
import { guides } from './guides';
import { ExcelGuide } from './types';

export const ModifyGuide: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const currentGuideType = (location.state as ExcelGuide) || ExcelGuide.IMPORT_SAFES;

  const currentGuide = guides[currentGuideType].guide;

  const currentGuideSteps = guides[currentGuideType].stepsTitles;

  const currentGuideTitle = guides[currentGuideType].guideTitle;

  return (
    <div className="relative flex h-screen w-full flex-col gap-2 bg-brand-25">
      <div className="flex w-full justify-between rounded-b-3xl bg-white px-8 py-3">
        <div className="flex items-center gap-2" onClick={() => navigate('/')}>
          <CapQuestLogo size={32} />
          <span className="text-xl font-semibold text-gray-700">CapQuest</span>
        </div>
        <Button
          className="shadow-xxs size-9 rounded-full border-[1px] border-[#f2f2f2] bg-white"
          onClick={() => navigate(-1)}
        >
          <CrossIcon className="size-4" />
        </Button>
      </div>
      <div className="flex h-full w-full flex-col gap-8 rounded-t-3xl bg-white px-8 py-4">
        <div className="flex h-[38px] w-full gap-2 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              'border-brand-700 font-[550] text-brand-700',
            )}
            styleType="NONE"
          >
            {currentGuideTitle}
          </Button>
        </div>
        <div className="flex w-full gap-6">
          <div className="flex h-fit max-h-[80vh] w-max flex-col gap-2 rounded-md py-4 shadow-sm">
            <span
              className="text-nowrap px-6 py-2 text-label-sm font-bold uppercase text-gray-400"
              style={{ letterSpacing: '1px' }}
            >
              On this page
            </span>
            <div className="flex h-full w-full flex-col gap-[2px] overflow-y-auto">
              {currentGuideSteps.map(({ title }, i) => (
                <div
                  className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                  key={`${title}-${i + 1}`}
                  onClick={() => scrollToSection(`main-step-${i + 1}`)}
                >
                  {`Step ${i + 1}: ${title}`}
                </div>
              ))}
              <div
                className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                onClick={() => scrollToSection(`key-notes-and-tips`)}
              >
                Key Notes and Tips
              </div>
            </div>
          </div>
          <div className="flex h-[80vh] w-full flex-col gap-4 overflow-y-auto rounded-md p-4 shadow-sm">
            {currentGuide}
          </div>
        </div>
      </div>
    </div>
  );
};
