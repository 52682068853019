import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { DatePicker } from '../../../PoolForm/DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type BspceProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const Bspce: FC<BspceProps> = ({ control, setFormData, companyId, resetField }) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: bspceItemsFields,
    append: bspceItemsAppend,
    remove: bspceItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.bspce.bspceItems',
  });

  const handleRemoveBspceItems = useCallback(
    (index: number) => {
      bspceItemsRemove(index);
    },
    [bspceItemsRemove],
  );

  const handleAppendBspceItems = useCallback(() => {
    const defaultDate = new Date();
    bspceItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        grantItem: {
          plan: { strikePrice: 0, conversionRatio: 1, pool: { shareClass: { name: '' } } },
        },
        exercisedCount: 0,
        vestedSharesCount: 0,
      },
      date: defaultDate,
      exercised: 0,
    });
  }, [bspceItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.bspce.bspceItems.${index}.grant`);
  };

  const calculateBalance = useCallback(
    (index: number) => {
      const conversionRatio =
        stepOne?.bspce?.bspceItems?.[index]?.grant?.grantItem?.plan?.conversionRatio || 1;
      const vestedShares = stepOne?.bspce?.bspceItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares = stepOne?.bspce?.bspceItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.bspce?.bspceItems?.[index]?.exercised || 0;
      const balance = Math.max(
        vestedShares / conversionRatio - exercisedShares / conversionRatio - value,
        0,
      );

      return balance.toLocaleString('en-US');
    },
    [stepOne?.bspce?.bspceItems],
  );

  useEffect(() => {
    if (bspceItemsFields.length === 0) {
      handleAppendBspceItems();
    }
  }, [handleAppendBspceItems, bspceItemsFields.length]);

  const showRemoveButton = bspceItemsFields.length > 1;

  return (
    <>
      <div className="flex w-[1340px] flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <div
          className={twMerge(
            'grid w-full rounded-t-lg',
            showRemoveButton
              ? 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)]'
              : 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))]',
          )}
        >
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.STAKEHOLDER} />} />
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.GRANT} />} />
          <TableHeaderItem
            showTooltip
            text={<AppFormattedMessage id={StringKey.EXERCISE_DATE} />}
          />
          <TableHeaderItem text={<AppFormattedMessage id={StringKey.SHARE_CLASS} />} />
          <TableHeaderItem text={<AppFormattedMessage id={StringKey.STRIKE_PRICE} />} />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.EXERCISED_OPTIONS} />}
            variant="dark"
          />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
            variant="dark"
          />
          {showRemoveButton && <TableHeaderItem text=" " />}
        </div>
        {bspceItemsFields.map((field, index) => (
          <div
            className={twMerge(
              'grid w-full border-b-[1px] border-b-gray-100',
              showRemoveButton
                ? 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)]'
                : 'grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))]',
            )}
            key={field.id}
          >
            <div className="px-4 pb-10 pt-7">
              <StakeholderCombobox
                companyId={companyId}
                control={control}
                handleStakeholderChange={() => handleStakeholderChange(index)}
                name={`stepOne.bspce.bspceItems.${index}.stakeholder`}
                planType={SharePlanType.BSPCE}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <GrantCombobox
                companyId={companyId}
                control={control}
                name={`stepOne.bspce.bspceItems.${index}.grant`}
                selectedGrantIds={stepOne?.bspce?.bspceItems?.map((item) => item?.grant?.id || '')}
                sharePlanType={SharePlanType.BSPCE}
                stakeholderId={stepOne?.bspce?.bspceItems?.[index]?.stakeholder?.id || ''}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <DatePicker
                defaultMonth={stepOne?.bspce?.bspceItems?.[index]?.date || undefined}
                onSelect={(date) => setFormData(`stepOne.bspce.bspceItems.${index}.date`, date)}
                value={stepOne?.bspce?.bspceItems?.[index]?.date}
                wrapperClassName="w-full"
              >
                <FormInput
                  autoComplete="off"
                  control={control}
                  customValue={(value) => {
                    return value && format(value, 'dd/MM/yyyy');
                  }}
                  disabledInput
                  icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                  inputClassName="h-10"
                  name={`stepOne.bspce.bspceItems.${index}.date`}
                  wrapperClassName="w-full"
                />
              </DatePicker>
            </div>
            <div className="flex items-center px-4 pb-10 pt-7">
              <span className="text-sm font-[450] text-gray-700">
                {
                  stepOne?.bspce?.bspceItems?.[index]?.grant?.grantItem?.plan?.pool?.shareClass
                    ?.name
                }
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <FormInput
                control={control}
                disabled={true}
                inputClassName="h-10"
                name={`stepOne.bspce.bspceItems.${index}.grant.grantItem.plan.strikePrice`}
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <span className="block max-w-[164px] overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.TOTAL_COST} /> ${' '}
                {(
                  (stepOne?.bspce?.bspceItems?.[index]?.grant?.grantItem?.plan?.strikePrice || 0) *
                  (stepOne?.bspce?.bspceItems?.[index]?.exercised || 1)
                ).toLocaleString('en-US')}
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <FormInput
                control={control}
                inputClassName="h-10"
                name={`stepOne.bspce.bspceItems.${index}.exercised`}
                numberOnly
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <span className="text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.AVAILABLE} /> {calculateBalance(index)}
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <div className="max-w-[164px] rounded-t-[4px] bg-gray-50 px-3 py-2">
                <span className="block w-full overflow-hidden truncate text-ellipsis whitespace-nowrap text-sm font-[450] text-gray-700">
                  {(
                    (stepOne?.bspce?.bspceItems?.[index]?.exercised || 0) *
                    (stepOne?.bspce?.bspceItems?.[index]?.grant?.grantItem?.plan?.conversionRatio ||
                      1)
                  ).toLocaleString('en-US')}
                </span>
              </div>
              <span className="text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.CONVERSION} />{' '}
                {stepOne?.bspce?.bspceItems?.[index]?.grant?.grantItem?.plan?.conversionRatio || 1}x
              </span>
            </div>
            {showRemoveButton && (
              <div>
                <Button
                  className="flex h-full w-full items-center justify-center"
                  onClick={() => handleRemoveBspceItems(index)}
                  styleType="NONE"
                >
                  <CloseIcon />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      {bspceItemsFields.length < 10 && (
        <div className="sticky bottom-[76px] z-10 w-full bg-white">
          <Button
            className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
            onClick={handleAppendBspceItems}
            styleType="NONE"
          >
            + <AppFormattedMessage id={StringKey.ADD_EXERCISING} />
          </Button>
        </div>
      )}
    </>
  );
};
