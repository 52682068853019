import { z } from 'zod';

import { StringKey } from '../../../lang';
import { AntiDilutionBase } from '../../../types/share-classes.types';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];

export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);
export const formSchemaStepOne = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  creationDate: z.coerce.date(),
  votingRight: z
    .object({
      enabled: z.boolean(),
      value: z.coerce.number().min(1, { message: ' ' }).max(100000000, { message: ' ' }),
    })
    .optional(),
  conversionRatio: z
    .object({
      enabled: z.boolean(),
      value: z.coerce.number().min(1, { message: ' ' }).max(100000000, { message: ' ' }),
    })
    .optional(),
  dividendRightEnabled: z.boolean(),
});

const participating = z.union([
  z
    .object({
      enabled: z.boolean(),
      capValue: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      interest: z.string().optional(),
      daysPersYear: z.undefined().optional(),
    })
    .refine(
      ({ interest, daysPersYear }) => (interest && daysPersYear) || (!interest && !daysPersYear),
    ),
  z.object({
    enabled: z.boolean(),
    capValue: z.coerce.number().min(0, { message: getTranslation(StringKey.REQUIRED) }),
    interest: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
    daysPersYear: z.string(),
  }),
]);
export const fromSchemaStepTwo = z
  .object({
    liquidityPreferences: z
      .object({
        enabled: z.boolean(),
        seniority: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        multiple: z.coerce
          .number()
          .min(0.001, { message: getTranslation(StringKey.REQUIRED) })
          .max(100000000, { message: ' ' }),
        participating: participating.optional(),
      })
      .superRefine(({ multiple, participating }, ctx) => {
        if (participating && multiple > participating.capValue) {
          ctx.addIssue({
            code: 'custom',
            params: { participating: 'error' },
            path: ['liquidityPreferences', 'multiple'],
            message: getTranslation(StringKey.CAP_VALUE_LESS_THAN_MULTIPLE),
          });
        }
      })
      .optional(),
  })
  .optional();

export const formSchemaStepThree = z
  .object({
    antiDilutionRights: z
      .object({
        enabled: z.boolean(),
        base: z.nativeEnum(AntiDilutionBase),
      })
      .optional(),
  })
  .optional();

export const fromSchemaStepFour = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne: formSchemaStepOne,
  stepTwo: fromSchemaStepTwo,
  stepThree: formSchemaStepThree,
  stepFour: fromSchemaStepFour,
});

export type FormSchema = z.infer<typeof formSchema>;
