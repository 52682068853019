import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { Company } from '..';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Valuation } from '../../types/events.types';
import { useAppQuery } from '../useAppQuery';

export const useValuation = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  const { selectedCompanyId: companyId } = Company.useSelected();

  const { data: valuation, isPending } = useAppQuery({
    queryKey: [QueryKey.GET_VALUATION, companyId, id],
    queryFn: () =>
      ApiService.get<Valuation>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'valuation', id],
      }),
    enabled: !!companyId && !!id,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_VALUATION] });
  }, [queryClient]);

  return {
    invalidateQuery,
    valuation,
    isPending,
  };
};
