import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { useFormat, ValuationHooks } from '../../../../hooks';
import { useSelected } from '../../../../hooks/company';
import { StringKey } from '../../../../lang';
import { toRound } from '../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../utils/toNumber';
import { ConversionSummary } from '../ConversionSummary';
import { EventCombobox } from '../EventCombobox';
import { SafeSelect } from '../SafeSelect';
import { ShareClassCombobox } from '../ShareClassCombobox';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  filedState,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const isNoValuation = stepOne?.linkedEvent?.id === 'no-valuation';

  const { valuation } = ValuationHooks.useValuation({
    id: isNoValuation ? '' : stepOne?.linkedEvent?.id || '',
  });

  const isValid = success && !filedState('stepOne').invalid;

  const { selectedCompany } = useSelected();

  const isValidSafes = isNoValuation
    ? stepOne?.safes?.every((safe) => safe?.floorValue && safe?.floorValue > 0)
    : true;

  const issuedShares = valuation?.issuedShares || valuation?.issuedSharesOnStart;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <FormDatePicker
          defaultMonth={stepOne?.conversionDate || undefined}
          inputValue={stepOne?.conversionDate ? format(stepOne?.conversionDate, 'dd/MM/yyyy') : ''}
          inputWrapperClassName="w-full"
          isDefaultOpenCalendar={isOpenCalendar}
          onSelect={(date) => {
            setFormData('stepOne.conversionDate', date);
            setOpenCalendar(false);
          }}
          placeholder={<AppFormattedMessage id={StringKey.CONVERSION_DATE} />}
          value={stepOne?.conversionDate}
        />
        <EventCombobox companyId={companyId} control={control} />
        {!!(stepOne?.linkedEvent?.sharePrice && stepOne?.linkedEvent?.sharePrice !== 0) && (
          <div className="flex w-full items-center justify-between rounded-md px-4 py-3 shadow-sm">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARES_PRICE} />
            </span>
            <span className="rounded bg-brand-25 px-3 py-1 text-sm font-[550] text-brand-700">
              {selectedCompany?.currency?.symbol} {toRound(stepOne?.linkedEvent?.sharePrice, 2)}
            </span>
          </div>
        )}
        <ShareClassCombobox companyId={companyId} control={control} />
        <SafeSelect
          companyId={companyId}
          control={control}
          currencySign={selectedCompany?.currency?.symbol}
          setValue={setFormData}
        />
        {isValid && (
          <ConversionSummary
            allSafeIds={stepOne?.safeIds}
            companyId={companyId}
            conversionSummary={stepOne?.conversionSummary}
            currencySign={selectedCompany?.currency?.symbol}
            postMoneyValuation={toNumber(issuedShares) * toNumber(valuation?.sharePrice)}
            preMoneyValuation={
              toNumber(valuation?.issuedSharesOnStart) * toNumber(valuation?.sharePrice)
            }
            setValue={setFormData}
            valuationId={isNoValuation ? undefined : stepOne?.linkedEvent?.id}
            valuationIssuedShares={isNoValuation ? undefined : issuedShares}
          />
        )}
        <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
          <DropDown
            answer="A linked event is a financing round or liquidity event that triggers the SAFE's conversion into equity."
            question={<AppFormattedMessage id={StringKey.WHAT_IS_LINKED_EVENT} />}
          />
          <DropDown
            answer="This is a price per share after the SAFE converts, based on the valuation and terms of the conversion event."
            question={<AppFormattedMessage id={StringKey.WHAT_IS_EVENT_SHARE_PRICE} />}
          />
          <DropDown
            answer="The percentage of company equity the SAFE investor holds after conversion, calculated based on shares issued and total outstanding shares.
(include cap, discount, floor)"
            question={<AppFormattedMessage id={StringKey.WHAT_IS_PERCENT_OWNED_AT_CONVERSION} />}
          />
          <DropDown
            answer="The approach used to convert the SAFE into equity, such as based on floor value, a valuation cap, discount, or a combination of both."
            question={<AppFormattedMessage id={StringKey.WHAT_IS_METHOD_OF_CONVERSION} />}
          />
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid || !isValidSafes}
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.NEXT} />
          </Button>
        </div>
      </div>
    </>
  );
};
