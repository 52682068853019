import { FC, useEffect, useMemo } from 'react';

import { ShareClass, useLocale } from '../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../translations/stakeholderTranslation';
import { Column } from '../../../../types/snapshot.types';
import { filterByAllFields } from '../../../../utils/filterByAllFields';
import { toRound } from '../../../../utils/getRoundedNumber';
import { calcAvg, calcTotal } from '../../../../utils/reduceUtils';
import { toNumber } from '../../../../utils/toNumber';
import { StakeholderTableItem } from '../TableVariants/Items/StakeholderTableItem';
import { getShareClasses, SnapshotViewByProps } from './type';

export const SnapshotViewByType: FC<SnapshotViewByProps> = ({
  snapshot: {
    stats: { stakeholdersTypes, shareClasses },
    issuedShares: issuedTotal,
    dilutedShares: dilutedTotal,
  },
  viewBy,
  search,
  visibleColumns,
  setEmptySearchResult,
}) => {
  const { messagesLocale } = useLocale();

  const searchResult = useMemo(
    () => filterByAllFields(stakeholdersTypes, search),
    [stakeholdersTypes, search],
  );

  const stakeholderTypesWithDiluted = searchResult.filter(
    (stakeholder) => stakeholder.diluted > 0 || stakeholder.invested > 0,
  );

  useEffect(() => {
    if (stakeholderTypesWithDiluted.length === 0) {
      setEmptySearchResult(true);
    }
  }, [stakeholderTypesWithDiluted.length, setEmptySearchResult]);

  const { shareClass } = ShareClass.useDefaultShareClass();

  const defaultShareClassId = shareClass?.id || '';

  const { otherShareClasses, defaultShareClass } = getShareClasses(
    defaultShareClassId,
    shareClasses,
  );

  const issuedPercentageTotal = calcTotal(
    stakeholdersTypes,
    ({ issuedPercentage }) => issuedPercentage,
  );

  const dilutedPercentageTotal = calcTotal(
    stakeholdersTypes,
    ({ dilutedPercentage }) => dilutedPercentage,
  );

  const votingRightPercentageTotal = calcTotal(
    stakeholdersTypes,
    ({ votingPercentage }) => votingPercentage,
  );

  const votingRightTotal = calcTotal(stakeholdersTypes, ({ votingRight }) => votingRight);

  const commonStockTotal = toNumber(defaultShareClass?.issued);

  const shareClassesTotal = otherShareClasses
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ issued }) => issued);

  const investmentTotal = calcTotal(stakeholdersTypes, ({ invested }) => invested);

  const averageSharePrice = calcAvg(
    stakeholderTypesWithDiluted,
    ({ avgSharePrice }) => avgSharePrice,
  );

  const convertedTotal = calcTotal(stakeholdersTypes, ({ converted }) => converted);
  const convertedPercentageTotal = calcTotal(
    stakeholdersTypes,
    ({ convertedPercentage }) => convertedPercentage,
  );

  const allShareClassNames = Array.from(
    new Set(
      stakeholderTypesWithDiluted.flatMap(() => {
        return otherShareClasses.map(({ name }) => name);
      }),
    ),
  ).sort((a, b) => a.localeCompare(b));

  return (
    <>
      {stakeholderTypesWithDiluted.map(
        (
          {
            diluted,
            dilutedPercentage,
            issued,
            issuedPercentage,
            type,
            votingPercentage,
            shareClasses,
            votingRight,
            avgSharePrice,
            invested,
            converted,
            convertedPercentage,
          },
          i,
        ) => {
          const { defaultShareClass, otherShareClasses } = getShareClasses(
            defaultShareClassId,
            shareClasses,
          );

          const otherShareClassesMap = otherShareClasses
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce<Record<string, (typeof otherShareClasses)[0]>>((acc, curr) => {
              acc[curr.name] = curr;
              return acc;
            }, {});

          return (
            <StakeholderTableItem
              allShareClassNames={allShareClassNames}
              fields={{
                [Column.NAME]: null,
                [Column.STAKEHOLDER_NAME]: null,
                [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
                [Column.ISSUED]: toRound(issuedPercentage * 100),
                [Column.DILUTED]: toRound(dilutedPercentage * 100),
                [Column.VOTING]: toRound(votingPercentage * 100),
                [Column.NO_ISSUED]: issued,
                [Column.DILUTED_SHARES]: diluted,
                [Column.COMMON_STOCK]: toNumber(defaultShareClass?.issued),
                [Column.OTHER_SHARE_CLASSES]: allShareClassNames.map((name) => {
                  return otherShareClassesMap[name] ? otherShareClassesMap[name].issued : 0;
                }),
                [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
                [Column.INVESTMENT]: invested.toLocaleString('en-US'),
                [Column.AVERAGE_SHARE_PRICE]: toRound(avgSharePrice),
                [Column.CONVERTED_SHARES]: converted,
                [Column.CONVERTED_PERCENTAGE]: `${toRound(convertedPercentage * 100)}`,
              }}
              key={`${i}_${issued}${type}`}
              viewBy={viewBy}
              visibleColumns={visibleColumns}
            />
          );
        },
      )}
      <StakeholderTableItem
        allShareClassNames={allShareClassNames}
        className="font-[550] text-gray-800"
        fields={{
          [Column.NAME]: null,
          [Column.STAKEHOLDER_NAME]: null,
          [Column.TYPE]: 'Total',
          [Column.ISSUED]: toRound(issuedPercentageTotal * 100),
          [Column.DILUTED]: toRound(dilutedPercentageTotal * 100),
          [Column.VOTING]: toRound(votingRightPercentageTotal * 100),
          [Column.NO_ISSUED]: issuedTotal.toLocaleString('en-US'),
          [Column.DILUTED_SHARES]: dilutedTotal.toLocaleString('en-US'),
          [Column.VOTING_RIGHTS]: votingRightTotal.toLocaleString('en-US'),
          [Column.COMMON_STOCK]: commonStockTotal.toLocaleString('en-US'),
          [Column.OTHER_SHARE_CLASSES]: shareClassesTotal,
          [Column.INVESTMENT]: investmentTotal.toLocaleString('en-US'),
          [Column.AVERAGE_SHARE_PRICE]: toRound(averageSharePrice),
          [Column.CONVERTED_SHARES]: convertedTotal,
          [Column.CONVERTED_PERCENTAGE]: `${toRound(convertedPercentageTotal * 100)}`,
        }}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      />
    </>
  );
};
