import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { FormRadio, FormRadioGroup } from '../../../../components/RadioGroup';
import { Switch } from '../../../../components/Switch';
import { StringKey } from '../../../../lang';
import { DaysPerYearOption } from '../../../../types/share-classes.types';
import { ChildrenFormProps } from '../type';
import { FormSchema, fromSchemaStepTwo } from '../Validation';

export type StepTwoProps = ChildrenFormProps;

export const StepTwo: FC<StepTwoProps> = ({
  control,
  handleCloseModal,
  prevFormStep,
  nextFormStep,
  setFormData,
  lockMode,
}) => {
  const { stepTwo } = useWatch<FormSchema>({ control });
  const { success, error } = fromSchemaStepTwo.safeParse(stepTwo);
  const isValid = success;
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div
          className={twMerge(
            'flex flex-col rounded-md border border-gray-100 bg-white',
            stepTwo?.liquidityPreferences?.enabled && 'bg-gray-50',
          )}
        >
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepTwo?.liquidityPreferences?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepTwo.liquidityPreferences.enabled', state);
                setFormData('stepTwo.liquidityPreferences', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.LIQUIDITY_PREFERENCES} />
            </span>
          </div>
          {stepTwo?.liquidityPreferences?.enabled && (
            <div className="flex flex-col gap-3 divide-y-[1px] divide-gray-100 rounded-md bg-white py-3 shadow-sm">
              <div className="flex items-center gap-4 px-4">
                <FormInput
                  control={control}
                  isShownErrorMessage={false}
                  maxDecimalPlaces={2}
                  name="stepTwo.liquidityPreferences.seniority"
                  placeholder={<AppFormattedMessage id={StringKey.SENIORITY} />}
                />
                <FormInput
                  control={control}
                  isShownErrorMessage={false}
                  maxDecimalPlaces={2}
                  name="stepTwo.liquidityPreferences.multiple"
                  placeholder={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                />
              </div>
              <div className="flex flex-col gap-3 px-4 pt-3">
                <div className="flex h-14 items-center gap-4">
                  <div className="flex w-full items-center gap-2">
                    <Switch
                      checked={stepTwo?.liquidityPreferences?.participating?.enabled}
                      onChange={(state) => {
                        state
                          ? setFormData(
                              'stepTwo.liquidityPreferences.participating.enabled',
                              state,
                              { shouldValidate: true },
                            )
                          : setFormData('stepTwo.liquidityPreferences.participating', undefined);
                      }}
                    />
                    <span className="text-sm font-[450] text-gray-700">
                      {<AppFormattedMessage id={StringKey.PARTICIPATING} />}
                    </span>
                  </div>
                  {stepTwo?.liquidityPreferences.participating?.enabled && (
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      maxDecimalPlaces={2}
                      name="stepTwo.liquidityPreferences.participating.capValue"
                      placeholder={<AppFormattedMessage id={StringKey.CAP_VALUE} />}
                      wrapperClassName="w-full"
                    />
                  )}
                </div>
                {stepTwo?.liquidityPreferences.participating?.enabled && (
                  <div className="flex flex-col gap-4 rounded-lg border border-gray-100 p-3">
                    <div className="flex items-center gap-4">
                      <div className="flex w-full flex-col">
                        <span className="text-sm font-[450] text-gray-700">
                          <AppFormattedMessage id={StringKey.YEARLY_INTEREST} />
                        </span>
                        <span className="text-label-sm font-[450] text-gray-400">
                          (<AppFormattedMessage id={StringKey.NON_COMPOUNDING} />)
                        </span>
                      </div>
                      <FormInput
                        control={control}
                        isShownErrorMessage={false}
                        maxDecimalPlaces={2}
                        name="stepTwo.liquidityPreferences.participating.interest"
                        onChange={(value) => {
                          if (value) return;

                          setFormData(
                            'stepTwo.liquidityPreferences.participating.daysPersYear',
                            undefined,
                          );
                        }}
                        placeholder="%"
                        wrapperClassName="w-full"
                      />
                    </div>
                    <div className="flex items-center gap-4">
                      <span className="w-full text-sm font-[450] text-gray-700">
                        <AppFormattedMessage id={StringKey.DAYS_PER_YEAR} />
                      </span>

                      <FormRadioGroup
                        className={'h-11 divide-x divide-gray-200 overflow-hidden'}
                        control={control}
                        name="stepTwo.liquidityPreferences.participating.daysPersYear"
                        wrapperClassName="w-full"
                      >
                        {Object.values(DaysPerYearOption).map((option, i) => (
                          <FormRadio
                            className={twMerge(
                              'w-full bg-gray-50 px-4 py-3 text-[12px] leading-normal text-gray-700 data-[checked]:text-sm data-[checked]:text-gray-25',
                              !stepTwo.liquidityPreferences?.participating?.interest &&
                                'cursor-default bg-gray-50 text-label-md text-gray-700',
                            )}
                            control={control}
                            disabled={!stepTwo.liquidityPreferences?.participating?.interest}
                            key={`${option}_${i}`}
                            name="stepTwo.liquidityPreferences.participating.daysPersYear"
                            value={option}
                          >
                            <span className="w-full text-nowrap text-center font-[450]">
                              {option}
                            </span>
                          </FormRadio>
                        ))}
                      </FormRadioGroup>
                    </div>
                    {stepTwo.liquidityPreferences.participating.daysPersYear &&
                      !stepTwo.liquidityPreferences.participating.interest && (
                        <span className="flex items-center gap-1 text-xs font-[450] text-fireside-600">
                          <InfoCircle />
                          <AppFormattedMessage id={StringKey.INTEREST_PERCENTAGE_PROMPT} />
                        </span>
                      )}
                    {error?.errors[0].path.includes('multiple') && (
                      <span className="flex items-center gap-1 text-xs font-[450] text-fireside-600">
                        <InfoCircle />
                        {error?.errors[0].message}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="flex shrink-0 flex-col divide-y-[1px] divide-gray-100 overflow-hidden rounded-lg bg-gray-50">
          <DropDown
            answer="Liquidity preferences dictate the order and amount of payments to stakeholders during a liquidation event, such as the sale of the company. They determine which investors get paid first and how much they receive before common shareholders receive any proceeds. "
            question={<AppFormattedMessage id={StringKey.WHAT_ARE_LIQUIDITY_PREFERENCES} />}
          />
          <DropDown
            answer={[
              'Seniority defines the order in which stakeholders are paid during a liquidity event (e.g. acquisition, IPO). Shareholders with a lower seniority number (e.g., 1) have priority over those with a higher number. ',
              'Example: If Investor A has Seniority 1 and Investor B has Seniority 2, Investor A will be fully paid back before Investor B receives anything.',
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_SENIORITY} />}
          />
          <DropDown
            answer={[
              'The Multiple Value indicates the minimum return an investor will receive in a liquidity event, relative to their original investment. ',
              'Example: If an investor invested $1,000,000 with a 2x multiple, they are entitled to receive $2,000,000 before common shareholders receive any proceeds.',
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_MULTIPLY} />}
          />
          <DropDown
            answer={[
              'Participation rights allow an investor to receive their liquidation preference and then also "participate" in the remaining proceeds based on their ownership percentage.',
              'Example: If Investor A has a 20% ownership and participation rights, they receive their liquidation preference first, and then 20% of the remaining distribution, increasing their total payout.',
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_PARTICIPATING} />}
          />
          <DropDown
            answer={[
              'A Cap Value limits how much an investor with participation rights can receive in total, relative to their original investment.',
              'Example: If Investor A invested $1,000,000, owns 20% of a company worth $10M, and has a participation cap of 1.5x, they will receive a maximum of $1,500,000, even if their ownership stake would entitle them to $2,000,000',
            ]}
            question={<AppFormattedMessage id={StringKey.WHAT_IS_CAP_VALUE} />}
          />
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : stepTwo?.liquidityPreferences?.enabled ? (
              <AppFormattedMessage id={StringKey.NEXT} />
            ) : (
              <AppFormattedMessage id={StringKey.SKIP} />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};
