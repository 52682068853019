import { TagVariant } from '../components/Tag';
import { Locale } from '../lang';
import { AuthProvider } from './authTypes';

export type PatchUserDto = {
  fullName?: string;
  email?: string;
  password?: string;
  language?: Locale;
};

export type PatchUserNotificationSettings = {
  invitation?: boolean;
  exerciseDone?: boolean;
  exerciseRequestConfirmation?: boolean;
  grant?: boolean;
  vesting?: boolean;
  transaction?: boolean;
  investment?: boolean;
  fundraising?: boolean;
  passwordReset?: boolean;
  verificationLink?: boolean;
  roleChange?: boolean;
  stakeholderLimit?: boolean;
  planUpgrade?: boolean;
  companyCreation?: boolean;
  exerciseRequest?: boolean;
  pool?: boolean;
  plan?: boolean;
  adminVesting?: boolean;
  adminTransaction?: boolean;
  shareClass?: boolean;
  adminInvestment?: boolean;
  adminFundraising?: boolean;
  enterprisePlanRequest?: boolean;
  receiptsConfirmations?: boolean;
  investmentConversions?: boolean;
};

export enum Importance {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export const importanceColor: Record<Importance, TagVariant> = {
  [Importance.HIGH]: TagVariant.YELLOW,
  [Importance.LOW]: TagVariant.GRAY,
  [Importance.MEDIUM]: TagVariant.GREEN,
};

export const importanceTitle = {
  [Importance.HIGH]: 'High',
  [Importance.LOW]: 'Low',
  [Importance.MEDIUM]: 'Medium',
};

export enum AdminNotificationTag {
  EXERCISE_REQUEST = 'exercise-request',
  POOL = 'pool',
  PLAN = 'plan',
  ADMIN_VESTING = 'admin-vesting',
  ADMIN_TRANSACTION = 'admin-transaction',
  SHARE_CLASS = 'share-class',
  ADMIN_INVESTMENT = 'admin-investment',
  ADMIN_FUNDRAISING = 'admin-fundraising',
  RECEIPTS_CONFIRMATIONS = 'receipts-confirmations',
}
export enum NotificationTag {
  EXERCISE_DONE = 'exercise-done',
  GRANT = 'grant',
  VESTING = 'vesting',
  TRANSACTION = 'transaction',
  INVESTMENT = 'investment',
  INVESTMENT_CONVERSIONS = 'investment-conversions',
  FUNDRAISING = 'fundraising',
}

type NotificationTagUnion = NotificationTag | AdminNotificationTag;

export const notificationTagImportance: Record<NotificationTagUnion, Importance> = {
  [NotificationTag.EXERCISE_DONE]: Importance.MEDIUM,
  [NotificationTag.GRANT]: Importance.HIGH,
  [NotificationTag.VESTING]: Importance.MEDIUM,
  [NotificationTag.TRANSACTION]: Importance.HIGH,
  [NotificationTag.INVESTMENT]: Importance.HIGH,
  [NotificationTag.INVESTMENT_CONVERSIONS]: Importance.MEDIUM,
  [NotificationTag.FUNDRAISING]: Importance.HIGH,
  [AdminNotificationTag.EXERCISE_REQUEST]: Importance.HIGH,
  [AdminNotificationTag.POOL]: Importance.MEDIUM,
  [AdminNotificationTag.PLAN]: Importance.MEDIUM,
  [AdminNotificationTag.ADMIN_VESTING]: Importance.MEDIUM,
  [AdminNotificationTag.ADMIN_TRANSACTION]: Importance.HIGH,
  [AdminNotificationTag.SHARE_CLASS]: Importance.HIGH,
  [AdminNotificationTag.ADMIN_INVESTMENT]: Importance.HIGH,
  [AdminNotificationTag.ADMIN_FUNDRAISING]: Importance.HIGH,
  [AdminNotificationTag.RECEIPTS_CONFIRMATIONS]: Importance.HIGH,
};

export const notificationTagTitle: Record<NotificationTagUnion, string> = {
  [NotificationTag.EXERCISE_DONE]: 'Exercise Updates',
  [NotificationTag.GRANT]: 'Grant Updates',
  [NotificationTag.VESTING]: 'Vesting Updates',
  [NotificationTag.TRANSACTION]: 'Transaction Updates',
  [NotificationTag.INVESTMENT]: 'Investment Updates',
  [NotificationTag.INVESTMENT_CONVERSIONS]: 'Investment Conversions',
  [NotificationTag.FUNDRAISING]: 'Fundraising Updates',
  [AdminNotificationTag.EXERCISE_REQUEST]: 'Exercise Requests',
  [AdminNotificationTag.POOL]: 'Pool Updates',
  [AdminNotificationTag.PLAN]: 'Plan Updates',
  [AdminNotificationTag.ADMIN_VESTING]: 'Vesting Updates',
  [AdminNotificationTag.ADMIN_TRANSACTION]: 'Transactions Updates',
  [AdminNotificationTag.SHARE_CLASS]: 'Share Class Updates',
  [AdminNotificationTag.ADMIN_INVESTMENT]: 'Investments Updates',
  [AdminNotificationTag.ADMIN_FUNDRAISING]: 'Fundraising',
  [AdminNotificationTag.RECEIPTS_CONFIRMATIONS]: 'Receipts confirmations (subscription payment)',
};

export const notificationTagDescription: Record<NotificationTagUnion, string> = {
  [NotificationTag.EXERCISE_DONE]: 'Stay informed about exercised grants.',
  [NotificationTag.GRANT]:
    'Get alerts on newly issued equity grants or updates to existing grant details.',
  [NotificationTag.VESTING]: 'Get alerted about vesting milestones.',
  [NotificationTag.TRANSACTION]: 'Stay informed about share transactions affecting your holdings.',
  [NotificationTag.INVESTMENT]:
    'Track new or updated investments, including changes to investment terms or maturity dates.',
  [NotificationTag.INVESTMENT_CONVERSIONS]:
    'Learn when SAFEs, convertibles, or loans convert into equity.',
  [NotificationTag.FUNDRAISING]:
    'Keep an eye on new fundraising rounds, key milestones, and any changes in ongoing rounds.',
  [AdminNotificationTag.EXERCISE_REQUEST]:
    'Notifications about exercise requests from stakeholders.',
  [AdminNotificationTag.POOL]: 'Alerts for any changes to the company’s equity pool.',
  [AdminNotificationTag.PLAN]:
    'Notifications regarding changes to equity plans, such as new plan creation or modifications.',
  [AdminNotificationTag.ADMIN_VESTING]:
    'Updates on vesting schedules, acceleration reminders and other vesting-related events.',
  [AdminNotificationTag.ADMIN_TRANSACTION]:
    'Updates on new recorded or modified transactions (e.g., share issuances, class conversions).',
  [AdminNotificationTag.SHARE_CLASS]:
    'Get notified about the creation or modification of share classes.',
  [AdminNotificationTag.ADMIN_INVESTMENT]:
    'Keep track of SAFEs, Convertibles, Loans and conversions.',
  [AdminNotificationTag.ADMIN_FUNDRAISING]:
    'Alerts for fundraising rounds, including new rounds, status changes, and key milestones.',
  [AdminNotificationTag.RECEIPTS_CONFIRMATIONS]: 'Receipts confirmations (subscription payment)',
};

export const notificationTagToUserNotificationSettings: Record<
  AdminNotificationTag | NotificationTag,
  keyof Except<EmailSettings, 'id'>
> = {
  [AdminNotificationTag.EXERCISE_REQUEST]: 'exerciseRequest',
  [AdminNotificationTag.POOL]: 'pool',
  [AdminNotificationTag.PLAN]: 'plan',
  [AdminNotificationTag.ADMIN_VESTING]: 'adminVesting',
  [AdminNotificationTag.ADMIN_TRANSACTION]: 'adminTransaction',
  [AdminNotificationTag.SHARE_CLASS]: 'shareClass',
  [AdminNotificationTag.ADMIN_INVESTMENT]: 'adminInvestment',
  [AdminNotificationTag.ADMIN_FUNDRAISING]: 'adminFundraising',
  [AdminNotificationTag.RECEIPTS_CONFIRMATIONS]: 'receiptsConfirmations',
  [NotificationTag.EXERCISE_DONE]: 'exerciseDone',
  [NotificationTag.GRANT]: 'grant',
  [NotificationTag.VESTING]: 'vesting',
  [NotificationTag.TRANSACTION]: 'transaction',
  [NotificationTag.INVESTMENT]: 'investment',
  [NotificationTag.FUNDRAISING]: 'fundraising',
  [NotificationTag.INVESTMENT_CONVERSIONS]: 'investmentConversions',
};

export const notificationTagMap = Object.values(NotificationTag);
export const adminNotificationTagMap = Object.values(AdminNotificationTag);

export type EmailSettings = {
  id: string;
  invitation: boolean;
  exerciseDone: boolean;
  exerciseRequestConfirmation: boolean;
  grant: boolean;
  vesting: boolean;
  transaction: boolean;
  investment: boolean;
  fundraising: boolean;
  passwordReset: boolean;
  verificationLink: boolean;
  roleChange: boolean;
  stakeholderLimit: boolean;
  planUpgrade: boolean;
  companyCreation: boolean;
  exerciseRequest: boolean;
  pool: boolean;
  plan: boolean;
  adminVesting: boolean;
  adminTransaction: boolean;
  shareClass: boolean;
  adminInvestment: boolean;
  adminFundraising: boolean;
  enterprisePlanRequest: boolean;
  receiptsConfirmations: boolean;
  investmentConversions: boolean;
};

export type InAppSettings = {
  id: string;
  invitation: boolean;
  exerciseDone: boolean;
  exerciseRequestConfirmation: boolean;
  grant: boolean;
  vesting: boolean;
  transaction: boolean;
  investment: boolean;
  fundraising: boolean;
  passwordReset: boolean;
  verificationLink: boolean;
  roleChange: boolean;
  stakeholderLimit: boolean;
  planUpgrade: boolean;
  companyCreation: boolean;
  exerciseRequest: boolean;
  pool: boolean;
  plan: boolean;
  adminVesting: boolean;
  adminTransaction: boolean;
  shareClass: boolean;
  adminInvestment: boolean;
  adminFundraising: boolean;
  enterprisePlanRequest: boolean;
  receiptsConfirmations: boolean;
  investmentConversions: boolean;
};

export type User = {
  id: string;
  email: string;
  fullName: string;
  authProvider: AuthProvider;
  stripeCustomerId: string;
  selectedCompany?: string;
  createdAt: string;
  language: Locale;
  adminInAppNotifications: undefined;
  adminEmailAppNotifications: undefined;
  stakeholderInAppNotifications: undefined;
  stakeholderEmailAppNotifications: undefined;
  emailSettings: EmailSettings;
  inAppSettings: InAppSettings;
};
