export const cookieUtil = <T>(key: string) => ({
  delete: () => {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  },

  get: (): T | null => {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArr = decodedCookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
      const cookie = cookieArr[i].trim();
      if (cookie.indexOf(name) === 0) {
        return JSON.parse(cookie.substring(name.length)) as T;
      }
    }
    return null;
  },

  key,

  set: (value: Partial<T>, days = 365) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `expires=${date.toUTCString()};`;
    }

    if (value instanceof Object) {
      const currentCookie = cookieUtil<T>(key).get();
      const updatedCookie = { ...currentCookie, ...value };
      document.cookie = `${key}=${JSON.stringify(updatedCookie)};${expires}path=/`;
    }

    if (value instanceof Array) {
      const currentCookie = cookieUtil<T>(key).get();
      if (!currentCookie) return null;
      const updatedCookie = [...(currentCookie as unknown as T[]), ...value];
      document.cookie = `${key}=${JSON.stringify(updatedCookie)};${expires}path=/`;
    }

    return (document.cookie = `${key}=${JSON.stringify(value)};${expires}path=/`);
  },
});
