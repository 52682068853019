import React, { FC, memo } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  companyIndustryTranslation,
  listOfCompanyIndustryTranslation,
} from '../../../../translations/companyTranslation';
import { FormSchema } from './Validation';

export type CompanyIndustryItemsListProps = {
  control: Control<FormSchema>;
};

const CompanyIndustryItemsList: FC<CompanyIndustryItemsListProps> = memo(({ control }) => (
  <>
    {listOfCompanyIndustryTranslation.map(([companyIndustryKey, companyIndustry]) => (
      <FormComboboxOption
        control={control}
        key={companyIndustry}
        name="industry"
        value={companyIndustryKey}
      >
        <span className="text-sm font-normal text-gray-700">
          <AppFormattedMessage id={companyIndustry} />
        </span>
      </FormComboboxOption>
    ))}
  </>
));

CompanyIndustryItemsList.displayName = 'CompanyIndustryItemsList';

export type CompanyIndustryComboboxProps = {
  control: Control<FormSchema>;
};

const CompanyIndustryCombobox: FC<CompanyIndustryComboboxProps> = memo(({ control }) => {
  const { messagesLocale } = useLocale();

  return (
    <FormCombobox className="relative" control={control} name={'industry'}>
      <div className="relative">
        <FormComboboxInput
          className={'absolute z-0 bg-gray-900'}
          control={control}
          customValue={(value) =>
            value ? messagesLocale[companyIndustryTranslation[value]] : value
          }
          icon={<ChevronDownIcon className="mt-3" />}
          name={'industry'}
          placeholder={<AppFormattedMessage id={StringKey.INDUSTRY} />}
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <CompanyIndustryItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
});

CompanyIndustryCombobox.displayName = 'CompanyIndustryCombobox';

export default CompanyIndustryCombobox;
