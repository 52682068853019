import React, { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Loader from '../../components/Loader';
import { Company, Stakeholder } from '../../hooks';
import { StringKey } from '../../lang';
import { toRound } from '../../utils/getRoundedNumber';
import { toNumber } from '../../utils/toNumber';
import { AutomaticVesting } from './AutomaticVesting';
import { ConsolidatedPosition } from './ConsolidatedPosition';
import { EmptyMyHoldings } from './EmptyMyHoldings';
import { EquityGraph, PositionGraph } from './Graphs';
import { OwnershipSummary } from './OwnershipSummary';
import { StakeholderSafes } from './StakeholderSafes';
import { SummaryItem } from './SummaryItem';

const MyHoldings: FC = () => {
  const { selectedCompanyId, isLoading, selectedCompany } = Company.useSelected();
  const currency = selectedCompany?.currency;
  const companyId = selectedCompanyId;
  const stakeholderId = selectedCompany?.stakeholder?.id || '';

  const { ownershipSummary, isLoading: isLoadingOwnershipSummary } =
    Stakeholder.useOwnershipSummary({
      companyId,
      stakeholderId: stakeholderId,
    });

  const { isHaveVestingTasks } = Stakeholder.useHaveVestingTasks({ companyId, stakeholderId });
  const { isHaveSafes } = Stakeholder.useHaveSafes({ companyId, stakeholderId });

  const { isLoading: isLoadingInvestmentSummary, investmentSummary } =
    Stakeholder.useInvestmentSummary({ companyId, stakeholderId });

  if (isLoading || isLoadingOwnershipSummary || isLoadingInvestmentSummary) return <Loader />;

  if (!isHaveVestingTasks) return <EmptyMyHoldings />;

  return (
    <div className="flex h-fit w-full flex-col gap-4 overflow-hidden rounded-lg p-1">
      <div className="flex w-full flex-wrap gap-4">
        <OwnershipSummary {...ownershipSummary} />
        <ConsolidatedPosition companyId={companyId} />
      </div>
      <div className="flex w-full flex-wrap gap-4">
        {selectedCompany?.stakeholder?.positionAccess && (
          <PositionGraph companyId={companyId} stakeholderId={stakeholderId} />
        )}

        {selectedCompany?.stakeholder?.capitalChangeAccess && (
          <EquityGraph companyId={companyId} stakeholderId={stakeholderId} />
        )}
      </div>
      <AutomaticVesting companyId={companyId} stakeholderId={stakeholderId} />
      {!!isHaveSafes && <StakeholderSafes companyId={companyId} stakeholderId={stakeholderId} />}
      <div className="flex w-full flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          <AppFormattedMessage id={StringKey.INVESTMENT_SUMMARY} />
        </span>
        <div className="flex gap-1 max-lg:flex-wrap">
          {selectedCompany?.stakeholder?.companyTableAccess && (
            <div className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              <span className="p-4 text-xl font-semibold text-gray-700">
                <AppFormattedMessage id={StringKey.COMPANY} />
              </span>
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.TOTAL_ROUNDS} />}
                value={toNumber(investmentSummary?.companyTotalRounds).toLocaleString('en-US')}
              />
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.CURRENT_VALUATION} />}
                value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(investmentSummary?.currentValuationValue).toLocaleString('en-US')}`}
              />
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.TOTAL_RAISED_AMOUNT} />}
                value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(investmentSummary?.totalAmountRaise).toLocaleString('en-US')}`}
              />
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.AVG_VALUATION_CHANGE} />}
                titleAdditionalNote={<AppFormattedMessage id={StringKey.PER_ROUND} />}
                value={`${toNumber(toRound(investmentSummary?.avgValuationChange, 2)) * 100}%`}
              />
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.LATEST_SHARE_PRICE} />}
                value={toRound(investmentSummary?.latestSharePrice, 2).toLocaleString('en-US')}
              />
            </div>
          )}
          <div className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
            <span className="p-4 text-xl font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.YOUR_PARTICIPATION} />
            </span>
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.TOTAL_ROUNDS} />}
              value={toNumber(investmentSummary?.stakeholderTotalRounds).toLocaleString('en-US')}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.TOTAL_INVESTMENT} />}
              value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(investmentSummary?.stakeholderTotalInvestment).toLocaleString('en-US')}`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.ESTIMATED_VALUE} />}
              value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toRound(investmentSummary?.stakeholderEstimatedValue, 2).toLocaleString('en-US')}`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.OVERALL_EQUITY_CHANGE} />}
              titleAdditionalNote={<AppFormattedMessage id={StringKey.TOTAL_ROUNDS} />}
              value={`${toRound(toNumber((investmentSummary?.equityChange || 0) * 100), 2).toLocaleString('en-US')}%`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.AVERAGE_INVESTMENT} />}
              titleAdditionalNote={<AppFormattedMessage id={StringKey.TOTAL_ROUNDS} />}
              value={`${toNumber(investmentSummary?.stakeholderAverageInvestment).toLocaleString('en-US')}`}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.AVERAGE_SHARE_PRICE} />}
              value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(investmentSummary?.averageSharePrice).toLocaleString('en-US')}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MyHoldings.displayName = 'MyHoldings';

export default MyHoldings;
