import { format } from 'date-fns';

import { StringKey } from '../lang';
import { stakeholderTypeGroupTranslation } from '../translations/stakeholderTranslation';
import { toRound } from '../utils/getRoundedNumber';
import { getTranslation } from '../utils/getTranslation';
import { Company } from './companyTypes';
import { Valuation } from './events.types';
import { ExerciseRequest } from './exerciseRequest.types';
import { SharePlan, SharePlanType, sharePlanTypeTitle } from './pool-plans.types';
import {
  Stakeholder,
  StakeholderType,
  StakeholderTypeGroup,
  stakeholderTypeTitle,
  TerminationCause,
  TerminationCauseTitle,
} from './stakeholderTypes';
import { TargetBasedVestingMilestoneItem, VestingTask } from './vestingTasks.types';

export enum NotificationType {
  ACTIVITY = 'activity',
  REQUEST = 'request',
}

export const notificationTypeMap = Object.values(NotificationType);

export enum NotificationAction {
  //admin/contributor:
  STAKEHOLDER_ADDED = 'stakeholder-added',
  INVITATION_ACCEPTED = 'invitation-accepted',
  STAKEHOLDER_TERMINATED = 'stakeholder-terminated',
  ACCESS_REVOKED = 'access-revoked',
  STAKEHOLDER_ROLE_CHANGED = 'stakeholder-role-changed',
  BULK_STAKEHOLDERS_UPLOAD = 'bulk-stakeholders-upload',
  NEW_SHARE_CLASS_ADDED = 'new-share-class-added',
  NEW_POOL_ADDED = 'new-pool-added',
  NEW_PLAN_ADDED = 'new-plan-added',
  WARRANT_PLAN_EXPIRATION_7_DAYS = 'warrant-plan-expiration-7-days',
  WARRANT_EXPIRATION_DATE_REACHED = 'warrant-expiration-date-reached',
  STOCK_OPTION_EXERCISED = 'stock-option-exercised',
  WARRANT_EXERCISED = 'warrant-exercised',
  HURDLE_EXERCISED = 'hurdle-exercised',
  // RSA_EXERCISED = 'rsa-exercised',
  SAR_EXERCISED = 'sar-exercised',
  PHANTOM_SHARES_PAID_OUT = 'phantom-shares-paid-out',
  NEW_GRANT_ISSUED = 'new-grant-issued',
  BULK_GRANT_UPLOAD = 'bulk-grant-upload',
  NEW_SECONDARY_RECORDED = 'new-secondary-recorded',
  NEW_BUYBACK_RECORDED = 'new-buyback-recorded',
  NEW_SHARE_ISSUANCE = 'new-share-issuance',
  NEW_FUNDRAISING_ROUND_ADDED = 'new-fundraising-round-added',
  NEW_CLASS_CONVERSION = 'new-class-conversion',
  NEW_VALUATION_EVENT_ADDED = 'new-valuation-event-added',
  ACCELERATION_DATE_7_DAYS = 'acceleration-date-7-days',
  ACCELERATION_DATE_REACHED = 'acceleration-date-reached',
  SAFE_MATURITY_DATE = 'safe-maturity-date',
  SAFE_CONVERTED = 'safe-converted',
  STAKEHOLDER_LIMIT_REACHED = 'stakeholder-limit-reached',
  //stakeholder:
  GRANT_EXERCISED = 'grant-exercised',
  GRANT_PAID_OUT = 'grant-paid-out',
  GRANT_ADDED = 'grant-added',
  STAKEHOLDER_NEW_SECONDARY_RECORDED = 'stakeholder-new-secondary-recorded',
  STAKEHOLDER_NEW_BUYBACK_RECORDED = 'stakeholder-new-buyback-recorded',
  STAKEHOLDER_NEW_SHARE_ISSUANCE = 'stakeholder-new-share-issuance',
  SHARE_CLASS_CONVERTED = 'share-class-converted',
  SUCCESSFUL_INVESTMENT = 'successful-investment',
  SHARES_VESTED = 'shares-vested',
  MILESTONE_APPROVED = 'milestone-approved',
  STAKEHOLDER_SAFE_CONVERTED = 'stakeholder-safe-converted',
  GRANT_ACCELERATION_DATE = 'grant-acceleration-date',
}

export const notificationActionTitle: Record<NotificationAction, StringKey> = {
  [NotificationAction.STAKEHOLDER_ADDED]: StringKey.NEW_STAKEHOLDER_ADDED,
  [NotificationAction.INVITATION_ACCEPTED]: StringKey.INVITATION_ACCEPTED,
  [NotificationAction.STAKEHOLDER_TERMINATED]: StringKey.STAKEHOLDER_TERMINATED,
  [NotificationAction.ACCESS_REVOKED]: StringKey.ACCESS_REVOKED,
  [NotificationAction.STAKEHOLDER_ROLE_CHANGED]: StringKey.STAKEHOLDER_ROLE_CHANGED,
  [NotificationAction.BULK_STAKEHOLDERS_UPLOAD]: StringKey.BULK_STAKEHOLDERS_UPLOAD_NOTIFICATION,
  [NotificationAction.NEW_SHARE_CLASS_ADDED]: StringKey.NEW_SHARE_CLASS_ADDED_NOTIFICATION,
  [NotificationAction.NEW_POOL_ADDED]: StringKey.NEW_POOL_ADDED_NOTIFICATION,
  [NotificationAction.NEW_PLAN_ADDED]: StringKey.NEW_PLAN_ADDED_NOTIFICATION,
  [NotificationAction.WARRANT_PLAN_EXPIRATION_7_DAYS]:
    StringKey.WARRANT_PLAN_EXPIRATION_7_DAYS_NOTIFICATION,
  [NotificationAction.WARRANT_EXPIRATION_DATE_REACHED]:
    StringKey.WARRANT_EXPIRATION_DATE_REACHED_NOTIFICATION,
  [NotificationAction.STOCK_OPTION_EXERCISED]: StringKey.STOCK_OPTION_EXERCISED_NOTIFICATION,
  [NotificationAction.WARRANT_EXERCISED]: StringKey.WARRANT_EXERCISED_NOTIFICATION,
  [NotificationAction.HURDLE_EXERCISED]: StringKey.HURDLE_EXERCISED_NOTIFICATION,
  // [NotificationAction.RSA_EXERCISED]: StringKey.RSA_EXERCISED_NOTIFICATION,
  [NotificationAction.SAR_EXERCISED]: StringKey.SAR_EXERCISED_NOTIFICATION,
  [NotificationAction.PHANTOM_SHARES_PAID_OUT]: StringKey.PHANTOM_SHARES_PAID_OUT_NOTIFICATION,
  [NotificationAction.NEW_GRANT_ISSUED]: StringKey.NEW_GRANT_ISSUED_NOTIFICATION,
  [NotificationAction.BULK_GRANT_UPLOAD]: StringKey.BULK_GRANT_UPLOAD_NOTIFICATION,
  [NotificationAction.NEW_SECONDARY_RECORDED]: StringKey.NEW_SECONDARY_RECORDED_NOTIFICATION,
  [NotificationAction.NEW_BUYBACK_RECORDED]: StringKey.NEW_BUYBACK_RECORDED_NOTIFICATION,
  [NotificationAction.NEW_SHARE_ISSUANCE]: StringKey.NEW_SHARE_ISSUANCE_NOTIFICATION,
  [NotificationAction.NEW_FUNDRAISING_ROUND_ADDED]:
    StringKey.NEW_FUNDRAISING_ROUND_ADDED_NOTIFICATION,
  [NotificationAction.NEW_CLASS_CONVERSION]: StringKey.NEW_CLASS_CONVERSION_NOTIFICATION,
  [NotificationAction.NEW_VALUATION_EVENT_ADDED]: StringKey.NEW_VALUATION_EVENT_ADDED_NOTIFICATION,
  [NotificationAction.ACCELERATION_DATE_7_DAYS]: StringKey.ACCELERATION_DATE_NOTIFICATION,
  [NotificationAction.ACCELERATION_DATE_REACHED]: StringKey.ACCELERATION_DATE_NOTIFICATION,
  [NotificationAction.SAFE_MATURITY_DATE]: StringKey.SAFE_MATURITY_DATE_NOTIFICATION,
  [NotificationAction.SAFE_CONVERTED]: StringKey.SAFE_CONVERTED_NOTIFICATION,
  [NotificationAction.STAKEHOLDER_LIMIT_REACHED]: StringKey.STAKEHOLDER_LIMIT_REACHED_NOTIFICATION,
  [NotificationAction.GRANT_EXERCISED]: StringKey.GRANT_EXERCISED_NOTIFICATION,
  [NotificationAction.GRANT_PAID_OUT]: StringKey.GRANT_PAID_OUT_NOTIFICATION,
  [NotificationAction.GRANT_ADDED]: StringKey.GRANT_ADDED_NOTIFICATION,
  [NotificationAction.STAKEHOLDER_NEW_SECONDARY_RECORDED]:
    StringKey.STAKEHOLDER_NEW_SECONDARY_RECORDED_NOTIFICATION,
  [NotificationAction.STAKEHOLDER_NEW_BUYBACK_RECORDED]:
    StringKey.STAKEHOLDER_NEW_BUYBACK_RECORDED_NOTIFICATION,
  [NotificationAction.STAKEHOLDER_NEW_SHARE_ISSUANCE]:
    StringKey.STAKEHOLDER_NEW_SHARE_ISSUANCE_NOTIFICATION,
  [NotificationAction.SHARE_CLASS_CONVERTED]: StringKey.SHARE_CLASS_CONVERTED_NOTIFICATION,
  [NotificationAction.SUCCESSFUL_INVESTMENT]: StringKey.SUCCESSFUL_INVESTMENT_NOTIFICATION,
  [NotificationAction.SHARES_VESTED]: StringKey.SHARES_VESTED_NOTIFICATION,
  [NotificationAction.MILESTONE_APPROVED]: StringKey.MILESTONE_APPROVED_NOTIFICATION,
  [NotificationAction.STAKEHOLDER_SAFE_CONVERTED]:
    StringKey.STAKEHOLDER_SAFE_CONVERTED_NOTIFICATION,
  [NotificationAction.GRANT_ACCELERATION_DATE]: StringKey.GRANT_ACCELERATION_DATE_NOTIFICATION,
};

export const notificationRequestTitle: Record<SharePlanType, StringKey> = {
  [SharePlanType.COMMON_STOCK]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.GROWTH_HURDLE]: StringKey.HURDLE_APPROVAL,
  [SharePlanType.WARRANTS]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.BSPCE]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.PHANTOM]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.RSA]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.RSU]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.SAR]: StringKey.EXERCISE_REQUESTED,
  [SharePlanType.STOCK_OPTIONS]: StringKey.EXERCISE_REQUESTED,
};

export const renderMyHoldingButtonTypes = [
  NotificationAction.GRANT_EXERCISED,
  NotificationAction.GRANT_PAID_OUT,
  NotificationAction.SHARES_VESTED,
  NotificationAction.MILESTONE_APPROVED,
  NotificationAction.STAKEHOLDER_SAFE_CONVERTED,
  NotificationAction.GRANT_ACCELERATION_DATE,
];

export type NotificationStakeholdersRead = {
  id: string;
  notification: Notification;
  stakeholder: Stakeholder;
  notificationId: string;
  stakeholderId: string;
};

export type ApproveRequest = {
  id: string;
  vestingTasks: VestingTask[];
  sharePlan: SharePlan;
  valuation: Valuation;
};

export type AppNotification = {
  id: string;
  company: Company;
  type: NotificationType;
  action?: NotificationAction;

  stakeholderName?: string;
  stakeholderType?: StakeholderType;
  stakeholderId?: string;

  requestId?: string;
  requestSharesCount?: number;
  requestPlan?: string;
  requestPlanType?: SharePlanType;
  requestHurdleValue?: number;
  numberOfGrants?: number;
  requestTotalSharesCount?: number;
  requestNextTriggerTime?: string;
  approveRequestId?: string;
  adminName?: string;
  adminType?: StakeholderType;
  adminId?: string;

  resolved: boolean;
  createdAt: Date;
  companyId: string;
  is_read: boolean;
  exerciseRequest?: ExerciseRequest;
  targetBasedMilestoneRequest?: TargetBasedVestingMilestoneItem;
  milestones?: TargetBasedVestingMilestoneItem[];
  notificationStakeholdersRead: NotificationStakeholdersRead[];

  terminationType?: TerminationCause;
  bulkCount?: number;
  stakeholderTypeGroup?: StakeholderTypeGroup;
  eventName?: string;
  date?: string;
  sharesCount?: number;
  grantId?: string;
  payout?: number;
  payoutIssuedShares?: number;
  sharePlanType?: SharePlanType;
  fromName?: string;
  totalInvestment?: number;
  totalShares?: number;
  sharePrice?: number;
  preMoneyValuation?: number;
  percentage?: number;
};

type NotificationBaseOptions = {
  isAdminYou: boolean;
  adminName: string;
  stakeholderName: string;
  stakeholderType: StakeholderType;
  currencySymbol: string;
};

type ActiveNotificationBaseOptions = {
  action?: NotificationAction;
  terminationType?: TerminationCause;
  bulkCount?: number;
  stakeholderTypeGroup?: StakeholderTypeGroup;
  eventName?: string;
  date?: string;
  sharesCount?: number;
  grantId?: string;
  payout?: number;
  payoutIssuedShares?: number;
  sharePlanType?: SharePlanType;
  fromName?: string;
  totalInvestment?: number;
  totalShares?: number;
  sharePrice?: number;
  preMoneyValuation?: number;
  percentage?: number;
} & NotificationBaseOptions;

type RequestNotificationBaseOptions = {
  planName: string;
  hurdleValue: number;
  planGrunts: number;
  planType: SharePlanType;
  planAvailableCount: number;
  requestedSharesCount: number;
  milestoneName: string;
  milestoneDate: string;
} & NotificationBaseOptions;

const getAdminPart = ({ isAdminYou, adminName }: NotificationBaseOptions) =>
  isAdminYou
    ? `${getTranslation(StringKey.BY).toLowerCase()} ${adminName} (${getTranslation(StringKey.YOU)})`
    : `${getTranslation(StringKey.BY).toLowerCase()} ${adminName}`;

const createStakeholderAddedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} ${getTranslation(StringKey.WAS_ADDED_AS_A)} '${options.stakeholderType && getTranslation(stakeholderTypeTitle[options.stakeholderType])}' ${getAdminPart(options)}`;

const createInvitationAcceptedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} ${getTranslation(StringKey.HAS_ACCEPTED_INVITATION)} ${getAdminPart(options)}`;

const createStakeholderTerminatedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} was Terminated ('${options.terminationType && getTranslation(TerminationCauseTitle[options.terminationType])}') as ${options.stakeholderType && getTranslation(stakeholderTypeTitle[options.stakeholderType])} ${getAdminPart(options)}`;

const createAccessRevokedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} has had access revoked ${getAdminPart(options)}`;

const createRoleChangedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.PERMISSION_CHANGED_TO_CONTRIBUTOR)}`;

const createBulkStakeholderUploadNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.bulkCount} ${options?.stakeholderTypeGroup && getTranslation(stakeholderTypeGroupTranslation[options?.stakeholderTypeGroup])} ${options?.bulkCount && options?.bulkCount > 1 ? getTranslation(StringKey.STAKEHOLDERS_ADDED) : getTranslation(StringKey.STAKEHOLDER_ADDED).toLowerCase()} ${getAdminPart(options)}`;

const createNewShareClassNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.NEW_SHARE_CLASS)} "${options?.eventName}" ${getTranslation(StringKey.HAS_BEEN_ADDED)} ${getAdminPart(options)}`;

const createNewPoolNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.THE_NEW_POOL)} "${options?.eventName}" ${getTranslation(StringKey.HAS_BEEN_ADDED)} ${getAdminPart(options)}`;

const createNewPlanNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.THE_NEW_PLAN)} "${options?.eventName}" ${getTranslation(StringKey.HAS_BEEN_ADDED)} ${getAdminPart(options)}`;

const createWarrantExpiration7DaysNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.WARRANT_PLAN)} "${options?.eventName}" ${getTranslation(StringKey.EXPIRES_IN_7_DAYS)} ${options?.date && format(new Date(options.date), 'dd/MM/yyyy')}. ${getTranslation(StringKey.TAKE_REQUIRED_ACTIONS)}`;

const createWarrantExpirationDateReachedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.EXPIRATION_DATE_FOR_WARRANT)} "${options?.eventName}" ${getTranslation(StringKey.HAS_BEEN_REACHED_AS_OF)} ${options?.date && format(new Date(options.date), 'dd/MM/yyyy')}. ${getTranslation(StringKey.ENSURE_TO_TAKE_ACTION)}`;

const createStockOptionExercisedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.STOCK_OPTION_EXERCISED_NOTIFICATION)} ${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.STOCK_OPTION_GRANTS)} (${options?.grantId?.split('-')[0].toUpperCase()}) ${getTranslation(StringKey.OF)} ${options.stakeholderName} ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)} ${getAdminPart(options)}`;

const createWarrantExercisedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.WARRANT)} (${options?.grantId?.split('-')[0].toUpperCase()}) ${getTranslation(StringKey.OF)} ${options.stakeholderName} ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)} ${getAdminPart(options)}`;

const createHurdleExercisedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.HURDLE_GRANTS)} (${options?.grantId?.split('-')[0].toUpperCase()}) ${getTranslation(StringKey.OF)} ${options.stakeholderName} ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)} ${getAdminPart(options)}`;

// const createRsaExercisedNotification = (options: ActiveNotificationBaseOptions) =>
//   `${options?.sharesCount?.toLocaleString('en-US')} RSA Grants (${options?.grantId?.split('-')[0].toUpperCase()}) of ${options.stakeholderName} ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)} ${getAdminPart(options)}`;

const createSarExercisedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.SARS)} (${options?.grantId?.split('-')[0].toUpperCase()}) ${getTranslation(StringKey.OF)} ${options.stakeholderName} ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)} ${getAdminPart(options)}. ${getTranslation(StringKey.PAYOUT)}: ${options?.payout?.toLocaleString('en-US')}. ${getTranslation(StringKey.ISSUED_SHARES)}: ${options?.payoutIssuedShares?.toLocaleString('en-US')}`;

const createPhantomPayedOutNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.PHANTOM_SHARES)} (${options?.grantId?.split('-')[0].toUpperCase()}) ${getTranslation(StringKey.OF)} ${options.stakeholderName} ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)} ${getAdminPart(options)}. ${getTranslation(StringKey.PAYOUT)}: ${options?.payout?.toLocaleString('en-US')}. ${getTranslation(StringKey.ISSUED_SHARES)}: ${options?.payoutIssuedShares?.toLocaleString('en-US')}`;

const createNewGrantNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.GRANTS_OF)} "${options?.eventName}" ${options?.sharePlanType && getTranslation(sharePlanTypeTitle[options?.sharePlanType])} ${getTranslation(StringKey.HAVE_BEEN_GRANTED)}  ${options.stakeholderName} (${options?.grantId?.split('-')[0].toUpperCase()}) ${getAdminPart(options)}`;

const createBulkGrantUploadNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.bulkCount} ${getTranslation(StringKey.GRANTS_ADDED)} ${getAdminPart(options)}`;

const createNewSecondaryNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.SECONDARY_TRANSACTION_OF)} ${options?.sharesCount?.toLocaleString('en-US')} "${options?.eventName}" ${getTranslation(StringKey.FROM)} ${options?.fromName} ${getTranslation(StringKey.TO)} ${options?.stakeholderName} ${getTranslation(StringKey.HAS_BEEN_RECORDED)} ${getAdminPart(options)}`;

const createNewBuyBackNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.SHARE_BUYBACK_TRANSACTIONS_OF)} ${options?.sharesCount?.toLocaleString('en-US')} "${options?.eventName}" ${getTranslation(StringKey.FROM)} ${options?.stakeholderName} ${getTranslation(StringKey.RECORDED)} ${getAdminPart(options)}`;

const createNewShareIssuanceNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} "${options?.eventName}" ${getTranslation(StringKey.HAVE_BEEN_ISSUED)} ${getTranslation(StringKey.TO)} ${options?.stakeholderName} ${getAdminPart(options)}`;

const createNewFundraisingNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.bulkCount} ${getTranslation(StringKey.INVESTMENTS_HAVE_BEEN_ADDED)} "${options?.eventName}", ${getTranslation(StringKey.BRINGING_TOTAL_INVESTMENTS)} ${options?.totalInvestment?.toLocaleString('en-US')} ${options.currencySymbol}`;

const createNewClassConversionNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')}/${options?.totalShares?.toLocaleString('en-US')} ${getTranslation(StringKey.SHARES_CONVERTED_FROM)} "${options?.fromName}" ${getTranslation(StringKey.TO)} "${options?.eventName}" ${getTranslation(StringKey.FOR)} ${options?.stakeholderName} ${getAdminPart(options)}. ${getTranslation(StringKey.TOTAL_CONVERTED_SHARES)}: ${options?.sharesCount?.toLocaleString('en-US')}`;

const createNewValuationNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.NEW_VALUATION_EVENT)} "${options?.eventName}" ${getTranslation(StringKey.HAS_BEEN_ADDED)} ${getAdminPart(options)} ${getTranslation(StringKey.WITH_SHARE_PRICE)}: ${options?.sharePrice} ${options.currencySymbol} ${getTranslation(StringKey.AND_PRE_MONEY_VALUATION)} ${options?.preMoneyValuation?.toLocaleString('en-US')} ${options.currencySymbol}`;

const createAccelerationDate7DaysNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.REMINDER_ACCELERATION)} "${options?.eventName}" ${getTranslation(StringKey.SET_FOR)} ${options?.date && format(new Date(options.date), 'dd/MM/yyyy')}. ${getTranslation(StringKey.NUMBER_OF_IMPACTED_STAKEHOLDERS)}: ${options?.bulkCount}`;

const createAccelerationDateReachedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.ACCELERATION_NOTIFICATION)} "${options?.eventName}" ${getTranslation(StringKey.HAS_BEEN_TRIGGERED)}. ${getTranslation(StringKey.STAKEHOLDERS_WITH_VESTED_GRANTS)}: ${options?.bulkCount}`;

const createSafeMaturityDateReachedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.SAFE_MATURITY_DATE_FOR)} ${options?.stakeholderName} ${getTranslation(StringKey.IS_REACHED)}. ${getTranslation(StringKey.TAKE_REQUIRED_ACTIONS)}`;

const createSafeConvertedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.SAFE_OF)} ${options?.stakeholderName} ${getTranslation(StringKey.HAS_BEEN_CONVERTED)} ${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.SHARES).toLowerCase()}. ${getTranslation(StringKey.TOTAL_PERCENT_OF_COMPANY_OWNED)}: ${toRound((options?.percentage || 0) * 100, 2)}.`;

const createStakeholderLimitReachedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.STAKEHOLDER_LIMIT_REACHED_NOTIFICATION_TEXT)}`;

const createGrantExercisedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.GRANTS_ASSOCIATED)} "${options?.eventName}" (${options?.sharePlanType && getTranslation(sharePlanTypeTitle[options?.sharePlanType])}) ${getTranslation(StringKey.HAVE_BEEN_EXERCISED)}. `;

const createStakeholderPhantomPayedOutNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.payout?.toLocaleString('en-US')} ${options.currencySymbol} ${getTranslation(StringKey.ASSOCIATED_TO_PLAN)} "${options?.eventName}" (${getTranslation(StringKey.PHANTOM)}) ${getTranslation(StringKey.HAVE_BEEN_PAID_OUT)}. `;

const createGrantAddedStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.YOU_HAVE_BEEN_ISSUED)} ${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.GRANTS_UNDER_PLAN)} "${options?.eventName}" (${options?.sharePlanType && getTranslation(sharePlanTypeTitle[options?.sharePlanType])})`;

const createSecondaryStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.SECONDARY_TRANSACTION_OF)} ${options?.sharesCount?.toLocaleString('en-US')} "${options?.eventName}" ${getTranslation(StringKey.FROM)} ${options?.fromName} ${getTranslation(StringKey.TO_YOU_HAS_BEEN_RECORDED)} ${getAdminPart(options)}`;

const createBuybackStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.SHARE_BUYBACK_TRANSACTIONS_OF)} ${options?.sharesCount?.toLocaleString('en-US')} "${options?.eventName}" ${getTranslation(StringKey.IS_SUCCESSFUL)}`;

const createShareIssuanceStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} "${options?.eventName}" ${getTranslation(StringKey.HAVE_BEEN_ISSUED_TO_YOU)} ${getAdminPart(options)}`;

const createShareClassConversionStakeholderNotification = (
  options: ActiveNotificationBaseOptions,
) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.OF)} "${options?.fromName}" ${getTranslation(StringKey.HAVE_BEEN_SUCCESSFULLY_CONVERTED)} "${options?.eventName}"`;

const createInvestmentStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.YOUR_INVESTMENT_OF)} ${options?.totalInvestment?.toLocaleString('en-US')} ${options.currencySymbol} ${getTranslation(StringKey.HAS_BEEN_RECORDED)}`;

const createTimeBasedVestedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.GRANTS_OF).toLowerCase()} "${options?.eventName}" (${options?.sharePlanType && getTranslation(sharePlanTypeTitle[options?.sharePlanType])}) ${getTranslation(StringKey.HAVE_VESTED_NOTIFICATION)}`;

const createTargetBasedVestedNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.MILESTONE_NOTIFICATION)} "${options?.eventName}" ${getTranslation(StringKey.MILESTONE_HAS_BEEN_APPROVED)}. ${getTranslation(StringKey.AS_A_RESULT)} ${toRound(options?.percentage, 2)}% ${getTranslation(StringKey.OF_YOUR)} ${options?.sharePlanType && getTranslation(sharePlanTypeTitle[options?.sharePlanType])} ${getTranslation(StringKey.REPRESENTING)} ${options?.sharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.SHARES).toLowerCase()} ${getTranslation(StringKey.HAVE_VESTED_NOTIFICATION)}`;

const createSafeConvertedStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.YOUR_SAFE_CONVERTED)} ${options?.sharesCount?.toLocaleString('en-US')}. ${getTranslation(StringKey.TOTAL_PERCENT_OF_COMPANY_OWNED)}: ${toRound((options?.percentage || 0) * 100, 2)}%.`;

const createAccelerationDateStakeholderNotification = (options: ActiveNotificationBaseOptions) =>
  `${getTranslation(StringKey.YOUR_GRANT)} ${options?.grantId?.split('-')[0].toUpperCase()} ${getTranslation(StringKey.OF)} "${options?.eventName}" (${options?.sharePlanType && getTranslation(sharePlanTypeTitle[options?.sharePlanType])}) ${getTranslation(StringKey.GRANT_FULLY_VESTED_NOTIFICATION)}`;

const createExerciseRequestedNotification = (options: RequestNotificationBaseOptions) =>
  `${options.stakeholderName} ${getTranslation(StringKey.HAS_REQUESTED_EXERCISE)} ${options.requestedSharesCount?.toLocaleString('en-US')} ${options.planType && getTranslation(sharePlanTypeTitle[options.planType])} ${getTranslation(StringKey.OUT_OF)} ${options.planAvailableCount} ${getTranslation(StringKey.AVAILABLE).toLowerCase()}`;

const createHurdleApprovalNotification = (options: RequestNotificationBaseOptions) =>
  `${getTranslation(StringKey.HURDLE_PLAN)} ${options.planName} ${getTranslation(StringKey.HAS_EXCEEDED_HURDLE)} ${options.hurdleValue?.toLocaleString('en-US')}. ${options.planGrunts} ${getTranslation(StringKey.GRANTS_PENDING_APPROVAL)}.`;

const createWarrantNotification = (options: RequestNotificationBaseOptions) =>
  `${options.stakeholderName} ${getTranslation(StringKey.HAS_REQUESTED_EXERCISE)} ${options.requestedSharesCount?.toLocaleString('en-US')} ${getTranslation(StringKey.WARRANT)} ${getTranslation(StringKey.OUT_OF)} ${options.planAvailableCount.toLocaleString('en-US')} ${getTranslation(StringKey.AVAILABLE).toLowerCase()}.`;

const createTargetBasedVestingApprovalNotification = (options: RequestNotificationBaseOptions) =>
  `${getTranslation(StringKey.MILESTONE)} ${options.milestoneName} ${getTranslation(StringKey.UNDER_TARGET_BASED)} ${options.planName} (${options.planType && sharePlanTypeTitle[options.planType]}) ${getTranslation(StringKey.HAS_SET_TARGET_DATE)} ${options.milestoneDate && format(options.milestoneDate, 'dd/MM/yyyy')}. ${getTranslation(StringKey.WOULD_YOU_LIKE_APPROVE_TARGET)}`;

const notificationHandlers: Record<
  NotificationAction,
  (options: ActiveNotificationBaseOptions) => string
> = {
  [NotificationAction.STAKEHOLDER_ADDED]: createStakeholderAddedNotification,
  [NotificationAction.INVITATION_ACCEPTED]: createInvitationAcceptedNotification,
  [NotificationAction.STAKEHOLDER_TERMINATED]: createStakeholderTerminatedNotification,
  [NotificationAction.STAKEHOLDER_ROLE_CHANGED]: createRoleChangedNotification,
  [NotificationAction.BULK_STAKEHOLDERS_UPLOAD]: createBulkStakeholderUploadNotification,
  [NotificationAction.NEW_SHARE_CLASS_ADDED]: createNewShareClassNotification,
  [NotificationAction.NEW_POOL_ADDED]: createNewPoolNotification,
  [NotificationAction.NEW_PLAN_ADDED]: createNewPlanNotification,
  [NotificationAction.WARRANT_PLAN_EXPIRATION_7_DAYS]: createWarrantExpiration7DaysNotification,
  [NotificationAction.WARRANT_EXPIRATION_DATE_REACHED]:
    createWarrantExpirationDateReachedNotification,
  [NotificationAction.STOCK_OPTION_EXERCISED]: createStockOptionExercisedNotification,
  [NotificationAction.WARRANT_EXERCISED]: createWarrantExercisedNotification,
  [NotificationAction.HURDLE_EXERCISED]: createHurdleExercisedNotification,
  // [NotificationAction.RSA_EXERCISED]: createRsaExercisedNotification,
  [NotificationAction.SAR_EXERCISED]: createSarExercisedNotification,
  [NotificationAction.PHANTOM_SHARES_PAID_OUT]: createPhantomPayedOutNotification,
  [NotificationAction.NEW_GRANT_ISSUED]: createNewGrantNotification,
  [NotificationAction.BULK_GRANT_UPLOAD]: createBulkGrantUploadNotification,
  [NotificationAction.NEW_SECONDARY_RECORDED]: createNewSecondaryNotification,
  [NotificationAction.NEW_BUYBACK_RECORDED]: createNewBuyBackNotification,
  [NotificationAction.NEW_SHARE_ISSUANCE]: createNewShareIssuanceNotification,
  [NotificationAction.NEW_FUNDRAISING_ROUND_ADDED]: createNewFundraisingNotification,
  [NotificationAction.NEW_CLASS_CONVERSION]: createNewClassConversionNotification,
  [NotificationAction.NEW_VALUATION_EVENT_ADDED]: createNewValuationNotification,
  [NotificationAction.ACCELERATION_DATE_7_DAYS]: createAccelerationDate7DaysNotification,
  [NotificationAction.ACCELERATION_DATE_REACHED]: createAccelerationDateReachedNotification,
  [NotificationAction.SAFE_MATURITY_DATE]: createSafeMaturityDateReachedNotification,
  [NotificationAction.SAFE_CONVERTED]: createSafeConvertedNotification,
  [NotificationAction.STAKEHOLDER_LIMIT_REACHED]: createStakeholderLimitReachedNotification,
  [NotificationAction.GRANT_EXERCISED]: createGrantExercisedNotification,
  [NotificationAction.GRANT_PAID_OUT]: createStakeholderPhantomPayedOutNotification,
  [NotificationAction.GRANT_ADDED]: createGrantAddedStakeholderNotification,
  [NotificationAction.STAKEHOLDER_NEW_SECONDARY_RECORDED]: createSecondaryStakeholderNotification,
  [NotificationAction.STAKEHOLDER_NEW_BUYBACK_RECORDED]: createBuybackStakeholderNotification,
  [NotificationAction.STAKEHOLDER_NEW_SHARE_ISSUANCE]: createShareIssuanceStakeholderNotification,
  [NotificationAction.SHARE_CLASS_CONVERTED]: createShareClassConversionStakeholderNotification,
  [NotificationAction.SUCCESSFUL_INVESTMENT]: createInvestmentStakeholderNotification,
  [NotificationAction.SHARES_VESTED]: createTimeBasedVestedNotification,
  [NotificationAction.MILESTONE_APPROVED]: createTargetBasedVestedNotification,
  [NotificationAction.STAKEHOLDER_SAFE_CONVERTED]: createSafeConvertedStakeholderNotification,
  [NotificationAction.GRANT_ACCELERATION_DATE]: createAccelerationDateStakeholderNotification,
  [NotificationAction.ACCESS_REVOKED]: createAccessRevokedNotification,
};

const ActionNotificationFactory = {
  createActionNotification: (options: ActiveNotificationBaseOptions) => {
    const handler = options?.action && notificationHandlers[options.action];
    if (handler) {
      return handler(options);
    }
    return createAccessRevokedNotification(options);
  },
};
const RequestNotificationFactory = {
  createRequestNotification: (options: RequestNotificationBaseOptions) => {
    switch (options.planType) {
      case SharePlanType.STOCK_OPTIONS:
        return createExerciseRequestedNotification(options);
      case SharePlanType.GROWTH_HURDLE:
        return createHurdleApprovalNotification(options);
      case SharePlanType.WARRANTS:
        return createWarrantNotification(options);
      case SharePlanType.SAR:
        return createExerciseRequestedNotification(options);
      case SharePlanType.BSPCE:
        return createExerciseRequestedNotification(options);
      // case SharePlanType.RSA:
      //   return createExerciseRequestedNotification(options);
      default:
        return createTargetBasedVestingApprovalNotification(options);
    }
  },
};

export const activityNotificationBuilder = ActionNotificationFactory.createActionNotification;
export const requestNotificationBuilder = RequestNotificationFactory.createRequestNotification;
