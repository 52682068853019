import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SafeConversion } from '../../types/safes.types';
import { useAppQuery } from '../useAppQuery';

export const useSafeConversion = ({
  companyId,
  conversionId,
}: {
  companyId: string;
  conversionId: string;
}) => {
  const queryClient = useQueryClient();

  const { data: safeConversion, isLoading } = useAppQuery<SafeConversion>({
    queryKey: [QueryKey.GET_SAFE_CONVERSION, companyId, conversionId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'safes', 'safe-conversion', conversionId],
      }),
    enabled: !!companyId && !!conversionId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SAFE_CONVERSION] });
  }, [queryClient]);

  return {
    invalidateQuery,
    safeConversion,
    isLoading,
  };
};
