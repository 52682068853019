import React, { FC, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';

type DropDownProps = {
  question: string | ReactNode;
  answer: string | ReactNode;
  className?: string;
};
export const DropDown: FC<DropDownProps> = ({ answer, question, className }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      className={twMerge('flex w-full cursor-pointer flex-col p-4 transition-all', className)}
      onClick={() => {
        setOpen((prev) => !prev);
      }}
    >
      <div className="flex w-full items-center justify-between">
        <span className="text-xs font-[450] text-gray-700">{question}</span>
        <div className="h-fit w-fit p-1 transition-colors hover:bg-gray-50">
          <ChevronDownIcon
            className={twMerge('size-4', isOpen ? 'rotate-180' : '', 'transition-transform')}
            iconColor={isOpen ? '#2565C8' : '#344054'}
          />
        </div>
      </div>
      <div
        className={twMerge(
          'grid grid-flow-row transition-all duration-300',
          isOpen ? 'mt-4 grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <span className={twMerge('overflow-hidden text-xs font-normal text-gray-700')}>
          {Array.isArray(answer)
            ? answer.map((paragraph, index) => (
                <p className="mb-1" key={index}>
                  {paragraph}
                </p>
              ))
            : answer}
        </span>
      </div>
    </div>
  );
};
