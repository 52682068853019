import { CompanyModule } from './companyTypes';

export const replaceIdWithPath = (path: string) => {
  return ('/' + path.split('/')[1]) as PageRoute;
};

export enum LandingSolutionPageRoute {
  EQUITY_PLAN_AUTOMATION = 'equity-plan-automation',
  OPTIMIZED_FUNDRAISING_INSTRUMENTS = 'optimized-fundraising-instruments',
  SIMPLIFIED_CAP_TABLE = 'simplified-cap-table',
  ENHANCED_INVESTOR_RELATIONS = 'enhanced-investor-relations',
  EFFORTLESS_SCENARIO_MODELING = 'effortless-scenario-modeling',
}

export enum PageRoute {
  HOME = '/',
  PROFILE = '/profile',
  MY_COMPANIES = '/my-companies',
  COMPANY_SETUP = '/company-setup',
  COMPANY_SETTINGS = '/company-settings',
  DASHBOARD = '/dashboard',
  STAKEHOLDERS = '/stakeholders',
  SHARE_CLASSES = '/share-classes',
  POOL_PLANS = '/pool-plans',
  POOL_PLANS_POOL = '/pool-plans/:poolId',
  POOL_PLANS_PLAN = '/pool-plans/:poolId/:planId',
  EVENTS = '/events',
  CAP_TABLE = '/cap-table',
  MY_HOLDINGS = '/holdings',
  SAFES = '/safes',
  SETTINGS = '/settings',
  ACCEPT_INVITATION = '/accept-invitation/:id',
  LOGIN = '/login',
  REGISTER = '/register',
  REGISTER_VERIFY = '/register/verify/:id',
  FORGOT_PASSWORD = '/forgot-password',
  PASSWORD_RECOVERY = '/password-recovery/:id',
  BILLING = '/billing',
  ALL_DONE = '/all-done',
  HANG_TIGHT = '/hang-tight',
  TERMS = '/terms',
  ONBOARDING = '/onboarding',
  GUIDES = '/guides',
  VAULT = '/vault',
  ERROR = '*',
}

export const pageModule: { [key in PageRoute]?: CompanyModule } = {
  [PageRoute.CAP_TABLE]: CompanyModule.CAP_TABLE,
  [PageRoute.EVENTS]: CompanyModule.EVENTS,
  [PageRoute.MY_HOLDINGS]: CompanyModule.MY_HOLDINGS,
  [PageRoute.POOL_PLANS]: CompanyModule.POOLS_AND_PLANS,
  [PageRoute.STAKEHOLDERS]: CompanyModule.STAKEHOLDER,
  [PageRoute.SHARE_CLASSES]: CompanyModule.SHARE_CLASSES,
};

export const pageTitle: Record<PageRoute, string> = {
  [PageRoute.HOME]: 'My Companies',
  [PageRoute.TERMS]: 'Terms',
  [PageRoute.PROFILE]: 'My Profile',
  [PageRoute.MY_COMPANIES]: 'My Companies',
  [PageRoute.COMPANY_SETUP]: 'Company Setup',
  [PageRoute.COMPANY_SETTINGS]: 'Company Settings',
  [PageRoute.DASHBOARD]: 'Dashboard',
  [PageRoute.STAKEHOLDERS]: 'Stakeholders',
  [PageRoute.EVENTS]: 'Events',
  [PageRoute.MY_HOLDINGS]: 'My Holdings',
  [PageRoute.SAFES]: 'SAFEs',
  [PageRoute.FORGOT_PASSWORD]: 'Forgot Password',
  [PageRoute.SHARE_CLASSES]: 'Share Classes',
  [PageRoute.POOL_PLANS_POOL]: 'Pool Plans - Pool',
  [PageRoute.POOL_PLANS_PLAN]: 'Pool Plans - Plan',
  [PageRoute.POOL_PLANS]: 'Pool Plans',
  [PageRoute.CAP_TABLE]: 'Cap Table',
  [PageRoute.SETTINGS]: 'Settings',
  [PageRoute.ACCEPT_INVITATION]: 'Accept Invitation',
  [PageRoute.LOGIN]: 'Login',
  [PageRoute.REGISTER]: 'Register',
  [PageRoute.REGISTER_VERIFY]: 'Register Verification',
  [PageRoute.PASSWORD_RECOVERY]: 'Password Recovery',
  [PageRoute.BILLING]: 'Billing',
  [PageRoute.ERROR]: 'Error',
  [PageRoute.ALL_DONE]: 'All Done',
  [PageRoute.HANG_TIGHT]: 'Hang Tight',
  [PageRoute.ONBOARDING]: 'Onboarding',
  [PageRoute.GUIDES]: 'Guides',
  [PageRoute.VAULT]: 'The Vault',
};
