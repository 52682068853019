import React, { FC, ReactNode, useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { CloseIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { useReactForm, VestingTaskHooks } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ExerciseType, ExerciseTypeTitle } from '../../../../types/pool-plans.types';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { ChildrenFormProps } from './type';
import { FormSchema, formSchema } from './validation';

export type ExerciseFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  type?: ExerciseType;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
];

export const ExerciseForm: FC<ExerciseFormProps> = ({
  isOpen,
  companyId,
  handleClose,
  invalidateQuery,
  type,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  const { create: exercise } = VestingTaskHooks.useExercise();
  const { create: expire } = VestingTaskHooks.useExpire();

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo } = data;
      const bpsce =
        type === ExerciseType.BSPCE &&
        stepOne.bspce?.bspceItems &&
        stepOne.bspce?.bspceItems.length > 0 &&
        stepOne.bspce?.bspceItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised * (grant?.grantItem?.plan?.conversionRatio || 1),
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const hurdle =
        type === ExerciseType.HURDLE &&
        stepOne.hurdle?.hurdleItems &&
        stepOne.hurdle?.hurdleItems.length > 0 &&
        stepOne.hurdle?.hurdleItems
          .filter(({ selected }) => selected)
          .map(({ issuedShares, grantId: vestingTaskId }) => ({
            vestingTaskId,
            value: issuedShares,
            filesLinks:
              stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
          }));

      const phantom =
        type === ExerciseType.PHANTOM &&
        stepOne.phantom?.phantomItems &&
        stepOne.phantom?.phantomItems.length > 0 &&
        stepOne.phantom?.phantomItems.map(({ phantomShares, grant, sharePrice }) => ({
          vestingTaskId: grant.id,
          value: phantomShares,
          payout: phantomShares * sharePrice,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      // const rsa =
      //   type === ExerciseType.RSA &&
      //   stepOne.rsa?.rsaItems &&
      //   stepOne.rsa?.rsaItems.length > 0 &&
      //   stepOne.rsa?.rsaItems.map(({ exercised, grant }) => ({
      //     vestingTaskId: grant.id,
      //     value: exercised * (grant.grantItem.plan.conversionRatio || 1),
      //     filesLinks:
      //       stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
      //   }));

      const sar =
        type === ExerciseType.SAR &&
        stepOne.sar?.sarItems &&
        stepOne.sar?.sarItems.length > 0 &&
        stepOne.sar?.sarItems.map(({ sarCount, grant, sarPayout }) => ({
          vestingTaskId: grant.id,
          value: sarCount,
          payout: sarPayout,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const stock =
        type === ExerciseType.STOCK_OPTIONS &&
        stepOne.commonStock?.stockItems &&
        stepOne.commonStock.stockItems.length > 0 &&
        stepOne.commonStock?.stockItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised * (grant?.grantItem?.plan?.conversionRatio || 1),
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const warrantExercise =
        type === ExerciseType.WARRANTS &&
        stepOne.warrantExercise?.warrantExerciseItems &&
        stepOne.warrantExercise.warrantExerciseItems.length > 0 &&
        stepOne.warrantExercise?.warrantExerciseItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised * (grant?.grantItem?.plan?.conversionRatio || 1),
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const warrantExpire =
        type === ExerciseType.WARRANTS &&
        stepOne.warrantExpire?.warrantExpireItems &&
        stepOne.warrantExpire?.warrantExpireItems.length > 0 &&
        stepOne.warrantExpire?.warrantExpireItems.map(({ expired, grant }) => ({
          vestingTaskId: grant.id,
          value: expired,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const exerciseData = bpsce || hurdle || phantom || sar || stock || warrantExercise;
      if (exerciseData) {
        exercise(
          {
            companyId,
            data: exerciseData,
          },
          {
            onSuccess: () => {
              toast.success(<AppFormattedMessage id={StringKey.EXERCISED_SUCCESSFULLY} />);
              invalidateQuery();
              handleCloseModal();
            },
          },
        );
      } else if (warrantExpire) {
        expire(
          {
            companyId,
            data: warrantExpire,
          },
          {
            onSuccess: () => {
              toast.success(<AppFormattedMessage id={StringKey.EXPIRED_SUCCESSFULLY} />);
              invalidateQuery();
              handleCloseModal();
            },
          },
        );
      }
    },
    [companyId, exercise, expire, handleCloseModal, invalidateQuery, type],
  );

  const hurdlePlanId = useWatch({ control, name: 'stepOne.hurdle.planId' });
  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <Sheet open={isOpen}>
      <SheetContent
        className={twMerge(
          'h-fit max-h-[800px] w-[90%] max-w-[1422px] border-transparent bg-transparent p-2 shadow-none',
          type === ExerciseType.HURDLE && 'w-fit min-w-[490px]',
        )}
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div
          className={twMerge(
            'flex h-fit w-full flex-col gap-8 rounded-2xl bg-white p-8',
            type === ExerciseType.HURDLE && !hurdlePlanId && 'gap-1',
          )}
        >
          <div className="flex h-fit w-full items-center justify-between">
            <span
              className={twMerge(
                'text-xl font-semibold text-gray-700',
                type === ExerciseType.HURDLE && !hurdlePlanId && 'text-[17px]',
              )}
            >
              {type === ExerciseType.HURDLE && !hurdlePlanId ? (
                <AppFormattedMessage id={StringKey.SELECT_HURDLE_TO_APPROVE} />
              ) : type ? (
                <AppFormattedMessage id={ExerciseTypeTitle[type]} />
              ) : (
                <>
                  <AppFormattedMessage id={StringKey.EXERCISE} />
                </>
              )}
            </span>
            <Button
              className="flex size-7 items-center justify-center rounded-full"
              onClick={handleCloseModal}
              style={{
                border: '0.78px solid #F2F2F2',
                boxShadow: ' 0px 0px 18.67px 0px #00000014',
              }}
              styleType="NONE"
            >
              <CloseIcon />
            </Button>
          </div>
          <div className="flex h-full max-h-[520px] flex-col overflow-y-auto">
            {formSteps[currentStep - 1]({
              companyId,
              formData: getValues,
              nextFormStep: handleNextStep,
              prevFormStep: handlePrevStep,
              setFormData: setValue,
              control,
              clearErrors,
              handleCloseModal,
              setError,
              filedState: getFieldState,
              errors,
              type,
              resetField,
              reset,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
