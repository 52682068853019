import React, { FC, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CloseIcon } from '../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { BillingPlanCard, SelectButtonTextVariant } from '../../../../components/BillingPlanCard';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { HttpException } from '../../../../exceptions';
import { Plan as PlanHook, useAppQuery, useLocale, useModalState } from '../../../../hooks';
import { Locale, StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import {
  enterpriseBenefits,
  growthBenefits,
  startUpBenefits,
} from '../../../../translations/planTranslation';
import { SubscriptionStatus } from '../../../../types/companyTypes';
import { Plan, SubscriptionDuration, SubscriptionType } from '../../../../types/planTypes';
import { Stakeholder } from '../../../../types/stakeholderTypes';
import { PlanChangeWarningModal } from '../PlanChangeWarningModal';

export type ChangeBillingPlanModalProps = {
  isOpenModal: boolean;
  onClose: () => void;
  onSuccess?: (props: Plan) => void;
  handleChangePlan: (props: Plan) => void;
  handleSwitchPlan: (props: Plan) => void;
  handleOpenEnterpriseModal: () => void;
  selectedPlan: Plan | undefined;
  companyId: string;
  isEnterprise: boolean;
  renewDate: number;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  stripePlanDuration?: SubscriptionDuration;
  stripePlanType?: SubscriptionType;
  isPending: boolean;
  subscriptionStatus: SubscriptionStatus;
};

const cardAvgPxWidth = 300;
const cardListPxGap = 16;

type GetCompaniesStakeholderResponse = {
  stakeholders: Stakeholder[];
  totalPages: number;
  totalStakeholders: number;
};

const ChangeBillingPlanModal: FC<ChangeBillingPlanModalProps> = ({
  onClose,
  selectedPlan,
  handleSwitchPlan,
  handleChangePlan,
  handleOpenEnterpriseModal,
  isOpenModal,
  companyId,
  isEnterprise,
  renewDate,
  stripePlanDuration,
  stripePlanType,
  isPending,
  currentPeriodEnd,
  currentPeriodStart,
  subscriptionStatus,
}) => {
  const { messagesLocale, locale } = useLocale();
  const { data: stakeholdersData, isLoading } = useAppQuery<
    GetCompaniesStakeholderResponse,
    HttpException
  >({
    queryKey: [QueryKey.GET_STAKEHOLDERS],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders'] },
        {
          queryParams: {
            take: 0,
            skip: 0,
          },
        },
      ),
    enabled: !!companyId,
  });

  const totalStakeholders = stakeholdersData?.totalStakeholders;

  const [selectedPlanDuration, setSelectedPlanDuration] = useState(
    stripePlanDuration || selectedPlan?.duration || SubscriptionDuration.MONTHLY,
  );
  const { plans } = PlanHook.usePlans();

  const sortedData = plans?.reduce<Record<SubscriptionDuration, Plan[]>>(
    (acc, plan) => {
      const planDuration = plan.duration;
      if (!acc[planDuration]) {
        acc[planDuration] = [];
      }
      acc[planDuration].push(plan);
      return acc;
    },
    {} as Record<SubscriptionDuration, Plan[]>,
  );

  const planListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = planListRef.current;
    const selectedCardBillingCardId =
      plans?.findIndex((plan) => plan.title === selectedPlan?.title) || 0;

    const result = (selectedCardBillingCardId - 1) * cardAvgPxWidth;

    element?.scrollTo({
      left: result,
      behavior: 'smooth',
    });
  }, [plans, selectedPlan?.title]);

  const handleCardMode = ({
    planVariant,
    selectedPlan,
    onSwitch,
    onBlockedStakeholders,
    onBlockedMonth,
    onSelected,
    onUpgrade,
    onDowngrade,
  }: {
    selectedPlan: Plan | undefined;
    planVariant: Plan;
    onSelected: () => keyof typeof SelectButtonTextVariant | void;
    onUpgrade: () => keyof typeof SelectButtonTextVariant | void;
    onBlockedStakeholders: () => keyof typeof SelectButtonTextVariant | void;
    onBlockedMonth: () => keyof typeof SelectButtonTextVariant | void;
    onSwitch: () => keyof typeof SelectButtonTextVariant | void;
    onDowngrade: () => keyof typeof SelectButtonTextVariant | void;
  }) => {
    if (
      stripePlanDuration === SubscriptionDuration.ANNUALY &&
      planVariant.duration === SubscriptionDuration.MONTHLY
    )
      return onBlockedMonth();

    if (
      (totalStakeholders || 0) <= planVariant.stakeholdersCount &&
      stripePlanType !== SubscriptionType.START_UP &&
      stripePlanType !== planVariant.type
    )
      return onDowngrade();

    if (stripePlanDuration === SubscriptionDuration.MONTHLY && stripePlanType === planVariant.type)
      return onSwitch();

    if (stripePlanType === planVariant.type) return onSelected();

    if ((selectedPlan?.stakeholdersCount || 0) > planVariant.stakeholdersCount)
      return onBlockedStakeholders();

    return onUpgrade();
  };

  const {
    toggler: planChangeWarningModalToggler,
    isOpen: isOpenPlanChangeWarningModal,
    handleOpenModal: handleOpenPlanChangeWarningModal,
    handleCloseModal: handleClosePlanChangeWarningModal,
    modalData: planChangeWarningModalData,
  } = useModalState<Plan>();

  const handleSpecialClick = () => {
    onClose();
    handleOpenEnterpriseModal();
  };

  if (isLoading) return <Loader />;

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[1051px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={onClose}
        side="CENTER"
      >
        <AlertDialogWrapper
          control={{
            onOpenChange: planChangeWarningModalToggler,
            open: isOpenPlanChangeWarningModal,
          }}
        >
          <PlanChangeWarningModal
            currentPeriodEnd={currentPeriodEnd}
            currentPeriodStart={currentPeriodStart}
            handleChangePlan={handleChangePlan}
            handleClose={handleClosePlanChangeWarningModal}
            isPending={isPending}
            newPlan={planChangeWarningModalData as Plan | undefined}
            renewDate={renewDate}
            selectedPlan={selectedPlan}
            subscriptionStatus={subscriptionStatus}
          />
        </AlertDialogWrapper>
        <div className="relative flex w-full max-w-fit flex-col gap-10 rounded-2xl bg-white p-3 py-4 shadow-xl max-lg:pt-20 lg:p-10">
          <div className="m-auto flex h-11 w-full min-w-[330px] max-w-fit gap-1 rounded-full border-2 border-brand-700 p-[2px]">
            <Button
              className={twMerge(
                'w-fit gap-1 text-nowrap rounded-full bg-white p-2 text-sm font-[550] text-brand-900',
                selectedPlanDuration === SubscriptionDuration.MONTHLY && 'bg-brand-700 text-white',
              )}
              onClick={() => setSelectedPlanDuration(SubscriptionDuration.MONTHLY)}
              styleType="NONE"
            >
              <AppFormattedMessage id={StringKey.PAY_MONTHLY} />
            </Button>
            <Button
              className={twMerge(
                'w-full gap-1 text-nowrap rounded-full bg-white p-2 text-sm font-[550] text-brand-900',
                selectedPlanDuration === SubscriptionDuration.ANNUALY && 'bg-brand-700 text-white',
              )}
              onClick={() => setSelectedPlanDuration(SubscriptionDuration.ANNUALY)}
              styleType="NONE"
            >
              <AppFormattedMessage id={StringKey.PAY_YEARLY} />
              <span
                className={twMerge(
                  'text-sm font-[550] text-forest-600',
                  selectedPlanDuration === SubscriptionDuration.ANNUALY && 'text-white',
                  locale === Locale.FR && 'max-lg:hidden',
                )}
              >
                <AppFormattedMessage id={StringKey.SAVE_APPROX} />
              </span>
            </Button>
          </div>
          <div
            className="flex overflow-x-auto"
            ref={planListRef}
            style={{ gap: `${cardListPxGap}px` }}
          >
            {sortedData?.[selectedPlanDuration]
              .sort((a, b) => a.stakeholdersCount - b.stakeholdersCount)
              .map((plan, i) => (
                <BillingPlanCard
                  key={plan.id}
                  {...plan}
                  cardMode={
                    (selectedPlan &&
                      handleCardMode({
                        selectedPlan,
                        planVariant: plan,
                        onBlockedStakeholders: () => 'BLOCKED_STAKEHOLDERS',
                        onBlockedMonth: () => 'BLOCKED_MONTH',
                        onSelected: () => 'SELECTED',
                        onSwitch: () => 'CAN_SWITCH',
                        onUpgrade: () => 'CAN_CHANGE',
                        onDowngrade: () => 'CAN_DOWNGRADE',
                      })) ||
                    'DEFAULT'
                  }
                  includes={i === 0 ? '' : <AppFormattedMessage id={StringKey.INCLUDES_STARTUP} />}
                  listOfBenefits={i === 0 ? startUpBenefits : growthBenefits}
                  onSelectClick={(plan) => {
                    handleCardMode({
                      selectedPlan,
                      planVariant: plan,
                      onBlockedStakeholders: () => {},
                      onBlockedMonth: () => {},
                      onSelected: () => {},
                      onDowngrade: () => {
                        handleOpenPlanChangeWarningModal(plan);
                      },
                      onSwitch: () => {
                        const annualPlan = sortedData.annualy.find(
                          ({ title }) => title === plan.title,
                        );
                        if (!annualPlan) return;
                        handleSwitchPlan(annualPlan);
                      },
                      onUpgrade: () => handleOpenPlanChangeWarningModal(plan),
                    });
                  }}
                  selectSpecialText={
                    selectedPlan?.title === plan.title &&
                    stripePlanDuration === SubscriptionDuration.MONTHLY
                      ? messagesLocale[StringKey.SAVE_APPROX_W_ANNUAL_BILLING]
                      : ''
                  }
                />
              ))}
            <BillingPlanCard
              cardMode="SPECIAL"
              cardStyleType="SPECIAL"
              description={<AppFormattedMessage id={StringKey.FOR_CUSTOM_PRICING} />}
              includes={<AppFormattedMessage id={StringKey.INCLUDES_GROWTH} />}
              isEnterprise={isEnterprise}
              listOfBenefits={enterpriseBenefits}
              onSpecialSelectClick={
                isEnterprise
                  ? () => (window.location.href = 'mailto:contact@capquest.io')
                  : handleSpecialClick
              }
              selectButtonStyleType="SPECIAL"
              specialMessage={messagesLocale[StringKey.LETS_TALK]}
              title={<AppFormattedMessage id={StringKey.ENTERPRISE} />}
              type={SubscriptionType.ENTERPRISE}
            />
          </div>
          <Button
            className="absolute right-4 top-4 flex size-8 items-center justify-center rounded-full shadow-sm"
            onClick={onClose}
            styleType="NONE"
          >
            <CloseIcon iconColor="#172335" />
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

ChangeBillingPlanModal.displayName = 'ChangeBillingPlanModal';

export default ChangeBillingPlanModal;
