import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';

import MinusIcon from '../../../assets/icons/MinusIcon';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Checkbox } from '../../../components/Checkbox';
import { StringKey } from '../../../lang';
import { DetailsTab, Safe, SafeOrder, SafeSortBy } from '../../../types/safes.types';
import { SafeTableItem } from './SafeTableItem';
import { TableSortingCell } from './TableSortingCell';

export type DesktopDetailsProps = {
  safes: Safe[];
  isSelectStarted: boolean;
  isSelected: (id: string) => boolean;
  onSelectAll: (id: string[]) => void;
  onRemoveSelectAll: () => void;
  onSortClick: (field: SafeSortBy) => void;
  onSelect: (id: string) => void;
  onRemoveSelect: (id: string) => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  handleConvert: (id: string) => void;
  sortMode: SafeOrder | null;
  sortField: SafeSortBy | null;
  selectedTab: DetailsTab;
  handleViewConversion: (id: string) => void;
};

export const DesktopDetails: FC<DesktopDetailsProps> = ({
  safes,
  isSelectStarted,
  isSelected,
  onSelectAll,
  onRemoveSelectAll,
  onSortClick,
  onSelect,
  onRemoveSelect,
  handleView,
  handleEdit,
  handleDelete,
  handleConvert,
  sortMode,
  sortField,
  selectedTab,
  handleViewConversion,
}) => {
  return (
    <table className="h-fit w-full divide-y-[1px] divide-[#F2F2F2] border-b-[1px] border-[#F2F2F2] max-lg:hidden">
      <colgroup>
        <col className="w-12 shrink-0" span={1} />
      </colgroup>
      <thead>
        <tr className="h-11 bg-gray-50">
          {selectedTab === DetailsTab.PENDING_CONVERSION && (
            <td className="relative">
              <div className="flex h-full w-full items-center justify-center px-4">
                <Field className="flex cursor-pointer items-center">
                  <Checkbox
                    checked={isSelectStarted}
                    id="select-all"
                    onChange={(checked) =>
                      checked ? onSelectAll(safes?.map(({ id }) => id) || []) : onRemoveSelectAll()
                    }
                    selectIcon={<MinusIcon />}
                  />
                  <Label
                    className="absolute left-0 top-0 h-full w-full cursor-pointer"
                    htmlFor="select-all"
                  />
                </Field>
              </div>
            </td>
          )}

          <td className="pl-4">
            <TableSortingCell
              onSortClick={onSortClick}
              sortByCell={SafeSortBy.FULL_NAME}
              sortField={sortField}
              sortMode={sortMode}
              title={<AppFormattedMessage id={StringKey.NAME} />}
            />
          </td>

          <td className="pl-4 text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.INVESTMENT} />
          </td>

          {selectedTab === DetailsTab.PENDING_CONVERSION && (
            <td className="pl-4">
              <TableSortingCell
                onSortClick={onSortClick}
                sortByCell={SafeSortBy.ISSUE_DATE}
                sortField={sortField}
                sortMode={sortMode}
                title={<AppFormattedMessage id={StringKey.ISSUE_DATE} />}
              />
            </td>
          )}

          {selectedTab === DetailsTab.PENDING_CONVERSION ? (
            <>
              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.CAP_VALUE}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.VAL_CAP} />}
                />
              </td>

              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.DISCOUNT}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.SHARE_PRICE_DISCOUNT_PERCENTAGE} />}
                />
              </td>

              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.FLOOR}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.FLOOR} />}
                />
              </td>

              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.MATURITY_DATE}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.MATURITY_DATE} />}
                />
              </td>
            </>
          ) : (
            <>
              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.CONVERSION_DATE}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.CONVERSION_DATE} />}
                />
              </td>

              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.CONVERSION_METHOD}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.CONVERSION_METHOD} />}
                />
              </td>

              <td className="pl-4 text-label-md font-[450] text-[#172335]">
                <AppFormattedMessage id={StringKey.LINKED_EVENT} />
              </td>

              <td className="pl-4 text-label-md font-[450] text-[#172335]">
                <AppFormattedMessage id={StringKey.SHARE_PRICE} />
              </td>

              <td className="pl-4">
                <TableSortingCell
                  onSortClick={onSortClick}
                  sortByCell={SafeSortBy.CONVERTED_SHARES}
                  sortField={sortField}
                  sortMode={sortMode}
                  title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                />
              </td>
            </>
          )}

          <td></td>
        </tr>
      </thead>
      <tbody className="divide-y-[1px] divide-[#F2F2F2] bg-gray-600">
        {safes?.map((safe) => (
          <SafeTableItem
            handleConvert={handleConvert}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleView={handleView}
            handleViewConversion={handleViewConversion}
            isSelected={isSelected}
            key={safe.id}
            onRemoveSelect={onRemoveSelect}
            onSelect={onSelect}
            safe={safe}
            selectedTab={selectedTab}
          />
        ))}
      </tbody>
    </table>
  );
};
