import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { FormDropDown } from '../FormDropDown';
import { SubEventFileUpload } from '../SubEventFileUpload';
import { ChildrenFormProps } from '../type';
import { FormSchema } from '../Validation';
export type StepTwoProps = ChildrenFormProps;

export const StepTwo: FC<StepTwoProps> = ({
  handleCloseModal,
  control,
  setFormData,
  nextFormStep,
  prevFormStep,
  formData,
  clearErrors,
  setError,
  errors: { stepOne: errors },
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <span className="text-xs font-[450] text-gray-500">
          <AppFormattedMessage id={StringKey.DOCUMENTS_UPLOAD} />
        </span>
        {stepOne?.safes?.map((safe, index) => (
          <FormDropDown key={safe.id} title={safe.stakeholder?.fullName || ''}>
            <SubEventFileUpload
              clearErrors={clearErrors}
              errors={errors?.safes?.[index]?.files || undefined}
              files={stepOne?.safes?.[index]?.files || []}
              formData={formData}
              index={index}
              setError={setError}
              setFormData={setFormData}
            />
          </FormDropDown>
        ))}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.NEXT} />
          </Button>
        </div>
      </div>
    </>
  );
};
