import React, { FC, useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import {
  CloseIcon,
  CloudUploadIcon,
  InfoCircle,
  UploadedFileIcon,
  UploadIcon,
} from '../../../assets/icons';
import LoaderIcon from '../../../assets/icons/LoaderIcon';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { BackendRoute } from '../../../config';
import { useLocale, useModalState, useReactForm, VaultHooks } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { Repository, VaultSelect } from '../../../types/vault.types';
import { RenameModal } from './RenameModal';
import { FormSchema, formSchema } from './validation';

export type AddFileModalProps = {
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  isOpenModal: boolean;
  selectedFolderId: string | undefined;
  data: Repository;
  isOpenRepository: boolean;
};

export const AddFileModal: FC<AddFileModalProps> = ({
  companyId,
  handleClose,
  invalidateQuery,
  isOpenModal,
  selectedFolderId,
  data,
  isOpenRepository,
}) => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  const filesNames = data?.files?.map((file) => file.name);

  const { files } = useWatch<FormSchema>({ control });
  const { messagesLocale, messagesLocaleFn } = useLocale();
  const [filesToRename, setFilesToRename] = useState<
    { link: string; name: string; oldName: string }[]
  >([]);

  const addFileToRename = useCallback((link: string, name: string, oldName: string) => {
    setFilesToRename((prev) => [...prev, { link, name, oldName }]);
  }, []);

  const removeFileToRename = useCallback((nameToRemove: string | undefined) => {
    setFilesToRename((prev) => prev.filter((file) => file.oldName !== nameToRemove));
  }, []);

  const isValid = files && (files.some(({ docLink }) => docLink) || files.length > 0);
  const uploadedFiles = files
    ? files.reduce((prev, curr) => (curr.docLink ? prev + 1 : prev), 0)
    : 0;
  const isReachedUploadMaximum = isOpenRepository ? false : uploadedFiles >= 20;
  const getStepTwoFilesData = useCallback(() => getValues('files') || [], [getValues]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((e) => {
            if (e.code === 'file-invalid-type') {
              setError('files', {
                type: 'manual',
                message: `${messagesLocale[StringKey.INVALID_FILE_TYPE].replace('{fileType}', file.name)}`,
              });
            }
          });
        });
        return;
      }

      if (isReachedUploadMaximum) return;
      const existingFiles = getStepTwoFilesData();
      const existingFileNames = existingFiles.map((file) => file.doc.name);
      const duplicateFiles = acceptedFiles.filter((file) => existingFileNames.includes(file.name));

      if (duplicateFiles.length > 0) {
        setError('files', {
          type: 'manual',
          message: `${duplicateFiles[0].name} is already exists`,
        });
        return;
      }

      const filesToUpload = isOpenRepository
        ? acceptedFiles
        : acceptedFiles.slice(0, 20 - existingFiles.length);

      filesToUpload.forEach(async (file) => {
        const { callback, abort } = ApiService.uploadFile<string>();
        const id = uuidv4();
        const { error, success } = formSchema.safeParse({
          files: [{ doc: file, docLink: '', loadProgress: 1, abort, id }],
        });

        if (!success) {
          setError('files', error.errors[0]);
          return;
        }

        clearErrors('files');

        const existingFiles = getValues('files') || [];

        setValue(
          'files',
          [...existingFiles, { doc: file, docLink: '', loadProgress: 1, abort, id }],
          { shouldValidate: true },
        );

        const link = await callback(
          { endpoint: BackendRoute.SHARE_CLASSES, routePath: ['file'] },
          ['file', file],
          {
            onUploadProgress: (n) => {
              const currentFiles = getValues('files') || [];
              setValue(
                'files',
                currentFiles.map((f) => (f.id === id ? { ...f, loadProgress: n } : f)),
              );
            },
            onAboard: () => {
              const currentFiles = getValues('files') || [];
              setValue(
                'files',
                currentFiles.filter((f) => f.id !== id),
              );
            },
          },
        );

        if (!link) return;
        const currentFiles = getValues('files') || [];
        setValue(
          'files',
          currentFiles.map((f) => (f.id === id ? { ...f, loadProgress: 100, docLink: link } : f)),
        );
      });
    },
    [
      clearErrors,
      getStepTwoFilesData,
      getValues,
      isOpenRepository,
      isReachedUploadMaximum,
      messagesLocale,
      setError,
      setValue,
    ],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    multiple: true,
    disabled: isReachedUploadMaximum,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
      'text/csv': ['.csv'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const handleDeleteFile = useCallback(
    (id: string) => {
      const current = (getValues('files') || []).find((file) => file.id === id);
      removeFileToRename(current?.doc.name);

      if (current && !current.docLink) current.abort();

      const currentData = (getValues('files') || []).filter((file) => file.id !== id);
      setValue('files', currentData);
      if (currentData.length === 0) return clearErrors('files');
    },
    [clearErrors, getValues, removeFileToRename, setValue],
  );

  const handleCloseModal = useCallback(() => {
    reset();
    setFilesToRename([]);
    handleClose();
  }, [reset, handleClose]);

  const { create } = VaultHooks.useCreateFile();

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const updatedFiles =
        data?.files?.map((file) => {
          const fileToRename = filesToRename.find((fr) => fr.link === file.docLink);
          return fileToRename
            ? { link: fileToRename.link, name: fileToRename.name }
            : { link: file.docLink, name: file?.doc?.name };
        }) || [];
      create(
        {
          companyId,
          data: {
            files: updatedFiles,
            folderId: selectedFolderId || undefined,
          },
        },
        {
          onSuccess: () => {
            toast.success(
              data?.files?.length > 1
                ? messagesLocaleFn(StringKey.FILES_SUCCESSFULLY_UPLOADED, {
                    count: data?.files?.length,
                  })
                : messagesLocaleFn(StringKey.FILE_SUCCESSFULLY_UPLOADED),
            );
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [
      companyId,
      create,
      filesToRename,
      handleCloseModal,
      invalidateQuery,
      messagesLocaleFn,
      selectedFolderId,
    ],
  );

  const {
    toggler: renameModalToggler,
    isOpen: isOpenRenameModal,
    handleOpenModal: handleOpenRenameModal,
    handleCloseModal: handleCloseRenameModal,
    handleSuccessModal: handleSuccessRenameModal,
    modalData: currentName,
  } = useModalState<string | undefined, unknown, string>({
    onSuccess: (name?: string) => {
      if (name && currentName) {
        const currentFile = files?.find((file) => file.doc?.name === currentName);

        if (currentFile) {
          addFileToRename(currentFile.docLink || '', name, currentName || '');
        }
      }
    },
  });

  const isNamesDuplicates = files?.some(
    ({ doc }) =>
      doc?.name &&
      filesNames?.includes(doc?.name) &&
      !filesToRename.some((fr) => fr.oldName === doc?.name),
  );

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper control={{ onOpenChange: renameModalToggler, open: isOpenRenameModal }}>
          <RenameModal
            currentName={currentName}
            onClose={handleCloseRenameModal}
            onSuccess={handleSuccessRenameModal}
            type={VaultSelect.FILE}
          />
        </AlertDialogWrapper>

        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.UPLOAD_FILES_DOCUMENTS} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>

          <div className="flex h-full flex-col gap-4 overflow-hidden">
            <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
              <span className="text-xs font-[450] text-gray-500">
                <AppFormattedMessage id={StringKey.DOCUMENTS_UPLOAD} />
              </span>
              <input
                {...getInputProps()}
                accept=".pdf, .doc, .docx, .xlsx, .xls, .csv, .png, .jpeg"
                className="hidden"
                multiple
                type="file"
              />
              <label
                {...getRootProps()}
                className={twMerge(
                  'relative flex w-full items-center gap-6 rounded-lg border border-dashed border-gray-200 p-6',
                  isReachedUploadMaximum ? 'cursor-auto' : 'cursor-pointer',
                )}
              >
                {errors?.files ? (
                  <InfoCircle className="size-14" thinIconVariant />
                ) : (
                  <CloudUploadIcon />
                )}
                <div className="flex flex-col gap-2">
                  <div className="flex">
                    {errors?.files ? (
                      <span className="text-sm font-[550] text-fireside-600">
                        {errors?.files.message?.toString()}
                      </span>
                    ) : (
                      <p className="text-sm font-medium text-gray-700">
                        <span className="underline">
                          <AppFormattedMessage id={StringKey.CLICK_TO_UPLOAD} />{' '}
                        </span>
                        <AppFormattedMessage id={StringKey.OR_DRAG_AND_DROP} />
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col gap-1 text-label-sm font-[450] text-gray-500">
                    <span>
                      <AppFormattedMessage
                        id={StringKey.SUPPORTED_FORMATS}
                        values={{
                          formats: 'doc/docx, xls/xlsx, csv, jpeg, png or pdf',
                        }}
                      />
                    </span>
                    <span>
                      <AppFormattedMessage id={StringKey.MAXIMUM_FILE_SIZE} values={{ size: 10 }} />
                    </span>
                  </div>
                  {errors?.files && (
                    <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
                      <UploadIcon />
                      <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
                    </span>
                  )}
                </div>
              </label>
              {(files?.length || 0) > 0 && (
                <div className="flex flex-col gap-2">
                  <span className="pb-2 text-xs font-[450] text-gray-500">
                    {uploadedFiles > 1 ? (
                      <AppFormattedMessage
                        id={StringKey.COUNT_FILES_UPLOADED}
                        values={{ count: uploadedFiles }}
                      />
                    ) : (
                      <AppFormattedMessage id={StringKey.ONE_FILE_UPLOADED} />
                    )}
                  </span>
                  {files?.map(({ doc, docLink, loadProgress, id }, i) => (
                    <div className="flex flex-col gap-2">
                      <div
                        className="flex flex-col overflow-hidden rounded border border-brand-50 bg-brand-25"
                        key={`${docLink}_${id}`}
                      >
                        <div className="flex justify-between p-3" key={`${doc?.name}_${i}`}>
                          <div className="flex items-center gap-2 overflow-hidden">
                            {docLink ? (
                              doc?.name &&
                              filesNames?.includes(doc?.name) &&
                              !filesToRename.some((fr) => fr.oldName === doc?.name) ? (
                                <InfoCircle className="size-4" />
                              ) : (
                                <UploadedFileIcon />
                              )
                            ) : (
                              <LoaderIcon className="size-4" />
                            )}
                            <span className="truncate text-xs font-[450] text-gray-700">
                              {filesToRename.find((fr) => fr.oldName === doc?.name)?.name ||
                                doc?.name}
                            </span>
                          </div>
                          <Button
                            className="w-fit p-1 hover:bg-gray-50"
                            onClick={() => handleDeleteFile(id || '')}
                            styleType="NONE"
                            type="button"
                          >
                            <CloseIcon className="size-2" />
                          </Button>
                        </div>
                        <div
                          className={twMerge(
                            'h-[2px] shrink-0 bg-brand-700 transition-all duration-700',
                            docLink && 'bg-transparent',
                          )}
                          style={{ width: (loadProgress || (docLink && 100)) + '%' }}
                        />
                      </div>
                      {doc?.name &&
                        filesNames?.includes(doc?.name) &&
                        !filesToRename.some((fr) => fr.oldName === doc?.name) && (
                          <div className="flex items-center gap-2 pl-3">
                            <span className="text-xs font-[450] text-fireside-600">
                              <AppFormattedMessage id={StringKey.FILE_EXISTS} />
                            </span>
                            <Button
                              className="w-fit p-0 text-xs font-[450] text-brand-700 underline"
                              onClick={() => handleOpenRenameModal(doc?.name)}
                              styleType="NONE"
                            >
                              <AppFormattedMessage id={StringKey.RENAME_FILE} />
                            </Button>
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex h-9 w-full justify-between gap-3 px-6">
              <Button
                className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
                onClick={handleCloseModal}
                styleType="NONE"
              >
                <AppFormattedMessage id={StringKey.CANCEL} />
              </Button>
              <div className="flex gap-3">
                <Button
                  className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
                  disabled={!isValid || isNamesDuplicates}
                  onClick={() => handleSubmit(submitHandler)()}
                  type="button"
                >
                  <AppFormattedMessage id={StringKey.DONE} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
