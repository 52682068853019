import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { StringKey } from '../../../../../lang';
import { TableHeaderItem } from '../../FormComponents';
import { FormSchema } from '../../validation';
import { SarTableRow } from './SarTableRow';
import { sarColumnMap, sarColumnTitle } from './types';

export type SarProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const Sar: FC<SarProps> = ({ control, setFormData, companyId, resetField }) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const {
    fields: sarItemsFields,
    remove: sarItemsRemove,
    append: appendRsaItem,
  } = useFieldArray({
    control,
    name: 'stepOne.sar.sarItems',
  });

  const handleAppendRsaItem = useCallback(() => {
    appendRsaItem({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        exercisedCount: 0,
        vestedSharesCount: 0,
        grantItem: { plan: { pool: { shareClass: { name: '' } }, sarBasePrice: 0 } },
      },
      issuedShares: 0,
      sarCount: 0,
      sarPayout: 0,
      sharePrice: 0,
      date: new Date(),
    });
  }, [appendRsaItem]);

  const handleRemoveRsaItems = useCallback(
    (index: number) => {
      if (sarItemsFields.length === 1) return;
      sarItemsRemove(index);
    },
    [sarItemsFields.length, sarItemsRemove],
  );

  const calculatePayoutBalance = useCallback(
    (index: number) => {
      const sarCount = stepOne?.sar?.sarItems?.[index]?.sarCount || 0;
      const sarPayout = stepOne?.sar?.sarItems?.[index]?.sarPayout || 0;
      const sharePrice = stepOne?.sar?.sarItems?.[index]?.sharePrice || 0;
      const sarBasePrice =
        stepOne?.sar?.sarItems?.[index]?.grant?.grantItem?.plan?.sarBasePrice || 0;
      const issuedShares = stepOne?.sar?.sarItems?.[index]?.issuedShares || 0;

      const priceDifference = sharePrice - sarBasePrice;
      const balance =
        priceDifference > 0
          ? Math.max(
              (sarCount -
                (issuedShares * sharePrice) / priceDifference -
                sarPayout / priceDifference) *
                priceDifference,
              0,
            )
          : 0;

      return balance;
    },
    [stepOne?.sar?.sarItems],
  );

  const calculateIssuedBalance = useCallback(
    (index: number) => {
      const sarCount = stepOne?.sar?.sarItems?.[index]?.sarCount || 0;
      const sharePrice = stepOne?.sar?.sarItems?.[index]?.sharePrice || 0;
      const sarBasePrice =
        stepOne?.sar?.sarItems?.[index]?.grant?.grantItem?.plan?.sarBasePrice || 0;
      const sarPayout = stepOne?.sar?.sarItems?.[index]?.sarPayout || 0;
      const issuedShares = stepOne?.sar?.sarItems?.[index]?.issuedShares || 0;

      const priceDifference = sharePrice - sarBasePrice;

      const balance =
        priceDifference > 0
          ? Math.max(
              (priceDifference * (sarCount - sarPayout / priceDifference)) / sharePrice -
                issuedShares,
            )
          : 0;

      return balance;
    },
    [stepOne?.sar?.sarItems],
  );

  const calculateBalance = useCallback(
    (index: number) => {
      const vestedShares = stepOne?.sar?.sarItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares = stepOne?.sar?.sarItems?.[index]?.grant?.exercisedCount || 0;
      const sarCount = stepOne?.sar?.sarItems?.[index]?.sarCount || 0;
      const balance = Math.max(vestedShares - exercisedShares - sarCount, 0);

      return balance;
    },
    [stepOne?.sar?.sarItems],
  );

  const handleStakeholderChange = (index: number) =>
    resetField(`stepOne.sar.sarItems.${index}.grant`);

  useEffect(() => {
    if (sarItemsFields.length === 0) {
      handleAppendRsaItem();
    }
  }, [handleAppendRsaItem, sarItemsFields.length]);

  return (
    <>
      <div className="flex w-[1330px] flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <div
          className={twMerge(
            'grid w-full grid-cols-[repeat(9,143.3px)_repeat(1,40px)] rounded-t-lg',
          )}
        >
          {sarColumnMap.map((field, i) => (
            <TableHeaderItem
              key={`${field}_${i}`}
              text={<AppFormattedMessage id={sarColumnTitle[field]} />}
            />
          ))}
        </div>
        {sarItemsFields.map(({ id }, i) => (
          <SarTableRow
            availableIssuedShares={calculateIssuedBalance(i)}
            availableNoOfSar={calculateBalance(i)}
            availableSarPayout={calculatePayoutBalance(i)}
            companyId={companyId}
            control={control}
            handleRemoveRow={() => handleRemoveRsaItems(i)}
            handleStakeholderChange={() => handleStakeholderChange(i)}
            id={id}
            key={id}
            rowIndex={i}
            selectedGrantIds={stepOne?.sar?.sarItems?.map((item) => item?.grant?.id || '')}
            setFormData={setFormData}
            totalItems={sarItemsFields.length}
          />
        ))}
      </div>

      {sarItemsFields.length < 10 && (
        <div className="sticky bottom-[76px] z-10 w-full bg-white">
          <Button
            className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
            onClick={handleAppendRsaItem}
            styleType="NONE"
          >
            + <AppFormattedMessage id={StringKey.ADD_EXERCISING} />
          </Button>
        </div>
      )}
    </>
  );
};
