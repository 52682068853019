import { z } from 'zod';

import { StringKey } from '../../../lang';
import { EventFormType, ValuationSelect } from '../../../types/events.types';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const defaultSharePrice = 1.000000000000001;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];

export const ACCEPTED_GRANT_FILE_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

const grantFileSchema = z
  .custom<File>()
  .refine(
    (files) => ACCEPTED_GRANT_FILE_MIME_TYPES.includes(files?.type),
    getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
  )
  .refine((files) => {
    return files?.size <= MAX_FILE_SIZE;
  }, fileSchemaMessage);

export const subFilesSchema = z.array(
  z.object({
    docLink: z.string(),
    loadProgress: z.number().max(100),
    abort: z.function(),
    id: z.string(),
    doc: z
      .custom<File>()
      .or(
        z.object({
          size: z.number(),
          type: z.string(),
          name: z.string(),
        }),
      )
      .refine(
        ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
        getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
      )
      .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
  }),
);

export const initialStep = z.object({
  type: z.nativeEnum(EventFormType),
  id: z.string().optional(),
});

const grant = z.object({
  enabled: z.boolean(),
  grantItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          grantDate: z.coerce.date(),
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          sharePlan: z.object({
            name: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            pool: z.object({
              id: z
                .string()
                .trim()
                .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            }),
          }),
          shares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
          initialShares: z.coerce.number().optional(),
          initialSharePlanId: z.string().trim().optional(),
          balance: z.coerce.number(),
          files: subFilesSchema.optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: getTranslation(StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE),
            path: ['shares'],
          },
        )
        .refine(
          ({ grantDate }) => {
            return grantDate.getTime() !== new Date(+0).getTime();
          },
          {
            message: 'Invalid grant date',
            path: ['grantDate'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(30, {
      message: getTranslation(StringKey.ITEMS_MAXIMUM, { count: 30 }),
    }),
});

const bulkGrant = z.object({
  enabled: z.boolean(),
  file: grantFileSchema,
});

const fundraisingRoundOne = z
  .object({
    enabled: z.boolean(),
    isOpen: z.boolean(),
    event: z
      .object({
        name: z
          .string()
          .trim()
          .min(1, { message: getTranslation(StringKey.REQUIRED) })
          .max(50, { message: getTranslation(StringKey.CHARACTER_LIMIT_IS_COUNT, { count: 50 }) }),
        id: z
          .string()
          .trim()
          .min(1, { message: getTranslation(StringKey.REQUIRED) }),
        sharePrice: z.coerce
          .number()
          .min(1, { message: getTranslation(StringKey.REQUIRED) })
          .default(defaultSharePrice),
        issuedSharesOnStart: z.coerce.number().default(0),
      })
      .refine(
        ({ sharePrice }) => {
          return sharePrice !== defaultSharePrice;
        },
        {
          message: '',
          path: ['sharePrice'],
        },
      ),
    date: z.coerce.date(),
  })
  .refine(
    ({ date }) => {
      return date.getTime() !== new Date(+0).getTime();
    },
    {
      message: getTranslation(StringKey.INVALID_DATE),
      path: ['date'],
    },
  );

const fundraisingRoundTwo = z
  .object({
    closeDate: z.coerce.date().optional(),
    investmentItems: z
      .array(
        z
          .object({
            id: z.string().optional(),
            sharePrice: z.coerce
              .number()
              .min(0, { message: getTranslation(StringKey.REQUIRED) })
              .default(1),
            stakeholder: z.object({
              fullName: z
                .string()
                .trim()
                .min(1, { message: getTranslation(StringKey.REQUIRED) }),
              id: z
                .string()
                .trim()
                .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            }),
            investment: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
            shareClass: z.object({
              name: z
                .string()
                .trim()
                .min(1, { message: getTranslation(StringKey.REQUIRED) }),
              id: z
                .string()
                .trim()
                .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            }),
            investmentDate: z.coerce.date(),
            files: subFilesSchema.optional(),
          })
          .refine(
            ({ sharePrice, investment }) => {
              const roundedSharePrice = Math.round(sharePrice);
              return investment % roundedSharePrice === 0;
            },
            {
              message: 'Invalid investment',
              path: ['investment'],
            },
          )
          .refine(
            ({ investmentDate }) => {
              return investmentDate.getTime() !== new Date(+0).getTime();
            },
            {
              message: getTranslation(StringKey.INVALID_INVESTMENT_DATE),
              path: ['investmentDate'],
            },
          ),
      )
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  })
  .superRefine((data, ctx) => {
    const { closeDate, investmentItems } = data;

    if (closeDate) {
      investmentItems.forEach((item, index) => {
        if (item.investmentDate > closeDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getTranslation(StringKey.INVESTMENT_DATE_MUST_BE_BEFORE_CLOSE_DATE),
            path: ['investmentItems', index, 'investmentDate'],
          });
        }
      });
    }
  });

const issueSharesOne = z.object({
  enabled: z.boolean(),
  event: z
    .object({
      name: z
        .string()
        .trim()
        .min(1, { message: getTranslation(StringKey.REQUIRED) })
        .max(50, { message: getTranslation(StringKey.CHARACTER_LIMIT_IS_COUNT, { count: 50 }) }),
      id: z
        .string()
        .trim()
        .min(1, { message: getTranslation(StringKey.REQUIRED) }),
      sharePrice: z.coerce
        .number()
        .min(0, { message: getTranslation(StringKey.REQUIRED) })
        .default(defaultSharePrice),
      issuedSharesOnStart: z.coerce.number().default(0),
    })
    .refine(
      ({ sharePrice }) => {
        return sharePrice !== defaultSharePrice;
      },
      {
        message: '',
        path: ['sharePrice'],
      },
    ),
  date: z.coerce.date(),
});

const issueSharesTwo = z.object({
  issuanceItems: z
    .array(
      z.object({
        id: z.string().optional(),
        stakeholder: z.object({
          fullName: z
            .string()
            .trim()
            .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          id: z
            .string()
            .trim()
            .min(1, { message: getTranslation(StringKey.REQUIRED) }),
        }),
        shareClass: z.object({
          name: z
            .string()
            .trim()
            .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          id: z
            .string()
            .trim()
            .min(1, { message: getTranslation(StringKey.REQUIRED) }),
        }),
        shares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      }),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(30, { message: getTranslation(StringKey.ITEMS_MAXIMUM, { count: 30 }) }),
});

const buyBack = z.object({
  enabled: z.boolean(),
  buyBackItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          date: z.coerce.date(),
          balance: z.coerce.number(),
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          shareClass: z.object({
            name: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          shares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
          initialShares: z.coerce.number().optional(),
          initialStakeholderId: z.string().trim().optional(),
          initialShareClassId: z.string().trim().optional(),
          files: subFilesSchema.optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: getTranslation(StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE),
            path: ['shares'],
          },
        )
        .refine(
          ({ date }) => {
            return date.getTime() !== new Date(+0).getTime();
          },
          {
            message: getTranslation(StringKey.INVALID_DATE),
            path: ['date'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(30, { message: getTranslation(StringKey.ITEMS_MAXIMUM, { count: 30 }) }),
});

const secondaries = z.object({
  enabled: z.boolean(),
  secondariesItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          date: z.coerce.date(),
          balance: z.coerce.number(),
          stakeholderFrom: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          stakeholderTo: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          shareClass: z.object({
            name: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          shares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
          shareValue: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
          initialShares: z.coerce.number().optional(),
          initialStakeholderFromId: z.string().trim().optional(),
          initialStakeholderToId: z.string().trim().optional(),
          initialShareClassId: z.string().trim().optional(),
          files: subFilesSchema.optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: getTranslation(StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE),
            path: ['shares'],
          },
        )
        .refine(
          ({ date }) => {
            return date.getTime() !== new Date(+0).getTime();
          },
          {
            message: getTranslation(StringKey.INVALID_DATE),
            path: ['date'],
          },
        )
        .refine(({ stakeholderFrom, stakeholderTo }) => stakeholderFrom.id !== stakeholderTo.id, {
          message: getTranslation(StringKey.STAKEHOLDERS_MUST_BE_DIFFERENT),
          path: ['stakeholderTo', 'id'],
        }),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(30, { message: getTranslation(StringKey.ITEMS_MAXIMUM, { count: 30 }) }),
});

const classConversion = z.object({
  enabled: z.boolean(),
  classConversionItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          date: z.coerce.date(),
          balance: z.coerce.number(),
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          shareClassFrom: z.object({
            name: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            conversionRatio: z.number(),
          }),
          shareClassTo: z.object({
            name: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          shares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
          initialShares: z.coerce.number().optional(),
          initialStakeholderId: z.string().trim().optional(),
          initialShareClassFromId: z.string().trim().optional(),
          initialShareClassToId: z.string().trim().optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: getTranslation(StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE),
            path: ['shares'],
          },
        )
        .refine(
          ({ date }) => {
            return date.getTime() !== new Date(+0).getTime();
          },
          {
            message: getTranslation(StringKey.INVALID_DATE),
            path: ['date'],
          },
        )
        .refine(({ shareClassFrom, shareClassTo }) => shareClassFrom.id !== shareClassTo.id, {
          message: getTranslation(StringKey.SHARE_CLASSES_MUST_BE_DIFFERENT),
          path: ['shareClassTo', 'id'],
        }),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(30, { message: getTranslation(StringKey.ITEMS_MAXIMUM, { count: 30 }) }),
});

const valuation = z.object({
  enabled: z.boolean(),
  name: z
    .string()
    .trim()
    .min(1, { message: getTranslation(StringKey.REQUIRED) })
    .max(50, { message: getTranslation(StringKey.CHARACTER_LIMIT_IS_COUNT, { count: 50 }) }),
  date: z.coerce.date(),
  sharePrice: z.coerce.number().min(0, { message: getTranslation(StringKey.REQUIRED) }),
  issuedSharesOnStart: z.coerce.number().optional(),
  valuationSelect: z.nativeEnum(ValuationSelect).optional(),
  preMoneyValuation: z.coerce.number().optional(),
});

export const stepOne = z.object({
  eventDetails: z.object({
    grant: grant.optional(),
    'bulk-import-grant': bulkGrant.optional(),
    'fundraising-round': fundraisingRoundOne.optional(),
    'share-issuance': issueSharesOne.optional(),
    buyback: buyBack.optional(),
    secondaries: secondaries.optional(),
    conversion: classConversion.optional(),
    valuation: valuation.optional(),
  }),
});

export const optionalStep = z
  .object({
    'fundraising-round': fundraisingRoundTwo.optional(),
    'share-issuance': issueSharesTwo.optional(),
  })
  .optional();

export const stepTwo = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()
      .optional(),
  })
  .optional();

export const formSchema = z.object({
  initialStep,
  optionalStep,
  stepOne,
  stepTwo,
});

export type FormSchema = z.infer<typeof formSchema>;
