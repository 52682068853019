import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { Stakeholder, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import {
  FormDropDown,
  ShareClassCombobox,
  StakeholderCombobox,
  SubEventFileUpload,
} from '../Components';
import { FormSchema } from '../validation';

export type SecondariesStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  formData: UseFormGetValues<FormSchema>;
  setError: UseFormSetError<FormSchema>;
  clearErrors: UseFormClearErrors<FormSchema>;
  errors: FieldErrors<FormSchema>;
};

export const SecondariesStepOne: FC<SecondariesStepOneProps> = ({
  control,
  setFormData,
  companyId,
  formData,
  setError,
  clearErrors,
  errors,
}) => {
  const { initialStep } = useWatch<FormSchema>({ control });

  const defaultDate = new Date(+0);

  const {
    fields: secondariesItemsFields,
    append: secondariesItemsAppend,
    remove: secondariesItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.eventDetails.secondaries.secondariesItems',
  });

  const handleRemoveSecondariesItems = useCallback(
    (index: number) => {
      secondariesItemsRemove(index);
    },
    [secondariesItemsRemove],
  );

  const handleAppendSecondariesItems = useCallback(() => {
    secondariesItemsAppend({
      date: defaultDate,
      balance: 0,
      stakeholderFrom: { id: '', fullName: '' },
      stakeholderTo: { id: '', fullName: '' },
      shareClass: { id: '', name: '' },
      shares: 0,
      shareValue: 0,
    });
  }, [secondariesItemsAppend]);

  useEffect(() => {
    if (initialStep?.type === EventFormType.SECONDARIES && secondariesItemsFields.length === 0) {
      handleAppendSecondariesItems();
    }
  }, [initialStep?.type]);

  return (
    <>
      {secondariesItemsFields.map((field, index) => (
        <SecondariesItem
          clearErrors={clearErrors}
          companyId={companyId}
          control={control}
          errors={errors}
          formData={formData}
          handleRemoveSecondariesItems={handleRemoveSecondariesItems}
          index={index}
          key={field.id}
          setError={setError}
          setFormData={setFormData}
          showRemoveButton={secondariesItemsFields.length > 1}
        />
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        disabled={secondariesItemsFields.length >= 30}
        onClick={handleAppendSecondariesItems}
        styleType="NONE"
      >
        + <AppFormattedMessage id={StringKey.ADD_ANOTHER_SHARE_TRANSFER} />
      </Button>
      <DropDown
        answer="A Secondaries (Share transfer) event involves the transfer of shares from one stakeholder to another."
        className="rounded-lg bg-gray-50"
        question={<AppFormattedMessage id={StringKey.WHAT_IS_SECONDARY} />}
      />
    </>
  );
};

const SecondariesItem: FC<
  SecondariesStepOneProps & {
    index: number;
    handleRemoveSecondariesItems: (index: number) => void;
    showRemoveButton: boolean;
  }
> = ({
  control,
  setFormData,
  companyId,
  index,
  handleRemoveSecondariesItems,
  showRemoveButton,
  clearErrors,
  errors: { stepOne: errors },
  formData,
  setError,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const defaultDate = new Date(+0);

  const stakeholderId =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderFrom?.id || '';
  const shareClassId =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.shareClass?.id || '';
  const initialShares =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.initialShares || undefined;
  const initialStakeholderFromId =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.initialStakeholderFromId;
  const initialStakeholderToId =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.initialStakeholderToId;
  const initialShareClassId =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.initialShareClassId;
  const shouldAddInitialShares =
    initialShares &&
    initialStakeholderFromId === stakeholderId &&
    initialShareClassId === shareClassId;
  const shouldSubtractInitialShares =
    initialShares &&
    initialStakeholderToId === stakeholderId &&
    initialShareClassId === shareClassId;

  const { balance } = Stakeholder.useBalance({ companyId, stakeholderId, shareClassId });

  useEffect(() => {
    if (balance) {
      setFormData(
        `stepOne.eventDetails.secondaries.secondariesItems.${index}.balance`,
        shouldAddInitialShares
          ? Number(balance) + initialShares
          : shouldSubtractInitialShares
            ? Number(balance) - initialShares
            : Number(balance),
      );
    }
  }, [
    balance,
    index,
    initialShares,
    setFormData,
    shouldAddInitialShares,
    shouldSubtractInitialShares,
  ]);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 15 ? `${name.slice(0, 15)}...` : name;
      };

      const stakeholderFrom =
        stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderFrom?.fullName;
      const stakeholderTo =
        stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderTo?.fullName;

      if (stakeholderFrom && stakeholderTo) {
        return `Share Transfer - ${truncateName(stakeholderFrom)} to ${truncateName(stakeholderTo)}`;
      }

      return 'Share Transfer';
    },
    [stepOne?.eventDetails?.secondaries?.secondariesItems],
  );

  const calculateTransferAmount = useCallback(
    (index: number) => {
      const shares = stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.shares;
      const shareValue = stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.shareValue;

      if (shares && shareValue) {
        return Number(shares * shareValue).toLocaleString('en-US');
      }

      return '00';
    },
    [stepOne?.eventDetails?.secondaries?.secondariesItems],
  );

  const calculateBalance = useCallback(
    (index: number) => {
      if (balance) {
        const currentItem = stepOne?.eventDetails?.secondaries?.secondariesItems?.[index];

        if (!currentItem) {
          return Number(balance);
        }

        const filteredItems =
          stepOne?.eventDetails?.secondaries?.secondariesItems &&
          stepOne?.eventDetails?.secondaries?.secondariesItems.filter((_, i) => i !== index);

        if (!filteredItems || filteredItems.length === 0) {
          return Number(balance);
        }

        const matchingItems = filteredItems.filter(
          (item) =>
            item.stakeholderFrom?.id === currentItem.stakeholderFrom?.id &&
            item.shareClass?.id === currentItem.shareClass?.id,
        );

        if (matchingItems.length === 0) {
          return Number(balance);
        }

        const totalShares = matchingItems.reduce((sum, item) => sum + (item.shares || 0), 0);

        return Number(balance - totalShares);
      }
      return 0;
    },
    [balance, stepOne?.eventDetails?.secondaries?.secondariesItems],
  );

  const calculateAvailableShares = useCallback(
    (index: number) => {
      const shares = stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.shares || 0;
      const balance = shouldAddInitialShares
        ? calculateBalance(index) + (initialShares || 0)
        : shouldSubtractInitialShares
          ? calculateBalance(index) - (initialShares || 0)
          : calculateBalance(index);

      return balance - shares;
    },
    [
      calculateBalance,
      initialShares,
      shouldAddInitialShares,
      shouldSubtractInitialShares,
      stepOne?.eventDetails?.secondaries?.secondariesItems,
    ],
  );

  const isStakeholdersDuplicate =
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderFrom?.id &&
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderTo?.id &&
    stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderFrom?.id ===
      stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderTo?.id;

  return (
    <FormDropDown
      handleRemove={() => handleRemoveSecondariesItems(index)}
      showRemoveButton={showRemoveButton}
      title={getFullTitle(index)}
    >
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              {' '}
              <AppFormattedMessage id={StringKey.EVENT_DATE} />
            </span>
            <DatePicker
              defaultMonth={
                stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.date || undefined
              }
              onSelect={(date) =>
                setFormData(`stepOne.eventDetails.secondaries.secondariesItems.${index}.date`, date)
              }
              value={stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.date}
              wrapperClassName="w-[224px]"
            >
              <FormInput
                autoComplete="off"
                control={control}
                customValue={(value) => {
                  return value && new Date(value).getTime() !== defaultDate.getTime()
                    ? format(value, 'dd/MM/yyyy')
                    : '';
                }}
                disabledInput
                icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
                name={`stepOne.eventDetails.secondaries.secondariesItems.${index}.date`}
                placeholder={<AppFormattedMessage id={StringKey.DATE} />}
                wrapperClassName="w-full"
              />
            </DatePicker>
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.FROM_STAKEHOLDER} />
            </span>
            <StakeholderCombobox
              companyId={companyId}
              control={control}
              name={`stepOne.eventDetails.secondaries.secondariesItems.${index}.stakeholderFrom`}
              onlyWithShares
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.TO_STAKEHOLDER} />
            </span>
            <StakeholderCombobox
              companyId={companyId}
              control={control}
              name={`stepOne.eventDetails.secondaries.secondariesItems.${index}.stakeholderTo`}
              stakeholderFromId={
                stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderFrom?.id
              }
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARE_CLASS} />
            </span>
            <ShareClassCombobox
              companyId={companyId}
              control={control}
              fieldName={`stepOne.eventDetails.secondaries.secondariesItems.${index}.shareClass`}
              stakeholderFromId={
                stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.stakeholderFrom?.id
              }
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.NO_OF_SHARES} />
            </span>
            <div className="flex flex-col items-start gap-2">
              <FormInput
                control={control}
                name={`stepOne.eventDetails.secondaries.secondariesItems.${index}.shares`}
                numberOnly
                placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                shouldFormatNumber
                wrapperClassName="w-[224px]"
              />
              {balance && (
                <span className="text-xs text-gray-600">
                  <AppFormattedMessage id={StringKey.AVAILABLE} />{' '}
                  {Math.max(calculateAvailableShares(index), 0).toLocaleString('en-US')}
                </span>
              )}
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARE_VALUE} />
            </span>
            <FormInput
              control={control}
              currencySign
              name={`stepOne.eventDetails.secondaries.secondariesItems.${index}.shareValue`}
              numberOnly
              placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
              shouldFormatNumber
              wrapperClassName="w-[224px]"
            />
          </div>
          {isStakeholdersDuplicate && (
            <div className="flex items-center gap-1">
              <InfoCircle />
              <span className="rounded text-xs font-[450] text-fireside-600">
                <AppFormattedMessage id={StringKey.DIFFERENT_STAKEHOLDERS} />
              </span>
            </div>
          )}
          <div className="h-[1px] w-full bg-gray-100" />
          <div className="flex w-full items-center justify-between py-3">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.TRANSFER_AMOUNT} />
            </span>
            <div>
              <span
                className={twMerge(
                  'rounded px-3 py-1 text-sm font-[550]',
                  calculateTransferAmount(index) === '00'
                    ? 'bg-gray-100 text-gray-300'
                    : 'bg-brand-25 text-brand-700',
                )}
              >
                {calculateTransferAmount(index)}
              </span>
            </div>
          </div>
        </div>
        <SubEventFileUpload
          clearErrors={clearErrors}
          errors={errors?.eventDetails?.secondaries?.secondariesItems?.[index]?.files || undefined}
          field={`stepOne.eventDetails.secondaries.secondariesItems.${index}.files`}
          files={stepOne?.eventDetails?.secondaries?.secondariesItems?.[index]?.files || []}
          formData={formData}
          setError={setError}
          setFormData={setFormData}
          text="secondary"
        />
      </div>
    </FormDropDown>
  );
};
