import { ReactNode } from 'react';

import { ExcelGuide } from '../types';
import { BulkModifyStkaeholders } from './BulkModifyStakeholders';
import { GrantImport } from './GrantImport';
import { ImportSafes } from './ImportSafes';
import { ImportStakeholders } from './ImportStakeholders';

export const guides: Record<
  ExcelGuide,
  { guide: ReactNode; stepsTitles: { title: string }[]; guideTitle: string }
> = {
  [ExcelGuide.IMPORT_GRANTS]: {
    guideTitle: 'Bulk Grant Import guide',
    guide: <GrantImport />,
    stepsTitles: [
      {
        title: 'Open the Bulk Grant Import Blade',
      },
      {
        title: 'Download the Excel Template',
      },
      {
        title: 'Understanding the Excel Template',
      },
      {
        title: 'Fill in the Grant Details',
      },
      {
        title: 'Upload the Filled Template',
      },
    ],
  },
  [ExcelGuide.IMPORT_SAFES]: {
    guideTitle: 'SAFE import guide',
    guide: <ImportSafes />,
    stepsTitles: [
      {
        title: 'Open the SAFE Import Blade',
      },
      {
        title: 'Download the Excel Template',
      },
      {
        title: 'Understanding the Excel Template',
      },
      {
        title: 'Fill in the SAFE Details',
      },
      {
        title: 'Upload the Filled Template',
      },
    ],
  },
  [ExcelGuide.IMPORT_STAKEHOLDERS]: {
    guideTitle: 'Stakeholders Import Guide',
    guide: <ImportStakeholders />,
    stepsTitles: [
      {
        title: 'Open the Import Stakeholders Blade',
      },
      {
        title: 'Select Stakeholder Type',
      },
      {
        title: 'Download the Excel Template',
      },
      {
        title: 'Understanding the Excel File',
      },
      {
        title: 'Fill in the Stakeholders List',
      },
      {
        title: 'Upload the Filled Template',
      },
    ],
  },
  [ExcelGuide.MODIFY_STAKEHOLDERS]: {
    guideTitle: 'Bulk Modify Guide',
    guide: <BulkModifyStkaeholders />,
    stepsTitles: [
      {
        title: 'Open the Bulk Modify Blade',
      },
      {
        title: 'Select Stakeholder Type',
      },
      {
        title: 'Download the Excel Template',
      },
      {
        title: 'Understanding the Excel File',
      },
      {
        title: 'Editing the Excel File',
      },
      {
        title: 'Upload the Updated File',
      },
    ],
  },
};
