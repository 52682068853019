import { useQueryClient } from '@tanstack/react-query';
import { FC, Fragment, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckCircleIcon, CloseIcon, DoubleChevronIcon, MessageIcon } from '../../assets/icons';
import { QueryKey } from '../../constants';
import { useNotifications } from '../../context/notifications.context';
import { Company, NotificationHooks, useModalState } from '../../hooks';
import { StringKey } from '../../lang';
import { NotificationType, notificationTypeMap } from '../../types/notification.types';
import { SharePlanType } from '../../types/pool-plans.types';
import { StakeholderRole } from '../../types/stakeholderTypes';
import { percentage } from '../../utils/percentage';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import { ProgressToolTip } from '../ProgressToolTip';
import { Sheet, SheetContent } from '../Sheet';
import { Switch } from '../Switch';
import { ActivityTab, RequestedTab, ResolvedTab } from './ActivityBarTab';
import { ApproveHurdlePlan, ApproveTargetBasedPlan } from './ApproveModals';
import { ExerciseForm } from './Exercise';
import { GetStarted } from './GetStarted';
import { ActivityType, ActivityVariant, activityVariantMap } from './types';

const activityTabVariant: Record<
  ActivityVariant,
  (props: {
    companyId: string;
    onExerciseRequest: (id: string, type: SharePlanType | undefined) => void;
    unreadOnly: boolean;
    isAllRead: boolean;
    setMilestoneIndex: (index: number | null) => void;
    currencySymbol: string;
    handleCloseModal: () => void;
  }) => ReactNode
> = {
  [NotificationType.ACTIVITY]: (props) => <ActivityTab {...props} />,
  [ActivityType.GET_STARTED]: (props) => <GetStarted {...props} />,
  [NotificationType.REQUEST]: (props) => <RequestedTab {...props} />,
  [ActivityType.RESOLVED]: (props) => <ResolvedTab {...props} />,
};

export const ActivityBar: FC = () => {
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState<ActivityVariant>(NotificationType.ACTIVITY);
  const [unreadOnly, setUnreadOnly] = useState(false);
  const [milestoneIndex, setMilestoneIndex] = useState<number | null>(0);

  const { unreadNotification } = useNotifications();

  const [selectedExercisePlan, setSelectedExercisePlan] = useState<
    | {
        id: string;
        type: SharePlanType;
      }
    | undefined
  >(undefined);

  const { selectedCompanyId, selectedCompany: { roleInCompany, currency } = {} } =
    Company.useSelected();

  const { markAllAsRead } = NotificationHooks.useMarkReadAll({
    companyId: selectedCompanyId,
  });

  const { companyProgress = {} } = Company.useGetStartedProgress({
    companyId: selectedCompanyId,
    enabled: roleInCompany === StakeholderRole.ADMIN,
  });

  const completedStepsMap = Object.values(companyProgress);

  const progress = percentage(
    completedStepsMap.length + 1,
    completedStepsMap.filter((state) => state).length + 1,
  );

  const { isOpen, toggler, handleOpenModal, handleCloseModal } = useModalState('activity-bar');

  const onProgressToolTipClick = () => {
    setSelectedTab(ActivityType.GET_STARTED);
    handleOpenModal();
  };

  return (
    <>
      <ProgressToolTip companyId={selectedCompanyId} onClick={onProgressToolTipClick} />
      <ApproveHurdlePlan />
      <ApproveTargetBasedPlan companyId={selectedCompanyId} milestoneIndex={milestoneIndex} />
      {selectedExercisePlan && (
        <ExerciseForm
          companyId={selectedCompanyId}
          handleClose={() => setSelectedExercisePlan(undefined)}
          invalidateQuery={() => ''}
          isOpen={!!selectedExercisePlan}
          {...selectedExercisePlan}
        />
      )}
      <div
        className="sticky top-0 flex h-full w-14 shrink-0 cursor-pointer flex-col items-center justify-start gap-6 rounded-md bg-white pt-4 shadow-sm max-lg:hidden"
        onClick={handleOpenModal}
      >
        <div className="flex flex-col items-center gap-2">
          <div className="relative h-fit w-fit">
            <MessageIcon />
            {!!unreadNotification.total && (
              <span
                className="absolute -right-1 -top-1 flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-600 text-white"
                style={{
                  fontSize: '9px',
                  fontWeight: '600',
                  lineHeight: '11.2px',
                  textAlign: 'center',
                }}
              >
                {unreadNotification.total}
              </span>
            )}
          </div>
          <span className="text-label-md font-medium text-gray-700">
            <AppFormattedMessage id={StringKey.ACTIVITY} />
          </span>
        </div>
        <DoubleChevronIcon iconColor="#344054" />
      </div>

      <Sheet modal={false} onOpenChange={toggler} open={isOpen}>
        <SheetContent
          className="flex h-full w-full max-w-[550px] gap-0 border-transparent bg-transparent p-0 pb-2 pr-2 shadow-none lg:pt-[4%]"
          side="RIGHT"
        >
          <div
            className="flex h-full w-full flex-col rounded-lg border border-gray-200 bg-white"
            style={{
              boxShadow: '-8px 0px 8px -4px #10182808',
            }}
          >
            <header className="flex flex-col divide-y divide-gray-200 border-b">
              <div className="flex items-center justify-between px-4 py-3">
                <span className="text-base font-semibold text-gray-700">
                  <AppFormattedMessage id={StringKey.ACTIVITY_CENTER} />
                </span>
                <div className="flex h-8 w-fit items-center gap-2 text-label-md font-medium text-gray-600">
                  <Button
                    className="flex gap-2 border border-gray-100 px-2 py-[7px] shadow-xs"
                    onClick={() => {
                      queryClient.invalidateQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
                      setUnreadOnly((prev) => !prev);
                    }}
                    styleType="DEFAULT_ROUNDED"
                  >
                    <AppFormattedMessage id={StringKey.UNREAD_ONLY} />
                    <Switch checked={unreadOnly} />
                  </Button>
                  <Button
                    className="flex gap-2 border border-gray-100 px-2 py-[7px] shadow-xs"
                    onClick={() => markAllAsRead()}
                    styleType="DEFAULT_ROUNDED"
                  >
                    <CheckCircleIcon className="size-4" />
                    <span className="max-lg:hidden">
                      <AppFormattedMessage id={StringKey.MARK_ALL_AS_READ} />
                    </span>
                  </Button>
                  <Button
                    className="flex size-8 gap-2 rounded-full border border-gray-100 shadow-xs"
                    onClick={handleCloseModal}
                    styleType="NONE"
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </div>
              <section className="flex w-full overflow-x-auto pt-4">
                {roleInCompany === StakeholderRole.STAKEHOLDER ? (
                  <Button
                    className="w-fit items-center gap-2 rounded-none border-b-2 border-brand-700 px-5 pb-4 text-sm font-[550] text-brand-700"
                    styleType="NONE"
                  >
                    <AppFormattedMessage id={StringKey.ACTIVITY} />
                    {notificationTypeMap.includes(NotificationType.ACTIVITY) &&
                      !!unreadNotification[NotificationType.ACTIVITY] && (
                        <span
                          className="centered flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-600 text-white"
                          style={{
                            fontSize: '9px',
                            fontWeight: '600',
                            lineHeight: '11.2px',
                            textAlign: 'center',
                          }}
                        >
                          {unreadNotification[NotificationType.ACTIVITY]}
                        </span>
                      )}
                  </Button>
                ) : (
                  <>
                    {activityVariantMap.map(([key, title]) => {
                      if (key === ActivityType.GET_STARTED && progress === 100)
                        return <Fragment key={key} />;

                      return (
                        <Button
                          className={twMerge(
                            'w-fit items-center gap-2 rounded-none px-5 pb-4 text-sm font-[450] text-gray-400',
                            selectedTab === key &&
                              'border-b-2 border-brand-700 font-[550] text-brand-700',
                          )}
                          key={key}
                          onClick={() => setSelectedTab(key)}
                          styleType="NONE"
                        >
                          <AppFormattedMessage id={title} />
                          {notificationTypeMap.includes(key as NotificationType) &&
                            !!unreadNotification[key as NotificationType] && (
                              <span
                                className="centered flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-600 text-white"
                                style={{
                                  fontSize: '9px',
                                  fontWeight: '600',
                                  lineHeight: '11.2px',
                                  textAlign: 'center',
                                }}
                              >
                                {unreadNotification[key as NotificationType]}
                              </span>
                            )}
                        </Button>
                      );
                    })}
                  </>
                )}
              </section>
            </header>
            <section
              className="flex flex-col gap-4 overflow-y-auto p-4"
              id="scroll-notifications-target"
            >
              {activityTabVariant[selectedTab]({
                companyId: selectedCompanyId,
                setMilestoneIndex: setMilestoneIndex,
                onExerciseRequest: (id, type) => type && setSelectedExercisePlan({ id, type }),
                unreadOnly,
                isAllRead: unreadNotification.total === 0,
                currencySymbol: currency?.symbol || '',
                handleCloseModal,
              })}
            </section>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};
